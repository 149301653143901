import { FC } from 'react';
import { GameListItemSkeleton } from '@entities/games';

export type LoadingListProps = {
  length: number;
};

export const LoadingList: FC<LoadingListProps> = ({ length }) => {
  return Array(length)
    .fill(null)
    .map((_, i) => (
      <GameListItemSkeleton key={i}/>
    ));
};
