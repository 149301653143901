import {
  FC,
  ReactNode
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Person as PersonIcon,
  Chat as ChatIcon,
  Info as InfoIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  Box,
  Drawer,
  Divider,
  Stack,
  Typography,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  List,
  ListItemButton,
  ListItemDecorator
} from '@shared/ui';
import {
  isEmpty,
  useModalQueryParam
} from '@shared/lib';
import {
  resetStore
} from '@shared/store-reset';
import {
  useLayoutSelector,
  selectSidebarMenuBanner,
  selectSidebarMenuLogo,
  SidebarMenuBanner,
} from '@entities/page-layout';
import {
  useLazySignOutQuery,
  selectIsAuthenticated,
  useSessionSelector,
  clearAuthTokenCookieSetStatusCookieAndCleanup
} from '@entities/session';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  useLiveChat
} from '@features/contact-live-support';
import {
  useSidebarMenuSelector,
  selectIsOpenSidebarMenu,
  selectMenuItems,
  closeSidebarMenu,
} from '../model';
import {
  SidebarMenuHeader
} from './sidebar-menu-header.component';
import {
  VipProgressBar
} from './vip-progress-bar.component';
import {
  BalancesButtons
} from './balances-buttons.component';

export type SidebarMenuProps = {
  notificationsSidebarButtonSlot: ReactNode;
};

export const SidebarMenu: FC<SidebarMenuProps> = ({
  notificationsSidebarButtonSlot
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(490));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = useModalQueryParam();
  const liveChat = useLiveChat();
  const [signOut, { isFetching }] = useLazySignOutQuery();
  const { sendDataToGtm } = useSendDataToGtm();

  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const isOpen = useSidebarMenuSelector(selectIsOpenSidebarMenu);
  const sidebarMenuBannerSrc = useLayoutSelector(selectSidebarMenuBanner);
  const sidebarMenuLogoSrc = useLayoutSelector(selectSidebarMenuLogo);
  const sidebarMenuItems = useSelector(selectMenuItems);

  const navigateTo = (slug: string): void => {
    dispatch(closeSidebarMenu());
    navigate(slug);
  };

  const handleClose = (): void => {
    dispatch(closeSidebarMenu());
  };

  const handleNavigateTo = (slug: Url) => (): void => {
    if (slug === '?redeem')
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_click',
          paymentMethod: 'Trustly'
        }
      });
    navigateTo(slug);
    dispatch(closeSidebarMenu());
  };

  const handleSignOut = async (): Promise<void> => {
    navigateTo('/?login=true'); // Do not move this line! It will break the navigation logic
    await signOut();
    liveChat?.logoutChat();
    clearAuthTokenCookieSetStatusCookieAndCleanup();
    dispatch(resetStore());
  };

  const handleContactLiveSupport = (): void => {
    openModal('chat');
    dispatch(closeSidebarMenu());
  };

  return (
    <Drawer
      open={isOpen}
      sx={{ zIndex: 15 }}
      slotProps={{
        content: {
          sx: ({ breakpoints, palette }) => ({
            width: 390,
            backgroundColor: palette.common[475],
            [breakpoints.down(491)]: {
              backgroundColor: palette.common[900],
              width: '100%',
            }
          }),
        },
      }}
      onClose={handleClose}>
      <SidebarMenuHeader
        isAuthenticated={isAuthenticated}
        sidebarMenuLogoSrc={sidebarMenuLogoSrc}
        notificationsSidebarButtonSlot={notificationsSidebarButtonSlot}
        onClose={handleClose}
      />
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          gap: 2,
          paddingBlock: 3,
          paddingInline: 2,
          [breakpoints.down(491)]: {
            paddingTop: 2,
            gap: 2,
          }
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            gap: 2,
            [breakpoints.down(491)]: {
              gap: 2,
            }
          })}>
          {isAuthenticated ? (
            <>
              <VipProgressBar />
              <BalancesButtons />
            </>
          ) : (
            sidebarMenuBannerSrc && <SidebarMenuBanner imgSrc={sidebarMenuBannerSrc} />
          )}
          {isAuthenticated && <Divider />}
          <List
            size='lg'
            sx={{
              gap: 0.25,
              paddingBlock: 0,
            }}>
            {sidebarMenuItems.map(({ id, text, slug, iconSrc }) => (
              <ListItemButton
                disabled={isFetching}
                key={id}
                variant={isMobile ? 'plain' : 'soft'}
                onClick={handleNavigateTo(slug)}
                sx={({ palette, breakpoints }) => ({
                  gap: 1,
                  [breakpoints.down(491)]: {
                    backgroundColor: palette.common[475]
                  }
                })}>
                <ListItemDecorator>
                  <Box
                    component='img'
                    loading='lazy'
                    src={iconSrc}
                    sx={{ width: 22 }}
                  />
                </ListItemDecorator>
                <Typography sx={({ palette }) => ({
                  fontSize: 16,
                  color: palette.common.white,
                })}>
                  {text}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Stack>
        {!isEmpty(sidebarMenuItems) && <Divider />}
        <List
          size='lg'
          sx={{
            gap: 0.25,
            paddingBlock: 0,
          }}>
          {isAuthenticated && (
            <ListItemButton
              disabled={isFetching}
              onClick={handleNavigateTo('/account')}
              variant={isMobile ? 'plain' : 'soft'}
              sx={({ palette, breakpoints }) => ({
                gap: 1,
                [breakpoints.down(491)]: {
                  backgroundColor: palette.common[475]
                }
              })}>
              <ListItemDecorator>
                <PersonIcon sx={{ fontSize: 22 }} />
              </ListItemDecorator>
              <Typography sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common[150],
              })}>
                Account
              </Typography>
            </ListItemButton>
          )}
          <ListItemButton
            disabled={isFetching}
            onClick={handleNavigateTo('/how-it-works')}
            variant={isMobile ? 'plain' : 'soft'}
            sx={({ palette, breakpoints }) => ({
              gap: 1,
              [breakpoints.down(491)]: {
                backgroundColor: palette.common[475]
              }
            })}>
            <ListItemDecorator>
              <InfoIcon sx={{ fontSize: 22 }} />
            </ListItemDecorator>
            <Typography sx={({ palette }) => ({
              fontSize: 16,
              color: palette.common[150],
            })}>
              How it works
            </Typography>
          </ListItemButton>
          <ListItemButton
            disabled={isFetching}
            sx={({ palette, breakpoints }) => ({
              gap: 1,
              [breakpoints.down(491)]: {
                backgroundColor: palette.common[475]
              }
            })}
            variant={isMobile ? 'plain' : 'soft'}
            onClick={handleContactLiveSupport}>
            <ListItemDecorator>
              <ChatIcon sx={{ fontSize: 22 }} />
            </ListItemDecorator>
            <Typography sx={({ palette }) => ({
              fontSize: 16,
              color: palette.common[150],
            })}>
              Live Support
            </Typography>
          </ListItemButton>
          {isAuthenticated && (
            <ListItemButton
              disabled={isFetching}
              variant='plain'
              sx={{ gap: 1 }}
              onClick={handleSignOut}>
              <ListItemDecorator>
                <LogoutIcon sx={{ fontSize: 22 }} />
              </ListItemDecorator>
              <Typography sx={({ palette }) => ({
                fontSize: 16,
                color: palette.common[150],
              })}>
                Log out
              </Typography>
            </ListItemButton>
          )}
        </List>
      </Stack>
    </Drawer>
  );
};
