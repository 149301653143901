import { styled } from '@mui/joy';
import { LinkButton } from '@shared/ui';

export const SlideAction = styled(LinkButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '19%',
  transform: 'translateX(-50%)',
  left: '50%',
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '2.25rem',
  paddingBlock: '0.75rem',
  paddingInline: '3.375rem',
  textTransform: 'uppercase',
  [theme.breakpoints.down(928)]: {
    fontSize: '0.875rem',
    paddingBlock: '0.3rem',
    paddingInline: '2.375rem',
  },
  [theme.breakpoints.down(768)]: {
    fontSize: '0.875rem',
    lineHeight: '0.5rem',
    paddingBlock: '0',
    paddingInline: '0.9375rem',
    bottom: '22%',
    left: '50%',
    minHeight: '2.25rem',
  },
}));
