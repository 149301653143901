import {
  FC,
  ChangeEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  isEmpty,
} from '@shared/lib';
import {
  Pagination
} from '@mui/material';
import {
  DatePicker,
  useToaster
} from '@shared/ui';
import {
  useGetTransactionsQuery,
  useTransactionsSelector,
  selectTransactions,
  selectPagination,
  moveToPage,
  resetPagination,
  TransactionTable
} from '@entities/transactions';
import {
  selectTransactionType,
  selectSessionKeyByTransactionType,
  selectCurrentTransactionTypeWithCurrency,
  useTransactionFilterByTypeSelector,
  TransactionFilterByTypeDropdown,
  selectCurrencyType
} from '@features/transaction-filter-by-type';
import {
  selectStartDate,
  selectEndDate,
  useTransactionListSelector,
  setStartDate,
  setEndDate,
  resetTransactionList,
} from '../model';

import dayjs, { Dayjs } from 'dayjs';

type DateType = 'start' | 'end';

export const TransactionList: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));

  const toaster = useToaster();
  const dispatch = useDispatch();

  const sessionKey = useSelector(selectSessionKeyByTransactionType);
  const transactionType = useTransactionFilterByTypeSelector(selectTransactionType);
  const currencyType = useTransactionFilterByTypeSelector(selectCurrencyType);
  const currentTransactionTypeWithCurrency = useTransactionFilterByTypeSelector(
    selectCurrentTransactionTypeWithCurrency
  );

  const pagination = useTransactionsSelector(selectPagination);
  const transactions = useTransactionsSelector(selectTransactions);

  const startDate = useTransactionListSelector(selectStartDate);
  const endDate = useTransactionListSelector(selectEndDate);

  const { isFetching, refetch } = useGetTransactionsQuery({
    tranType: transactionType,
    sessionKey: sessionKey ?? '',
    startDateTime: `${dayjs(startDate).format('YYYY-MM-DD')}T00:00:00Z`,
    endDateTime: `${dayjs(endDate).format('YYYY-MM-DD')}T23:59:59Z`,
    pageNum: pagination.page,
    pageSize: pagination.pageSize,
    currency: currencyType
  });

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  useEffect(() => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);

    return () => {
      dispatch(resetTransactionList());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
  }, [
    startDate,
    endDate,
    pagination,
    currentTransactionTypeWithCurrency,
    refetch
  ]);

  useEffect(() => {
    dispatch(resetPagination());
  }, [dispatch]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<any>, page: number) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(moveToPage(page));
    }, [dispatch]);


  const handleDateChange = (type: DateType, date: Maybe<Dayjs>): void => {
    if (!date) return;

    if (type === 'start') {
      if (date.isSame(dayjs(endDate)) || date.isBefore(dayjs(endDate))) {
        setLocalStartDate(date.toISOString());
        dispatch(setStartDate(date.toISOString()));
      } else {
        dispatch(setStartDate(date.toISOString()));
        setTimeout(() => dispatch(setStartDate(dayjs(localStartDate).toISOString())), 0);
        toaster.error({
          message: 'Start date must be before the end date',
          autoHideDuration: 2000
        });
      }
    } else if (type === 'end') {
      if (date.isSame(dayjs(startDate)) || date.isAfter(dayjs(startDate))) {
        setLocalEndDate(date.toISOString());
        dispatch(setEndDate(date.toISOString()));
      } else {
        dispatch(setEndDate(date.toISOString()));
        setTimeout(() => dispatch(setStartDate(localEndDate)), 0);
        toaster.error({
          message: 'End date must be after the start date',
          autoHideDuration: 2000
        });
      }
    }
  };

  const handleTransactionFilterChanged = (): void => {
    dispatch(resetPagination());
  };

  return (
    <Stack
      direction='column'
      width='100%'>
      <Stack
        direction='row'
        gap={1.5}
        sx={({ breakpoints }) => ({
          mb: 1.5,
          [breakpoints.down(1024)]: {
            flexDirection: 'column',
            mb: 3,
          },
          '& .MuiFormControl-root.MuiFormControl-sizeMd': {
            height: 66,
            minHeight: 'unset',
          }
        })}>
        <TransactionFilterByTypeDropdown
          disabled={isFetching}
          onChange={handleTransactionFilterChanged}
        />
        <DatePicker
          disabled={isFetching}
          label='Start Date'
          views={['year', 'month', 'day']}
          value={dayjs(startDate)}
          onChange={newValue => handleDateChange('start', dayjs(newValue))}
          sx={({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          })}
        />
        <DatePicker
          label='End Date'
          disabled={isFetching}
          views={['year', 'month', 'day']}
          value={dayjs(endDate)}
          onChange={newValue => handleDateChange('end', dayjs(newValue))}
          sx={({ breakpoints }) => ({
            width: 189,
            minHeight: 'unset',
            [breakpoints.down(1024)]: {
              width: '100%'
            }
          })}
        />
      </Stack>
      <TransactionTable transactions={transactions} />
      <Stack
        direction='column'
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '100%',
          marginBlockStart: 3,
        }}>
        {!isEmpty(pagination.total) && (
          <Pagination
            size={isMobile ? 'small' : 'medium'}
            count={pagination?.pageCount}
            page={pagination?.page}
            defaultPage={1}
            onChange={handlePageChange}
          />
        )}
      </Stack>
    </Stack>
  );
};