import {
  FC
} from 'react';
import ReactDOM from 'react-dom';
import {
  Stack
} from '@mui/joy';
import {
  selectShowLoader,
  useCircularLoaderSelector
} from '../model';
import {
  SimpleCircularLoader
} from './simple-circular-loader.component';

export const CircularLoader: FC = () => {
  const loaderRoot = document.getElementById('loader-root');
  const showLoader = useCircularLoaderSelector(selectShowLoader);
  return showLoader && loaderRoot && ReactDOM.createPortal((
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        zIndex: 100000000,
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}>
      <SimpleCircularLoader />
    </Stack>
  ), loaderRoot);
};