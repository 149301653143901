import {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  SwiperSlide,
} from 'swiper/react';
import {
  Navigation
} from 'swiper/modules';
import {
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  GameListItem,
  type GamesItem,
} from '@entities/games';
import {
  FavoriteGameSwitcher
} from '@features/favorite-game-switcher';
import {
  LaunchGame
} from '@features/launch-game';
import {
  LoadingList
} from './loading-list.component';
import {
  QualifyingGamesHeaderSkeleton
} from './qualifying-games-header-skeleton.component';
import {
  QualifyingGamesHeader
} from './qualifying-games-header';
import {
  Swiper
} from './swiper.component';

export type QualifyingGamesBlockProps = {
  isGamesLoading: boolean;
  games: Maybe<GamesItem[]>;
};

export const QualifyingGamesBlock: FC<QualifyingGamesBlockProps> = ({
  games,
  isGamesLoading
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.between(1024, 913));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1349));

  const [showSlider, setShowSlider] = useState<boolean>(false);
  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const slidesPerView: number = useMemo(
    () => isDesktop ? 6 : isSmallDesktop ? 4 : 5, [isDesktop, isSmallDesktop]
  );

  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    }, 0);
  }, []);

  return (
    <Stack
      direction='column'
      gap={isMobile ? 4 : 5.25}>
      {games && !!games.length && (
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            height: '100%',
            width: '100%',
            gap: 2.5,
            [breakpoints.down(912)]: {
              gap: 1.25
            }
          })}>
          {isGamesLoading ? (
            <QualifyingGamesHeaderSkeleton />
          ) : (
            <QualifyingGamesHeader
              navigationNextRef={navigationNextRef}
              navigationPrevRef={navigationPrevRef}
            />
          )}
          <Stack direction='column'>
            {isGamesLoading && <LoadingList length={isMobile ? 6 : slidesPerView} />}
            {!isGamesLoading && showSlider && (
              <Swiper
                slidesPerView={isMobile ? 'auto' : slidesPerView}
                spaceBetween={isMobile ? 8 : 16}
                modules={[Navigation]}
                navigation={{
                  enabled: true,
                  nextEl: navigationNextRef.current,
                  prevEl: navigationPrevRef.current,
                }}>
                {games?.map((game: GamesItem) => (
                  <SwiperSlide key={game.id}>
                    <GameListItem
                      key={game.id}
                      game={game}
                      favoriteSwitcherSlot={
                        <FavoriteGameSwitcher game={game} />
                      }
                      launchGameSlot={
                        <LaunchGame id={game.id} />
                      }
                      bonusFeature
                      volatility='Hight'
                      rtp='98%'
                      sx={({ breakpoints }) => ({
                        width: '160px',
                        height: '120px',
                        [breakpoints.up(911)]: {
                          width: '100%',
                        },
                        [breakpoints.down(390)]: {
                          width: '160px',
                          height: '120px',
                        },
                      })}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
