import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/joy';
import { AvatarProps } from '@shared/ui';
import { selectCurrentVIPLevel, useSessionSelector } from '../model';
import { mapLevelImage } from '../lib';
import { WithPullingPlayerInfo } from './with-pulling-player-info.component';

export type LoggedUserVipLevelProps = Pick<AvatarProps, 'size'> & {
  onClick?: () => void;
};

export const LoggedUserVipLevel: FC<LoggedUserVipLevelProps> = ({
  size,
  onClick
}) => {
  const currentVIPLevel = useSessionSelector(selectCurrentVIPLevel);
  const imgSrc = useMemo(() => mapLevelImage(currentVIPLevel), [currentVIPLevel])

  const navigate = useNavigate();

  const handleNavigateToProfile = (): void => {
    onClick?.();
    navigate('/account');
  };

  return (
    <WithPullingPlayerInfo>
      {currentVIPLevel && (
        <Stack
          alignItems='center'
          justifyContent='center'
          onClick={handleNavigateToProfile}
          sx={{
            ':hover': {
              cursor: 'pointer'
            }
          }}>
          <Box
            component='img'
            loading='lazy'
            src={imgSrc}
            sx={{
              width: 32,
              aspectRatio: '1/1',
              ...(size === 'md' && ({ width: 40 })),
              ...(size === 'sm' && ({ width: 32 })),
              ...(size === 'xs' && ({ width: 26 })),
            }}
          />
        </Stack>
      )}
    </WithPullingPlayerInfo>
  );
};