import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { selectUserStatus } from '@entities/session';
import { GamesItem } from '@entities/games';
import { promotionDetailsPageSlice } from './slice';
import {
  type PromotionDetailsPageUserCarouselSliderItem,
  type PromotionDetailsPageState,
  type ActionItem,
  type HowItWorksBlock,
  type FaqBlock
} from './types';

type RootState = {
  [promotionDetailsPageSlice.name]: PromotionDetailsPageState;
};

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.description
);

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.keywords
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[promotionDetailsPageSlice.name]?.canonical
);

export const selectHowItWorksBlock = (state: RootState): Maybe<HowItWorksBlock> => (
  state[promotionDetailsPageSlice.name]?.howItWorksBlock
);

export const selectFaqBlock = (state: RootState): Maybe<Exclude<FaqBlock, 'title'>> => (
  state[promotionDetailsPageSlice.name]?.faqBlock
);

export const selectGames = (state: RootState): Maybe<Array<GamesItem>> => (
  state[promotionDetailsPageSlice.name]?.games
)

export const selectAction = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<ActionItem>> => (
    state[promotionDetailsPageSlice.name]?.action
  ),
  (userStatus, action) => {
    const _action = action?.find(actionItem => actionItem.userState === userStatus);
    return _action;
  },
  {
    devModeChecks: { identityFunctionCheck: 'never' }
  }
);

export const selectSlider = createSelector(
  selectUserStatus,
  (state: RootState) => (
    state[promotionDetailsPageSlice.name]?.slider
  ),
  (userState, slider) => {
    const _slider = slider as unknown as Maybe<Array<PromotionDetailsPageUserCarouselSliderItem & { actions: Array<ActionItem>, }>>;
    const sliderWithActionByUserStatus = _slider?.map(({ actions, ...props }) => {
      const actionByUserStatus = actions?.find(actionItem => actionItem.userState === userState);
      return ({ ...props, action: actionByUserStatus });
    });
    return sliderWithActionByUserStatus;
  },
  {
    devModeChecks: { identityFunctionCheck: 'never' }
  }
);

export const usePromotionDetailsPageSelector: TypedUseSelectorHook<{
  [promotionDetailsPageSlice.name]: PromotionDetailsPageState;
}> = useSelector;

