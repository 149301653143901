import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import { Box } from '@mui/joy';
import { useTheme, useMediaQuery } from '@mui/material';
import { SlideAction } from './slide-action.component';
import { SlideImage } from './slide-image.component';
import { Swiper } from './swiper.component';

export type Banner = {
  id: number;
  variant1ImgSrc: Url;
  variant2ImgSrc: Url;
  action: Maybe<{
    url: Url;
    text: string;
  }>;
  bannerUrl?: Url;
  isVariant1ItemLooped: boolean;
  isVariant2ItemLooped: boolean;
};

export type CarouselSlideProps = {
  items: Banner[];
};

const pagination = {
  clickable: true,
  renderBullet: (_: number, className: string) => `<span class='${className}'></span>`
};

export const CarouselSlider: FC<CarouselSlideProps> = ({ items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  return (
    <Swiper
      pagination={pagination}
      modules={[Pagination]}>
      {items?.map((item) => (
        <SwiperSlide
          key={item.id}>
          <Box
            to={item.bannerUrl}
            component={
              item.bannerUrl ? Link : 'div'
            }>
            <SlideImage
              src={
                isMobile
                  ? item.variant1ImgSrc
                  : item.variant2ImgSrc
              }
              isLooped={
                isMobile
                  ? item.isVariant1ItemLooped
                  : item.isVariant2ItemLooped
              }
            />
          </Box>
          {item?.action && (
            <SlideAction
              size='lg'
              variant='solid'
              color='success'
              href={item.action.url}>
              {item.action.text}
            </SlideAction>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
