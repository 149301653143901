import { createSelector } from '@reduxjs/toolkit';
import { selectLoyaltyStatus, type LoyaltyStatus } from '@entities/session';

const levelRanges = [
  { min: 0, max: 1250 }, // Bronze to Silver
  { min: 1251, max: 5000 }, // Silver to Gold
  { min: 5001, max: 25000 }, // Gold to Ruby
  { min: 25001, max: 100000 }, // Ruby to Emerald
  { min: 100001, max: 375000 }, // Emerald to Sapphire
  { min: 375001, max: 2500000 }, // Sapphire to Diamond
  { min: 2500001, max: 18750000 } // Diamond to Platinum
];

export const selectCurrentLoyaltyProgress = createSelector(
  selectLoyaltyStatus,
  (loyaltyStatus: Maybe<LoyaltyStatus>) => {
    const accumulatedLoyaltyPoints = loyaltyStatus?.accumulatedLoyaltyPoint ?? 0;
    let progress = 0;

    const currentRange = levelRanges.find(range => accumulatedLoyaltyPoints >= range.min && accumulatedLoyaltyPoints < range.max);
    if (currentRange) {
      progress = ((accumulatedLoyaltyPoints - currentRange.min) / (currentRange.max - currentRange.min)) * 100;
    }
    return progress;
  }
);
