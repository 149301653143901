import { ComponentType } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const withGoogleAuth = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <WrappedComponent {...props} />
    </GoogleOAuthProvider>
  );