import { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  Badge,
  IconButton,
  useTheme
} from '@mui/joy';
import {
  Notifications as NotificationsIcon
} from '@mui/icons-material';
import {
  openNotificationsSidebar,
  useNotificationsSidebarSelector,
  selectIsAnyUnreadMessage
} from '../model';

export type NotificationsSidebarButtonProps = {
  onClick?(): void;
};

export const NotificationsSidebarButton: FC<NotificationsSidebarButtonProps> = ({
  onClick
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isAnyUnreadMessage = useNotificationsSidebarSelector(selectIsAnyUnreadMessage);

  const handleClick = (): void => {
    dispatch(openNotificationsSidebar());
    onClick?.();
  };

  return (
    <Badge
      size='sm'
      badgeInset='35%'
      badgeContent={isAnyUnreadMessage ? <></> : null}
      color='danger'
      sx={{
        '.MuiBadge-badge': {
          minWidth: 6,
          minHeight: 6,
          padding: 0,
          boxShadow: `0 0 0 1px ${theme.palette.common[475]}`,
        },
      }}>
      <IconButton
        size='sm'
        onClick={handleClick}
        sx={{
          height: 'min-content',
        }}>
        <NotificationsIcon
          sx={{
            fontSize: 24,
            color: theme.palette.common[1065]
          }}
        />
      </IconButton>
    </Badge>
  );
};
