import { FC } from 'react';
import { Stack } from '@mui/joy';
import { isDefined } from '@shared/lib';
import {
  BoxedFaqBlock,
  FaqBlock
} from '@widgets/faq-block';
import {
  selectFaqContent,
  useFaqPageContentSelector,
} from '../model';

export const FaqContent: FC = () => {
  const faqContent = useFaqPageContentSelector(selectFaqContent);
  return isDefined(faqContent) && (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 5,
        paddingBlockEnd: 8,
        gap: 8,
        [breakpoints.down(1350)]: {
          paddingInline: 2,
        },
        [breakpoints.down(769)]: {
          paddingBlockStart: 5,
          paddingBlockEnd: 3,
          gap: 5,
        },
      })}>
      <BoxedFaqBlock
        title={faqContent?.boxedFaqBlock?.title}
        items={faqContent?.boxedFaqBlock?.items}
      />
      <FaqBlock
        title={faqContent?.faqBlock?.title}
        items={faqContent?.faqBlock?.items}
      />
    </Stack>
  );
};