export type GetFileNameOptions = {
  returnValueIfEmpty: string;
};

export const getFileName = (file: Maybe<File>, options: GetFileNameOptions): string => {

  if (!file) return options.returnValueIfEmpty;
  if (file.name.length <= 28) return file.name;

  let name = file.name.slice(0, file.name.lastIndexOf('.'));
  let extension = file.name.slice(file.name.lastIndexOf('.'));

  return name.slice(0, 15) + '...' + name.slice(name.length - 6) + extension;
};