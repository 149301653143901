import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { closeFullScreen, openInFullScreen } from '../model';

type EscapeListenerProps = {
  children: ReactNode;
};

export const FullScreenListener: FC<EscapeListenerProps> = ({ children }) => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const exitHandler = useCallback(() => {
      if (!document.fullscreenElement) {
        dispatch(closeFullScreen());
      } else {
        dispatch(openInFullScreen());
      }
  }, [dispatch]);
  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler, false);
    };
  }, [exitHandler]);
  return children;
};
