import { FC, PropsWithChildren } from 'react';
import { Stack } from '@mui/joy';

export const TournamentsLayout: FC<PropsWithChildren> = ({ children }) => (
  <Stack
    sx={({ breakpoints }) => ({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 3,
      [breakpoints.down(912)]: {
        gridTemplateColumns: '1fr 1fr',
        gap: 2,
      },
      [breakpoints.down(491)]: {
        gridTemplateColumns: '1fr',
        gap: 2,
      },
    })}>
    {children}
  </Stack>
);