import {
  FC,
  useMemo
} from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  useMediaQuery,
  useTheme as useMaterialTheme,
} from '@mui/material';
import {
  AccessTime,
  Check,
  Description,
  Close,
  RemoveRedEyeOutlined
} from '@mui/icons-material';
import {
  isEmpty
} from '@shared/lib';
import {
  getDocumentLabelByStatus
} from '../lib';
import {
  type UserDocument,
  type DocumentType,
  selectDocumentTypes,
  selectUserDocuments,
  useDocumentsSelector,
  UserDocumentStatusEnum,
} from '../model';

export const UserDocumentsList: FC = () => {
  const joyTheme = useJoyTheme();
  const materialTheme = useMaterialTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down(767));

  const documentTypes = useDocumentsSelector(selectDocumentTypes);
  const userDocuments = useDocumentsSelector(selectUserDocuments);

  const prettifyFileName = (document: UserDocument): string => {
    return document.fileName
      .slice(document.fileName.indexOf(document.documentType)
        + document.documentType.length + 1);
  };

  const groupedUserDocuments: Record<string, Array<UserDocument>> = useMemo(() => {
    const documents: Record<string, Array<UserDocument>> = {};
    documentTypes.forEach((documentType: DocumentType) => {
      const documentsInType: Array<UserDocument> = userDocuments
        .filter((doc: UserDocument) => doc.documentType === documentType.documentType);
      if (!isEmpty(documentsInType))
        documents[documentType.documentType] = documentsInType;
    });
    return documents;
  }, [documentTypes, userDocuments]);

  return (
    <Stack
      component='aside'
      direction='column'
      width='100%'
      gap={2}>
      {!isEmpty(userDocuments) && (
        <>
          {Object
            .keys(groupedUserDocuments)
            .map((key: string) => (
              <Stack
                key={key}
                sx={({ palette }) => ({
                  padding: '12px',
                  gap: 1.5,
                  alignItems: 'start',
                  backgroundColor: palette.common[475],
                  borderRadius: 10,
                })}>
                <Typography
                  sx={({ palette }) => ({
                    textAlign: 'center',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '18px',
                    color: palette.common.white,
                  })}>
                  {key.replace('_', ' ').replace('_', ' ')}
                </Typography>

                {groupedUserDocuments[key]
                  .map((
                    document: UserDocument,
                    index: number
                  ) => (
                    <Stack
                      key={index}
                      sx={{
                        width: '100%',
                        gap: 1.5,
                      }}>
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                          width: '100%',
                          display: 'grid',
                          gridTemplateColumns: '1fr auto',
                          gap: 1
                        }}>
                        <Stack
                          direction='row'
                          alignItems='center'
                          gap={0.5}
                          sx={{
                            wordBreak: 'break-all'
                          }}>
                          <Description
                            sx={({ palette }) => ({
                              width: 16,
                              height: 16,
                              fill: palette.common.black,
                              opacity: 0.8
                            })} />
                          <Typography
                            sx={({ palette }) => ({
                              textAlign: 'center',
                              fontSize: 14,
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '18px',
                              color: palette.common.white,
                            })}>
                            {prettifyFileName(document)}
                          </Typography>
                        </Stack>
                        <Stack
                          direction='row'
                          gap={1.5}>
                          {!isMobile && (
                            <Typography
                              sx={({ palette }) => ({
                                textAlign: 'center',
                                fontSize: 14,
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '18px',
                                color: palette.common[1060],
                                ...(document.status === UserDocumentStatusEnum.Approved && ({
                                  color: palette.common[825]
                                })),
                                ...(document.status === UserDocumentStatusEnum.Declined && ({
                                  color: palette.common[1325]
                                }))
                              })}>
                              {getDocumentLabelByStatus(document.status)}
                            </Typography>
                          )}

                          {isMobile && document.status === UserDocumentStatusEnum.Declined && (
                            <Close sx={{
                              fontSize: '20px',
                              fill: joyTheme.palette.common[1325]
                            }} />
                          )}

                          {isMobile &&
                            document.status !== UserDocumentStatusEnum.Approved &&
                            document.status !== UserDocumentStatusEnum.Declined && (
                              <AccessTime sx={{
                                width: 20,
                                height: 20,
                                fill: joyTheme.palette.common[1060]
                              }}
                              />
                            )}

                          {isMobile && document.status === UserDocumentStatusEnum.Approved && (
                            <Check sx={{
                              width: 20,
                              height: 20,
                              fill: joyTheme.palette.common[825]
                            }}
                            />
                          )}

                          <RemoveRedEyeOutlined
                            sx={{
                              width: 20,
                              height: 20,
                              fill: joyTheme.palette.common[300]
                            }}
                          />
                        </Stack>
                      </Stack>

                      {index !== groupedUserDocuments[key].length - 1 && (
                        <Box
                          sx={({ palette }) => ({
                            height: '1px',
                            width: '100%',
                            backgroundColor: palette.common[925]
                          })}
                        />
                      )}
                    </Stack>
                  ))}
              </Stack>
            ))}
        </>
      )}
      {isEmpty(userDocuments) && (
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: '100%',
            height: '100%',
            gap: 2
          }}>
          <Description
            sx={{
              fontSize: 71,
              color: joyTheme.palette.common[475],
            }}
          />
          <Typography
            sx={({ palette }) => ({
              textAlign: 'center',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '18px',
              color: palette.common.white,
              maxWidth: 250,
            })}>
            Your documents will be displayed here once you upload them
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
