import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  type CarouselSliderActionItem,
  type CarouselSliderItem
} from '@shared/types';
import { selectUserStatus } from '@entities/session';
import { GamesItem } from '@entities/games';
import { type TournamentDetailsPageState } from './types';
import { tournamentDetailsPageSlice } from './slice';


type RootState = {
  [tournamentDetailsPageSlice.name]: TournamentDetailsPageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.canonical
);

export const selectTournamentId = (state: RootState): Maybe<number> => (
  state[tournamentDetailsPageSlice.name]?.tournamentId
);

export const selectHeading = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.heading
);

export const selectGames = (state: RootState): Maybe<Array<GamesItem>> => (
  state[tournamentDetailsPageSlice.name]?.games
);

export const selectSlider = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<CarouselSliderItem>> => (
    state[tournamentDetailsPageSlice.name]?.slider
  ),
  (userState, sliderItems) => {
    const result = sliderItems?.map(({ actions, ...props }) => {
      const action = actions?.find(actionItem => actionItem.userState === userState);
      return { ...props, action };
    });
    return result;
  }
);

export const selectPrizeGC = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.prizeGC
);

export const selectPrizeSC = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.prizeSC
);

export const selectAction = createSelector(
  selectUserStatus,
  (state: RootState): Maybe<Array<CarouselSliderActionItem>> => (
    state[tournamentDetailsPageSlice.name]?.action
  ),
  (userState, actionItems) => {
    const action = actionItems?.find(actionItem => actionItem.userState === userState);
    return action;
  }
);

export const selectLeaderTitle = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.leaderTitle
);

export const selectHtmlBlockTitle = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.htmlBlockTitle
);

export const selectHtmlBlock = (state: RootState): Maybe<string> => (
  state[tournamentDetailsPageSlice.name]?.htmlBlock
);

export const useTournamentDetailsPageSliceSelector: TypedUseSelectorHook<{
  [tournamentDetailsPageSlice.name]: TournamentDetailsPageState;
}> = useSelector;
