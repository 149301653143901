import {
  useEffect,
  useRef,
  FC,
  ReactNode,
  useState,
} from 'react';
import {
  CHAT_API_URL
} from '@shared/config';
import {
  useModalQueryParam,
  useQueryParamModalVisibility,
} from '@shared/lib';
import {
  selectFullProfile,
  useSessionSelector
} from '@entities/session';
import {
  type LiveChatOptions,
  type LoginLiveChatOptions,
  generateHash,
  LiveChatContext
} from '../lib';

type TawkAPI = {
  maximize: () => any;
  minimize: () => any;
  toggle: () => any;
  popup: () => any;
  showWidget: () => any;
  hideWidget: () => any;
  toggleVisibility: () => any;
  endChat: () => any;
  getWindowType: () => any;
  getStatus: () => 'online' | 'away' | 'offline';
  isChatMaximized: () => boolean;
  isChatMinimized: () => boolean;
  isChatHidden: () => boolean;
  isChatOngoing: () => boolean;
  isVisitorEngaged: () => boolean;
  widgetPosition: () => any;
  visitor: { name: string; email: Email };
  setAttributes: (attribute: Record<string, any>, callback?: (error: any) => void) => void;
  addEvent: (event: string, metadata: any, callback?: (error: any) => void) => void;
  addTags: (tags: string[], callback?: (error: any) => void) => void;
  removeTags: (tags: string[], callback?: (error: any) => void) => void;
  start: () => void;
  shutdown: () => void;
  login: (data: any, callback?: (error: any) => void) => any;
  logout: (callback?: (error: any) => void) => void;
  onStatusChange: (status: string) => void;
  onLoad: () => any;
  onBeforeLoad: () => any;
  onChatMinimized: () => void;
};

type WindowWithTawkScript = {
  Tawk_API: any;
  Tawk_LoadStart: any;
};

export type LiveChatProviderProps = {
  options: LiveChatOptions;
  children: ReactNode;
};


export const LiveChatProvider: FC<LiveChatProviderProps> = ({ options, children }) => {
  const { apiKey, propertyId, widgetId } = options;

  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const isScriptLoading = useRef<boolean>(false);
  const tawkMessengerRef = useRef<Maybe<TawkAPI>>(null);
  const isOpened = useRef<boolean>(false);
  const isLoggedIn = useRef<boolean>(false);
  const closeChatFunctionRef = useRef<() => void>(() => { });

  const userFullProfile = useSessionSelector(selectFullProfile);
  const openLiveChat = useQueryParamModalVisibility('chat');

  const { closeModal } = useModalQueryParam();

  const loginChat = async ({
    userId,
    name,
    email,
    phone
  }: LoginLiveChatOptions): Promise<void> => {

    if (!isLoggedIn.current) {
      isLoggedIn.current = true;
      const hash = generateHash(userId, apiKey);
      await tawkMessengerRef.current?.login(
        {
          hash,
          userId,
          name,
          email,
          phone,
        },
        (error: any) => {
          console.error(`login: ${error}`);
          isLoggedIn.current = false;
        }
      );
    }
  };

  const logoutChat = async (): Promise<void> => {
    tawkMessengerRef.current?.logout(error => {
      isLoggedIn.current = false;
      console.error('TAWK Chat Error:', error);
    });
  };

  const closeChat = (): void => {
    isOpened.current = false;
    // tawkMessengerRef.current?.minimize();
    // tawkMessengerRef.current?.hideWidget();
    closeModal('chat');
  };

  const loadScript = (): void => {
    if (!isScriptLoading.current || !isScriptLoaded) {
      isScriptLoading.current = true;
      const script = document.createElement('script');
      script.async = false;
      script.src = `${CHAT_API_URL}/${propertyId}/${widgetId}`;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      script.onload = () => {
        const withScript: WindowWithTawkScript = window as any;
        withScript.Tawk_API = withScript.Tawk_API || {};
        withScript.Tawk_API.autoStart = false;
        withScript.Tawk_LoadStart = new Date();

        tawkMessengerRef.current = withScript.Tawk_API;

        tawkMessengerRef.current!.onLoad = () => {
          isScriptLoading.current = false;
          setIsScriptLoaded(true);

          tawkMessengerRef.current?.start();
        };

        tawkMessengerRef.current!.onChatMinimized = () => {
          isOpened.current = false;
          setTimeout(() => {
            tawkMessengerRef.current?.minimize();
            tawkMessengerRef.current?.hideWidget();
            closeChatFunctionRef.current?.();
          }, 800);
        };
      };
      document.body.appendChild(script);
    }
  };

  // eslint-disable-next-line
  const openChat = async (): Promise<void> => {
    const withScript: WindowWithTawkScript = window as any;
    withScript.Tawk_API = withScript.Tawk_API || {};

    if (userFullProfile?.userId) {
      const hash = generateHash(userFullProfile.userId, apiKey);
      tawkMessengerRef.current?.setAttributes({
        hash,
        name: userFullProfile?.nickname ?? '',
        email: userFullProfile?.email ?? '',
        phone: userFullProfile?.phone ?? '',
        userId: userFullProfile?.userId ?? ''
      });
    }

    setTimeout(() => {
      tawkMessengerRef.current?.showWidget();
      tawkMessengerRef.current?.maximize();
      tawkMessengerRef.current?.start();
    }, 1500);
  };

  useEffect(() => {
    if (!isScriptLoaded) loadScript();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openLiveChat) {
      closeChatFunctionRef.current = closeChat;
      openChat();
    }
    // eslint-disable-next-line
  }, [openLiveChat]);

  return (
    <LiveChatContext.Provider
      value={{
        loginChat,
        closeChat,
        logoutChat
      }}>
      {children}
    </LiveChatContext.Provider>
  );
};