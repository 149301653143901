import { InfoOutlined, TaskAltOutlined } from "@mui/icons-material"
import { FC, useMemo } from "react"
import { ToastColor } from "../lib/types"


const iconMap: Record<ToastColor, JSX.Element> = {
  danger: <InfoOutlined sx={{ fontSize: '1.25rem' }} />,
  success: <TaskAltOutlined sx={{ fontSize: '1.25rem' }} />
}

type ToastIconProps = {
  type: ToastColor
}

export const ToastIcon: FC<ToastIconProps> = ({ type }) => {
  const icon = useMemo(() => iconMap[type], [type])
  return icon;
}