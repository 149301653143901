import {
  ComponentType,
} from 'react';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  ConfirmResetPasswordModal,
  NewPasswordModal,
  ResetPasswordModalsQueries, SendResetPasswordModal,
} from '@features/reset-password';
import {
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';

// eslint-disable-next-line operator-linebreak
export const withResetPasswordModal =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      const isSendResetPasswordModal = useQueryParamModalVisibility(ResetPasswordModalsQueries.SentResetPassword);
      const isConfirmResetPasswordModal = useQueryParamModalVisibility(ResetPasswordModalsQueries.ConfirmResetPassword);
      const isNewPasswordModal = useQueryParamModalVisibility(ResetPasswordModalsQueries.NewPasswordModal);
      const isAuthenticated = useSessionSelector(selectIsAuthenticated)
      const { toggleModal, closeModal } = useModalQueryParam();

      const handleSendResetPasswordClose = (): void => {
        toggleModal(ResetPasswordModalsQueries.SentResetPassword, 'login');
      };

      const handleNewPasswordModalClose = (): void => {
        closeModal(ResetPasswordModalsQueries.NewPasswordModal);
      }

      const handleConfirmResetPasswordClose = (): void => {
        closeModal(ResetPasswordModalsQueries.ConfirmResetPassword)
      }

      return (
        <>
          <WrappedComponent {...props} />
          {isAuthenticated ? null : (
            <>
              <SendResetPasswordModal
                open={isSendResetPasswordModal}
                onClose={handleSendResetPasswordClose}
              />
              <ConfirmResetPasswordModal
                open={isConfirmResetPasswordModal}
                onClose={handleConfirmResetPasswordClose}
              />
              <NewPasswordModal
                open={isNewPasswordModal}
                onClose={handleNewPasswordModalClose}
              />
            </>
          )}
        </>
      );
    };
