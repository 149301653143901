import { mapper } from '@shared/lib';
import { type StickyNavigation } from '../model';
import { mapStickyNavigationItem } from './map-sticky-navigation-item';

type MapStickyNavigation = (data: any) => StickyNavigation;
export const mapStickyNavigation: MapStickyNavigation = mapper([
  {
    name: 'menuItemsAuth',
    from: 'menuItemsAuth',
    transform: (menuItemsData: Array<any>) =>
      menuItemsData?.map(mapStickyNavigationItem),
  },
  {
    name: 'menuItemsNotAuth',
    from: 'menuItemsNotAuth',
    transform: (menuItemsData: Array<any>) =>
      menuItemsData?.map(mapStickyNavigationItem),
  },
]);
