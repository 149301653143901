import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';

export const NoChallengesPlaceholder: FC = () => (
  <Stack
    direction='column'
    alignItems='center'
    justifyContent='center'
    sx={{
      width: '100%',
      height: '13vh',
    }}>
    <Typography
      sx={({ palette }) => ({
        fontSize: 16,
        fontWeight: 600,
        paddingInline: 2,
        width: '100%',
        color: palette.common[515],
        textAlign: 'center'
      })}>
      No challenges are available right now. Please check again later
    </Typography>
  </Stack>
);