import * as Yup from 'yup';

// Will remove after testing
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\-!@$^&*]{8,20}$/,

export const passwordString = Yup.string()
  .required('Password is a required field')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&!+=]{8,20}$/,
    'Must be 8-20 characters in length with at least 1 number, 1 uppercase letter, 1 lowercase letter'
  );

export const passwordSchema = Yup.object().shape({
  password: passwordString
});