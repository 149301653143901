import {
  createContext,
  useContext,
  useEffect
} from 'react';

export type TrackConversionOptions = {
  adsLabel: string;
  transactionId?: string;
};

export interface GoogleAdsContextType {
  trackConversion: (options: TrackConversionOptions) => void;
};

export const GoogleAdsContext = createContext<GoogleAdsContextType>({
  trackConversion: () => { }
});

export const useLazyGoogleAds = () => useContext(GoogleAdsContext);

export const useGoogleAds = (options: TrackConversionOptions) => {
  const { trackConversion } = useContext(GoogleAdsContext);
  useEffect(() => {
    trackConversion({ ...options });
    //eslint-disable-next-line
  }, []);
};
