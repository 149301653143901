import {
  FC
} from 'react';
import {
  Typography,
  TypographyProps
} from '@mui/joy';

export type NoTransactionsLabelProps = TypographyProps;

export const NoTransactionsLabel: FC<NoTransactionsLabelProps> = ({
  sx,
  ...props
}) => (
  <Typography
    sx={[({ palette }) => ({
      fontSize: 14,
      fontWeight: 600,
      color: palette.common[150],
      textAlign: 'center'
    }),
    ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    Sorry, no results found
  </Typography>
);
