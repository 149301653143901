import { baseOmegaApi } from '@shared/api';
import { GetMessagesHistoryPayload } from './types';

export const messagesApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getMessagesHistory: build.query<any, GetMessagesHistoryPayload>({
      query: (payload: GetMessagesHistoryPayload) => ({
        url: `${process.env.REACT_APP_OMEGA_CHRONOS_API}user/${payload.partyId}/message`,
        params: { size: payload.size, channels: payload.channel },
        headers: { sessionKey: payload.sessionKey },
      }),
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});
