import {
  FC
} from 'react';
import {
  Button,
  ButtonProps
} from '@shared/ui';
import {
  useModalQueryParam
} from '@shared/lib';

export type BuyCoinsButtonProps = Pick<ButtonProps, 'color' | 'size' | 'children'> & {
  onOpenBuyCoinsModal?(): void;
};

export const BuyCoinsButton: FC<BuyCoinsButtonProps> = ({
  children,
  onOpenBuyCoinsModal,
  ...props
}) => {
  const { openModal } = useModalQueryParam();

  const handleClick = (): void => {
    openModal('buy');
    onOpenBuyCoinsModal?.();
  };

  return (
    <Button
      {...props}
      color='success'
      size='md'
      onClick={handleClick}>
      {children}
    </Button>
  );
};
