import { isEmpty, mapTextWithInlineLink, mapper } from '@shared/lib';
import {
  type FaqContent,
  type BoxedFaqBlockItem,
  type FaqBlockItem
} from '../model';

type MapBoxedFaqBlockItem = (data: any) => BoxedFaqBlockItem;
const mapBoxedFaqBlockItem: MapBoxedFaqBlockItem = mapper([
  { name: 'shortSummary', from: 'shortSummary' },
  { name: 'shortSummaryStyle', from: 'shortSummaryStyle' },
  { name: 'details', from: 'details' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
]);

type MapFaqBlockItem = (data: any) => FaqBlockItem;
const mapFaqBlockItem: MapFaqBlockItem = mapper([
  { name: 'summary', from: 'summary' },
  { name: 'details', from: 'details' },
]);

export type MapFaqContent = (data: any) => FaqContent;
export const mapFaqContent: MapFaqContent = mapper([
  {
    name: 'boxedFaqBlock',
    from: 'content',
    transform: (contentData: Array<{ boxedFaqBlockTitle: any, boxedFaqBlock: Array<any> }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = mapTextWithInlineLink(content?.boxedFaqBlockTitle);
      const items = content?.boxedFaqBlock?.map(
        (boxedFaqBlockItemData: any) => mapBoxedFaqBlockItem(boxedFaqBlockItemData)
      );
      return { title, items };
    }
  },
  {
    name: 'faqBlock',
    from: 'content',
    transform: (contentData: Array<{ faqBlockTitle: any, faqBlock: Array<any> }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = content.faqBlockTitle;
      const items = content?.faqBlock?.map(
        (faqBlockItemData) => mapFaqBlockItem(faqBlockItemData)
      );
      return { title, items };
    }
  }
]);