const CODES_NAMES_TO_TRACK = [
  'refcode',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  's1',
  's2',
  's3',
  's4',
  's5',
];

type MapTrackingCodes = (data: Record<string, string>) => Record<string, string>;
export const mapTrackingCodes: MapTrackingCodes = (data: Record<string, string>) => {
  Object.keys(data).forEach((key: string) => {
    if (!CODES_NAMES_TO_TRACK.some((code: string) => code === key)) {
      delete data[key];
    }
  });

  return data;
};
