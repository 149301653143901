import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';
import { isDefined } from '@shared/lib';
import {
  selectShortProfile,
  selectFullProfile,
  useSessionSelector
} from '../model';
import { LoggedUserVipLevel } from './logged-user-vip-level.component';

export const UserIdentityCard: FC = () => {
  const shortProfile = useSessionSelector(selectShortProfile);
  const fullProfile = useSessionSelector(selectFullProfile);
  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      gap={2}
      sx={({ palette }) => ({
        height: 72,
        borderRadius: 8,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: palette.common[925],
        background: palette.common[475],
        paddingBlock: 2,
        paddingInline: 3,
      })}>
      <LoggedUserVipLevel size='md' />
      <Stack
        direction='column'
        sx={{
          width: '100%',
        }}>
        <Typography
          sx={({ palette }) => ({
            maxWidth: 233,
            fontSize: 16,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontStyle: 'normal',
            fontWeight: 400,
            lineWeight: 'normal',
            color: palette.common.white,
          })}>
          {fullProfile?.nickname ?? 'Nickname'}
        </Typography>
        {isDefined(shortProfile?.partyId) && (
          <Typography
            sx={({ palette }) => ({
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 233,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineWeight: '20px',
              color: palette.common[150],
            })}>
            {`User ID: ${shortProfile?.partyId}`}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};