import { FC } from 'react';
import { Typography } from '@mui/joy';
import { Link } from '@shared/ui';

const PromotionalRulesLink: FC = () => (
  <Link
    color='primary'
    href='/promo-terms-and-conditions'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    }}>
    Promotional Rules
  </Link>
);

const TermsConditionsLink: FC = () => (
  <Link
    color='primary'
    href='/terms-and-conditions'
    underline='none'
    sx={{
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }}>
    Terms & Conditions
  </Link>
);

export const FreePlayDisclaimer: FC = () => {
  return (
    <Typography
      sx={({ palette }) => ({
        color: palette.common[150],
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
      })}>
      It’s always Free to play our Promotional Coins games. No purchase necessary!&nbsp;
      <PromotionalRulesLink />
      &nbsp;and the&nbsp;
      <TermsConditionsLink />
    </Typography >
  );
};