import { CurrencyCodesEnum } from '@shared/types';
import { TransactionTypeEnum } from '../model';

export const getCurrencyByTransactionType = (
  transactionType: TransactionTypeEnum,
  currencyCode: CurrencyCodesEnum
): string => {
  const labelPostfixMatrix: Record<CurrencyCodesEnum, string> = {
    GCO: 'GC',
    SCO: 'SC'
  };
  const labelForTransactionTypeMatrix: Record<TransactionTypeEnum, string> = {
    [TransactionTypeEnum.GameBet]: `${labelPostfixMatrix[currencyCode]}`,
    [TransactionTypeEnum.GameWin]: `${labelPostfixMatrix[currencyCode]}`,
    [TransactionTypeEnum.Withdrawal]: '$',
    [TransactionTypeEnum.Deposit]: 'SC',
  };
  return labelForTransactionTypeMatrix[transactionType];
};