import {
  FC,
  PropsWithChildren,
  useEffect,
  useRef
} from 'react';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import {
  clearAuthCookieAndRedirectAfterLogout,
  selectIsAuthenticated
} from '@entities/session';
import {
  selectIsOpenConfirmationSignUpModal,
  selectIsOpenSuccessConfirmationSignUpModal,
  useSignUpSelector
} from '@features/sign-up';
import {
  selectIsOpenConfirmationAuthViaSocialsModal,
  useAuthViaSocialsSliceSelector
} from '@features/authentication-via-socials';
import {
  selectIsOpenSuccessConfirmationAuthViaSocialsModal
} from '@features/authentication-via-socials/model';

export const RedirectIfNotAuthorized: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const previousSearch = useRef(location.search);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isOpenConfirmationSignUpModal = useSignUpSelector(selectIsOpenConfirmationSignUpModal);
  const isOpenSuccessConfirmationSignUpModal = useSignUpSelector(selectIsOpenSuccessConfirmationSignUpModal);
  const isOpenConfirmationAuthViaSocialsModal = useAuthViaSocialsSliceSelector(selectIsOpenConfirmationAuthViaSocialsModal);
  const isOpenSuccessConfirmationAuthViaSocialsModal = useAuthViaSocialsSliceSelector(
    selectIsOpenSuccessConfirmationAuthViaSocialsModal
  );

  const allowToRedirect =
    !isAuthenticated &&
    !isOpenConfirmationSignUpModal &&
    !isOpenSuccessConfirmationSignUpModal &&
    !isOpenConfirmationAuthViaSocialsModal &&
    !isOpenSuccessConfirmationAuthViaSocialsModal;

  useEffect(() => {
    const hasQueryParams = location.search.length > 0;
    const hadQueryParams = previousSearch.current.length > 0;

    const shouldRedirect = (allowToRedirect && hadQueryParams && !hasQueryParams)
      || (allowToRedirect && !hasQueryParams)
    if (shouldRedirect) clearAuthCookieAndRedirectAfterLogout();

    previousSearch.current = location.search;
  }, [allowToRedirect, location.search, navigate]);

  return children;
};
