import { FC, ReactElement, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/joy';
import { IconButton } from '@shared/ui';
import { selectIsAuthenticated, useSessionSelector } from '@entities/session';
import { InfoToolTip } from './info-tooltip.component';

export type GameListItemDetailsProps = {
  isOverlayActive: boolean;
  name: string;
  features: string;
  volatility: string;
  rtp: string;
  favoriteSwitcherSlot: ReactElement;
  launchGameSlot: ReactElement;
};

export const GameListItemDetails: FC<GameListItemDetailsProps> = ({
  isOverlayActive,
  name,
  features,
  rtp,
  volatility,
  favoriteSwitcherSlot,
  launchGameSlot,
}) => {
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const [showInfo, setShowInfo] = useState<boolean>(false);
  const showTooltip = Boolean(features || rtp || volatility);

  const handleClickShowInfo = () => {
    setShowInfo((state) => !state);
  };

  const handleCloseTooltip = () => {
    setShowInfo(false);
  }
  
  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Box sx={(theme) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.90)',
        padding: '0.5rem 0.75rem',
        opacity: isOverlayActive ? 1 : 0,
        transition: 'opacity 0.4s linear',
        cursor: 'pointer',
        [theme.breakpoints.down(768)]: {
          padding: '0.5rem',
        },
      })}>
        <Stack
          zIndex='1'
          direction='row'
          position='relative'
          justifyContent={isAuthenticated ? 'space-between' : 'end'}>
          {favoriteSwitcherSlot}
          {showTooltip && (
            <InfoToolTip open={showInfo} features={features} rtp={rtp} volatility={volatility}>
              <IconButton sx={{
                padding: 0,
                minHeight: 'min-content',
                minWidth: 'min-content',
                fontSize: '14px',
              }}
                onClick={handleClickShowInfo}>
                <InfoOutlined
                  sx={({ palette }) => ({
                    color: palette.common.white,
                    fontSize: '1.25rem',
                  })}
                />
              </IconButton>
            </InfoToolTip>
          )}
        </Stack>
        <Stack sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          paddingInline: '0.25rem',
        }}>
          <Typography sx={({ palette }) => ({
            color: palette.common.white,
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.5rem',
            textAlign: 'center',
          })}>
            {name}
          </Typography>
          {launchGameSlot}
        </Stack>
      </Box>
    </ClickAwayListener>
  );
};
