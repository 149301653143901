import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';
import { isDefined } from '@shared/lib';
import { HtmlBlock } from '@shared/ui';
import {
  selectHtmlBlock,
  useHtmlPageContentSelector
} from '../model';

export const HtmlContent: FC = () => {
  const htmlBlock = useHtmlPageContentSelector(selectHtmlBlock);
  return isDefined(htmlBlock) && (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        width: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 5,
        paddingBlockEnd: 8,
        gap: 8,
        [breakpoints.down(1350)]: {
          paddingInline: 2,
        },
        [breakpoints.down(769)]: {
          paddingBlockStart: 0,
          paddingBlockEnd: 3,
          gap: 6,
        },
      })}>
      <Stack
        direction='column'
        gap={1.5}>
        {htmlBlock?.title && (
          <Typography sx={({ colorSchemes }) => ({
            width: '100%',
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '36x',
            letterSpacing: 0,
            textAlign: 'start',
            color: colorSchemes.dark.palette.common.white
          })}>
            {htmlBlock?.title}
          </Typography>
        )}
        {htmlBlock?.html && (
          <HtmlBlock html={htmlBlock?.html} />
        )}
      </Stack>
    </Stack>
  );
};