import { FC } from 'react';
import { PlayArrowRounded } from '@mui/icons-material';
import { IconButton } from '@mui/joy';
import { useLaunchGame } from '../lib';

export type LaunchGameProps = {
  id: number;
};

// TODO: refactoring needed
export const LaunchGame: FC<LaunchGameProps> = ({ id }) => {
  const { launchGame } = useLaunchGame();

  const handleOpenGame = () => {
    launchGame(id);
  }

  return (
    <IconButton
      sx={(theme) => ({
        background: theme.colorSchemes.dark.palette.common[300],
        color: theme.colorSchemes.dark.palette.common.white,
        borderRadius: '50%',
        fontSize: '1.5rem',
        minWidth: '2rem',
        minHeight: '2rem',
      })}
      variant='solid'
      onClick={handleOpenGame}>
      <PlayArrowRounded />
    </IconButton>
  );
};
