import { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from '@shared/lib';
import { setTrackingCodes } from '../model';
import { mapTrackingCodes } from '../lib';

type CheckTrackingCodesProps = {
  children: ReactNode;
};

export const CheckTrackingCodes: FC<CheckTrackingCodesProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search.toLowerCase());
    const trackingCodesInQueryParams: Record<string, string>
      = mapTrackingCodes(Object.fromEntries(queryParams));

    if (!isEmpty(trackingCodesInQueryParams)) {
      dispatch(setTrackingCodes(JSON.stringify(trackingCodesInQueryParams)));
    }
  }, [dispatch, location]);

  return children;
};
