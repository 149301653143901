import { mapper } from '@shared/lib';
import { type FollowUs } from '../model';
import { mapSocialMedia } from './map-social-media';

type MapFollowUs = (data: FollowUs) => FollowUs;

export const mapFollowUs: MapFollowUs = mapper([
  { name: 'title', from: 'title' },
  { name: 'socialMedia', 
    from: 'socialMedia',
    transform: (socialMedia: any[]) => socialMedia?.map(mapSocialMedia), 
  },
]);