import { experimental_extendTheme as materialExtendTheme } from '@mui/material/styles';

export const materialTheme = materialExtendTheme({
  components: {

    MuiPagination: {
      styleOverrides: {
        root: () => ({
          alignSelf: 'center',
          '& .MuiPaginationItem-previousNext svg': {
            fill: '#465DFB',
          },
        }),
      }
    },

    //  TODO: need add disabled status
    MuiPaginationItem: {
      styleOverrides: {
        root: () => ({
          color: '#959595',
          '&.Mui-selected': {
            background: '#2A3648',
            color: '#465DFB',
          },
          '&:hover': {
            background: '#344257 !important',
            color: '#959595',
          },

        }),
      }
    },
  }
});
