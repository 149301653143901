import { mapper } from '@shared/lib';
import { PromotionsPageState } from '../model';

type MapPromotionsPageState = (data: any) => PromotionsPageState
export const mapPromotionsPageState: MapPromotionsPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  { name: 'heading', from: 'data.attributes.heading' },
])