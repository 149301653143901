import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { type ChannelMessage, type MessagesState, selectMessagesInChannel, messagesSlice } from '@entities/messages';
import { type NotificationsSidebarState } from './types';
import { notificationsSidebarSlice } from './slice';

type RootState = {
  [notificationsSidebarSlice.name]: NotificationsSidebarState;
  [messagesSlice.name]: MessagesState;
};

export const selectIsOpen = (state: RootState): boolean => state[notificationsSidebarSlice.name].isOpen ?? false;

export const selectMessages: (state: RootState) => Maybe<Array<ChannelMessage>> = createSelector(
  [selectMessagesInChannel(process.env.REACT_APP_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? '')],
  (messages: Maybe<Array<ChannelMessage>>): Maybe<Array<ChannelMessage>> => messages ? [...messages] : null
);

export const selectIsAnyUnreadMessage: (state: RootState) => boolean = createSelector(
  [selectMessagesInChannel(process.env.REACT_APP_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? '')],
  (messages: Maybe<Array<ChannelMessage>>): boolean =>
    !!(messages ?? []).find((message: ChannelMessage) => !message.isRead)
);

export const useNotificationsSidebarSelector: TypedUseSelectorHook<RootState> = useSelector;
