import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/joy';

export const FormLayoutFragment: FC<PropsWithChildren> = ({ children }) => (
  <Box sx={({ breakpoints }) => ({
    gap: 3,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [breakpoints.down(1190)]: {
      gridTemplateColumns: '1fr',
    }
  })}>
    {children}
  </Box>
);