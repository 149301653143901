import {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Swiper
} from './swiper.component';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch
} from 'redux-thunk';
import {
  UnknownAction
} from 'redux';
import {
  Stack
} from '@mui/joy';
import {
  SwiperSlide,
} from 'swiper/react';
import {
  Navigation
} from 'swiper/modules';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  type GameCategory,
  useGetGamesCategoriesQuery,
  GameListItem
} from '@entities/games';
import {
  selectGamesByCategoryKey,
  fetchGamesByCategory,
  useGamesFilterByCategorySelector,
} from '@features/games-filter-by-category';
import {
  LaunchGame
} from '@features/launch-game';
import {
  FavoriteGameSwitcher
} from '@features/favorite-game-switcher';
import {
  LoadingList
} from './loading-list.component';
import {
  GamesCategoryItemHeader
} from './games-category-item-header.component';

export type GamesCategoryItemProps = {
  category: GameCategory;
};

export const GamesCategoryItem: FC<GamesCategoryItemProps> = ({ category }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down(912));
  const isSmallDesktop = useMediaQuery(breakpoints.between(1024, 913));
  const isDesktop = useMediaQuery(breakpoints.up(1349));

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const games = useGamesFilterByCategorySelector(selectGamesByCategoryKey(category?.key));
  // eslint-disable-next-line
  const {
    isFetching: isGamesCategoriesLoading
  } = useGetGamesCategoriesQuery();

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);

  const slidesPerView: number = useMemo(() => {
    return isDesktop ? 6 : isSmallDesktop ? 4 : 5;
  }, [isDesktop, isSmallDesktop]);

  const fetchGamesForCategories = useCallback(() => {
    if (category?.key) {
      dispatch(fetchGamesByCategory({
        page: 1,
        pageSize: 12,
        categoryKey: category?.key
      }));
    }
  }, [category, dispatch]);

  useEffect(() => {
    fetchGamesForCategories();
  }, [fetchGamesForCategories]);

  return (
    <Stack
      direction='column'
      sx={({ breakpoints }) => ({
        height: '100%',
        width: '100%',
        gap: 2.5,
        [breakpoints.down(912)]: {
          gap: 1.25
        }
      })}>

      <GamesCategoryItemHeader
        category={category}
        navigationNextRef={navigationNextRef}
        navigationPrevRef={navigationPrevRef}
      />

      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          height: '152px',
          [breakpoints.down(390)]: { height: '120px' },
        })}>

        {isGamesCategoriesLoading && <LoadingList
          length={isMobile ? 6 : slidesPerView}
          sx={({ breakpoints }) => ({
            ...{ display: 'none' },
            ...(isGamesCategoriesLoading && ({
              [breakpoints.down(912)]: { display: 'grid' },
              display: 'flex',
            })),
          })}
        />}

        <Swiper
          slidesPerView={isMobile ? 'auto' : slidesPerView}
          spaceBetween={isMobile ? 8 : 16}
          modules={[Navigation]}
          sx={{
            ...(isGamesCategoriesLoading && ({
              display: 'none'
            }))
          }}
          navigation={{
            enabled: true,
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}>
          {games?.map(({ id, ...rest }) => (
            <SwiperSlide key={id}>
              <GameListItem
                key={id}
                game={{ id, ...rest }}
                bonusFeature
                volatility='Hight'
                rtp='98%'
                sx={({ breakpoints }) => ({
                  width: '160px',
                  height: '120px',
                  [breakpoints.up(911)]: {
                    width: '100%',
                  },
                  [breakpoints.down(390)]: {
                    width: '160px',
                    height: '120px',
                  },
                })}
                favoriteSwitcherSlot={
                  <FavoriteGameSwitcher game={{ id, ...rest }} />
                }
                launchGameSlot={
                  <LaunchGame id={id} />
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Stack>
    </Stack>
  );
};
