import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterTypeEnum, type GamesListState } from './types';

export const initialState: GamesListState = {
  activeFilterType: FilterTypeEnum.Category,
};

export const gamesListWidgetSlice = createSlice({
  name: 'games-list-widget-slice',
  initialState,
  reducers: {
    setActiveFilterType: (state: GamesListState, action: PayloadAction<FilterTypeEnum>) => {
      state.activeFilterType = action.payload;
    },
  },
});

export const gamesListWidgetReducer = gamesListWidgetSlice.reducer;

export const { setActiveFilterType } = gamesListWidgetSlice.actions;
