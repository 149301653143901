import { Component, ComponentType } from 'react';
import { FullScreenListener } from '@pages/play-game';

export const withFullScreenListener = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return class FullScreenListenerProvider extends Component<P> {
    render() {
      return (
        <FullScreenListener>
          <WrappedComponent {...this.props} />
        </FullScreenListener>
      );
    }
  };
};
