import { FC } from 'react';
import { Stack, StackProps, Typography } from '@mui/joy';
import { MailSubscriptionSwitcher } from './mail-subscription-switcher.component';
import { SmsSubscriptionSwitcher } from './sms-subscription-switcher.component';

export type ManagePromotionalSubscriptionsCardProps = Maybe<{
  inputProps?: Maybe<{
    root?: StackProps
  }>
}>;

export const ManagePromotionalSubscriptionsCard: FC<ManagePromotionalSubscriptionsCardProps> = (props) => {
  return (
    <Stack
      direction='column'
      gap={5.75}
      sx={[
        ({ palette }) => ({
          paddingInline: 2,
          paddingBlockStart: 2,
          borderRadius: 8,
          width: 426.5,
          background: palette.common[475],
        }),
        ...(props?.inputProps?.root && Array.isArray(props?.inputProps?.root?.sx)
          ? props?.inputProps?.root?.sx
          : [props?.inputProps?.root?.sx])
      ]}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'>
        <Typography
          sx={({ palette }) => ({
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '30px',
            color: palette.common.white
          })}>
          Subscriptions
        </Typography>
      </Stack>
      <Stack
        direction='column'
        justifyContent='space-between'
        alignItems='flex-start'
        gap={3}
        width='100%'>
        <MailSubscriptionSwitcher />
        <SmsSubscriptionSwitcher />
      </Stack>
    </Stack>
  );
};