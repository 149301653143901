import { Box, Skeleton, Stack } from '@mui/joy'
import { FC } from 'react'

type SkeletonListProps = {
  length: number,
}

export const SkeletonList: FC<SkeletonListProps> = ({ length }) => {
  return Array(length).fill(null).map((_, i) => <Stack sx={({ colorSchemes }) => ({
    padding: '1.5rem 1rem',
    position: 'relative',
    borderRadius: '0.5rem',
    background: colorSchemes.dark.palette.common[475],
    gap: '1rem'
  })}>
    <Stack>
      <Skeleton variant='text' width='5.5rem' />
      <Skeleton variant='text' width='90%' />
      <Skeleton variant='text' width='65%' />
    </Stack>
    <Box sx={{
      aspectRatio: '3/2',
      width: '100%',
      borderRadius: '0.5rem',
      position: 'relative',
      overflow: 'hidden'
    }}>
      <Skeleton />
    </Box>
  </Stack>)
}