import { baseApi } from '@shared/api';
import { PromotionsPageState } from '../model';
import { mapPromotionCards, mapPromotionsPageState } from '../lib';

export const promotionsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotionsPage: build.query<PromotionsPageState, void>({
      query: () => ({ url: `api/promotions-page` }),
      extraOptions: { showLoader: true },
      transformResponse: mapPromotionsPageState
    }),
    getPromotionCards: build.query<Pick<PromotionsPageState, 'promotionCards'>, string>({
      query: (categoryKey: string) => ({ url: `api/promotion-cards?categoryKey=${categoryKey}` }),
      extraOptions: { auth: true },
      transformResponse: mapPromotionCards
    }),
  })
})

export const {
  useGetPromotionsPageQuery,
  useLazyGetPromotionCardsQuery
} = promotionsPageApiSlice;