import { mapActionItem, mapper } from '@shared/lib';
import { mapGamesItem } from '@entities/games';
import {
  FaqBlockItem,
  HowItWorksBlockItem,
  type PromotionDetailsPageState,
  type PromotionDetailsPageUserCarouselSliderItem
} from '../model';

type MapPromotionsSliderItemAction = (data: any) => {
  text: string;
  slug: Url;
};
const mapPromotionSliderItemAction: MapPromotionsSliderItemAction =
  mapper([
    { name: 'text', from: 'text' },
    { name: 'url', from: 'url' },
    { name: 'userState', from: 'userState' },
  ]);

type MapPromotionCarouselSliderItem = (data: any) => PromotionDetailsPageUserCarouselSliderItem;
const mapPromotionCarouselSliderItem: MapPromotionCarouselSliderItem = mapper([
  { name: 'id', from: 'id' },
  {
    name: 'actions',
    from: 'actions',
    transform: (actionData: Array<any>) => {
      return actionData?.map(mapPromotionSliderItemAction);
    }
  },
  {
    name: 'variant1ImgSrc',
    from: 'variant1ImgSrc.data.attributes.url',
  },
  {
    name: 'variant2ImgSrc',
    from: 'variant2ImgSrc.data.attributes.url',
  },
  { name: 'bannerUrl', from: 'bannerUrl' },
  {
    name: 'isVariant1ItemLooped',
    from: 'isVariant1ItemLooped'
  },
  {
    name: 'isVariant2ItemLooped',
    from: 'isVariant2ItemLooped'
  }
]);

type MapHowItWorksBlockItem = (data: any) => HowItWorksBlockItem;
const mapHowItWorksBlockItem: MapHowItWorksBlockItem = mapper([
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
  { name: 'details', from: 'details' },
]);

type MapFaqBlockItem = (data: any) => FaqBlockItem;
const mapFaqBlockItem: MapFaqBlockItem = mapper([
  { name: 'summary', from: 'summary' },
  { name: 'details', from: 'details' },
]);

export type MapDynamicPageState = (data: any) => PromotionDetailsPageState;
export const mapPromotionDetailsPageState: MapDynamicPageState = mapper([
  { name: 'title', from: 'attributes.title' },
  { name: 'description', from: 'attributes.description' },
  { name: 'keywords', from: 'attributes.keywords' },
  { name: 'canonical', from: 'attributes.canonical' },
  { name: 'slug', from: 'attributes.slug' },
  {
    name: 'slider',
    from: 'attributes.carouselSlider',
    transform: (sliderData: Array<any>) => {
      const actions = sliderData?.map(sliderItemData => mapPromotionCarouselSliderItem(sliderItemData));
      return actions;
    }
  },
  {
    name: 'action',
    from: 'attributes.action',
    transform: (actionData: Array<any>) => {
      return actionData?.map(actionItem => mapActionItem(actionItem));
    }
  },
  {
    name: 'howItWorksBlock',
    from: 'attributes',
    transform: (attributesData: Maybe<{ howItWorksBlockTitle: any, howItWorksBlock: any }>) => {
      if (!attributesData) return null;
      const title = attributesData?.howItWorksBlockTitle;
      const items = attributesData?.howItWorksBlock?.map(
        (boxedFaqBlockItemData: any) => mapHowItWorksBlockItem(boxedFaqBlockItemData)
      );
      return { title, items };
    }
  },
  {
    name: 'faqBlock',
    from: 'attributes',
    transform: (attributesData: Maybe<{ faqBlock: any }>) => {
      if (!attributesData) return null;
      const items = attributesData?.faqBlock?.map((data: any) => mapFaqBlockItem(data));
      return { items };
    }
  },
  { 
    name: 'games', 
    from: 'attributes.games.data', 
    transform: (games: Array<any>) => games?.map(mapGamesItem) || [] },
]);