import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { playGamePageApiSlice } from '../api';
import { type PlayGameItem, type PlayGamePageState } from './types';

const initialState: PlayGamePageState = {
  game: null,
  isFullScreen: false
};

export const playGamePageSlice = createSlice({
  name: 'play-game-page',
  initialState,
  reducers: {
    openInFullScreen: (state: PlayGamePageState) => {
      state.isFullScreen = true;
    },
    closeFullScreen: (state: PlayGamePageState) => {
      state.isFullScreen = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<PlayGamePageState>) => {
    builder.addMatcher(
      playGamePageApiSlice.endpoints.getGame.matchFulfilled,
      (state: PlayGamePageState, action: PayloadAction<PlayGameItem>) => {
        state.game = action.payload;
      }
    );
  },
});

export const {
  openInFullScreen,
  closeFullScreen
} = playGamePageSlice.actions;

export const playGamePageReducer = playGamePageSlice.reducer;
