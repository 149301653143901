import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  type SessionState,
  sessionSlice,
  UserKycStatusEnum,
} from '@entities/session';

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectIsPlayerVerified = (state: RootState): boolean => {
  const fullProfile = state[sessionSlice.name]?.fullProfile;
  return fullProfile?.kycStatus === UserKycStatusEnum.Pass &&
    fullProfile?.registrationStatus === UserRegistrationStatusEnum.Player;
};

export const useBuyCoinsSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;