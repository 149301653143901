import { BaseSyntheticEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import {
  Link,
  Button,
  OtpInput,
  useToaster
} from '@shared/ui';
import {
  isEmpty,
  otpSchema,
  useModalQueryParam
} from '@shared/lib';
import {
  type OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper
} from '@shared/api';
import {
  useLazyResendVerificationCodeQuery,
  useLazySignUpConfirmationQuery
} from '@entities/session';
import {
  useAuthViaSocialsSliceSelector,
  selectIsOpenConfirmationAuthViaSocialsModal,
  selectPropsToAuthViaSocials,
  closeConfirmationAuthViaSocialsModal,
  openSuccessConfirmationAuthViaSocialsModal,
  removePropsToAuthViaSocials
} from '../model';

export type ConfirmationAuthViaSocialsModalProps = {
  onAuthConfirmation(method: string): void;
}

export const ConfirmationAuthViaSocialsModal: FC<ConfirmationAuthViaSocialsModalProps> = ({ onAuthConfirmation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toaster = useToaster()
  const { openModal } = useModalQueryParam();

  const isOpenConfirmationSignUpModal = useAuthViaSocialsSliceSelector(selectIsOpenConfirmationAuthViaSocialsModal);
  const propsToSocialLogin = useAuthViaSocialsSliceSelector(selectPropsToAuthViaSocials);

  const [signUpConfirmation, { isFetching }] = useLazySignUpConfirmationQuery();
  const [resendVerificationCode] = useLazyResendVerificationCodeQuery();

  const {
    control,
    formState,
    setError,
    setFocus,
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { otp: '' },
  });

  const handleSendConfirmationCode = async (
    formData: { otp: string },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const { email, consentedVersions } = propsToSocialLogin ?? { email: '', consentedVersions: '' };
    const { data } = await signUpConfirmation({
      confirmationCode: formData.otp,
      email: email ?? '',
      ...(consentedVersions && { consentedVersions })
    } as any);
    const { status, message, errors } = data as OmegaApiResponse;

    if (status === OmegaApiResponseStatusEnum.Fail) {
      const { field, error } = errors?.[0] as any;
      const fieldName = field === 'confirmationCode'
        ? 'otp'
        : 'root.serverError';
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
      return;
    }

    if (status !== OmegaApiResponseStatusEnum.Success) {
      return toaster.error({
        message: message ?? 'Something went wrong',
        autoHideDuration: 3000
      })
    }

    if (status === OmegaApiResponseStatusEnum.Success) {
      const method = propsToSocialLogin?.socialType === 'facebook'
        ? 'facebook'
        : 'google';
      onAuthConfirmation(method);
      dispatch(closeConfirmationAuthViaSocialsModal());
      dispatch(openSuccessConfirmationAuthViaSocialsModal());
    }
  };

  const handleResend = async (): Promise<void> => {
    const { data } = await resendVerificationCode({
      username: propsToSocialLogin?.email ?? '',
      verificationTarget: 'email'
    });
    const { errors } = data as OmegaApiResponse;

    if (!isEmpty(errors)) {
      const { error } = errors?.[0] as any;
      const fieldName = 'root.serverError';
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
    } else {
      toaster.success({ message: 'New code sent successfully' });
    }
  };

  const handleTryAnotherMethod = (): void => {
    dispatch(removePropsToAuthViaSocials());
    dispatch(closeConfirmationAuthViaSocialsModal());
    navigate('/');
    openModal('sign-up');
  };

  const handleFocus = (): void => {
    if (formState.errors.otp || formState.errors.root) {
      reset({});
    }
  };

  return (
    <Modal
      open={isOpenConfirmationSignUpModal}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints }) => ({
          maxWidth: 358,
          width: '100%',
          paddingBlock: 3,
          paddingInline: 3,
          [breakpoints.down(390)]: {
            maxWidth: 'calc(100vw - 2rem)',
          },
        })}>
        <Stack
          component='form'
          onSubmit={handleSubmit(handleSendConfirmationCode)}
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}>
          <Typography
            sx={({ palette }) => ({
              color: palette.common[700],
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%',
              textAlign: 'center'
            })}>
            Welcome to SweepLuxe
          </Typography>
          <Typography
            sx={({ palette }) => ({
              color: palette.common[150],
              textAlign: 'left',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%'
            })}>
            Claim your Sign Up Bonus by verifying your account with OTP received on your phone or email!
          </Typography>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1}
            sx={({ palette }) => ({
              background: palette.common[475],
              borderColor: palette.common[925],
              borderStyle: 'solid',
              borderWidth: 1,
              width: '100%',
              paddingBlock: 1,
              paddingInline: 2,
              borderRadius: 8,
            })}>
            <Box
              width={22}
              height={28}
              component='img'
              loading='lazy'
              src='/assets/webp/sign_up_bonus.webp'
            />
            <Typography sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: ' 20px',
              '& span': {
                color: palette.common[1075]
              }
            })}>
              5,000 GC
            </Typography>
          </Stack>
          <Controller
            disabled={isFetching}
            name='otp'
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <OtpInput
                disabled={isFetching}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={handleFocus}
                error={formState.errors?.otp?.message}
              />
            )}
          />
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            gap={0.5}>
            <Typography sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '142.857%'
            })}>
              Didn’t receive a password?
            </Typography>
            <Stack alignItems='center'>
              <Typography sx={{
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '142.857%'
              }}>
                <Link
                  color='primary'
                  underline='always'
                  disabled={isFetching}
                  onClick={handleResend}>
                  Send new OTP
                </Link>
              </Typography>
              <Divider
                orientation='horizontal'
                sx={{
                  marginBlock: 1,
                  width: '127px',
                  margin: 'auto'
                }}>
                or
              </Divider>
              <Typography sx={{
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '142.857%'
              }}>
                <Link
                  color='primary'
                  underline='always'
                  disabled={isFetching}
                  onClick={handleTryAnotherMethod}>
                  Try another registration method
                </Link>
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            width='100%'
            sx={{
              '& .MuiButton-root': {
                width: '100%'
              }
            }}>
            <Button
              disabled={!formState.isValid || isFetching}
              loading={isFetching}
              color='primary'
              variant='solid'
              type='submit'>
              Submit
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};