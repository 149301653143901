import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { type TournamentsPageState } from './types';
import { tournamentsPageSlice } from './slice';

type RootState = {
  [tournamentsPageSlice.name]: TournamentsPageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[tournamentsPageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[tournamentsPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[tournamentsPageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[tournamentsPageSlice.name]?.canonical
);

export const selectHeaderText = (state: RootState): Maybe<string> => (
  state[tournamentsPageSlice.name]?.headerText
);

export const selectIsLoading = (state: RootState): boolean => (
  state[tournamentsPageSlice.name].isLoading
)

export const useTournamentsPageSelector: TypedUseSelectorHook<RootState> = useSelector;
