
import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const CheckCircleIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g id='check-circle' clipPath='url(#clip0_1905_138202)'>
        <path
          id='Icon'
          d='M14.6663 7.38625V7.99958C14.6655 9.4372 14.2 10.836 13.3392 11.9875C12.4785 13.1389 11.2685 13.9812 9.88991 14.3889C8.5113 14.7965 7.03785 14.7475 5.68932 14.2493C4.3408 13.7511 3.18944 12.8303 2.40698 11.6243C1.62452 10.4183 1.25287 8.99163 1.34746 7.55713C1.44205 6.12263 1.99781 4.75713 2.93186 3.6643C3.86591 2.57146 5.1282 1.80984 6.53047 1.49301C7.93274 1.17619 9.39985 1.32114 10.713 1.90625M14.6663 2.66625L7.99968 9.33958L5.99968 7.33958'
          stroke='white'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_1905_138202'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

