import { baseOmegaApi, SESSION_TAG } from '@shared/api';
import { toQueryString } from '@shared/lib';
import {
  type SignUp,
} from '../model';

export const signUpApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    quickSignUp: build.query({
      query: (arg: SignUp) => ({ url: `ips/quickSignup${toQueryString(arg, { allowDuplicates: true })}` }),
      extraOptions: { showLoader: false },
      providesTags: [SESSION_TAG],
    }),
  }),
});

export const {
  useLazyQuickSignUpQuery,
  useQuickSignUpQuery,
} = signUpApiSlicer;
