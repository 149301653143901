import { mapper } from '@shared/lib';
import { mapPromotionCard } from './map-promotion-card';
import { PromotionsPageState } from '../model';

type MapPromotionCards = (data: any) => Pick<PromotionsPageState, 'promotionCards'>
export const mapPromotionCards: MapPromotionCards = mapper([
  { 
    name: 'promotionCards', 
    from: 'promotionCards',
    transform: (data: Array<any>) => data.map(mapPromotionCard) 
  }
])