import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { signUpSlice } from './slice';
import { type SignUpState, type SignUp } from './types';

type RootState = {
  [signUpSlice.name]: SignUpState
};

export const selectIsOpenConfirmationSignUpModal = (state: RootState): boolean => (
  state[signUpSlice.name].openConfirmationSignUpModal
);

export const selectIsOpenSuccessConfirmationSignUpModal = (state: RootState): boolean => (
  state[signUpSlice.name].openSuccessConfirmationSignUpModal
);

export const selectPropsToSignUp = (state: RootState): Maybe<SignUp> => (
  state[signUpSlice.name].propsToSignUp
);

export const useSignUpSelector: TypedUseSelectorHook<{
  [signUpSlice.name]: SignUpState;
}> = useSelector;
