import { FC, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Skeleton, Tab, TabList, Tabs } from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useQueryParamValue } from '@shared/lib';
import {
  selectIsCategoriesContainKey,
  selectPromotionCategories,
  selectSelectedCategoryKey,
  usePromotionsFilterByCategorySelector
} from '../model';
import { useLazyGetPromotionsCategoriesQuery } from '../api';

export const PromotionsCategoriesSelect: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const { modifyParam } = useQueryParamValue('category');
  const [searchParams] = useSearchParams();

  const categories = usePromotionsFilterByCategorySelector(selectPromotionCategories);
  const selectedCategoryKey = usePromotionsFilterByCategorySelector(selectSelectedCategoryKey);
  const [getPromotionCategories, { isFetching }] = useLazyGetPromotionsCategoriesQuery();
  const isCategoryContainKey = usePromotionsFilterByCategorySelector(selectIsCategoriesContainKey);
  const tabRefs = useRef<any>({});

  useEffect(() => {
    getPromotionCategories()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (selectedCategoryKey) {
      const activeTabRef = tabRefs.current[selectedCategoryKey];
      if (isMobile && activeTabRef) {
        activeTabRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [selectedCategoryKey, isMobile]);

  useEffect(() => {
    if (
      (categories && categories.length && !selectedCategoryKey && !searchParams.has('category')) ||
      (categories && selectedCategoryKey && !isCategoryContainKey)
    ) {
      const categoryKey = 'view-all';
      modifyParam(categoryKey);
    }
  }, [selectedCategoryKey, searchParams, modifyParam, categories, isCategoryContainKey]);

  const handleChange = (_: any, tab: Maybe<number | string>) => {
    if (typeof tab === 'string') {
      modifyParam(tab);
    }
  };

  return (
    <Tabs
      onChange={handleChange}
      value={selectedCategoryKey}
      sx={({ breakpoints }) => ({
        left: '50%',
        transform: 'translateX(-50%)',
        [breakpoints.down(912)]: {
          left: 0,
          transform: 'translateX(0)',
          alignSelf: 'start',
          flexWrap: 'wrap',
          width: '100vw',
          maxWidth: 'fit-content',
          overflowX: 'auto',
          scrollbarWidth: 'none',
        },
      })}>
      <TabList
        disableUnderline
        sx={{
          p: '0.1875rem',
          gap: '0.1875rem',
        }}>
        {isFetching ? (
          Array(3)
            .fill(null)
            .map((_, i) => (
              <Tab disableIndicator key={i}>
                <Skeleton
                  variant='circular'
                  sx={{
                    width: '1rem',
                    height: '1rem',
                  }}
                />
                <Skeleton
                  variant='text'
                  sx={{
                    width: '3.125rem',
                  }}
                />
              </Tab>
            ))) : (
          categories?.map(({ id, key, name }) => (
            <Tab
              key={id}
              value={key}
              ref={(el) => (tabRefs.current[key] = el)}
              disableIndicator
              sx={({ breakpoints }) => ({
                textWrap: 'nowrap',
                whiteSpace: 'nowrap',
                width: '11rem',
                [breakpoints.down(912)]: {
                  width: '6rem',
                },
              })}>
              {name}
            </Tab>
          ))
        )}
      </TabList>
    </Tabs>
  );
};