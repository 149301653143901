import { ComponentType } from 'react';
import { useGetGamesCategoriesQuery } from '@entities/games';

export const withGameCategories =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      useGetGamesCategoriesQuery();

      return <WrappedComponent {...props} />;
    };
