import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { documentsApiSlice } from '../api';
import { mapDocumentTypes, mapUserDocument, mapUserDocuments } from '../lib';
import { UserDocument, type DocumentsState } from './types';

export const initialState: DocumentsState = {
  documentTypes: [],
  userDocuments: [],
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        documentsApiSlice.endpoints.uploadUserDocument.matchFulfilled,
        (state: DocumentsState, action: PayloadAction<any>) => {
          const { status } = action.payload as OmegaApiResponse;
          if (status === OmegaApiResponseStatusEnum.Success) {
            const mappedUserDocument: UserDocument = mapUserDocument(action.payload.userDocument);
            state.userDocuments = [...state.userDocuments, mappedUserDocument];
          }
        }
      )
      .addMatcher(
        documentsApiSlice.endpoints.getDocumentTypes.matchFulfilled,
        (state: DocumentsState, action: PayloadAction<any>) => {
          state.documentTypes = mapDocumentTypes(action.payload).documentTypes;
        }
      )
      .addMatcher(
        documentsApiSlice.endpoints.getUserDocuments.matchFulfilled,
        (state: DocumentsState, action: PayloadAction<any>) => {
          state.userDocuments = mapUserDocuments(action.payload).userDocuments;
        }
      );
  },
});

export const documentsReducer = documentsSlice.reducer;
