import { FC } from 'react';
import { Stack, Typography, Box } from '@mui/joy';
import { TextWithInlineLink, IconLink } from '@shared/ui';
import {
  TextWithInlineLink as TextWithInlineLinkType,
  IconLink as IconLinkType,
  isEmpty
} from '@shared/lib';

type ContactUsBlockItem = {
  title: string;
  iconSrc: Maybe<Url>;
  description: Maybe<TextWithInlineLinkType>;
  icons: Maybe<Array<IconLinkType>>;
};

export type ContactUsBlockProps = {
  title?: Maybe<string>;
  items?: Maybe<Array<ContactUsBlockItem>>;
};

export const BoxedContactUsBlock: FC<ContactUsBlockProps> = ({ title, items }) => {
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='center'
      sx={{
        gap: 2,
        width: '100%',
      }}>
      {title && (
        <Typography
          sx={({ colorSchemes }) => ({
            width: '100%',
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '36x',
            letterSpacing: 0,
            textAlign: 'start',
            color: colorSchemes.dark.palette.common.white,
          })}>
          {title}
        </Typography>
      )}
      <Box
        sx={({ breakpoints }) => ({
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 2.5,
          [breakpoints.down(769)]: {
            gridTemplateColumns: '1fr',
            gap: 1,
          },
        })}>
        {items?.map(({ title, description, iconSrc, icons }) => (
          <Stack
            key={title}
            direction='row'
            alignItems='flex-start'
            justifyContent='center'
            position='relative'
            sx={({ colorSchemes }) => ({
              borderRadius: 8,
              background: colorSchemes.dark.palette.common[475],
            })}>
            {iconSrc && (
              <Stack
                alignItems='center'
                justifyContent='center'
                borderRadius={50}
                position='absolute'
                sx={({ breakpoints }) => ({
                  width: 50,
                  height: 50,
                  top: 16,
                  left: 16,
                  [breakpoints.down(490)]: {
                    width: 32,
                    height: 32,
                    top: 12,
                    left: 12,
                  },
                })}>
                <Box
                  component='img'
                  loading='lazy'
                  src={iconSrc}
                  sx={({ breakpoints }) => ({
                    width: 50,
                    height: 50,
                    [breakpoints.down(490)]: {
                      width: 26,
                      height: 26,
                    },
                  })}
                />
              </Stack>
            )}
            <Stack
              direction='column'
              justifyContent='center'
              alignItems='center'
              gap={1.5}
              sx={({ breakpoints }) => ({
                paddingInline: 3,
                paddingBlock: 3,
                [breakpoints.down(490)]: {
                  paddingInline: 1.5,
                  paddingBlock: 1.5,
                },
              })}>
              <Typography
                sx={({ breakpoints }) => ({
                  fontSize: 28,
                  fontWeight: 600,
                  lineHeight: '36px',
                  letterSpacing: 0,
                  textAlign: 'center',
                  [breakpoints.down(490)]: {
                    fontSize: 20,
                    lineHeight: '30px',
                  },
                })}>
                {title}
              </Typography>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='center'
                gap={2}>
                {description && (
                  <TextWithInlineLink
                    data={description}
                    typographyProps={{
                      sx: ({ breakpoints }) => ({
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: '24px',
                        letterSpacing: 0,
                        textAlign: 'center',
                        [breakpoints.down(769)]: {
                          fontSize: 16,
                        },
                      }),
                    }}
                  />
                )}
                {!isEmpty(icons) && (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='flex-start'
                    columnGap={2}>
                    {icons?.map(({ href, iconSrc }) => (
                      <IconLink
                        href={href ?? ''}
                        iconSrc={iconSrc ?? ''}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};
