import { isDefined } from './is-defined';

type ThrowErrorIfNotDefinedCheckableValue = {
  errorMessage: string;
  value: unknown;
};

export const throwErrorIfNotDefined = (
  checkableValues:
    | ThrowErrorIfNotDefinedCheckableValue[]
    | ThrowErrorIfNotDefinedCheckableValue
): void => {
  const tryToThrow = (checkableValue: ThrowErrorIfNotDefinedCheckableValue) => {
    if (!isDefined(checkableValue.value))
      throw new Error(checkableValue.errorMessage);
  };

  if (Array.isArray(checkableValues)) {
    checkableValues.forEach(tryToThrow);
  } else {
    tryToThrow(checkableValues);
  }
};