import {
  mapper,
  mapCarouselSliderItem,
  mapActionItem,
} from '@shared/lib';
import { mapGamesItem } from '@entities/games';
import { type TournamentDetailsPageState } from '../model';

type MapTournamentDetailsPageState = (data: any) => TournamentDetailsPageState;
export const mapTournamentDetailsPageState: MapTournamentDetailsPageState = mapper([
  { name: 'title', from: 'attributes.title' },
  { name: 'description', from: 'attributes.description' },
  { name: 'keywords', from: 'attributes.keywords' },
  { name: 'canonical', from: 'attributes.canonical' },
  { name: 'tournamentId', from: 'attributes.tournamentId' },
  { name: 'heading', from: 'attributes.heading' },
  {
    name: 'slider',
    from: 'attributes.carouselSlider',
    transform: (sliderItemsData: Array<any>) => sliderItemsData?.map(mapCarouselSliderItem),
  },
  { name: 'prizeGC', from: 'attributes.prizeGC' },
  { name: 'prizeSC', from: 'attributes.prizeSC' },
  {
    name: 'action',
    from: 'attributes.action',
    transform: (actionItemsData: Array<any>) => actionItemsData?.map(mapActionItem),
  },
  { name: 'leaderTitle', from: 'attributes.leaderTitle' },
  { name: 'htmlBlockTitle', from: 'attributes.htmlBlockTitle' },
  { name: 'htmlBlock', from: 'attributes.htmlBlock' },
  { 
    name: 'games', 
    from: 'attributes.games.data', 
    transform: (games: Array<any>) => games?.map(mapGamesItem) || [] },
]);
