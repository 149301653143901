export const isExternalUrl = (url: string): boolean => {
  const externalPattern = /^(http|https):\/\//i;
  if (externalPattern.test(url)) {
    return true;
  }

  const internalPattern = /^\/|^\.\/|^\.\.\//i;

  if (internalPattern.test(url)) {
    return false;
  }

  const domainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/|$)/;
  return domainPattern.test(url);
};