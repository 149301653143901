import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { documentsSlice } from './slice';
import {
  type DocumentType,
  type DocumentsState,
  type UserDocument,
} from './types';

type RootState = {
  [documentsSlice.name]: DocumentsState;
};

export const selectDocumentTypes = (state: RootState): Array<DocumentType> => (
  state[documentsSlice.name].documentTypes
);

export const selectUserDocuments = (state: RootState): Array<UserDocument> => (
  state[documentsSlice.name].userDocuments
);

export const useDocumentsSelector: TypedUseSelectorHook<{
  [documentsSlice.name]: DocumentsState;
}> = useSelector;
