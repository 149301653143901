import { FC } from 'react';
import { Button, ButtonProps } from '@shared/ui';
import { useModalQueryParam } from '@shared/lib';

export type SignUpButtonProps = Pick<
  ButtonProps,
  'color' | 'size' | 'variant' | 'children' | 'sx'
> & {
  onOpenSignUpModal?(): void;
};

export const SignUpButton: FC<SignUpButtonProps> = ({
  children,
  size = 'lg',
  onOpenSignUpModal,
  ...props
}) => {
  const { openModal } = useModalQueryParam();
  const handleOpenSignUpModal = (): void => {
    onOpenSignUpModal?.();
    openModal('sign-up');
  };

  return (
    <Button
      color='success'
      size={size}
      sx={{
        fontWeight: 700
      }}
      onClick={handleOpenSignUpModal}
      {...props}>
      {children}
    </Button>
  );
};