import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GamesWithPagination } from '@entities/games';
import { fetchGamesBySearch } from '../api';
import { type GamesFilterBySearchState } from './types';

export const initialState: GamesFilterBySearchState = {
  games: [],
  pagination: null,
  search: '',
  isLoading: false,
};

export const gamesFilteredBySearchSlice = createSlice({
  name: 'games-filter-by-search',
  initialState,
  reducers: {
    setSearch: (state: GamesFilterBySearchState, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamesBySearch.pending, (state: GamesFilterBySearchState) => {
        state.isLoading = true;
      })
      .addCase(
        fetchGamesBySearch.fulfilled,
        (state: GamesFilterBySearchState, action: PayloadAction<GamesWithPagination>) => {
          const { games, pagination } = action.payload;

          state.games = pagination?.page === 1 ? games : state.games.concat(games);
          state.pagination = pagination;
          state.isLoading = false;
        }
      );
  },
});

export const gamesFilteredBySearchReducer = gamesFilteredBySearchSlice.reducer;

export const { setSearch } = gamesFilteredBySearchSlice.actions;
