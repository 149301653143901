import { useRef } from 'react';
import {
  OverridableTokenClientConfig,
  TokenResponse,
  useGoogleLogin as useGoogleOAuthLogin,
} from '@react-oauth/google';

const GOOGLE_SCOPES = [
  'openid',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email'
].join(' ');

export type UseGoogleAuthentication = {
  authenticateWithGoogle(
    overrideConfig?: Maybe<OverridableTokenClientConfig>
  ): Promise<boolean>;
};

export type UseGoogleAuthenticationProps = {
  onError?: (error: any) => void;
  onSuccess(tokenResponse: TokenResponse): void;
};

export const useGoogleAuthentication = (
  { onSuccess, onError }: UseGoogleAuthenticationProps
): UseGoogleAuthentication => {
  const authenticateResolveCallbackRef =
    useRef<Maybe<(value: boolean | PromiseLike<boolean>) => void>>(null);

  const googleLogin = useGoogleOAuthLogin({
    scope: GOOGLE_SCOPES,


    onError(error: any) {
      authenticateResolveCallbackRef.current?.(false);
      console.error(error.message);
      onError?.(error);
    },
    onSuccess(tokenResponse: TokenResponse) {
      authenticateResolveCallbackRef.current?.(true);
      onSuccess(tokenResponse);
    },
  });

  const authenticateWithGoogle = (
    overrideConfig?: Maybe<OverridableTokenClientConfig>
  ): Promise<boolean> => {
    const resultPromise = new Promise<boolean>(
      (resolve) => authenticateResolveCallbackRef.current = resolve
    );
    googleLogin(overrideConfig as OverridableTokenClientConfig);
    return resultPromise;
  };

  return { authenticateWithGoogle };
};