import { baseApi, DYNAMIC_PAGE_TAG } from '@shared/api';
import { buildStrapiQuery, type Filter } from '@shared/lib';
import { PromotionDetailsPageState } from '../model';
import { mapPromotionDetailsPageState } from '../lib';

// TODO: fix it on backend
const slug = '/api/promotion-details-pages?populate[carouselSlider][populate][variant1ImgSrc][populate]=true&populate[carouselSlider][populate][variant2ImgSrc][populate]=true&populate[carouselSlider][populate][isVariant1ItemLooped][populate]=true&populate[carouselSlider][populate][isVariant2ItemLooped][populate]=true&populate[carouselSlider][populate][actions][populate]=true&populate[action][populate]=true&populate[faqBlock][populate]=true&populate[howItWorksBlock][populate][iconSrc][populate]=true&populate[howItWorksBlockTitle][populate]=true';

export const promotionDetailsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotionDetailsPage: build.query<PromotionDetailsPageState, Filter>({
      query: (filter: Filter) => ({
        url: `${slug}&${buildStrapiQuery(filter)}`
      }),
      extraOptions: { showLoader: true, auth: true },
      providesTags: [DYNAMIC_PAGE_TAG],
      transformResponse: (response: { data: unknown[] }) =>
        mapPromotionDetailsPageState(response.data[0]),
    })
  }),
});

export const {
  useGetPromotionDetailsPageQuery
} = promotionDetailsPageApiSlice;
