import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  VerificationMethodEnum,
  SessionState,
  sessionSlice
} from '@entities/session';

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectIsEmailVerificationMethod = (state: RootState): boolean => (
  state[sessionSlice.name]?.fullProfile?.verificationMethod === VerificationMethodEnum.Email
);

export const useVerifyEmailSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;