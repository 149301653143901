import * as Yup from 'yup';
import {
  phoneSchema,
  passwordSchema,
  userAgreementSchema
} from '@shared/lib';

export const signUpByPhoneSchema = phoneSchema
  .concat(passwordSchema)
  .concat(userAgreementSchema)
  .concat(
    Yup.object().shape({
      smsSubscriptionConfirmation: Yup.boolean()
        .required()
    })
  )