import { FC, PropsWithChildren, useMemo, useState } from "react";
import { ToastSnackbar } from "../ui";
import { Toast, ToastOptions, ToastColor } from "./types";
import { ToastContext } from "./context";

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<Toast>>([])

  const handleOpen = (type: ToastColor) => (data: ToastOptions) => {
    setToasts((state) =>
      [...state, {
        id: new Date().getTime(),
        type,
        message: data.message,
        autoHideDuration: data.autoHideDuration || 3000
      }]
    )
  }

  const handleClose = () => {
    const newToasts = toasts.slice(1);
    setToasts(newToasts)
  }

  const activeToast = useMemo(() => toasts[0], [toasts])

  return (
    <ToastContext.Provider value={{
      error: handleOpen('danger'),
      success: handleOpen('success'),
    }
    } >
      {children}
      {
        activeToast && (
          <ToastSnackbar
            key={activeToast.id}
            open={!!activeToast}
            onClose={handleClose}
            message={activeToast.message}
            autoHideDuration={activeToast.autoHideDuration}
            color={activeToast.type}
          />
        )
      }
    </ToastContext.Provider>
  );
};

