import { createSelector } from 'reselect';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  type Challenge,
  type ChallengesState,
  ChallengeStatusEnum,
  challengesSlice
} from '@entities/challenges';

dayjs.extend(utc);

type RootState = {
  [challengesSlice.name]: ChallengesState;
};

const selectChallenges = (state: RootState) => state[challengesSlice.name].challenges;

export const selectActiveOrCompletedChallenges = createSelector(
  [selectChallenges],
  (challenges) =>
    challenges.filter(({ status, endDate }) =>
      status === (ChallengeStatusEnum.Active || status === ChallengeStatusEnum.Completed)
      && dayjs(endDate).utc().isAfter(dayjs().utc())
    )
);

export const selectGroupsActiveOrCompletedChallenges = createSelector(
  [selectActiveOrCompletedChallenges],
  (activeOrCompletedChallenges) =>
    activeOrCompletedChallenges.reduce((acc, curr, index) => {
      if (index % 3 === 0) acc.push([]);
      acc[acc.length - 1].push(curr);
      return acc;
    }, new Array<Array<Challenge>>())
);

export const useChallengeListSelector: TypedUseSelectorHook<RootState> = useSelector;
