import { CarouselSliderItem } from '@shared/types';
import { mapper } from './mapper';

export type ImageSources = {
  variant1SizeImgSrc: Url;
  variant2SizeImgSrc: Url;
};
type MapImageSources = (data: any) => ImageSources;
export const mapImageSources: MapImageSources = mapper([
  { name: 'variant1SizeImgSrc', from: 'url' },
  { name: 'variant2SizeImgSrc', from: 'url' },
]);

export type TextWithInlineLink = {
  combinedText: string;
  linkKey: string;
  anchorText: string;
  href?: Url;
  underline: 'hover' | 'always' | 'none';
};
type MapTextWithInlineLink = (data: any) => TextWithInlineLink;
export const mapTextWithInlineLink: MapTextWithInlineLink = mapper([
  { name: 'combinedText', from: 'combinedText' },
  { name: 'linkKey', from: 'linkKey' },
  { name: 'anchorText', from: 'anchorText' },
  { name: 'href', from: 'href' },
]);

export type IconLink = {
  iconSrc: Maybe<Url>;
  href: Maybe<Url>;
};
export type MapIconLinkItem = (data: any) => IconLink;
export const mapIconLink: MapIconLinkItem = mapper([
  { name: 'href', from: 'href' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
]);

export type CarouselSliderItemAction = (data: any) => {
  text: string;
  slug: Url;
};
export const mapActionItem: CarouselSliderItemAction =
  mapper([
    { name: 'text', from: 'text' },
    { name: 'url', from: 'url' },
    { name: 'userState', from: 'userState' },
  ]);

type MapCarouselSliderItem = (data: any) => CarouselSliderItem;
export const mapCarouselSliderItem: MapCarouselSliderItem = mapper([
  { name: 'id', from: 'id' },
  {
    name: 'actions',
    from: 'actions',
    transform: (actionData: Array<any>) =>
      actionData?.map(mapActionItem),
  },
  {
    name: 'variant1ImgSrc',
    from: 'variant1ImgSrc.data.attributes.url',
  },
  {
    name: 'variant2ImgSrc',
    from: 'variant2ImgSrc.data.attributes.url',
  },
  { name: 'bannerUrl', from: 'bannerUrl' },
  {
    name: 'isVariant1ItemLooped',
    from: 'isVariant1ItemLooped'
  },
  {
    name: 'isVariant2ItemLooped',
    from: 'isVariant2ItemLooped'
  }
]);
