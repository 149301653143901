import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import { Input } from '@mui/joy';
import {
  Close,
  SearchRounded
} from '@mui/icons-material';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import { IconButton } from '@shared/ui';
import { debounce } from '@shared/lib';
import {
  selectSearch,
  setSearch,
  useGamesFilterBySearchSelector
} from '../model';
import { fetchGamesBySearch } from '../api';

const AWAIT_TIME = 500;

export type SearchProps = {
  onSearch(): void;
  onClear(): void;
};

export const Search: FC<SearchProps> = ({ onSearch, onClear }) => {
  // TODO: fix this cringe useDispatch generic
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const search = useGamesFilterBySearchSelector(selectSearch);

  const clearSearch = (): void => {
    dispatch(setSearch(''));
    onClear();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce(async (value: string) => {
      if (value.length >= 3) {
        await dispatch(fetchGamesBySearch({
          search: value,
          page: 1,
          pageSize: isMobile
            ? 12
            : 24
        })).unwrap();
        onSearch();
      }

      if (!value.length) {
        clearSearch();
      }
    }, AWAIT_TIME),
    [dispatch, isMobile]
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    dispatch(setSearch(value));
    searchGames(value);
  };

  const handleClear = clearSearch;

  return (
    <Input
      className='search-input'
      onChange={handleSearch}
      placeholder='Search'
      value={search}
      startDecorator={<SearchRounded sx={{ fontSize: '1.5rem' }} />}
      endDecorator={
        <IconButton onClick={handleClear}>
          <Close sx={{ fontSize: '1.5rem' }} />
        </IconButton>
      }
      sx={({ palette, breakpoints }) => ({
        width: '22.375rem',
        border: 'none',
        padding: '0.5rem 1rem',
        background: palette.common[900],
        borderColor: 'transparent',
        ':hover': {
          boxShadow: 'none',
        },
        '&.Mui-focused': {
          boxShadow: 'none',
        },
        [breakpoints.down(912)]: {
          maxWidth: '100%',
          background: palette.common[475],
        },
      })}
    />
  );
};
