import { FC, ReactNode, useState, useRef, useCallback, useEffect } from 'react';
import { SocketContext } from './use-socket';

export interface SocketProviderProps {
  children: ReactNode;
}

export const SocketProvider: FC<SocketProviderProps> = ({ children }) => {
  const [isConnectedRecords, setIsConnectedRecords] = useState<Record<string, boolean>>({});
  const socketsRef = useRef<Record<string, Maybe<WebSocket>>>({});

  const connectSocket = useCallback((channelId: string, url: string) => {
    const currentSocket: Maybe<WebSocket> = socketsRef.current[channelId];
    if (!currentSocket || currentSocket.readyState !== WebSocket.OPEN) {
      socketsRef.current[channelId] = new WebSocket(url);
      socketsRef.current[channelId]!.onopen = () =>
        setIsConnectedRecords((prevConnectedRecords) => ({ ...prevConnectedRecords, [channelId]: true }));
      socketsRef.current[channelId]!.onclose = () =>
        setIsConnectedRecords((prevConnectedRecords) => ({ ...prevConnectedRecords, [channelId]: false }));
    }
  }, []);

  const disconnectSocket = useCallback((channelId: string) => {
    if (socketsRef.current[channelId]) {
      socketsRef.current[channelId]!.close();
      socketsRef.current[channelId] = null;
      setIsConnectedRecords((prevConnectedRecords) => ({ ...prevConnectedRecords, [channelId]: false }));
    }
  }, []);

  const subscribe = useCallback((channelId: string, handler: (data: any) => void) => {
    if (!socketsRef.current[channelId]) return () => {};
    socketsRef.current[channelId]!.onmessage = handler;
    return () => {
      if (socketsRef.current[channelId]) socketsRef.current[channelId]!.onmessage = () => {};
    };
  }, []);

  useEffect(() => {
    return () => {
      //eslint-disable-next-line
      Object.keys(socketsRef.current).forEach((key: string) => {
        disconnectSocket(key);
      });
    };
  }, [disconnectSocket]);

  return (
    <SocketContext.Provider
      value={{
        isConnectedRecords,
        connectSocket,
        disconnectSocket,
        subscribe,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
