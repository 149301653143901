import * as Yup from 'yup';

export const userAgreementSchema = Yup.object().shape({
  ageAndStateConfirmation: Yup.boolean()
    .oneOf([true], 'Age and State Confirmation is required')
    .required(),
  termsAndPrivacyConfirmation: Yup.boolean()
    .oneOf([true], 'Terms and Privacy Confirmation is required')
    .required(),
});
