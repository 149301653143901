import React, { FC } from 'react';
import { TabPanel, Tabs } from '@mui/joy';

type CredentialTabsProps = {
  defaultValue?: number;
  emailFormSlot: React.ReactNode;
  phoneFormSlot: React.ReactNode;
};

export const CredentialTabs: FC<CredentialTabsProps> = ({
  defaultValue = 0,
  emailFormSlot,
  phoneFormSlot,
}) => {
  return (
    <Tabs
      aria-label='tabs'
      defaultValue={defaultValue}
      sx={{ width: '100%' }}>
      {/* <TabList
        disableUnderline
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          marginBottom: 2,
          padding: 0.25
        }}>
        <Tab
          disableIndicator
          sx={{ height: 38 }}>
          Email
        </Tab>
        <Tab
          disableIndicator
          sx={{ height: 38 }}>
          Phone number
        </Tab>
      </TabList> */}
      <TabPanel value={0}>
        {emailFormSlot}
      </TabPanel>
      {/* <TabPanel value={1}>
        {phoneFormSlot}
      </TabPanel> */}
    </Tabs>
  );
};
