import {
  FC,
  PropsWithChildren,
  useEffect
} from 'react';
import {
  useSelector
} from 'react-redux';
import {
  useLocation
} from 'react-router-dom';
import {
  selectIsAuthenticated,
} from '@entities/session';
import { useLazyGetChallengesQuery } from '../api';

export const WithPullingChallenges: FC<PropsWithChildren> = ({ children }) => {
  const [getChallenges] = useLazyGetChallengesQuery();
  const isAuth = useSelector(selectIsAuthenticated);
  const location = useLocation();

  useEffect(() => {
    if (isAuth) getChallenges().then()
  }, [isAuth, location.pathname, getChallenges]);

  return children;
};