import { FC } from 'react';
import { Stack, Typography, styled } from '@mui/joy';

const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.colorSchemes.dark.palette.common.white,
  fontSize: '0.875rem',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.25rem',
  [theme.breakpoints.down(768)]: {
    lineHeight: '1.5rem',
  },
}));

type TooltipBodyProps = {
  rtp: string;
  volatility: string;
  features: string;
};

export const TooltipBody: FC<TooltipBodyProps> = ({ rtp, volatility, features }) => {
  return (
    <Stack p='0.75rem' gap='0.25rem' minWidth='13.125rem'>
      {rtp && <Stack direction='row' justifyContent='space-between'>
        <StyledText>RTP</StyledText>
        <StyledText>{Number(+rtp * 100).toFixed(2)}%</StyledText>
      </Stack>}
      {volatility && <Stack direction='row' justifyContent='space-between'>
        <StyledText>Volatility</StyledText>
        <StyledText>{volatility}</StyledText>
      </Stack>}
      {features && <Stack direction='row' justifyContent='space-between'>
        <StyledText>Features</StyledText>
        <StyledText>{features}</StyledText>
      </Stack>}
    </Stack>
  );
};
