import {
  createSlice
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';

export type CircularLoaderState = {
  activeRequest: number;
}

const initialState: CircularLoaderState = {
  activeRequest: 0
};

export const circularLoaderSlice = createSlice({
  name: 'circular-loader',
  initialState,
  reducers: {
    incrementActiveRequests: (state: CircularLoaderState) => {
      state.activeRequest = state.activeRequest + 1;
    },
    decrementActiveRequests: (state: CircularLoaderState) => {
      state.activeRequest = state.activeRequest - 1;
    },
    resetActiveRequests: (state: CircularLoaderState) => {
      state.activeRequest = 0;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState)
  }
});

export const {
  incrementActiveRequests,
  decrementActiveRequests,
  resetActiveRequests
} = circularLoaderSlice.actions;

export const circularLoaderReducer = circularLoaderSlice.reducer;
