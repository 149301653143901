import { FC } from 'react';
import { Stack, Box, Typography } from '@mui/joy';
import { HtmlBlock, TextWithInlineLink } from '@shared/ui';
import { TextWithInlineLink as TextWithInlineLinkType } from '@shared/lib';

export type BoxedFaqBlockProps = {
  title?: Maybe<TextWithInlineLinkType>;
  items?: Maybe<Array<{
    iconSrc?: Maybe<Url>;
    shortSummary: string;
    shortSummaryStyle: Maybe<Record<string, string>>;
    details: string;
  }>>;
};

export const BoxedFaqBlock: FC<BoxedFaqBlockProps> = ({ title, items }) => (
  <Stack
    direction='column'
    justifyContent='center'
    sx={({ breakpoints }) => ({
      gap: 4,
      [breakpoints.down(769)]: {
        gap: 2,
      },
    })}>
    {title && (
      <TextWithInlineLink
        data={title}
        typographyProps={{
          sx: ({ breakpoints, colorSchemes }) => ({
            fontSize: 28,
            fontWeight: 600,
            lineHeight: '36px',
            letterSpacing: 0,
            textAlign: 'left',
            color: colorSchemes.dark.palette.common.white,
            [breakpoints.down(769)]: {
              fontSize: 20,
              lineHeight: '30px',
            },
          })
        }}
      />
    )}
    <Box sx={({ breakpoints }) => ({
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: 2.5,
      [breakpoints.down(769)]: {
        gridTemplateColumns: '1fr',
        gap: 1,
      }
    })}>
      {items?.map(({
        iconSrc,
        shortSummary,
        shortSummaryStyle,
        details
      }) => (
        <Stack
          key={shortSummary}
          direction='column'
          alignItems='center'
          justifyContent='flex-start'
          gap={1.5}
          sx={({ breakpoints, colorSchemes }) => ({
            minHeight: 192,
            borderRadius: 8,
            paddingInline: 1.5,
            paddingBlock: 3,
            background: colorSchemes.dark.palette.common[475],
            [breakpoints.down(769)]: {
              paddingBlock: 1.5,
            },
          })}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1}>
            {iconSrc && (
              <Box
                component='img'
                loading='lazy'
                src={iconSrc}
                sx={{ width: 20 }}
              />
            )}
            <Typography sx={({ breakpoints }) => ({
              fontSize: 28,
              fontWeight: 600,
              lineHeight: '36px',
              letterSpacing: 0,
              textAlign: 'left',
              [breakpoints.down(769)]: {
                fontSize: 20,
                lineHeight: '30px',
              },
              ...(shortSummaryStyle && ({ ...shortSummaryStyle }))
            })}>
              {shortSummary}
            </Typography>
          </Stack>
          <HtmlBlock html={details} />
        </Stack>
      ))}
    </Box>
  </Stack>
);