export const SESSION_TAG = 'SESSION_TAG';
export const HOME_PAGE_TAG = 'HOME_PAGE_TAG';
export const DYNAMIC_PAGE_TAG = 'DYNAMIC_PAGE_TAG';
export const DEFAULT_PAGE_LAYOUT_TAG = 'DEFAULT_PAGE_LAYOUT_TAG';
export const LOGIN_TAG = 'LOGIN_TAG';
export const GAMES_LIST_TAG = 'GAMES_LIST_TAG';
export const GAMES_PAGE_TAG = 'GAMES_PAGE_TAG';
export const GAME_PAGE_TAG = 'GAME_PAGE_TAG';
export const FAVORITE_GAMES_TAG = 'FAVORITE_GAMES_TAG';
export const COIN_PACKAGES_TAG = 'COIN_PACKAGES_TAG';
export const PAYMENT_INFO_TAG = 'PAYMENT_INFO_TAG';