import { FC } from 'react';
import { Tabs, Tab, TabList } from '@mui/joy';
import {
  selectTabs,
  selectCurrentTab,
  useAccountPageSelector,
  setCurrentTab,
  AccountTabEnum
} from '../model';
import { useDispatch } from 'react-redux';

export const AccountTabs: FC = () => {
  const dispatch = useDispatch();
  const tabs = useAccountPageSelector(selectTabs);
  const currentTab = useAccountPageSelector(selectCurrentTab);

  const handleChange = (_: unknown, tab: Maybe<string | number>): void => {
    const isProfileTab = Object.values(AccountTabEnum).includes(tab as AccountTabEnum);
    if (isProfileTab) dispatch(setCurrentTab(tab as AccountTabEnum));
  };

  return (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      sx={({ breakpoints }) => ({
        [breakpoints.down(768)]: {
          alignItems: 'center'
        },
        [breakpoints.down(390)]: {
          position: 'absolute',
          top: 0,
          flexWrap: 'wrap',
          width: '100vw',
          maxWidth: 'fit-content',
          overflowX: 'auto',
          paddingInline: '1rem',
          marginInline: 'auto',
        },
      })}>
      <TabList
        disableUnderline
        sx={({ breakpoints, palette }) => ({
          borderRadius: 8,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.common[925],
          p: .1875,
          gap: .1875,
          [breakpoints.up(768)]: {
            overflow: 'hidden',
            borderWidth: 0,
            background: palette.common[475],
            flexDirection: 'column',
            columnGap: 0,
            rowGap: 0,
            padding: 0,
          },
        })}>
        {tabs.map((tab, index) => (
          <Tab
            disableIndicator
            key={index}
            value={tab}
            sx={({ breakpoints }) => ({
              justifyContent: 'flex-start',
              height: 48,
              borderRadius: 0,
              textWrap: 'nowrap',
              textTransform: 'capitalize',
              fontSize: 16,
              fontStyle: 'normal',
              fontWeight: 500,
              [breakpoints.down(768)]: {
                height: 34,
                borderRadius: 8,
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
              },
            })}>
            {tab.toLowerCase()}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};