import { baseApi, GAME_PAGE_TAG } from '@shared/api';
import { type PlayGameItem } from '../model';
import { mapPlayGameItem } from '../lib';

export const playGamePageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGame: build.query<PlayGameItem, number>({
      query: (id) => ({ url: `/api/games/${id}` }),
      extraOptions: { showLoader: true },
      providesTags: [GAME_PAGE_TAG],
      transformResponse: (response: any) => mapPlayGameItem(response),
    }),
  }),
});

export const { useLazyGetGameQuery } = playGamePageApiSlice;
