import { FC } from 'react';
import { Stack, Typography } from '@mui/joy';
import { LeaderboardCupIcon } from '@shared/ui';

export const LeaderboardNotAvailable: FC = () => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      rowGap={3}
      sx={{
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
      }}>
      <LeaderboardCupIcon
        sx={{
          height: 63,
          width: 52
        }}
      />
      <Typography
        sx={({ palette }) => ({
          color: palette.common.white,
          textAlign: 'center',
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '18px'
        })}>
        Leaderboard will be available after the<br />
        tournament starts
      </Typography>
    </Stack >
  );
};