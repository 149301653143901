import { Component, ComponentType } from 'react';
import { CheckLoggedIn } from '@features/check-logged-in';
import { appRouter } from '../app-router';

export const withCheckLoggedIn = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return class CheckLoggedInProvider extends Component<P> {
    render() {
      return (
        <CheckLoggedIn navigate={appRouter.navigate}>
          <WrappedComponent {...this.props} />
        </CheckLoggedIn>
      );
    }
  };
};
