import {
  createContext,
  FC, ReactNode,
  useEffect,
  useState
} from 'react';
import TagManager, {
  DataLayerArgs
} from 'react-gtm-module';

export type GtmContextType = {
  sendDataToGtm: (data: DataLayerArgs) => void;
};

export const GtmContext = createContext<GtmContextType | null>(null);

export type GtmProviderProps = {
  children: ReactNode;
  gtmId: string;
};

export const GtmProvider: FC<GtmProviderProps> = ({ children, gtmId }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeGTM = () => {
      TagManager.initialize({ gtmId });
      setInitialized(true);
      // Remove event listeners once initialized
      window.removeEventListener('click', initializeGTM);
      window.removeEventListener('scroll', initializeGTM);
    };

    if (!initialized) {
      window.addEventListener('click', initializeGTM);
      window.addEventListener('scroll', initializeGTM);
    }

    return () => {
      window.removeEventListener('click', initializeGTM);
      window.removeEventListener('scroll', initializeGTM);
    };
  }, [gtmId, initialized]);

  const sendDataToGtm = (data: DataLayerArgs) => {
    if (initialized) {
      TagManager.dataLayer(data);
    }
  };

  return (
    <GtmContext.Provider value={{ sendDataToGtm }}>
      {children}
    </GtmContext.Provider>
  );
};
