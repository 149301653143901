import { FC } from 'react';
import { Stack, StackProps, Typography, Divider, Box } from '@mui/joy';
import { maskString } from '@shared/lib';
import {
  GoldCrownIcon,
  SilverCrownIcon,
  BronzeCrownIcon,
  YellowCrownIcon
} from '@shared/ui';
import {
  type TournamentPlace,
  TournamentPlaceEnum,
} from '../model';

export type TournamentPlacementCardProps =
  StackProps &
  TournamentPlace;

export const TournamentPlacementCard: FC<TournamentPlacementCardProps> = ({
  place,
  player,
  score,
  toWin,
  sx,
  ...props
}) => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      rowGap={1.5}
      sx={[({ palette, breakpoints }) => ({
        paddingBlock: 1.5,
        paddingInline: 2,
        width: '100%',
        maxWidth: 314,
        height: '100%',
        borderRadius: 8,
        backgroundColor: palette.common[475],
        ...(place === TournamentPlaceEnum.CurrentPlace && ({
          height: '100%',
          maxWidth: 313,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.common[1090],
          backgroundColor: palette.common[565],
          boxShadow: '0px 0px 8.1px 0px rgba(255, 181, 0, 0.51)'
        })),
        [breakpoints.down(1194)]: {
          maxWidth: '100%',
        }
      }),
      ...(sx ? Array.isArray(sx) ? sx : [sx] : [])
      ]}
      {...props}>
      {place === TournamentPlaceEnum.First && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          columnGap={1.5}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <GoldCrownIcon sx={{ fontSize: 32 }} />
          <Typography
            sx={({ palette }) => ({
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: palette.common.white,
            })}>
            1st Place
          </Typography>
        </Stack>
      )}
      {place === TournamentPlaceEnum.Second && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          columnGap={1.5}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <SilverCrownIcon sx={{ fontSize: 32 }} />
          <Typography
            sx={({ palette }) => ({
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: palette.common.white
            })}>
            2nd Place
          </Typography>
        </Stack>
      )}
      {place === TournamentPlaceEnum.Third && (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          columnGap={1.5}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <BronzeCrownIcon sx={{ fontSize: 32 }} />
          <Typography
            sx={({ palette }) => ({
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: palette.common.white
            })}>
            3rd Place
          </Typography>
        </Stack>
      )}
      {place === TournamentPlaceEnum.CurrentPlace && (
        <Stack
          direction='row'
          alignItems='center'
          columnGap={1.5}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <YellowCrownIcon sx={{ fontSize: 32 }} />
          <Typography
            sx={({ palette }) => ({
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '28px',
              color: palette.common.white
            })}>
            My Points
          </Typography>
        </Stack>
      )}
      <Divider
        orientation='horizontal'
        sx={({ palette }) => ({
          backgroundColor: palette.common[925],
          ...(place === TournamentPlaceEnum.CurrentPlace && ({
            backgroundColor: palette.common[1090],
          }))
        })}
      />
      <Box
        sx={() => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          width: '100%',
          height: '100%',
          ...(toWin.length > 12 && ({
            gridTemplateColumns: '70px 1fr 1fr',
          }))
        })}>
        <Stack
          direction='column'
          alignItems='flex-start'
          justifyContent='center'
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Typography
            sx={({ palette }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: palette.common[150]
            })}>
            Player
          </Typography>
          <Typography
            sx={({ palette, breakpoints }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              color: palette.common[300],
              [breakpoints.down(1194)]: {
                fontSize: 14,
              },
              ...(place === TournamentPlaceEnum.CurrentPlace && ({
                color: palette.common[1090],
              }))
            })}>
            {maskString(player)}
          </Typography>
        </Stack>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent='center'
          sx={{
            gridColumnStart: 2,
            gridColumnEnd: 3,
          }}>
          <Typography
            sx={({ palette }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: palette.common[150]
            })}>
            Score
          </Typography>
          <Typography
            sx={({ palette, breakpoints }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              color: palette.common[300],
              [breakpoints.down(1194)]: {
                fontSize: 14,
              },
              ...(place === TournamentPlaceEnum.CurrentPlace && ({
                color: palette.common[1090],
              }))
            })}>
            {score}
          </Typography>
        </Stack>
        <Stack
          direction='column'
          alignItems='flex-end'
          justifyContent='center'
          sx={{
            gridColumnStart: 3,
            gridColumnEnd: 4,
          }}>
          <Typography
            sx={({ palette }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '18px',
              color: palette.common[150]
            })}>
            To Win
          </Typography>
          <Typography
            sx={({ palette, breakpoints }) => ({
              textAlign: 'start',
              fontSize: 11,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              color: palette.common[300],
              [breakpoints.down(1194)]: {
                fontSize: 14,
              },
              ...(place === TournamentPlaceEnum.CurrentPlace && ({
                color: palette.common[1090],
              }))
            })}>
            {toWin}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};