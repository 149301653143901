import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGamesPageCategoryIds } from '@entities/games';
import { gamesPageApiSlice } from './slice';

export const fetchGamesPage = createAsyncThunk(
  'gamesPage/fetchGamesPage',
  async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const gamesPageResponse = await dispatch(
        gamesPageApiSlice.endpoints.getGamesPage.initiate()
      ).unwrap();

      dispatch(setGamesPageCategoryIds(gamesPageResponse.categoryIds))

      return fulfillWithValue(gamesPageResponse);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
