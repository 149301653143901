import { baseApi, DYNAMIC_PAGE_TAG } from '@shared/api';
import { buildStrapiQuery, type Filter } from '@shared/lib';
import { TournamentDetailsPageState } from '../model';
import { mapTournamentDetailsPageState } from '../lib';

export const tournamentDetailsPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTournamentDetailsPage: build.query<TournamentDetailsPageState, Filter>({
      query: (filter: Filter) => ({
        url: `api/tournament-details-pages?${buildStrapiQuery(filter)}`
      }),
      extraOptions: { showLoader: true, auth: true },
      providesTags: [DYNAMIC_PAGE_TAG],
      transformResponse: (response: { data: unknown[] }) =>
        mapTournamentDetailsPageState(response.data[0])
    })
  }),
});

export const {
  useGetTournamentDetailsPageQuery
} = tournamentDetailsPageApiSlice;
