import {
  FC,
  useCallback,
  useMemo
} from 'react';
import { useDispatch } from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import { Button } from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Pagination } from '@entities/games';
import {
  selectSelectedCategoryKey,
  selectPaginationByCategoryKey,
  useGamesFilterByCategorySelector,
  fetchGamesByCategory,
} from '@features/games-filter-by-category';
import {
  selectSearch,
  selectPagination,
  useGamesFilterBySearchSelector,
  fetchGamesBySearch,
} from '@features/games-filter-by-search';
import {
  useGamesFilterByFavoriteSelector,
  selectPagination as selectPaginationByFavorite,
  fetchGamesByFavorite,
} from '@features/games-filter-by-favorite';
import {
  FilterTypeEnum,
  selectActiveFilterType,
  useGamesListWidgetSelector
} from '../model';

export type LoadMoreButtonProps = {
  isLoading: boolean;
};

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({ isLoading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);

  const selectedCategoryKey = useGamesFilterByCategorySelector(selectSelectedCategoryKey);
  const search = useGamesFilterBySearchSelector(selectSearch);
  const paginationByCategory = useGamesFilterByCategorySelector(selectPaginationByCategoryKey(selectedCategoryKey));
  const paginationBySearch = useGamesFilterBySearchSelector(selectPagination);
  const paginationByFavorite = useGamesFilterByFavoriteSelector(selectPaginationByFavorite);

  const pagination: Maybe<Pagination> = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category: return paginationByCategory;
      case FilterTypeEnum.Search: return paginationBySearch;
      case FilterTypeEnum.Favorite: return paginationByFavorite;
    }
  }, [activeFilterType, paginationByCategory, paginationBySearch, paginationByFavorite]);

  const hideLoadMore = useMemo(
    () => !!pagination && pagination.page >= pagination.pageCount, [pagination]
  );

  const loadMoreByCategory = useCallback(async () => {
    await dispatch(
      fetchGamesByCategory({
        categoryKey: selectedCategoryKey!,
        page: pagination!.page + 1,
        pageSize: isMobile ? 12 : 24,
      })
    );
  }, [pagination, selectedCategoryKey, isMobile, dispatch]);

  const loadMoreBySearch = useCallback(async () => {
    await dispatch(
      fetchGamesBySearch({
        search,
        page: pagination!.page + 1,
        pageSize: isMobile ? 12 : 24,
      })
    );
  }, [pagination, search, isMobile, dispatch]);

  const loadMoreByFavorite = useCallback(async () => {
    await dispatch(
      fetchGamesByFavorite({
        page: pagination!.page + 1,
        pageSize: isMobile ? 12 : 24,
      })
    );
  }, [
    isMobile,
    pagination,
    dispatch
  ]);

  const handleLoadMore = useCallback(async (): Promise<void> => {
    if (!pagination) return;
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category: return await loadMoreByCategory();
      case FilterTypeEnum.Search: return await loadMoreBySearch();
      case FilterTypeEnum.Favorite: return await loadMoreByFavorite();
    }
  }, [
    activeFilterType,
    pagination,
    loadMoreByCategory,
    loadMoreBySearch,
    loadMoreByFavorite
  ]);

  return (
    !hideLoadMore && (
      <Button
        disabled={isLoading}
        onClick={handleLoadMore}
        variant='solid'
        color='primary'
        sx={({ breakpoints }) => ({
          [breakpoints.down(768)]: {
            maxWidth: '22.375rem',
            width: '100%',
          },
        })}>
        Load more
      </Button>
    )
  );
};
