import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const BronzeCrownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <g id='Frame'>
      <g id='Group'>
        <path
          id='Vector'
          d='M29.3376 7.99905C27.8646 7.99905 26.6705 9.19316 26.6705 10.6661C26.6711 10.9457 26.7161 11.2235 26.8039 11.4889L24.6409 12.8638C23.2167 13.7712 21.3391 13.4705 20.2695 12.1637L18.1492 9.57003C18.4866 9.11577 18.669 8.565 18.6693 7.99911C18.6693 6.52614 17.4752 5.33203 16.0022 5.33203C14.5293 5.33203 13.3351 6.52614 13.3351 7.99911C13.3354 8.565 13.5178 9.11577 13.8552 9.57003L11.7349 12.1637C10.6656 13.4697 8.78879 13.7704 7.36495 12.8638L5.20076 11.4889C5.28859 11.2235 5.33359 10.9457 5.33409 10.6661C5.33434 9.19316 4.14048 7.99886 2.66745 7.99861C1.19455 7.99836 0.000250081 9.19228 4.43707e-08 10.6652C-0.000249992 12.048 1.05628 13.2016 2.43367 13.3225L4.0099 22.7772C4.06334 23.0987 4.34139 23.3345 4.66731 23.3346H27.3373C27.6632 23.3345 27.9413 23.0987 27.9947 22.7772L29.571 13.3225C31.038 13.1909 32.1207 11.8949 31.9891 10.4278C31.866 9.05458 30.7164 8.00161 29.3376 7.99905Z'
          fill='#F99B43'
        />
        <path
          id='Vector_2'
          d='M16.0029 26.0035C22.2631 26.0035 27.3379 24.5109 27.3379 22.6697C27.3379 20.8285 22.2631 19.3359 16.0029 19.3359C9.74281 19.3359 4.66797 20.8285 4.66797 22.6697C4.66797 24.5109 9.74281 26.0035 16.0029 26.0035Z'
          fill='#DB8A4C'
        />
        <path
          id='Vector_3'
          d='M16.0018 26.6691C10.0369 26.6691 4 25.2956 4 22.6686C4 20.0415 10.0369 18.668 16.0018 18.668C21.9666 18.668 28.0035 20.0415 28.0035 22.6686C28.0035 25.2956 21.9666 26.6691 16.0018 26.6691ZM16.0018 20.0015C8.99804 20.0015 5.33351 21.735 5.33351 22.6686C5.33351 23.6021 8.99804 25.3356 16.0018 25.3356C23.0055 25.3356 26.67 23.6021 26.67 22.6686C26.67 21.735 23.0055 20.0015 16.0018 20.0015Z'
          fill='#FFC38E'
        />
      </g>
    </g>
  </SvgIcon>
);
