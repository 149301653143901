import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux';
import {
  Typography,
  LinearProgress,
  Stack,
  Skeleton
} from '@mui/joy';
import {
  CurrentVIPLevelEnum,
  selectCurrentVIPLevel,
  useGetLoyaltyPointsQuery,
  useSessionSelector
} from '@entities/session';
import { mapNextLevel } from '../lib';
import { selectCurrentLoyaltyProgress } from '../model';

// TODO: refactoring needed
export const LoyaltyProgressBar: FC = () => {
  const { isFetching } = useGetLoyaltyPointsQuery();

  const currentVIPLevel = useSessionSelector(selectCurrentVIPLevel);
  const currentLoyaltyProgress = useSelector(selectCurrentLoyaltyProgress);

  const nextLevel = useMemo(() => mapNextLevel(currentVIPLevel), [currentVIPLevel]);
  const allowToShow = useMemo(() => currentVIPLevel !== CurrentVIPLevelEnum.Level8, [currentVIPLevel]);

  return allowToShow && (
    <Stack
      sx={({ palette }) => ({
        backgroundColor: palette.common[475],
        padding: '1.25rem',
        width: '100%',
        borderRadius: '0.5rem',
        gap: '0.75rem'
      })}>
      <Typography sx={({ palette, breakpoints }) => ({
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: palette.common.white,
        [breakpoints.down(786)]: {
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        }
      })}>
        Your current Luxe Club level is{' '}
        <Typography
          sx={({ palette }) =>
          ({
            color: palette.common[300],
            position: 'relative'
          })}>
          {isFetching ? <Skeleton
            variant='inline'
            width='5rem'
            sx={{
              display: 'inline-block',
              height: '1rem',
              mb: '-0.125rem',
            }} />
            :
            currentVIPLevel}
        </Typography>.{' '}
        Upgrade to the next level and get exclusive access to rewards and personalized gifts
      </Typography>
      <LinearProgress
        determinate
        size='lg'
        value={currentLoyaltyProgress}
      />
      <Stack
        direction='row'
        justifyContent='space-between'>
        <Typography
          sx={({ palette, breakpoints }) => ({
            color: palette.common[150],
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            [breakpoints.down(768)]: {
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.25rem',
            }
          })}>
          {isFetching ? <Skeleton variant='text' width='5rem' /> : currentVIPLevel}
        </Typography>
        <Typography
          sx={({ palette, breakpoints }) => ({
            color: palette.common[150],
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            [breakpoints.down(768)]: {
              fontSize: '0.875rem',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '1.25rem',
            }
          })}>
          {isFetching ? <Skeleton variant='text' width='5rem' /> : nextLevel}
        </Typography>
      </Stack>
    </Stack>
  );
};