import { FC } from 'react';
import { Link } from '@shared/ui';

export const RestrictionAreasLink: FC = () => (
  <Link
    color='primary'
    href='/terms-and-conditions'
    underline='none'
    sx={{
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    }}>
    Restricted areas
  </Link>
);