import { passwordString } from '@shared/lib';
import * as yup from 'yup';

export const confirmPasswordSchema = yup.object({
  oldPassword: yup.string()
    .required('Current password is a required field'),
  newPassword: passwordString
    .notOneOf([yup.ref('oldPassword')], 'Please provide a new password'),
  confirmPassword: yup.string()
    .required('Confirm password is a required field')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});