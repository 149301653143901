import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { EmailPromotionSubscriptionState, managePromotionalSubscriptionsSlice } from './slice';

type RootState = {
  [managePromotionalSubscriptionsSlice.name]: EmailPromotionSubscriptionState;
};

export const selectEmailPromotionSubscription = (state: RootState): boolean => (
  state[managePromotionalSubscriptionsSlice.name]?.emailPromotionSubscription
)

export const selectSmsPromotionSubscription = (state: RootState): boolean => (
  state[managePromotionalSubscriptionsSlice.name]?.smsPromotionSubscription
)

export const usePromotionSubscriptionSelector: TypedUseSelectorHook<{
  [managePromotionalSubscriptionsSlice.name]: EmailPromotionSubscriptionState;
}> = useSelector;
