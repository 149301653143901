import { isDefined } from './is-defined';
import { isEmpty } from './is-empty';
import { isExternalUrl } from './is-external-url';

export const isImageUrl = (url: any): boolean => {
  if (isEmpty(url) || !isDefined(url)) {
    return false;
  }

  if (!isExternalUrl(url)) {
    return false;
  }

  const imagePattern = /\.(png|jpg|jpeg|gif|svg|webp)$/i;
  return imagePattern.test(url);
};