import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalDialog,
  Stack,
  Typography
} from '@mui/joy';
import {
  OmegaApiResponse,
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  Consent,
  useLazySaveConsentsQuery
} from '../api';

export type ConfirmConsentsModalProps = {
  open: boolean;
  consents: Array<Consent>;
  sessionKey?: Maybe<string>;
  onConfirm?(): void;
}

export const ConfirmConsentsModal: FC<ConfirmConsentsModalProps> = ({
  open,
  consents,
  sessionKey,
  onConfirm,
}) => {
  const [saveConsents, { isFetching }] = useLazySaveConsentsQuery();

  const defaultFormValues = useMemo(() => {
    return consents.reduce((form: any, value: Consent) => {
      form[value.key] = false;
      return form;
    }, {});
  }, [consents]);

  const saveConsentsSchema = useMemo(() => {
    let saveConsentsSchema = Yup.object().shape({});
    consents.forEach((consent: Consent) => {
      saveConsentsSchema = saveConsentsSchema.shape({
        [consent.key]: Yup.boolean()
          .oneOf([true], `${consent.name} Confirmation is required`)
          .required(),
      });
    });

    return saveConsentsSchema;
  }, [consents])

  const { control, formState, setError } = useForm({
    resolver: yupResolver(saveConsentsSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  });

  const handleConfirm = async (): Promise<void> => {
    const consentedVersions = consents.reduce(
      (
        consentedVersions: Array<number>,
        value: Consent
      ) => [...consentedVersions, value.consentVersionId],
      []);

    if (sessionKey) {
      const { data, isSuccess } = await saveConsents({ consentedVersions, sessionKey });
      const { status } = data as OmegaApiResponse;
      if (!isSuccess || status !== OmegaApiResponseStatusEnum.Success) {
        return setError('root.serverError', {
          message: 'Saving error'
        });
      }
    }

    onConfirm?.();
  };

  const handleLinkClick = (key: string) => () => {
    window.open(`${window.location.origin}/${key}`, '_blank');
  };

  return (
    <Modal open={open}>
      <ModalDialog
        layout='center'
        sx={() => ({
          width: '100%',
          maxWidth: 340,
        })}>
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            padding: 3,
            gap: 2,
          }}>
          <Typography
            level='h3'
            fontSize={18}>
            Terms of use update {dayjs().format('YYYY-MM-DD')}
          </Typography>

          <Typography fontSize={14}>
            Our policies have been updated in order to improve your experience with SweepLuxe.
            Please review and accept the following policies:
          </Typography>

          <Stack
            direction='column'
            gap={1}>
            {consents?.map((consent: Consent) => (
              <Stack
                direction='row'
                gap={1}
                alignItems='center'
                key={consent.id}>
                <Controller
                  name={consent.key}
                  disabled={isFetching}
                  control={control}
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Checkbox
                      disabled={isFetching}
                      checked={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      sx={{
                        textAlign: 'justify',
                        alignItems: 'center',
                        gap: 1,
                        height: 36
                      }}
                    />
                  )}
                />
                <Typography
                  sx={({ palette }) => ({
                    'span': {
                      cursor: 'pointer',
                      color: palette.common[300],
                      ':hover': {
                        color: palette.common[350],
                      }
                    }
                  })}>
                  {'I have read and agree with '}
                  <Box
                    component='span'
                    onClick={handleLinkClick(consent.key)}>
                    {`${consent.name} v${consent.version}`}
                  </Box>
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Button
            disabled={
              !formState.isValid ||
              isFetching
            }
            loading={isFetching}
            fullWidth
            onClick={handleConfirm}>
            Confirm
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}