export type SplitResult = {
  hasValue: boolean;
  firstPart?: string;
  secondPart?: string;
};

export const extractColorAndPackageTitle = (str: Maybe<string>): SplitResult => {
  if (!str) return { hasValue: false };

  const hasRed = str.includes(`{{'red'}}`);
  const hasYellow = str.includes(`{{'yellow'}}`);

  if (!hasRed && !hasYellow)
    return { hasValue: true, firstPart: `{{'green'}}`, secondPart: str };

  const separator = `:`;
  const parts = str.split(separator);
  const firstPart = parts[0] + separator;
  const secondPart = parts.slice(1).join(separator);
  return { hasValue: true, firstPart, secondPart };
};