import { useContext } from 'react';
import {
  type GtmContextType,
  GtmContext,
} from '../ui';
import { throwErrorIfNotDefined } from '@shared/lib';

export const useGtm = (): Maybe<GtmContextType> => {
  const context = useContext(GtmContext);
  throwErrorIfNotDefined({
    value: context,
    errorMessage: 'useGtm must be used within a GTMProvider'
  });
  return context;
};