import { mapper } from '@shared/lib';
import { type GamesState } from '../model';
import { mapGameCategory } from './map-category';

type MapGameCategories = (data: any) => Pick<GamesState, 'categories'>;
export const mapGameCategories: MapGameCategories = mapper([
  {
    name: 'categories',
    from: 'data',
    transform: (data: Array<any>) => data.map(mapGameCategory)
  },
]);
