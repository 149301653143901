import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { checkTrackingCodesSlice } from './slice';
import { type CheckTrackingCodesState } from './types';

type RootState = {
  [checkTrackingCodesSlice.name]: CheckTrackingCodesState
};

export const selectCodes = (state: RootState): Maybe<string> => (
  state[checkTrackingCodesSlice.name].codes
);

export const useCheckTrackingCodesSelector: TypedUseSelectorHook<RootState> = useSelector;
