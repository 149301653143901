
export enum TournamentPlaceEnum {
  First = 'first',
  Second = 'second',
  Third = 'third',
  CurrentPlace = 'user-place'
};

export type TournamentPlace = {
  place: TournamentPlaceEnum;
  player: string;
  score: string;
  toWin: string;
};