export const formatCurrency = (value: string | number): string => {
  const stringValue = value.toString();

  const dotIndex = stringValue.indexOf('.');
  if (dotIndex === -1)
    return `$ ${stringValue}.00`;

  const decimalPart = stringValue.substring(dotIndex + 1);
  if (decimalPart.length === 1)
    return `$ ${stringValue}0`;

  return `$ ${stringValue}`;
}