import { createApi } from '@reduxjs/toolkit/query/react';
import { baseStrapiQuery } from './base-strapi-query'
import {
  SESSION_TAG,
  HOME_PAGE_TAG,
  DEFAULT_PAGE_LAYOUT_TAG,
  GAMES_LIST_TAG,
  GAMES_PAGE_TAG,
  GAME_PAGE_TAG,
  DYNAMIC_PAGE_TAG
} from './tags';

export const baseApi = createApi({
  tagTypes: [
    SESSION_TAG,
    HOME_PAGE_TAG,
    DEFAULT_PAGE_LAYOUT_TAG,
    DYNAMIC_PAGE_TAG,
    GAMES_LIST_TAG,
    GAMES_PAGE_TAG,
    GAME_PAGE_TAG
  ],
  reducerPath: 'api',
  baseQuery: baseStrapiQuery,
  endpoints: () => ({}),
});
