import {
  baseOmegaApi
} from '@shared/api';
import {
  type PrepareWithdrawal,
  type PrepareWithdrawalResponse,
  type CancelWithdrawal,
  type CancelWithdrawalResponse,
  type CheckPaymentStatus,
  type CheckPaymentStatusResponse,
  type ProcessWithdrawal,
  type ProcessWithdrawalResponse
} from './types';

export const worldPayBuyCoinsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    prepareWithdrawal: build.query<PrepareWithdrawalResponse, PrepareWithdrawal>({
      query: params => ({ url: 'ips/prepareWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    processWithdrawal: build.query<ProcessWithdrawalResponse, ProcessWithdrawal>({
      query: (params) => ({ url: 'ips/processWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    checkPaymentStatus: build.query<CheckPaymentStatusResponse, CheckPaymentStatus>({
      query: (params) => ({ url: 'ips/checkPaymentStatus', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    cancelWithdrawal: build.query<CancelWithdrawalResponse, CancelWithdrawal>({
      query: (params) => ({ url: 'ips/cancelWithdrawal', params }),
      extraOptions: { auth: true, showLoader: true }
    })
  }),
});

export const {
  useProcessWithdrawalQuery,
  useLazyProcessWithdrawalQuery,
  useCheckPaymentStatusQuery,
  useLazyCheckPaymentStatusQuery,
  useCancelWithdrawalQuery,
  useLazyCancelWithdrawalQuery,
  usePrepareWithdrawalQuery,
  useLazyPrepareWithdrawalQuery,
} = worldPayBuyCoinsApiSlicer;