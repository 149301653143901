import { styled } from '@mui/joy';
import { Swiper as SwiperReact } from 'swiper/react';

export const Swiper = styled(SwiperReact)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down(912)]: {
    '.swiper-slide': {
      width: 'auto',
    },
  },
}));
