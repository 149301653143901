import { FC } from 'react'
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack } from '@mui/joy';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { phoneRequiredOnlySchema, useModalQueryParam } from '@shared/lib';
import { Button, PhoneInput } from '@shared/ui';
import { ResetPasswordModalsQueries, useLazySendResetPasswordOTPQuery } from '../api';
import { setPhone } from '../model';

export const PhoneForm: FC = () => {
  const dispatch = useDispatch()
  const { toggleModal } = useModalQueryParam()
  const [sendOpt, { isFetching, isError, data }] = useLazySendResetPasswordOTPQuery();

  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(phoneRequiredOnlySchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { mobile: '' }
  })

  const handleFormSubmit = async (
    formData: { mobile: string; },
  ) => {
    dispatch(setPhone(formData.mobile))
    const { isSuccess, data } = await sendOpt({ mobile: formData.mobile })

    if (isSuccess && data.status === OmegaApiResponseStatusEnum.Success) {
      toggleModal(ResetPasswordModalsQueries.SentResetPassword, ResetPasswordModalsQueries.ConfirmResetPassword)
    }
  }

  return <Stack
    component='form'
    gap='0.5rem'
  >
    {!isFetching && ((data && data?.status !== OmegaApiResponseStatusEnum.Success) || isError) && <Typography
      sx={({ palette }) => ({
        color: palette.common.error,
      })}
    >
      Something went went!
    </Typography>}
    <Controller
      control={control}
      name='mobile'
      render={({ field: { value, onBlur, onChange }, fieldState }) => (
        <PhoneInput
          label='Phone Number'
          defaultCountry='us'
          disabled={isFetching}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          error={fieldState.error?.message}
        />
      )}
    />

    <Button
      fullWidth
      loading={isFetching}
      type='submit'
      onClick={handleSubmit(handleFormSubmit)}
      disabled={!formState.isDirty || !formState.isValid || isFetching}
    >
      Continue
    </Button>
  </Stack>;
};