import { FC, useCallback, useEffect } from 'react'
import { Modal, ModalDialog, Typography } from '@mui/joy'
import { useDispatch } from 'react-redux'
import { Stack } from '@mui/system'
import { CloseIcon, CredentialTabs, IconButton } from '@shared/ui'
import { resetState } from '../model'
import { EmailForm } from './email-form.component'
import { PhoneForm } from './phone-form.component'

export type SendResetPasswordModalProps = {
  open: boolean,
  onClose: () => void
}

export const SendResetPasswordModal: FC<SendResetPasswordModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const initResetPasswordModal = useCallback(() => {
    dispatch(resetState())
  }, [dispatch])

  useEffect(() => {
    initResetPasswordModal()
  }, [initResetPasswordModal])

  return (
    <Modal open={open}>
      < ModalDialog
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: '358px',
          [breakpoints.down(490)]: {
            maxWidth: 'calc(100vw - 2rem)'
          }
        })}>
        <Stack sx={{
          padding: '1.5rem',
        }}>
          <Stack mb='1rem'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              mb='0.75rem'>
              <Typography sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: '1.125rem',
                fontWeight: 600,
                lineHeight: 'normal',
              })}>
                Reset password
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={({ palette }) => ({
              color: palette.common[150],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
            })}>
              Enter the email associated with your account to reset your password
            </Typography>
          </Stack>
          <CredentialTabs
            emailFormSlot={
              <EmailForm />
            }
            phoneFormSlot={
              <PhoneForm />
            }
          />
        </Stack>
      </ModalDialog >
    </Modal >
  )
}