import { useEffect } from 'react';
import { DataLayerArgs } from 'react-gtm-module';
import { useGtm } from './use-gtm';

export type UseSendDataToGtm = {
  sendDataToGtm: (data: DataLayerArgs) => void;
};

export const useSendDataToGtm = (data?: DataLayerArgs): UseSendDataToGtm => {
  const gtm = useGtm();

  const sendDataToGtm = (data: DataLayerArgs) => {
    if (gtm && data) {
      gtm.sendDataToGtm(data);
    }
  };

  useEffect(() => {
    if (data && document.readyState === 'complete') {
      sendDataToGtm(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return { sendDataToGtm };
};
