import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { promotionsFilteredByCategoriesSlice } from './slice';
import { type PromotionsFilterByCategoryState, type PromotionCategory } from './types';

type RootState = {
  [promotionsFilteredByCategoriesSlice.name]: PromotionsFilterByCategoryState;
};

export const selectSelectedCategoryKey = (state: RootState): Maybe<string> =>
  state[promotionsFilteredByCategoriesSlice.name].selectedCategoryKey;

export const selectPromotionCategories = (state: RootState): Maybe<Array<PromotionCategory>> =>
  state[promotionsFilteredByCategoriesSlice.name].promotionCategories;

export const selectIsCategoriesContainKey = (state: RootState): Maybe<boolean> =>
  !!state[promotionsFilteredByCategoriesSlice.name].promotionCategories?.find(
    (el: PromotionCategory) => el.key === state[promotionsFilteredByCategoriesSlice.name].selectedCategoryKey
  );

export const usePromotionsFilterByCategorySelector: TypedUseSelectorHook<{
  [promotionsFilteredByCategoriesSlice.name]: PromotionsFilterByCategoryState;
}> = useSelector;