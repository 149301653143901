import {
  baseOmegaApi
} from '@shared/api';
import {
  type PreparePurchasePackage,
  type PreparePurchasePackageResponse,
  type ProcessDeposit,
  type ProcessDepositResponse,
} from './types';

export const worldPayBuyCoinsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    preparePurchasePackage: build.query<PreparePurchasePackageResponse, PreparePurchasePackage>({
      query: (params) => ({ url: 'ips/purchasePackage', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    processDeposit: build.query<ProcessDepositResponse, ProcessDeposit>({
      query: (params) => ({ url: 'ips/processDeposit', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
  }),
});

export const {
  useLazyPreparePurchasePackageQuery,
  useLazyProcessDepositQuery,
} = worldPayBuyCoinsApiSlicer;