import {
  FC,
  ReactNode,
  memo
} from 'react';
import {
  AppLogo,
  IconButton
} from '@shared/ui';
import {
  UserAvatarWithNickname
} from '@entities/session';
import {
  SignInButton
} from '@features/sign-in';
import {
  SignUpButton
} from '@features/sign-up';
import {
  Stack,
  Divider
} from '@mui/joy';
import {
  Close as CloseIcon
} from '@mui/icons-material';

export type SidebarMenuHeaderProps = {
  isAuthenticated?: boolean;
  sidebarMenuLogoSrc?: Url;
  notificationsSidebarButtonSlot: ReactNode;
  onClose?: () => void;
};

export const SidebarMenuHeader: FC<SidebarMenuHeaderProps> = memo(({
  isAuthenticated = false,
  sidebarMenuLogoSrc,
  notificationsSidebarButtonSlot,
  onClose,
}) => (
  <>
    {/* Mobile */}
    <Stack
      direction='row'
      alignItems='center'
      sx={({ palette, breakpoints }) => ({
        display: 'none',
        justifyContent: sidebarMenuLogoSrc
          ? 'space-between'
          : 'flex-end',
        [breakpoints.down(491)]: {
          paddingTop: 3,
          paddingBottom: 2,
          display: 'flex',
          paddingInline: 2,
          backgroundColor: palette.common[475]
        },
      })}>
      {sidebarMenuLogoSrc && (
        <AppLogo
          src={sidebarMenuLogoSrc}
          sx={{
            width: 60,
            height: 30,
          }}
        />
      )}
      <Stack
        direction='row'
        gap={1}>
        {isAuthenticated ? (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            gap={2}>
            <UserAvatarWithNickname onClick={onClose} />
            {notificationsSidebarButtonSlot}
          </Stack>
        ) : (
          <Stack
            direction='row'
            alignItems='center'
            gap={1}>
            <SignInButton size='sm'>
              Log in
            </SignInButton>
            <SignUpButton size='sm'>
              Sign Up
            </SignUpButton>
            <Divider orientation='vertical' />
          </Stack>
        )}
        <IconButton
          size='sm'
          sx={{
            blockSize: 'fit-content',
            marginLeft: 1
          }}
          onClick={onClose}>
          <CloseIcon
            sx={({ palette }) => ({
              fontSize: 24,
              color: palette.common.white
            })} />
        </IconButton>
      </Stack>
    </Stack>

    {/* Desktop */}
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-start'
      sx={({ breakpoints }) => ({
        paddingTop: 3,
        paddingInline: 2,
        [breakpoints.down(491)]: {
          display: 'none',
        },
      })} >
      <IconButton
        size='sm'
        sx={{ blockSize: 'fit-content' }}
        onClick={onClose}>
        <CloseIcon sx={{ fontSize: 24 }} />
      </IconButton>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{
          width: '100%',
          height: isAuthenticated ? 32 : 60,
        }}>
        {sidebarMenuLogoSrc && (
          <AppLogo
            src={sidebarMenuLogoSrc}
            sx={{
              width: isAuthenticated ? 60 : 118,
              height: isAuthenticated ? 30 : 60,
              marginLeft: isAuthenticated ? 2 : -4,
            }}
          />
        )}
        {isAuthenticated && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='flex-end'
            width='100%'
            gap={2}>
            <UserAvatarWithNickname onClick={onClose} />
            {notificationsSidebarButtonSlot}
          </Stack>
        )}
      </Stack>
    </Stack>
  </>
));