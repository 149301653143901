import { FC, useMemo, useState } from 'react';
import { Box, Skeleton } from '@mui/joy';

export type SlideImageProps = {
  src: Url;
  isLooped: boolean;
};

export const SlideImage: FC<SlideImageProps> = ({ src, isLooped }) => {
  const [loading, setLoading] = useState(true);

  const isVideo = useMemo(
    () => src?.endsWith('.mp4') ||
      src?.endsWith('.webv') ||
      src?.endsWith('.webm'), [src]
  );

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}>
      <Skeleton loading={loading} />
      {isVideo ? (
        <Box
          onLoadedData={handleLoad}
          component='video'
          autoPlay
          muted
          playsInline={isLooped}
          loop={isLooped}
          src={src ?? ''}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'fill',
          }}
        />
      ) : (
        <Box
          onLoad={handleLoad}
          component='img'
          loading='lazy'
          src={src ?? ''}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      )}
    </Box>
  );
};
