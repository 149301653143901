import {
  FC,
  Fragment,
  ReactNode,
} from 'react';
import {
  Button,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
  useTheme as useJoyTheme
} from '@mui/joy';
import {
  useTheme as useMaterialTheme,
  useMediaQuery
} from '@mui/material';
import {
  HighlightOffRounded as HighlightOffRoundedIcon
} from '@mui/icons-material';
import {
  CloseIcon,
  IconButton
} from '@shared/ui';
import {
  formatCurrency
} from '@shared/lib';
import {
  usePaymentsSelector,
  selectWorldPayCheckoutResult,
  selectOpenWorldPayCheckoutFailedPopup
} from '../model';

type StyledTypographyProps = {
  children: ReactNode;
  sx?: object;
};

const StyledTypography: FC<StyledTypographyProps> = ({ children, sx }) => (
  <Typography
    sx={({ palette }) => ({
      fontSize: 18,
      color: palette.common[100],
      ...sx
    })}>
    {children}
  </Typography>
);

export type WorldPayCheckoutFailedPopupProps = {
  onClose?(): void;
  onTryAgain?(amount: number): void;
};

export const WorldPayCheckoutFailedPopup: FC<WorldPayCheckoutFailedPopupProps> = ({
  onClose,
  onTryAgain
}) => {
  const joyTheme = useJoyTheme();
  const materialTheme = useMaterialTheme();
  const isMobile = useMediaQuery(materialTheme.breakpoints.down(490));
  const checkoutResult = usePaymentsSelector(selectWorldPayCheckoutResult);
  const open = usePaymentsSelector(selectOpenWorldPayCheckoutFailedPopup);

  const handleTryAgain = (): void => {
    const amount = checkoutResult?.amount
      ? Number(checkoutResult?.amount)
      : 0;
    onTryAgain?.(amount);
  };

  return (
    <Modal open={open ?? false} disablePortal>
      <ModalDialog
        layout={(
          isMobile
            ? 'fullscreen'
            : 'center'
        )}
        sx={({ breakpoints, palette }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 488,
          maxHeight: 578,
          backgroundColor: palette.common[925],
          [breakpoints.down(491)]: {
            maxHeight: '100%',
            borderRadius: 0,
            boxShadow: 'none',
          },
        })}>
        <Stack
          direction='column'
          sx={({ breakpoints }) => ({
            width: '100%',
            height: '100%',
            padding: 3,
            [breakpoints.down(490)]: {
              paddingInline: 2,
              paddingTop: 2,
              paddingBottom: 4,
            },
            [breakpoints.down(390)]: {
              paddingInline: 1,
              paddingBottom: 2,
            },
          })}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
              width: '100%',
              marginBottom: 3,
            }}>
            <Typography
              level='h3'
              fontSize='1.25rem'>
              Checkout
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            direction='column'
            justifyContent='space-between'
            width='100%'
            height='100%'
            rowGap={7}>
            <Stack
              direction='column'
              alignItems='center'
              justifyContent='space-between'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={({ palette }) => ({
                  columnGap: 2,
                  marginInline: 1,
                  marginTop: 2,
                  paddingBlock: 2,
                  paddingInline: 2,
                  borderRadius: 8,
                  backgroundColor: palette.common.error
                })}>
                <HighlightOffRoundedIcon
                  sx={{
                    fontSize: 50,
                    color: joyTheme.palette.common.white
                  }}
                />
                <StyledTypography
                  sx={{
                    color: joyTheme.palette.common.white
                  }}>
                  The transaction failed, please contact support, or try again.
                </StyledTypography>
              </Stack>
              <Typography
                sx={({ palette, breakpoints }) => ({
                  paddingBlockStart: 4,
                  paddingBlockEnd: 8,
                  fontSize: 22,
                  fontWeight: 'bold',
                  color: palette.common.white,
                  [breakpoints.down(490)]: {
                    paddingBlockStart: 5,
                    paddingBlockEnd: 12,
                  }
                })}>
                {formatCurrency(checkoutResult?.amount ?? 0)}
              </Typography>
              <Stack
                direction='column'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  width: '100%',
                  rowGap: 1,
                  paddingInline: 2
                }}>
                {checkoutResult?.cardNumber && (
                  <Fragment>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='space-between'
                      width='100%'>
                      <StyledTypography>
                        Card number
                      </StyledTypography>
                      <StyledTypography>
                        {checkoutResult?.cardNumber}
                      </StyledTypography>
                    </Stack>
                    <Divider
                      orientation='horizontal'
                      sx={({ palette }) => ({
                        backgroundColor: palette.common[900]
                      })}
                    />
                  </Fragment>
                )}
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  width='100%'>
                  <StyledTypography>
                    Purchase amount
                  </StyledTypography>
                  <StyledTypography>
                    {formatCurrency(checkoutResult?.amount ?? 0)}
                  </StyledTypography>
                </Stack>
                <Divider
                  orientation='horizontal'
                  sx={({ palette }) => ({
                    backgroundColor: palette.common[900]
                  })}
                />
                {checkoutResult?.referenceId && (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'>
                    <StyledTypography>
                      Reference ID
                    </StyledTypography>
                    <StyledTypography>
                      {checkoutResult?.referenceId}
                    </StyledTypography>
                  </Stack>
                )}
                {checkoutResult?.paymentId && (
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'>
                    <StyledTypography>
                      Payment ID
                    </StyledTypography>
                    <StyledTypography>
                      {checkoutResult?.paymentId}
                    </StyledTypography>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Button
              fullWidth
              onClick={handleTryAgain}>
              Try again
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};