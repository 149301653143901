import { FC } from 'react';
import { sanitize } from 'dompurify';
import { Box } from '@mui/joy';

export type HtmlBlockProps = {
  html: string;
};

export const HtmlBlock: FC<HtmlBlockProps> = ({ html }) => (
  <Box
    component='div'
    sx={({ palette }) => ({
      '& figure': {
        overflow: 'auto',
        width: '100% !important',
        marginInline: '0 !important',
      },
      '& table': {
        width: '100% !important',
        borderRadius: 8,
        background: palette.common[475],
        borderCollapse: 'collapse',
        '& thead th': {
          paddingBlock: 1.5,
          paddingInline: 3,
        },
        '& thead tr:first-child th': {
          borderBottom: `1px solid ${palette.common[925]}`
        },
        '& tbody td': {
          paddingBlock: 2,
          paddingInline: 3,
        },
        '& tbody tr:first-child td': {
          borderTop: 'none'
        },
        '& tbody tr td': {
          borderTop: `1px solid ${palette.common[925]}`
        },
      },
      '& strong': {
        fontWeight: 'bold'
      },
      '& > p': {
        color: '#959595',
        paddingBlock: 0,
        marginBlock: 0,
        fontSize: 16
      }
    })}
    dangerouslySetInnerHTML={{
      __html: sanitize(html)
    }}
  />
);