import { FC, useCallback, useEffect } from 'react'
import { Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import { CloseIcon, IconButton } from '@shared/ui';
import { useModalQueryParam, useQueryParamModalVisibility } from '@shared/lib';
import { selectEmail, selectMobile, selectResetPasswordKey, useResetPasswordSelector } from '../model';
import { ResetPasswordModalsQueries } from '../api';
import { NewPasswordForm } from './new-password-form.component';

type NewPasswordModalProps = {
  open: boolean;
  onClose: () => void;
}

export const NewPasswordModal: FC<NewPasswordModalProps> = ({ open, onClose }) => {
  const { closeModal } = useModalQueryParam()
  const isNewPasswordModal = useQueryParamModalVisibility(ResetPasswordModalsQueries.NewPasswordModal);
  const email = useResetPasswordSelector(selectEmail)
  const mobile = useResetPasswordSelector(selectMobile)
  const resetPasswordKey = useResetPasswordSelector(selectResetPasswordKey)

  const closeIfNotComplete = useCallback(() => {
    if (((!email && !mobile) || !resetPasswordKey) && isNewPasswordModal) {
      closeModal(ResetPasswordModalsQueries.NewPasswordModal)
    }
  }, [closeModal, email, isNewPasswordModal, mobile, resetPasswordKey])

  useEffect(() => {
    closeIfNotComplete()
  }, [closeIfNotComplete])

  return (
    <Modal
      open={open}
    >
      <ModalDialog
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: '358px',
          overflow: 'hidden',
          [breakpoints.down(490)]: {
            maxWidth: 'calc(100vw - 2rem)'
          },
        })}
      >
        <Stack sx={{
          padding: '1.5rem',
        }}>
          <Stack mb='1rem'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              mb='0.75rem'>

              <Typography sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: '1.125rem',
                fontWeight: 600,
                lineHeight: 'normal',
              })}>
                New password
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={({ palette }) => ({
              color: palette.common[150],
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
            })}>
              Please create a new password
            </Typography>
          </Stack>
          <NewPasswordForm />
        </Stack>
      </ModalDialog>
    </Modal>
  )
};