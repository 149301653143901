import {
  FC,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/joy';
import { useModalQueryParam } from '@shared/lib';
import {
  type SessionState,
  type Consent,
  ConfirmConsentsModal,
} from '@entities/session';
import {
  setPropsToAuthViaSocials,
  openConfirmationAuthViaSocialsModal
} from '../model';
import {
  FacebookSignUpButton
} from './facebook-sign-up-button.component';
import {
  FacebookSignInButton
} from './facebook-sign-in-button.component';
import {
  GoogleSignUpButton
} from './google-sign-up-button.component';
import {
  GoogleSignInButton
} from './google-sign-in-button.component';
import { SocialLogin as SocialLoginType } from '../model';

export type SocialLoginProps = {
  signUpMode?: boolean;
  signInMode?: boolean;
  trackingCodes?: Maybe<string>;
  consents?: Maybe<Array<Consent>>;
  onSignedIn?(loginData: Pick<
    SessionState,
    'currencySessionKeys' |
    'sessionKey' |
    'shortProfile'
  >): void;
  onError?(error: Maybe<string>): void;
  onSocialSignUp(method: string): void;
};

export const SocialLogin: FC<SocialLoginProps> = ({
  signInMode = true,
  signUpMode = false,
  trackingCodes,
  consents,
  onSignedIn,
  onError,
  onSocialSignUp
}) => {
  const dispatch = useDispatch();
  const { closeModal } = useModalQueryParam();

  const consentIds = useMemo(() => consents?.map(({ consentVersionId }) => consentVersionId), [consents]);
  const [openConfirmConsentsModal, setOpenConfirmConsentsModal] = useState<boolean>(false);
  const completeAuthCallbackRef = useRef<Maybe<() => Promise<void>>>(null);

  const handleSignedIn = (
    loginData: Pick<
      SessionState,
      'currencySessionKeys' |
      'sessionKey' |
      'shortProfile'
    >
  ) => {
    onSignedIn?.(loginData);
  };

  const handleConfirmConsents = async (
    completeAuthCallback: () => Promise<void>
  ): Promise<void> => {
    completeAuthCallbackRef.current = completeAuthCallback;
    setOpenConfirmConsentsModal(true);
  };

  const handleConfirmedConsents = async (): Promise<void> => {
    await completeAuthCallbackRef.current?.();
    setOpenConfirmConsentsModal(false);
  };

  const handleNotCompletedSignUpError = (socialLoginProps: SocialLoginType): void => {
    closeModal('login');
    dispatch(setPropsToAuthViaSocials(socialLoginProps));
    dispatch(openConfirmationAuthViaSocialsModal());
  };

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={({ breakpoints }) => ({
          width: '100%',
          [breakpoints.down(490)]: {
            gap: 1.5
          },
          [breakpoints.down(389)]: {
            gap: .5,
          }
        })}>
        {signInMode && !signUpMode ? (
          <>
            <GoogleSignInButton
              onSignedIn={handleSignedIn}
              onError={onError}
              onNotCompletedSignUpError={handleNotCompletedSignUpError}
            />
            <FacebookSignInButton
              onSignedIn={handleSignedIn}
              onError={onError}
              onNotCompletedSignUpError={handleNotCompletedSignUpError}
            />
          </>
        ) : (
          <>
            <GoogleSignUpButton
              trackingCodes={trackingCodes}
              consentIds={consentIds}
              onError={onError}
              onConfirmConsents={handleConfirmConsents}
              onSocialSignUp={onSocialSignUp}
            />
            <FacebookSignUpButton
              trackingCodes={trackingCodes}
              consentIds={consentIds}
              onError={onError}
              onConfirmConsents={handleConfirmConsents}
              onSocialSignUp={onSocialSignUp}
            />
          </>
        )}
      </Stack>
      <ConfirmConsentsModal
        open={openConfirmConsentsModal}
        consents={consents ?? []}
        onConfirm={handleConfirmedConsents}
      />
    </>
  );
}