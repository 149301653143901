import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addOrUpdateQueryParam, removeQueryParam } from './utilities';

export type UseQueryParamValue = {
  value: Maybe<string>;
  modifyParam(value: string): void;
  removeParam(): void;
};

export const useQueryParamValue = (paramName: string): UseQueryParamValue => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState<Maybe<string>>(null);

  const modifyParam = (value: string): void => {
    let queryString = addOrUpdateQueryParam(location.search, paramName, value);
    navigate(`?${queryString}`, { replace: true });
  };

  const removeParam = (): void => {
    let queryString = removeQueryParam(location.search, paramName);
    navigate(`?${queryString}`, { replace: true });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search.toLowerCase());
    setValue(queryParams.get(paramName?.toLowerCase()));
  }, [location.search, paramName]);

  return {
    value,
    modifyParam,
    removeParam,
  };
};
