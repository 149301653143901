import { UserStateEnum } from '@shared/types';
import { IconLink, TextWithInlineLink } from '@shared/lib';

export enum DynamicPageContentEnum {
  Faq = 'widgets.faq-scoped',
  Html = 'widgets.html-content',
  ContactUs = 'widgets.contact-us-content',
  NotLoaded = 'NotLoaded'
};

export type BoxedFaqBlockItem = {
  iconSrc: Maybe<Url>;
  shortSummary: string;
  shortSummaryStyle: Maybe<Record<string, string>>;
  details: string;
};

export type BoxedFaqBlock = {
  title?: Maybe<TextWithInlineLink>;
  items?: Maybe<Array<BoxedFaqBlockItem>>;
};

export type FaqBlockItem = {
  summary: string;
  details: string;
};

export type FaqBlock = {
  title: Maybe<string>;
  items: Maybe<Array<FaqBlockItem>>;
};

export type FaqContent = {
  boxedFaqBlock?: Maybe<BoxedFaqBlock>;
  faqBlock?: Maybe<FaqBlock>;
};

export type HowItWorksBlockItem = {
  iconSrc: Maybe<Url>;
  details: string;
};

export type HowItWorksBlock = {
  title?: Maybe<string>;
  items?: Maybe<Array<HowItWorksBlockItem>>
};

export type ActionItem = {
  text: string;
  url: Url;
  userState: UserStateEnum;
};

export type DynamicPageCarouselSliderItem = {
  id: number;
  actions: Array<ActionItem>;
  variant1ImgSrc: Url;
  variant2ImgSrc: Url;
  bannerUrl: Url;
  isVariant1ItemLooped: boolean;
  isVariant2ItemLooped: boolean;
};

export type DynamicPageUserCarouselSliderItem = Prettify<Omit<
  DynamicPageCarouselSliderItem,
  'actions'
> & {
  action: Maybe<ActionItem>;
}>;

export type HtmlBlock = {
  title?: Maybe<string>;
  html?: Maybe<any>;
};

export type HtmlContent = {
  htmlBlock?: Maybe<HtmlBlock>;
};

export type BoxedContactBlockItem = {
  title: string;
  iconSrc: Maybe<Url>;
  description: Maybe<TextWithInlineLink>;
  icons: Maybe<Array<IconLink>>;
};

export type BoxedContactUsBlock = {
  title?: string;
  items?: Maybe<Array<BoxedContactBlockItem>>;
};

export type ContactUsHtmlBlock = {
  title: Maybe<string>;
  html: Maybe<any>;
};

export type ContactUsContent = {
  boxedContactUsBlock?: Maybe<BoxedContactUsBlock>;
  contactUsHtmlBlock?: Maybe<ContactUsHtmlBlock>;
};

export type DynamicPageContent = FaqContent | HtmlContent | ContactUsContent;

export type DynamicPageState = {
  title: Maybe<string>;
  keywords: Maybe<string>;
  description: Maybe<string>;
  canonical: Maybe<Url>;
  name: Maybe<string>;
  contentType: Maybe<DynamicPageContentEnum>;
  content: Maybe<DynamicPageContent>;
};