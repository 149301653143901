import {
  FC,
  useMemo,
  useState
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Drawer,
  IconButton,
  Stack,
  Typography,
  useTheme
} from '@mui/joy';
import {
  CloseIcon,
  Dropdown
} from '@shared/ui';
import {
  type ChannelMessage,
  readAllMessages
} from '@entities/messages';
import {
  closeNotificationsSidebar,
  useNotificationsSidebarSelector,
  selectIsOpen,
  selectMessages
} from '../model';
import {
  NotificationItem
} from './notification-item.component';

const notificationTypes = ['All', 'Read', 'Unread'];

export const NotificationsSidebar: FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isOpen = useNotificationsSidebarSelector(selectIsOpen);
  const messages = useNotificationsSidebarSelector(selectMessages);

  const [selectedType, setSelectedType] = useState<string>(notificationTypes[0]);

  const messagesToShow: Array<ChannelMessage> = useMemo(() => {
    let messagesToReturn = [];
    if (selectedType === 'All') {
      messagesToReturn = (messages ?? []);
    }
    else {
      messagesToReturn = (messages ?? []).filter((message: ChannelMessage) => (
        message.isRead === (selectedType === 'Read'))
      )
    }

    if (messagesToReturn.length > 1) {
      messagesToReturn = messagesToReturn.slice()
        .sort((messageA: ChannelMessage, messageB: ChannelMessage) => (
          new Date(messageB.date).getTime() - new Date(messageA.date).getTime()
        ));
    }

    return messagesToReturn;
    // eslint-disable-next-line
  }, [messages, selectedType, isOpen]);

  const handleClose = (): void => {
    dispatch(closeNotificationsSidebar());
    dispatch(readAllMessages({
      channelId: process.env.REACT_APP_OMEGA_WS_PLAYER_INBOX_CHANNEL ?? ''
    }));
  };

  const handleSelectType = (value: string): void => {
    setSelectedType(value);
  };

  return (
    <Drawer
      open={isOpen}
      anchor='right'
      sx={{ zIndex: 15 }}
      slotProps={{
        content: {
          sx: ({ breakpoints, palette }) => ({
            width: 406,
            backgroundColor: palette.common[475],
            [breakpoints.down(491)]: {
              width: '100%',
            },
          }),
        },
      }}
      onClose={handleClose}>
      <Stack
        sx={{
          gap: 1,
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          padding: 3,
          height: '100%',
        }}>
        <Stack gap={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              sx={({ palette }) => ({
                fontSize: 20,
                fontWeight: 600,
                lineHeight: '30px',
                color: palette.common.white,
              })}>
              {`Notifications (${messages?.length ?? 0})`}
            </Typography>
            <IconButton
              size='sm'
              sx={{
                blockSize: 'fit-content',
                marginLeft: 1
              }}
              onClick={handleClose}>
              <CloseIcon
                sx={({ palette }) => ({
                  fontSize: 24,
                  color: palette.common[200],
                })} />
            </IconButton>
          </Stack>
          <Dropdown
            value={selectedType}
            onChange={handleSelectType}
            options={notificationTypes.map((type: string) => ({
              label: type,
              value: type,
            }))}
            inputProps={{
              root: {
                sx: {
                  minHeight: 'auto',
                }
              },
              select: {
                sx: {
                  backgroundColor: theme.palette.common[900],
                }
              }
            }}
          />
        </Stack>
        <Stack
          sx={{
            gap: 1,
            overflowY: 'scroll',
          }}>
          {messagesToShow?.map((message: ChannelMessage, index: number) => (
            <NotificationItem
              key={index}
              message={message}
              onButtonClick={handleClose} />
          ))}
        </Stack>
      </Stack>
    </Drawer>
  );
};
