import {
  FC,
  Fragment
} from 'react';
import {
  Link
} from 'react-router-dom'
import {
  useSelector
} from 'react-redux';
import {
  Button,
  Stack
} from '@mui/joy';
import {
  CarouselSlider
} from '@shared/ui';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  GamesByCategoriesBlock
} from '@widgets/games-by-categories-block';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  TournamentsList
} from '@widgets/tournaments-list';
import {
  PlayInThreeSteps
} from '@widgets/play-in-three-steps';
import {
  GameWinnersCarousel
} from '@widgets/game-winners-carousel';
import {
  useHomePageSelector,
  selectTitlePlayInThreeSteps,
  selectStepsPlayInThreeSteps,
  selectMetaTagKeywords,
  selectMetaTagDescription,
  selectMetaTagCanonical,
  selectMetaTagTitle,
  selectSlider,
  selectHomePageGamesByCategoriesBlockTop,
  selectHomePageGamesByCategoriesBlockBottom,
  selectTournamentWidgetTitle,
  selectAction,
} from '../model';
import {
  useGetHomePageQuery
} from '../api';

export const Home: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'home',
    }
  });

  const title = useHomePageSelector(selectMetaTagTitle);
  const keywords = useHomePageSelector(selectMetaTagKeywords);
  const description = useHomePageSelector(selectMetaTagDescription);
  const canonical = useHomePageSelector(selectMetaTagCanonical);
  const titlePlayInThreeSteps = useHomePageSelector(selectTitlePlayInThreeSteps);
  const stepsPlayInThreeSteps = useHomePageSelector(selectStepsPlayInThreeSteps);
  const gamesByCategoriesBlockTop = useHomePageSelector(selectHomePageGamesByCategoriesBlockTop);
  const gamesByCategoriesBlockBottom = useHomePageSelector(selectHomePageGamesByCategoriesBlockBottom);
  const tournamentWidgetTitle = useHomePageSelector(selectTournamentWidgetTitle);
  const action = useSelector(selectAction);
  const slider = useSelector(selectSlider) as any;

  const { isFetching } = useGetHomePageQuery(undefined, { refetchOnMountOrArgChange: true });

  return isFetching ? null : (
    <Fragment>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        direction='column'
        gap={6}
        sx={({ breakpoints }) => ({
          width: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockEnd: 8,
          [breakpoints.down(1350)]: { paddingInline: 2 },
          [breakpoints.down(768)]: { paddingBlockStart: 3 },
          [breakpoints.down(414)]: {
            paddingBlock: 3,
            gap: 8
          },
        })}>
        <CarouselSlider items={slider} />
        <GamesByCategoriesBlock
          categories={gamesByCategoriesBlockTop}
          sx={{ minHeight: 446 }}
        />
        <TournamentsList title={tournamentWidgetTitle} />
        {(titlePlayInThreeSteps && stepsPlayInThreeSteps) ? (
          <PlayInThreeSteps
            title={titlePlayInThreeSteps}
            steps={stepsPlayInThreeSteps}
            loading={isFetching}
            actionSlot={action && (
              <Button
                component={Link}
                to={action.url}
                color='primary'
                sx={({ breakpoints }) => ({
                  height: 36,
                  [breakpoints.down(490)]: {
                    width: '100%'
                  }
                })}>
                {action.text}
              </Button>
            )}
          />
        ) : null}
        <GameWinnersCarousel />
        <GamesByCategoriesBlock
          categories={gamesByCategoriesBlockBottom}
          sx={{ minHeight: 212 }}
        />
      </Stack>
    </Fragment>
  );
};
