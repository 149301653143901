import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dynamicPageApiSlice } from '../api';
import { type DynamicPageState } from './types';

const initialState: DynamicPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  name: null,
  contentType: null,
  content: null
};

export const dynamicPageSlice = createSlice({
  name: 'dynamic-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DynamicPageState>) => {
    builder.addMatcher(
      dynamicPageApiSlice.endpoints.getDynamicPage.matchFulfilled,
      (state: DynamicPageState, action: PayloadAction<DynamicPageState>) => {
        state.title = action.payload.title;
        state.keywords = action.payload.keywords;
        state.description = action.payload.description;
        state.canonical = action.payload.canonical;
        state.name = action.payload.name;
        state.contentType = action.payload.contentType;
        state.content = action.payload.content;
      }
    );
  }
});

export const dynamicPageReducer = dynamicPageSlice.reducer;