import { mapper } from '@shared/lib';
import { type PageHeader } from '../model';
import { mapLogo } from './map-logo';

type MapPageHeader = (data: any) => PageHeader;
export const mapPageHeader: MapPageHeader = mapper([
  {
    name: 'logo',
    from: 'logo',
    transform: (logoData: any) => mapLogo(logoData),
  }
]);