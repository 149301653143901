import { createContext, useContext } from 'react';

export type LiveChatOptions = {
  propertyId: string;
  widgetId: string;
  apiKey: string;
};

export type LoginLiveChatOptions = {
  userId: string;
  name: string;
  email: Email;
  phone: Maybe<Phone>;
};

export type UseLiveChat = {
  loginChat: (options: LoginLiveChatOptions) => Promise<void>;
  logoutChat: () => void;
  closeChat: () => void;
};

export const LiveChatContext = createContext<Maybe<UseLiveChat>>(null);

export const useLiveChat = (): Maybe<UseLiveChat> => {
  return useContext(LiveChatContext);
};