const timeout = 15000;

const maskAfterSuccessValue = '*';

const checkoutPinMode = false;

const checkoutCombinedMode = false;

const showCvv = true;

const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

const numYears = 30;

const expYearFormat = 'YY';

const tooltipText
  = 'A CVV is the 3 digit code on the back of your Visa, Mastercard and Discover or a 4 digit code on the front of your American Express';

const tabIndex = {
  'accountNumber': 1,
  'expMonth': 2,
  'expYear': 3,
  'cvv': 4
};

const placeholderText = {
  cvv: 'CVV',
  accountNumber: 'Card number',
  pin: 'PIN Placeholder'
};

const enhancedUxFeatures = {
  inlineFieldValidations: true,
  expDateValidation: false,
  enhancedUxVersion: 2
};

const minPanLength = 16;

const iFrameTitle = 'My Custom Title';

const label = {
  accountNumber: 'Card number',
  expDate: 'Exp Date',
  cvv: 'CVV',
  pin: 'Pin'
};

export const worldPayIFrameConfig = {
  timeout,
  maskAfterSuccessValue,
  checkoutPinMode,
  checkoutCombinedMode,
  showCvv,
  months,
  numYears,
  expYearFormat,
  tooltipText,
  tabIndex,
  placeholderText,
  enhancedUxFeatures,
  minPanLength,
  iFrameTitle,
  label
};