// TODO: refactoring needed
import { OMEGA_API_URL } from '@shared/config';
import { CurrencyCodesEnum } from '@shared/types';

type MapGameUrlParams = {
  platform: string;
  gameId: string;
  sessionKey: string;
  currencyCode: CurrencyCodesEnum;
  lobbyURL: Url;
};

const GAME_CONTAINER_URL = 'game/GameContainer.action';
const getGameCurrencyByPlatform = (platform: string, currencyCode: CurrencyCodesEnum): string | undefined => {
  const gameCurrencyMatrix: Record<string, string> = {
    [`BETSOFT_V2:${CurrencyCodesEnum.GCO}`]: 'VND',
    [`BETSOFT_V2:${CurrencyCodesEnum.SCO}`]: 'USD',
    [`RELAX:${CurrencyCodesEnum.GCO}`]: 'GC.',
    [`RELAX:${CurrencyCodesEnum.SCO}`]: 'SC.',
  };

  return gameCurrencyMatrix[`${platform}:${currencyCode}`];
};

export const mapGameUrl = ({ currencyCode, ...data }: MapGameUrlParams): string => {
  const url = new URL(`${OMEGA_API_URL}${GAME_CONTAINER_URL}`);
  const searchParams = new URLSearchParams(data);

  searchParams.append('playForReal', 'true');
  searchParams.append('brandId', '1');

  if (!data.platform.includes('EVOPLAY')) {
    const gameCurrency = getGameCurrencyByPlatform(data.platform, currencyCode);
    if (gameCurrency) {
      searchParams.append('gameCurrency', gameCurrency);
    }
  }

  url.search = searchParams.toString();
  return url.href;
};
