import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  createSelector
} from '@reduxjs/toolkit';
import {
  type GameCategory,
  type GamesItem,
  type GamesState,
  type Pagination,
  gamesSlice
} from '@entities/games';
import {
  gamesFilteredByCategoriesSlice
} from './slice';
import {
  type GamesFilterByCategoryState
} from './types';

type RootState = {
  [gamesFilteredByCategoriesSlice.name]: GamesFilterByCategoryState;
  [gamesSlice.name]: GamesState;
};

export const selectPaginationByCategoryKey =
  (categoryKey: Maybe<string>) =>
    (state: RootState): Maybe<Pagination> =>
      categoryKey ? state[gamesFilteredByCategoriesSlice.name].paginationsByCategories[categoryKey] : null;

export const innerSelectGamesCategoryByCategoryKey =
  (categoryKey: Maybe<string>) =>
    (state: RootState): Maybe<GameCategory> => {
      const selectedCategory: Maybe<GameCategory> = state[gamesSlice.name].categories
        .find((category: GameCategory) => category.key === categoryKey);
      return selectedCategory;
    };

export const selectGamesByCategoryKey = (categoryKey: Maybe<string>) =>
  createSelector(
    [innerSelectGamesCategoryByCategoryKey(categoryKey), selectPaginationByCategoryKey(categoryKey)],
    (gameCategory: Maybe<GameCategory>, pagination: Maybe<Pagination>): Maybe<Array<GamesItem>> => {
      if (!gameCategory || !pagination) return null;
      return gameCategory.games.slice(0, pagination.page * pagination.pageSize);
    }
  );

export const selectIsLoading = (state: RootState): boolean =>
  state[gamesFilteredByCategoriesSlice.name].isLoading;

export const selectSelectedCategoryKey = (state: RootState): Maybe<string> =>
  state[gamesFilteredByCategoriesSlice.name].selectedCategoryKey;

export const useGamesFilterByCategorySelector: TypedUseSelectorHook<{
  [gamesFilteredByCategoriesSlice.name]: GamesFilterByCategoryState;
  [gamesSlice.name]: GamesState;
}> = useSelector;
