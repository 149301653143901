import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { dynamicPageSlice } from './slice';
import { selectDynamicPageContent } from './common-selectors';
import {
  type DynamicPageState,
  type FaqContent
} from './types';

export const selectFaqContent = createSelector(
  selectDynamicPageContent,
  (faqContent) => faqContent as Maybe<FaqContent>,
  {
    devModeChecks: { identityFunctionCheck: 'never' }
  }
);

export const useFaqPageContentSelector: TypedUseSelectorHook<{
  [dynamicPageSlice.name]: DynamicPageState;
}> = useSelector;