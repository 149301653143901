import {
  FC
} from 'react';
import {
  Navigate,
  useParams
} from 'react-router-dom';
import {
  FilterOperationEnum
} from '@shared/lib';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  selectMetaTagTitle,
  selectMetaTagKeywords,
  selectMetaTagDescription,
  selectMetaTagCanonical,
  useDynamicPageSelector,
  selectDynamicPageContentType,
  DynamicPageContentEnum,
} from '../model';
import {
  useGetDynamicPageQuery
} from '../api';
import {
  dynamicPagesMapper
} from './dynamic-pages-mapper';

export const Dynamic: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'dynamic'
    }
  });

  const { dynamicPageName } = useParams();
  const { isFetching } = useGetDynamicPageQuery({
    slug: { [FilterOperationEnum.Equals]: dynamicPageName }
  }, {
    refetchOnMountOrArgChange: true
  });

  const contentType = useDynamicPageSelector(selectDynamicPageContentType);
  const title = useDynamicPageSelector(selectMetaTagTitle);
  const keywords = useDynamicPageSelector(selectMetaTagKeywords);
  const description = useDynamicPageSelector(selectMetaTagDescription);
  const canonical = useDynamicPageSelector(selectMetaTagCanonical);

  const renderComponent = () => {
    const Component = dynamicPagesMapper[contentType ?? DynamicPageContentEnum.NotLoaded];
    return Component
      ? <Component />
      : <Navigate to='/' replace />;
  };

  return !isFetching && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      {renderComponent()}
    </>
  );
};