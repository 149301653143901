import { SvgIcon, SvgIconProps } from '@mui/joy';
import { FC } from 'react';

export const MenuIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M21.0257 7.16176H3.26838C2.54963 7.16176 2 6.61213 2 5.89338C2 5.17463 2.54963 4.625 3.26838 4.625H21.0257C21.7445 4.625 22.2941 5.17463 22.2941 5.89338C22.2941 6.61213 21.7445 7.16176 21.0257 7.16176Z'
      fill='currentColor'
    />
    <path
      d='M21.0257 13.081H3.26838C2.54963 13.081 2 12.5313 2 11.8126C2 11.0938 2.54963 10.5442 3.26838 10.5442H21.0257C21.7445 10.5442 22.2941 11.0938 22.2941 11.8126C22.2941 12.5313 21.7445 13.081 21.0257 13.081Z'
      fill='currentColor'
    />
    <path
      d='M21.0257 18.9999H3.26838C2.54963 18.9999 2 18.4503 2 17.7315C2 17.0128 2.54963 16.4631 3.26838 16.4631H21.0257C21.7445 16.4631 22.2941 17.0128 22.2941 17.7315C22.2941 18.4503 21.7445 18.9999 21.0257 18.9999Z'
      fill='currentColor'
    />
  </SvgIcon>
);
