import {
  ComponentType,
  Fragment,
  useEffect,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Typography
} from '@mui/joy';
import {
  ContactUsLink,
  TermsOfUseLink,
  useToaster
} from '@shared/ui';
import {
  featureToggles
} from '@shared/config';
import {
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  UserKycStatusEnum,
  selectIsAuthenticated,
  selectKycStatus,
  selectRegistrationStatus,
  selectUserBalance,
  useSessionSelector
} from '@entities/session';
import {
  WorldPayCheckoutFailedPopup,
  WorldPayCheckoutSuccessPopup,
  closeWorldPayCheckoutFailedPopup,
  openWorldPayCheckoutFailedPopup,
  closeWorldPayCheckoutSuccessPopup,
  openWorldPayCheckoutSuccessPopup,
  selectActionPurpose,
  usePaymentsSelector,
  WorldPayCheckoutResult
} from '@entities/payments';
import {
  CoinRedemptionPopup,
  PaymentIQCashierConfirmCoinRedemptionPopup,
  WorldPayConfirmCoinRedemptionPopup,
  PleaseConfirmYourIdentityModal,
  openConfirmCoinRedemptionPopup,
  selectIsOpenConfirmCoinRedemptionPopup,
  useRedeemCoinsSelector,
} from '@features/redeem-coins';
import {
  useSendDataToGtm
} from '@features/collect-analytics';

export const withCoinRedemptionPopup = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    const dispatch = useDispatch();
    const { error } = useToaster();
    const { closeModal, openModal } = useModalQueryParam();

    const isAuthenticated = useSessionSelector(selectIsAuthenticated);
    const kycStatus = useSessionSelector(selectKycStatus);
    const registrationStatus = useSessionSelector(selectRegistrationStatus);
    const userBalance = useSessionSelector(selectUserBalance);
    const openConfirmCoinRedemption = useRedeemCoinsSelector(selectIsOpenConfirmCoinRedemptionPopup);
    const isRedeemActionPurpose = usePaymentsSelector(selectActionPurpose('redeem'));
    const { sendDataToGtm } = useSendDataToGtm();
    const redeemCoinsPopup = useQueryParamModalVisibility('redeem');

    const allowRedeemCoins = useMemo(() =>
      registrationStatus === UserRegistrationStatusEnum.Player &&
      kycStatus === UserKycStatusEnum.Pass,
      [registrationStatus, kycStatus]
    );

    const openCoinRedemptionPopup = useMemo(
      () => redeemCoinsPopup && isAuthenticated && allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    const openPleaseConfirmYourIdentityModal = useMemo(
      () => redeemCoinsPopup && isAuthenticated && !allowRedeemCoins,
      [redeemCoinsPopup, isAuthenticated, allowRedeemCoins]
    );

    useEffect(() => {
      if (!isAuthenticated && redeemCoinsPopup) {
        error({
          message: 'You are not authorized! Please log in to your account to gain access',
          autoHideDuration: 5000
        });
      }
      // eslint-disable-next-line
    }, []);

    const handleClose = (): void => {
      closeModal('redeem');
    };

    const handleCoinRedeem = (amount: string | number): void => {
      closeModal('redeem');
      dispatch(openConfirmCoinRedemptionPopup(amount));
    };

    const handleRedeemStart = (amount: string | number): void => {
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_start',
          withdrawalAmount: amount,
          withdrawalCurrency: 'USD',
          withdrawalCount: '1',
          paymentMethod: 'Trustly'
        }
      })
    };

    const handleRedeemSuccess = (redeemResult?: WorldPayCheckoutResult): void => {
      if (redeemResult) {
        dispatch(openWorldPayCheckoutSuccessPopup({
          actionPurpose: 'redeem',
          result: redeemResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_success',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleRedeemFail = (redeemResult?: WorldPayCheckoutResult): void => {
      if (redeemResult) {
        dispatch(openWorldPayCheckoutFailedPopup({
          actionPurpose: 'redeem',
          result: redeemResult
        }));
      }
      sendDataToGtm({
        dataLayer: {
          event: 'redeem_fail',
          paymentMethod: 'Trustly'
        }
      });
    };

    const handleCloseWorldPayCheckoutSuccessPopup = (): void => {
      dispatch(closeWorldPayCheckoutSuccessPopup());
    };

    const handleCloseWorldPayCheckoutFailedPopup = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
    };

    const handleWorldPayCheckoutTryAgain = (): void => {
      dispatch(closeWorldPayCheckoutFailedPopup());
      openModal('redeem');
    };

    return (
      <Fragment>
        <WrappedComponent {...props} />
        <CoinRedemptionPopup
          open={openCoinRedemptionPopup}
          withdrawableBalance={userBalance?.withdrawableBalanceNumber ?? 0}
          bonusBalance={userBalance?.bonusBalanceNumber ?? 0}
          onClose={handleClose}
          onCoinRedeem={handleCoinRedeem}
        />
        <PleaseConfirmYourIdentityModal
          open={openPleaseConfirmYourIdentityModal}
          titleSlot={(
            <Typography
              level='h2'
              fontSize='1.25rem'>
              Redeem a prize
            </Typography>
          )}
          onClose={handleClose}
        />
        {featureToggles.paymentIQCashier && openConfirmCoinRedemption && (
          <PaymentIQCashierConfirmCoinRedemptionPopup
            onRedeemFail={handleRedeemFail}
            onRedeemStart={handleRedeemStart}
            onRedeemSuccess={handleRedeemSuccess}
          />
        )}
        {!featureToggles.paymentIQCashier && (
          <Fragment>
            {openConfirmCoinRedemption && (
              <WorldPayConfirmCoinRedemptionPopup
                onRedeemFail={handleRedeemFail}
                onRedeemStart={handleRedeemStart}
                onRedeemSuccess={handleRedeemSuccess}
              />
            )}
            {isRedeemActionPurpose && (
              <Fragment>
                <WorldPayCheckoutSuccessPopup
                  onClose={handleCloseWorldPayCheckoutSuccessPopup}
                  receiptFooterSlot={
                    <Typography
                      sx={({ palette }) => ({
                        fontSize: 16,
                        color: palette.common[100],
                        textAlign: 'center'
                      })}>
                      Your redemption request has been submitted <br />
                      successfully. It takes 1-2 business days to <br />
                      process your order.&nbsp;
                      <TermsOfUseLink sx={{ fontSize: 16 }} onClick={handleCloseWorldPayCheckoutSuccessPopup} />
                      &nbsp;apply.&nbsp;
                      <ContactUsLink sx={{ fontSize: 16 }} onClick={handleCloseWorldPayCheckoutSuccessPopup}>
                        For help, please contact our support team.
                      </ContactUsLink>
                    </Typography>
                  }
                />
                <WorldPayCheckoutFailedPopup
                  onClose={handleCloseWorldPayCheckoutFailedPopup}
                  onTryAgain={handleWorldPayCheckoutTryAgain}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  };