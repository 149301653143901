import { FC, ReactElement } from 'react';
import { Tooltip } from '@mui/joy';
import { TooltipBody } from './tooltip-body.component';

export type InfoToolTipProps = {
  open: boolean;
  children: ReactElement;
  features: string;
  rtp: string;
  volatility: string;
};

export const InfoToolTip: FC<InfoToolTipProps> = ({ open, children, features, rtp, volatility }) => {
  return (
    <Tooltip
      open={open}
      sx={(theme) => ({
        '&.MuiTooltip-root': {
          borderRadius: '0.5rem',
          background: theme.colorSchemes.dark.palette.common[475],
          boxShadow: 'none',
          padding: '0.75rem',
          zIndex: 1,
        },
      })}
      placement='bottom-end'
      title={<TooltipBody features={features} rtp={rtp} volatility={volatility} />}
    >
      {children}
    </Tooltip>
  );
};
