import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { GamesItem, Pagination } from '@entities/games';
import { gamesFilteredBySearchSlice } from './slice';
import { type GamesFilterBySearchState } from './types';

type RootState = {
  [gamesFilteredBySearchSlice.name]: GamesFilterBySearchState;
};

export const selectPagination = (state: RootState): Maybe<Pagination> =>
  state[gamesFilteredBySearchSlice.name].pagination;

export const selectGames = (state: RootState): Array<GamesItem> => state[gamesFilteredBySearchSlice.name].games;

export const selectSearch = (state: RootState): string => state[gamesFilteredBySearchSlice.name].search;

export const selectIsLoading = (state: RootState): boolean => state[gamesFilteredBySearchSlice.name].isLoading;

export const useGamesFilterBySearchSelector: TypedUseSelectorHook<{
  [gamesFilteredBySearchSlice.name]: GamesFilterBySearchState;
}> = useSelector;
