import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { type TransactionListState, transactionListSlice } from './slice';

type RootState = {
  [transactionListSlice.name]: TransactionListState;
};

export const selectStartDate = (state: RootState): string => (
  state[transactionListSlice.name].startDate
);

export const selectEndDate = (state: RootState): string => (
  state[transactionListSlice.name].endDate
);

export const useTransactionListSelector: TypedUseSelectorHook<{
  [transactionListSlice.name]: TransactionListState;
}> = useSelector;