import { mapper } from '@shared/lib';
import { type GameWinner } from '../model';

type MapGameWinner = (data: any) => GameWinner;
export const mapGameWinner: MapGameWinner = mapper([
  { name: 'playerName', from: 'playerName' },
  { name: 'gameId', from: 'gameId' },
  { name: 'amountWon', from: 'amountWon' },
  { name: 'currency', from: 'currency' },
]);
