import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { gamesListWidgetSlice } from './slice';
import { FilterTypeEnum, type GamesListState } from './types';

type RootState = {
  [gamesListWidgetSlice.name]: GamesListState;
};

export const selectActiveFilterType = (state: RootState): FilterTypeEnum =>
  state[gamesListWidgetSlice.name].activeFilterType;

export const useGamesListWidgetSelector: TypedUseSelectorHook<{
  [gamesListWidgetSlice.name]: GamesListState;
}> = useSelector;
