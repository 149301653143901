import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const DicesIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 16.64C18 15.9402 17.7216 15.2682 17.2262 14.7738C16.7318 14.2784 16.0598 14 15.36 14C13.4534 14 10.5466 14 8.64 14C7.9392 14 7.26816 14.2784 6.7728 14.7738C6.2784 15.2682 6 15.9402 6 16.64V23.36C6 24.0608 6.2784 24.7318 6.7728 25.2272C7.26816 25.7216 7.9392 26 8.64 26H15.36C16.0598 26 16.7318 25.7216 17.2262 25.2272C17.7216 24.7318 18 24.0608 18 23.36V16.64ZM16.56 16.64V23.36C16.56 23.6787 16.4333 23.984 16.2086 24.2086C15.983 24.4342 15.6778 24.56 15.36 24.56H8.64C8.32128 24.56 8.016 24.4342 7.79136 24.2086C7.56576 23.984 7.44 23.6787 7.44 23.36V16.64C7.44 16.3222 7.56576 16.017 7.79136 15.7914C8.016 15.5667 8.32128 15.44 8.64 15.44H15.36C15.6778 15.44 15.983 15.5667 16.2086 15.7914C16.4333 16.017 16.56 16.3222 16.56 16.64Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2288 19.6926C20.3606 19.7449 20.4888 19.7943 20.6125 19.8427C21.2233 20.0819 21.8963 20.047 22.4839 19.7468C23.0715 19.4456 23.5247 18.9033 23.7446 18.239C24.3437 16.4291 25.2563 13.6701 25.8555 11.8601C26.0754 11.1958 26.0433 10.4637 25.7664 9.82457C25.4904 9.18542 24.9919 8.6925 24.3802 8.45331C22.7172 7.80157 20.1807 6.80896 18.5168 6.15723C17.9061 5.91803 17.2321 5.95289 16.6454 6.25407C16.0578 6.55427 15.6038 7.09658 15.3839 7.76187C15.0082 8.8978 14.5078 10.4095 14.0395 11.8253C13.9149 12.202 14.0947 12.6184 14.4419 12.754C14.7883 12.8896 15.1711 12.6939 15.2957 12.3172L16.641 8.25382C16.7407 7.95168 16.9463 7.70473 17.2134 7.56819C17.4805 7.43164 17.7868 7.41518 18.0645 7.52461L23.928 9.81972C24.2058 9.92819 24.4328 10.1529 24.5583 10.4434C24.6838 10.7339 24.699 11.067 24.5984 11.3682L22.4884 17.7471C22.3887 18.0492 22.1821 18.2952 21.915 18.4327C21.6479 18.5692 21.3417 18.5847 21.0648 18.4763L20.6811 18.3262C20.3339 18.1906 19.9511 18.3862 19.8264 18.7639C19.7018 19.1406 19.8825 19.557 20.2288 19.6926Z'
        fill='#848587'
      />
      <path
        d='M12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7793 11.8952C19.2605 11.7083 18.6876 11.9782 18.5007 12.498C18.3138 13.0169 18.5837 13.5897 19.1025 13.7766C19.6214 13.9636 20.1952 13.6937 20.3821 13.1748C20.5691 12.656 20.2992 12.0822 19.7793 11.8952Z'
        fill='#848587'
      />
      <path
        d='M15 19C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18C14 18.5523 14.4477 19 15 19Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 23C16 22.448 15.552 22 15 22C14.448 22 14 22.448 14 23C14 23.552 14.448 24 15 24C15.552 24 16 23.552 16 23Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.9407 16.3389C22.1277 15.8191 21.8577 15.2462 21.3386 15.0593C20.8195 14.8723 20.2454 15.1423 20.0593 15.6611C19.8723 16.1809 20.1423 16.7538 20.6614 16.9407C21.1805 17.1277 21.7546 16.8577 21.9407 16.3389Z'
        fill='#848587'
      />
      <path
        d='M9 24C9.55228 24 10 23.5523 10 23C10 22.4477 9.55228 22 9 22C8.44772 22 8 22.4477 8 23C8 23.5523 8.44772 24 9 24Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 17C10 16.448 9.552 16 9 16C8.448 16 8 16.448 8 17C8 17.552 8.448 18 9 18C9.552 18 10 17.552 10 17Z'
        fill='#848587'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.9407 10.3388C19.1277 9.81982 18.8577 9.24587 18.3389 9.05889C17.8191 8.87291 17.2462 9.14189 17.0593 9.66183C16.8723 10.1808 17.1423 10.7537 17.6611 10.9407C18.1809 11.1277 18.7538 10.8577 18.9407 10.3388Z'
        fill='#848587'
      />
    </SvgIcon>
  );
};
