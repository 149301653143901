import {
  forwardRef
} from 'react';
import {
  styled
} from '@mui/joy';
import {
  useSendDataToGtm
} from '@features/collect-analytics';

type PlayGameIframeProps = {
  onLoad?(): void;
  key: string;
  src: string;
  gameId: string;
  gameMode: 'SC' | 'GC';
  isFetching: boolean;
}

const StyledIframe = styled('iframe')(({ theme }) => ({
  width: '100%',
  height: '100%',
  border: 'none',
  [theme.breakpoints.down(769)]: {
    width: '100vw',
  },
  '@media(orientation: landscape) and (max-height: 500px)': {
    width: '100%',
    height: 'calc(100vh - 4.25rem)'
  }
}));

export const PlayGameIframe = forwardRef<HTMLIFrameElement, PlayGameIframeProps>(
  ({ onLoad, key, src, gameId, gameMode, isFetching }, ref) => {
    useSendDataToGtm({
      dataLayer: {
        event: 'game_launch',
        pagePath: window.location.pathname,
        pageTitle: 'play_game_iframe',
        gameId: gameId,
        gameMode: gameMode
      }
    });

    return (
      <StyledIframe
        ref={ref}
        onLoad={onLoad}
        key={key}
        src={src}
        sx={{
          visibility: isFetching ? 'hidden' : 'visible',
        }}
      />
    );
  }
);