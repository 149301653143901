import { createAsyncThunk } from '@reduxjs/toolkit';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { playerApiSlicer } from '@entities/session';
import { documentsApiSlice } from './slice';

export const fetchDocumentTypesAndUserDocuments = createAsyncThunk<void, void>(
  'fetch-document-types-and-user-documents',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      let response: OmegaApiResponse = await dispatch(
        documentsApiSlice.endpoints.getDocumentTypes.initiate(_)
      ).unwrap();
      if (response.status !== OmegaApiResponseStatusEnum.Success) {
        throw Error(response.errors ? response.errors.map((error) => error.error).join('\n') : 'Omega error');
      }

      response = await dispatch(
        playerApiSlicer.endpoints.getUserDocuments.initiate(_, { forceRefetch: true })
      ).unwrap();
      if (response.status !== OmegaApiResponseStatusEnum.Success) {
        throw Error(response.errors ? response.errors.map((error) => error.error).join('\n') : 'Omega error');
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
