import { FC } from 'react';
import { Box, BoxProps } from '@mui/joy';

export interface IconLinkProps extends BoxProps {
  inputProps?: {
    icon?: BoxProps
  },
  href: Url;
  iconSrc: Url;
  alt?: Maybe<string>;
}

export const IconLink: FC<IconLinkProps> = ({
  href,
  iconSrc,
  alt,
  inputProps,
  ...props
}) => {
  return (
    <Box
      component='a'
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      sx={[{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      ...(props?.sx && Array.isArray(props?.sx)
        ? props?.sx
        : [props?.sx])
      ]}>
      <Box
        component='img'
        loading='lazy'
        src={iconSrc}
        alt={alt ?? 'Icon'}
        sx={[{
          height: 24,
          width: 24,
        },
        ...(inputProps?.icon?.sx && Array.isArray(inputProps?.icon?.sx)
          ? inputProps?.icon?.sx
          : [inputProps?.icon?.sx])
        ]}
      />
    </Box>
  );
};