import { ComponentType } from 'react';
import { LiveChatProvider } from '@features/contact-live-support';

export const withLiveChat = <T extends object>
  (WrappedComponent: ComponentType<T>) =>
  (props: T) => {
    return (
      <LiveChatProvider
        options={{
          propertyId: process.env.REACT_APP_PROPERTY_ID || '',
          widgetId: process.env.REACT_APP_WIDGET_ID || '',
          apiKey: process.env.REACT_APP_CHAT_API_KEY || ''
        }}>
        <WrappedComponent {...props} />
      </LiveChatProvider>
    );
  };