import {
  FC,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Stack,
  Typography,
  Divider
} from '@mui/joy';
import {
  Button,
  GoldCoinIcon,
  SilverCoinIcon
} from '@shared/ui';
import {
  isDefined
} from '@shared/lib';
import {
  CurrencyCodesEnum
} from '@shared/types';
import {
  toggleCurrencyCode,
  useBalanceQuery,
  useSessionSelector,
  selectToggledCurrencyCode,
  selectIsAuthenticated,
} from '@entities/session';
import {
  selectCurrencyBalances
} from '@features/toggle-coins';

export const BalancesButtons: FC = () => {
  const dispatch = useDispatch();

  const { isFetching, refetch } = useBalanceQuery();
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const currencyBalances = useSelector(selectCurrencyBalances);
  const toggledCurrencyCode = useSessionSelector(selectToggledCurrencyCode);

  const handleToggle = (newValue: CurrencyCodesEnum) => () => {
    dispatch(toggleCurrencyCode(newValue));
  };

  useEffect(() => {
    if (isAuthenticated) refetch();
  }, [isAuthenticated, refetch]);

  return (
    isDefined(currencyBalances) &&
    !isFetching && (
      <Stack
        direction='column'
        sx={{
          display: 'flex',
          gap: '0.25rem',
        }}>
        <Stack
          sx={({ palette, breakpoints }) => ({
            borderRadius: '6px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.common[900],
            [breakpoints.down(491)]: {
              borderColor: palette.common[475],
            }
          })}>
          <Button
            {...{
              variant: 'outlined',
              color: 'primary',
              ...(toggledCurrencyCode === CurrencyCodesEnum.SCO && {
                variant: 'plain',
                color: 'neutral',
              }),
            }}
            size='md'
            sx={{
              height: 48,
              width: '100%',
              justifyContent: 'start',
            }}
            onClick={handleToggle(CurrencyCodesEnum.GCO)}>
            <Stack
              direction='row'
              alignItems='center'
              gap='8px'
              height='100%'>
              <GoldCoinIcon sx={{ fontSize: '32px' }} />
              <Divider
                sx={({ palette }) => ({
                  width: '1px',
                  height: '22px',
                  alignSelf: 'center',
                  backgroundColor: palette.common[900],
                })}
              />
              <Stack
                direction='column'
                height='100%'
                justifyContent='end'>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '9px',
                    textAlign: 'start',
                    color: palette.common.white,
                  })}>
                  GC
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: '1rem',
                    fontWeight: 600,
                    lineHeight: '1.5rem',
                    color: palette.common.white,
                  })}>
                  {currencyBalances && currencyBalances[CurrencyCodesEnum.GCO].totalBalance}
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Stack>
        <Stack
          sx={({ palette, breakpoints }) => ({
            borderRadius: '6px',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: palette.common[900],
            [breakpoints.down(491)]: {
              borderColor: palette.common[475],
            }
          })}>
          <Button
            {...{
              variant: 'outlined',
              color: 'success',
              ...(toggledCurrencyCode === CurrencyCodesEnum.GCO && {
                variant: 'plain',
                color: 'neutral',
              }),
            }}
            size='md'
            sx={{
              height: 48,
              width: '100%',
              justifyContent: 'start',
            }}
            onClick={handleToggle(CurrencyCodesEnum.SCO)}>
            <Stack
              direction='row'
              alignItems='center'
              gap='8px'
              height='100%'>
              <SilverCoinIcon sx={{ fontSize: '32px' }} />
              <Divider
                sx={({ palette }) => ({
                  width: '1px',
                  height: '22px',
                  alignSelf: 'center',
                  backgroundColor: palette.common[900],
                })}
              />
              <Stack
                height='100%'
                direction='column'
                justifyContent='end'>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: '9px',
                    textAlign: 'start',
                    color: palette.common.white,
                  })}>
                  SC
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: '1rem',
                    fontWeight: 600,
                    lineHeight: '1.5rem',
                    color: palette.common.white,
                  })}>
                  {currencyBalances && currencyBalances[CurrencyCodesEnum.SCO].totalBalance}
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    )
  );
};
