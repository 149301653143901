import {
  FC,
  ReactElement,
  useState
} from 'react';
import ClickAwayListener from 'react-click-away-listener';
import {
  Box,
  Theme
} from '@mui/joy';
import {
  SystemStyleObject
} from '@mui/system';
import {
  isMobileDevice
} from '@shared/lib';
import {
  type GamesItem,
  GameItemLabelEnum,
} from '@entities/games';
import {
  GameListItemDetails
} from './game-list-item-details.component';
import {
  GameRibbon
} from './game-ribbon.component';
import {
  GameLabel
} from './game-label.component';

export type GameListItemProps = {
  game: GamesItem;
  favoriteSwitcherSlot: ReactElement;
  launchGameSlot: ReactElement;
  bonusFeature: boolean;
  volatility: string;
  rtp: string;
  type?: GameItemLabelEnum;
  // TODO: fix sx
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>);
};

export const GameListItem: FC<GameListItemProps> = ({
  game,
  favoriteSwitcherSlot,
  launchGameSlot,
  type,
  sx: styledSx,
}) => {
  const touchDevice = isMobileDevice();
  const [showDetails, setShowDetails] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleHoverShowDetails = (): void => {
    if (!touchDevice) setShowDetails(true);
  };

  const handleClickShowDetails = (): void => {
    if (touchDevice) setShowDetails(true);
  };

  const handleCloseShowDetails = (): void => {
    setShowDetails(false);
  };

  const handleImageLoaded = (isLoaded: boolean): () => void => (): void => {
    setImageLoaded(isLoaded);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseShowDetails}>
      <Box
        onMouseEnter={handleHoverShowDetails}
        onMouseLeave={handleCloseShowDetails}
        onClick={handleClickShowDetails}
        sx={[
          ({ breakpoints }) => ({
            borderRadius: '0.5rem',
            [breakpoints.up(768)]: {
              width: '12.5rem',
              height: '9.5rem',
            },
            [breakpoints.down(390)]: {
              width: 'auto',
              height: '120px',
            },
            height: '7.5rem',
            width: '10.8125rem',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }),
          styledSx ?? false,
        ]}>
        <Box
          component='img'
          loading='lazy'
          src={game.bannerUrl}
          onLoad={handleImageLoaded(true)}
          onError={handleImageLoaded(false)}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: imageLoaded ? 1 : 0,
            transition: 'opacity 0.5s ease-in',
            contain: 'layout style size',
          }}
        />
        <Box
          component='img'
          loading='lazy'
          src='/assets/webp/default_thumbnail.webp'
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: imageLoaded ? 0 : 1,
            transition: 'opacity 0.5s ease-out',
          }}
        />
        {game.ribbon ? <GameRibbon ribbon={game.ribbon} /> : null}
        {type ? <GameLabel type={type} /> : null}
        <GameListItemDetails
          isOverlayActive={showDetails}
          name={game.name}
          features={game?.features ?? ''}
          volatility={game?.volatility ?? ''}
          rtp={game?.rtp ?? ''}
          favoriteSwitcherSlot={favoriteSwitcherSlot}
          launchGameSlot={launchGameSlot}
        />
      </Box>
    </ClickAwayListener>
  );
};
