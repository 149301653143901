import {
  FC,
  PropsWithChildren,
  useEffect
} from 'react';
import {
  useSelector
} from 'react-redux';
import {
  useLocation
} from 'react-router-dom';
import {
  selectIsAuthenticated,
  useLazyBalanceQuery
} from '@entities/session';

export const WithPullingUserBalance: FC<PropsWithChildren> = ({ children }) => {
  const [getBalance] = useLazyBalanceQuery();
  const isAuth = useSelector(selectIsAuthenticated);
  const location = useLocation();

  useEffect(() => {
    if (isAuth) getBalance().then()
  }, [isAuth, location.pathname, getBalance]);

  return children;
};