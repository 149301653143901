import {
  baseOmegaApi,
  COIN_PACKAGES_TAG
} from '@shared/api';
import {
  GetPackagesFromOmegaResponse
} from './types';

export const coinPackagesApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPackagesFromOmega: build.query<GetPackagesFromOmegaResponse, void>({
      query: () => ({ url: 'ips/getPackage', params: { currency: 'USD' } }),
      providesTags: (result) => result ? [COIN_PACKAGES_TAG] : [],
      extraOptions: { auth: true, showLoader: true },
    }),
  }),
});

export const {
  useLazyGetPackagesFromOmegaQuery,
  useGetPackagesFromOmegaQuery,
} = coinPackagesApiSlice;
