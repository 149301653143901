export const toQueryString = (
  parameters: Record<string, any>,
  options: { allowDuplicates: boolean } = { allowDuplicates: false }
): string => {
  const queryString = new URLSearchParams();

  for (const [key, value] of Object.entries(parameters)) {
    if (Array.isArray(value) && options.allowDuplicates) {
      value.forEach((item) => queryString.append(key, item));
    } else {
      queryString.append(key, Array.isArray(value) ? value.join(',') : value);
    }
  }

  return `?${queryString.toString()}`;
};
