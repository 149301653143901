import { FC } from 'react';
import {
  List as JoyList,
  ListProps as JoyListProps
} from '@mui/joy';

export type ListProps = JoyListProps;

export const List: FC<ListProps> = ({ ...props }) => (
  <JoyList {...props} />
);