import { useCallback, useMemo } from 'react';
import { useSocket } from './use-socket';

export type UseConcreteSocket = {
  isConnected: boolean;
  connectSocket: (url: string) => void;
  disconnectSocket: () => void;
  subscribe: (handler: (data: any) => void) => () => void;
};

export const useConcreteSocket = (channelId: string): UseConcreteSocket => {
  const {
    isConnectedRecords,
    connectSocket: connectSocketByChannelId,
    disconnectSocket: disconnectSocketByChannelId,
    subscribe: subscribeByChannelId,
  } = useSocket();

  const isConnected: boolean = useMemo(() => {
    return !!isConnectedRecords[channelId];
  }, [isConnectedRecords, channelId]);

  const connectSocket = useCallback(
    (url: string) => {
      return connectSocketByChannelId(channelId, url);
    },
    [channelId, connectSocketByChannelId]
  );

  const disconnectSocket = useCallback(() => {
    return disconnectSocketByChannelId(channelId);
  }, [channelId, disconnectSocketByChannelId]);

  const subscribe = useCallback(
    (handler: (data: any) => void) => {
      return subscribeByChannelId(channelId, handler);
    },
    [channelId, subscribeByChannelId]
  );

  return {
    isConnected,
    connectSocket,
    disconnectSocket,
    subscribe,
  };
};
