import { FC, useState } from 'react';
import { Box, Skeleton } from '@mui/joy';

export const SingUpPromoBanner: FC = () => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  const handleImageLoad = (): void => {
    setImageLoaded(true);
  };

  return (
    <Box sx={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    }}>
      <Box
        component='img'
        src='/assets/webp/welcome_popup_desktop.webp'
        onLoad={handleImageLoad}
        alt='Sign up Banner'
        sx={() => ({
          height: '100%',
          width: '100%',
          display: imageLoaded ? 'block' : 'none',
          objectFit: 'cover'
        })}
      />
      {!imageLoaded && <Skeleton animation='wave' />}
    </Box>
  );
};