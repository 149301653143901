import { FC } from 'react'
import { Helmet } from 'react-helmet'

export type MetaTagsBlockProps = {
  title?: Maybe<string>;
  description?: Maybe<string>;
  keywords?: Maybe<string>;
  canonical?: Maybe<Url>;
};

export const MetaTagsBlock: FC<MetaTagsBlockProps> = ({
  title,
  description,
  keywords,
  canonical
}) => (
  <Helmet>
    {title && (
      <meta name='title' content={title} />
    )}
    {description && (
      <meta name='description' content={description} />
    )}
    {keywords && (
      <meta name='keywords' content={keywords} />
    )}
    {canonical && (
      <link rel='canonical' href={canonical} />
    )}
  </Helmet>
);