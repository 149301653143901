import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Stack, StackProps } from '@mui/joy';
import { TournamentPlaceEnum, selectPlaces } from '../model';
import {
  TournamentPlacementCard
} from './tournament-placement-card.component';

export type TournamentPlacementProps = StackProps & {
  loading?: boolean;
};

export const TournamentPlacement: FC<TournamentPlacementProps> = ({
  sx,
  loading,
  ...props
}) => {
  const places = useSelector(selectPlaces);
  const emptyPlaces = useMemo(() => [
    { place: TournamentPlaceEnum.First, player: '-', score: '-', toWin: '-' },
    { place: TournamentPlaceEnum.Second, player: '-', score: '-', toWin: '-' },
    { place: TournamentPlaceEnum.Third, player: '-', score: '-', toWin: '-' },
    { place: TournamentPlaceEnum.CurrentPlace, player: '-', score: '-', toWin: '-' }],
    []
  );
  return (
    <Stack
      gap={1}
      sx={[({ breakpoints }) => ({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        [breakpoints.down(1194)]: {
          gridTemplateColumns: '1fr 1fr',
        },
        [breakpoints.down(768)]: {
          gridTemplateColumns: '1fr'
        }
      }),
      ...(sx ? Array.isArray(sx) ? sx : [sx] : [])
      ]}
      {...props}>
      {!loading && places?.length && (
        places?.map((props, index) => (
          <TournamentPlacementCard
            key={index}
            {...props}
          />
        ))
      )}
      {!loading && !places?.length && (
        emptyPlaces?.map((props, index) => (
          <TournamentPlacementCard
            key={index}
            {...props}
          />
        ))
      )}
    </Stack>
  );
};