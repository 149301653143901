import { mapper } from '@shared/lib';
import { type FullProfile } from '../model';

type MapFullProfile = (data: any) => FullProfile;
export const mapFullProfile: MapFullProfile = mapper([
  { name: 'userId', from: 'userId' },
  { name: 'firstName', from: 'firstName' },
  { name: 'lastName', from: 'lastName' },
  { name: 'nickname', from: 'nickname' },
  {
    name: 'email',
    from: 'email',
    transform: (email: Maybe<Email>) => {
      if (!email || email?.includes('@email')) return null;
      return email;
    },
  },
  { name: 'phone', from: 'mobilePhone' },
  { name: 'birthDate', from: 'birthDate' },
  { name: 'address', from: 'address' },
  { name: 'country', from: 'country' },
  { name: 'province', from: 'province' },
  { name: 'city', from: 'city' },
  { name: 'postalCode', from: 'postalCode' },
  {
    name: 'kycStatus',
    from: 'kycStatus',
    transform: (kycStatus: Maybe<string>) => kycStatus ?? 'UNKNOWN',
  },
  { name: 'registrationStatus', from: 'registrationStatus' },
  { name: 'emailVerificationStatus', from: 'emailVerificationStatus' },
  { name: 'verificationStatus', from: 'verificationStatus' },
  { name: 'verificationMethod', from: 'verificationMethod' },
]);
