import {
  type ActionReducerMapBuilder,
  type PayloadAction, createSlice
} from '@reduxjs/toolkit';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import {
  type GetChallengesResponse,
  challengesApiSlicer
} from '../api';
import { mapChallenges } from '../lib';
import { ChallengesState } from './types';

const initialState: ChallengesState = {
  challenges: [],
  isLoading: true,
};

export const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ChallengesState>) => {
    builder.addMatcher(
      challengesApiSlicer.endpoints.getChallenges.matchPending,
      (state: ChallengesState) => {
        state.isLoading = true;
      });
    builder.addMatcher(
      challengesApiSlicer.endpoints.getChallenges.matchRejected,
      (state: ChallengesState) => {
        state.isLoading = false;
      });
    builder.addMatcher(
      challengesApiSlicer.endpoints.getChallenges.matchFulfilled,
      (state: ChallengesState, action: PayloadAction<GetChallengesResponse>) => {
        state.isLoading = false;
        if (action.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.challenges = mapChallenges(action.payload.data);
        }
      });
  },
});

export const challengesReducer = challengesSlice.reducer;
