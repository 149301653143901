export type SendResetPasswordByEmail = {
  email: string;
}

export type SendResetPasswordByPhone = {
  mobile: string;
}

export type ConfirmPasswordReset = {
  resetPasswordKey: string;
} & { email: string } |
{ mobile: string }

export enum ResetPasswordModalsQueries {
  SentResetPassword = 'send-reset-password',
  ConfirmResetPassword = 'confirm-reset-password',
  NewPasswordModal = 'new-password'
}

export type SetNewPassword = {
  resetPasswordKey: string;
  newPassword: string;
} & { email: string } |
{ mobile: string }