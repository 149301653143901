import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';

export type RedeemCoinsState = {
  isOpenConfirmCoinRedemptionPopup: boolean;
  coinRedeem: string | number;
};

const initialState: RedeemCoinsState = {
  isOpenConfirmCoinRedemptionPopup: false,
  coinRedeem: 0
};

export const redeemCoinsSlice = createSlice({
  name: 'redeem-coins',
  initialState,
  reducers: {
    resetRedeemCoins: (state: RedeemCoinsState) => {
      Object.assign(state, initialState);
    },
    openConfirmCoinRedemptionPopup: (state: RedeemCoinsState, action: PayloadAction<string | number>) => {
      state.isOpenConfirmCoinRedemptionPopup = true;
      state.coinRedeem = action.payload;
    },
    closeConfirmCoinRedemptionPopup: (state: RedeemCoinsState) => {
      state.isOpenConfirmCoinRedemptionPopup = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState);
  },
});

export const {
  resetRedeemCoins,
  openConfirmCoinRedemptionPopup,
  closeConfirmCoinRedemptionPopup,
} = redeemCoinsSlice.actions;

export const redeemCoinsReducer = redeemCoinsSlice.reducer;