import { FC } from 'react';
import { Button as JoyButton, ButtonProps as JoyButtonProps } from '@mui/joy';

export type ButtonProps = Pick<
  JoyButtonProps,
  | 'variant'
  | 'size'
  | 'color'
  | 'children'
  | 'startDecorator'
  | 'sx'
  | 'onClick'
  | 'fullWidth'
  | 'type'
  | 'component'
  | 'loading'
> & {
  size?: 'sm' | 'md' | 'lg' | 'xl';
  color?: 'primary' | 'neutral' | 'success';
  variant?: 'solid' | 'outlined' | 'plain';
  disabled?: boolean;
  to?: string;
};

export const Button: FC<ButtonProps> = ({ children, sx, ...props }: ButtonProps) => (
  <JoyButton {...props} sx={[{ width: 'fit-content' }, ...(Array.isArray(sx) ? sx : [sx])]}>
    {children}
  </JoyButton>
);
