export enum FilterOperationEnum {
  Equals = 'eq',
  NotEquals = 'ne',
  GreaterThan = 'gt',
  GreaterThanOrEqual = 'gte',
  LessThan = 'lt',
  LessThanOrEqual = 'lte',
  In = 'in',
  NotIn = 'nin',
  Contains = 'contains',
  NotContains = 'notContains',
}

export type FilterValue = string | number | boolean | { [K in FilterOperationEnum]?: string | number | boolean };
export type Filter = { [key: string]: FilterValue };