import { FC } from 'react';
import { sanitize } from 'dompurify';
import {
  Box,
  Stack,
  StackProps,
  Typography,
  Skeleton
} from '@mui/joy';
import {
  selectHtmlBlockTitle,
  selectHtmlBlock,
  useTournamentDetailsPageSliceSelector,
} from '../model';

export type HtmlBlockProps = StackProps & {
  loading?: boolean;
};

export const HtmlBlock: FC<HtmlBlockProps> = ({
  sx,
  loading,
  ...props
}) => {
  const htmlBlockTitle = useTournamentDetailsPageSliceSelector(selectHtmlBlockTitle);
  const htmlBlock = useTournamentDetailsPageSliceSelector(selectHtmlBlock);
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      rowGap={2}
      sx={[{
        width: '100%',
        height: '100%'
      },
      ...(sx ? Array.isArray(sx) ? sx : [sx] : [])]}
      {...props}>
      <Typography
        sx={({ breakpoints }) => ({
          position: 'relative',
          fontSize: 28,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '36px',
          [breakpoints.down(768)]: {
            fontSize: 20,
          }
        })}>
        <Skeleton
          overlay
          loading={loading}>
          {htmlBlockTitle}
        </Skeleton>
      </Typography>
      <Skeleton
        overlay
        loading={loading}
        sx={{
          position: 'static',
          width: '100%',
          paddingInline: 1.5,
          borderRadius: 8,
          height: '100%',
        }}>
        <Box
          component='div'
          sx={({ palette }) => ({
            width: '100%',
            paddingInline: 1.5,
            borderRadius: 8,
            backgroundColor: palette.common[475],
            color: palette.common.white,
            height: '100%',
            '& figure': {
              overflow: 'auto',
              width: '100% !important',
              marginInline: '0 !important',
            },
            '& table': {
              width: '100%',
              borderRadius: 8,
              background: palette.common[475],
              borderCollapse: 'collapse',
              '& thead th': {
                paddingBlock: 1.5,
                paddingInline: 3,
              },
              '& thead tr:first-child th': {
                borderBottom: `1px solid ${palette.common[925]}`
              },
              '& tbody td': {
                paddingBlock: 2,
                paddingInline: 3,
              },
              '& tbody tr:first-child td': {
                borderTop: 'none'
              },
              '& tbody tr td': {
                borderTop: `1px solid ${palette.common[925]}`
              },
            },
            '& strong': {
              fontWeight: 'bold'
            },
          })}
          dangerouslySetInnerHTML={{
            __html: sanitize(htmlBlock ?? '')
          }}
        />
      </Skeleton>
    </Stack>
  );
};