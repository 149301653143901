import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { luxeClubPageSlice } from './slice'
import { LuxeClubPageState } from './types'

type RootState = {
  [luxeClubPageSlice.name]: LuxeClubPageState;
}

export const selectLuxeClubPageHeading = (state: RootState): Maybe<string> => (
  state[luxeClubPageSlice.name]?.heading
)
export const selectLuxeClubPageMetaTitle = (state: RootState): Maybe<string> => (
  state[luxeClubPageSlice.name]?.title
)
export const selectLuxeClubPageMetaDescription = (state: RootState): Maybe<string> => (
  state[luxeClubPageSlice.name]?.description
)
export const selectLuxeClubPageMetaKeywords = (state: RootState): Maybe<string> => (
  state[luxeClubPageSlice.name]?.keywords
)
export const selectLuxeClubPageMetaCanonical = (state: RootState): Maybe<string> => (
  state[luxeClubPageSlice.name]?.canonical
)

export const useLuxeClubPageSelector: TypedUseSelectorHook<{
  [luxeClubPageSlice.name]: LuxeClubPageState;
}> = useSelector;