import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum,
  UserStateEnum
} from '@shared/types';
import { isDefined } from '@shared/lib';
import { sessionSlice } from './slice';
import {
  type SessionState,
  type ShortProfile,
  type FullProfile,
  type CurrencySessionKeys,
  type LoyaltyStatus,
  type UserBalance,
  UserKycStatusEnum,
  CurrentVIPLevelEnum,
  VerificationStatusEnum,
} from './types';

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectIsAuthenticated = (state: RootState): boolean => (
  isDefined(state[sessionSlice.name].shortProfile)
);

export const selectRegistrationStatus = (state: RootState): Maybe<UserRegistrationStatusEnum> => (
  state[sessionSlice.name].fullProfile?.registrationStatus
);

export const selectCurrencySessionKey = (state: RootState): Maybe<string> => {
  const currencySessionKeys = state[sessionSlice.name].currencySessionKeys;
  const toggledCurrencyCode = state[sessionSlice.name].toggledCurrencyCode;
  if (!currencySessionKeys) return null;
  return currencySessionKeys[toggledCurrencyCode]?.sessionKey;
};

export const selectCurrencySessionKeys = (state: RootState): Maybe<CurrencySessionKeys> => {
  return state[sessionSlice.name].currencySessionKeys;
};

export const selectToggledCurrencyCode = (state: RootState): CurrencyCodesEnum => (
  state[sessionSlice.name].toggledCurrencyCode
);

export const selectUserStatus = (state: RootState): Maybe<UserStateEnum> => (
  state[sessionSlice.name]?.userState
);

export const selectShortProfile = (state: RootState): Maybe<ShortProfile> => (
  state[sessionSlice.name]?.shortProfile
);

export const selectIsPlayerRegistrationStatus = (state: RootState): boolean => (
  state[sessionSlice.name]?.fullProfile?.registrationStatus === UserRegistrationStatusEnum.Player
);

export const selectKycStatus = (state: RootState): Maybe<UserKycStatusEnum> => (
  state[sessionSlice.name]?.fullProfile?.kycStatus
);

export const selectIsVerifiedStatus = (state: RootState): boolean => (
  state[sessionSlice.name]?.fullProfile?.verificationStatus === VerificationStatusEnum.Verified
);

export const selectFullProfile = (state: RootState): Maybe<FullProfile> => (
  state[sessionSlice.name]?.fullProfile
);

export const selectSessionKey = (state: RootState): string => (
  state[sessionSlice.name].sessionKey
);

export const selectUserBalance = (state: RootState): Maybe<UserBalance> => (
  state[sessionSlice.name].userBalance
);

export const selectEmailPromotionSubscription = (state: RootState): boolean => (
  state[sessionSlice.name]?.emailPromotionSubscription
);

export const selectCurrentVIPLevel = (state: RootState): Maybe<CurrentVIPLevelEnum> => (
  state[sessionSlice.name]?.loyaltyStatus?.currentVIPLevel
);

export const selectLoyaltyStatus = (state: RootState): Maybe<LoyaltyStatus> => (
  state[sessionSlice.name]?.loyaltyStatus
);

export const useSessionSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;
