import { styled } from '@mui/joy';
import { FC } from 'react';

interface DividerProps {
  size?: 'xs' | 'sm' | 'md';
}

const DividerComponent = styled('div')<DividerProps>(({ theme, size }) => ({
  backgroundColor: theme.palette.divider,
  width: '0.0625rem',
  height: '1.5rem',
  marginInline: size === 'sm' ? '0.75rem' : size === 'xs' ? '0.5rem' : '1rem',
}));

export const Divider: FC<DividerProps> = ({ size = 'md' }) => {
  return <DividerComponent size={size} />;
};
