import { mapper } from '@shared/lib';
import { type UserDocument } from '../model';

type MapUserDocument = (data: any) => UserDocument;
export const mapUserDocument: MapUserDocument = mapper([
  { name: 'id', from: 'id' },
  { name: 'documentType', from: 'documentType' },
  { name: 'fileName', from: 'fileName' },
  { name: 'documentNumber', from: 'documentNumber' },
  { name: 'status', from: 'status' },
]);
