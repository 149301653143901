import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type GetGamesByCategoryResponse, type GamesFilterByCategoryState } from './types';
import { fetchGamesByCategory } from './fetch-games-by-category';

export const initialState: GamesFilterByCategoryState = {
  paginationsByCategories: {},
  selectedCategoryKey: null,
  isLoading: false,
};

export const gamesFilteredByCategoriesSlice = createSlice({
  name: 'games-filter-by-category',
  initialState,
  reducers: {
    setSelectedCategoryKey: (state: GamesFilterByCategoryState, action: PayloadAction<Maybe<string>>) => {
      state.selectedCategoryKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamesByCategory.pending, (state: GamesFilterByCategoryState) => {
        state.isLoading = true;
      })
      .addCase(
        fetchGamesByCategory.fulfilled,
        (state: GamesFilterByCategoryState, action: PayloadAction<GetGamesByCategoryResponse>) => {
          const { games, page, pageSize } = action.payload;

          state.paginationsByCategories = {
            ...state.paginationsByCategories,
            [action.payload.categoryKey]: games
              ? {
                  page,
                  pageSize,
                  pageCount: Math.ceil(games.length / pageSize),
                  total: games.length,
                }
              : null,
          };

          state.isLoading = false;
        }
      );
  },
});

export const gamesFilteredByCategoriesReducer = gamesFilteredByCategoriesSlice.reducer;

export const { setSelectedCategoryKey } = gamesFilteredByCategoriesSlice.actions;
