import { FC } from 'react';
import { Stack, StackProps } from '@mui/joy';
import { type GameCategory } from '@entities/games';
import { GamesCategoryItem } from './games-category-item.component';
import { v4 as uuidv4 } from 'uuid';

export type GamesByCategoriesBlockProps = StackProps & {
  categories: Maybe<Array<GameCategory>>;
};

export const GamesByCategoriesBlock: FC<GamesByCategoriesBlockProps> = ({
  categories,
  sx,
  ...props
}) => {
  return (
    <Stack
      direction='column'
      sx={[({ breakpoints }) => ({
        gap: 5.25,
        [breakpoints.down(490)]: { gap: 4 }
      }),
      ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...props}>
      {categories?.map((category) => (
        <GamesCategoryItem
          key={uuidv4()}
          category={category!}
        />
      ))}
    </Stack>
  );
};
