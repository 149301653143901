import { FC } from 'react';
import { Typography } from '@mui/joy';

type TournamentsListHeaderSmallProps = {
  title: Maybe<string>;
};

export const TournamentsListHeaderSmall: FC<TournamentsListHeaderSmallProps> = ({
  title,
}) => (
  <Typography
    sx={({ palette }) => ({
      fontSize: 20,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '30px',
      color: palette.common.white,
    })}>
    {title ?? 'Tournaments'}
  </Typography>
);