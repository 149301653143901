import { mapper } from '@shared/lib';
import { TournamentsPageState } from '../model';

type MapTournamentsPageState = (data: any) => TournamentsPageState & {
  categoryIds: Array<number>
};
export const mapTournamentsPageState: MapTournamentsPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  { name: 'headerText', from: 'data.attributes.headerText' },
]);
