import {
  FC,
  ReactNode,
  useEffect
} from 'react';
import {
  Outlet
} from 'react-router-dom';
import {
  Stack
} from '@mui/joy';
import {
  useResizeObserver,
  useRestoreScroll
} from '@shared/lib';

export interface LayoutProps {
  headerSlot: ReactNode;
  footerSlot?: ReactNode;
  stickyNavigationSlot?: ReactNode;
  disableScroll?: boolean;
};

export const Layout: FC<LayoutProps> = ({
  headerSlot,
  footerSlot,
  stickyNavigationSlot,
  disableScroll
}) => {
  const headerRef = useResizeObserver();
  const stickyRef = useResizeObserver();
  const { scrollTo, ref: containerRef } = useRestoreScroll();

  useEffect(() => {
    scrollTo('top');
    // eslint-disable-next-line
  }, [location.pathname, location.search]);

  return (
    <Stack
      direction='column'
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}>
      <Stack
        direction='column'
        ref={headerRef.ref}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 15,
        }}>
        {headerSlot}
      </Stack>
      <Stack
        ref={containerRef}
        direction='column'
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          minHeight: '100%',
          paddingTop: headerRef.height / 8,
          paddingBottom: stickyRef.height / 8,
          ...(disableScroll && {
            maxHeight: `calc(100vh - ${headerRef.height}px)`,
            overflow: 'hidden',
          }),
        }}>
        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          sx={({
            width: '100%',
            ...(disableScroll && {
              height: '100%',
            })
          })}>
          <Outlet />
        </Stack>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={({ breakpoints, palette }) => ({
            marginTop: 'auto',
            backgroundColor: palette.common[475],
            paddingInline: 10,
            width: '100%',
            [breakpoints.down(913)]: {
              paddingInline: 2,
            },
          })}>
          {footerSlot}
        </Stack>
      </Stack>
      <Stack
        direction='column'
        ref={stickyRef.ref}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 15,
        }}>
        {stickyNavigationSlot}
      </Stack>
    </Stack>
  );
};
