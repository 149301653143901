import { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Box, Stack, Divider, Typography } from '@mui/joy';
import { useCountdown } from '@shared/lib';

dayjs.extend(utc);

export type ChallengeTimerProps = {
  endDate: string;
};

export const ChallengeTimer: FC<ChallengeTimerProps> = ({ endDate }) => {
  const timeArray = useCountdown(
    dayjs.utc(endDate).add(7, 'hours')
  );
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr auto 1fr auto 1fr',
        gap: 1.5,
      }}>
      <Stack
        alignItems='center'
        height={34}
        gap='2px'>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            verticalAlign: 'middle',
            color: palette.common.white,
          })}>
          {timeArray ? timeArray[0] : 0}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 10,
            fontStyle: 'normal',
            fontWeight: 400,
            color: palette.common[150],
          })}>
          Day
        </Typography>
      </Stack>
      <Divider
        orientation='vertical'
        sx={{
          height: 24,
          margin: 'auto',
        }}
      />
      <Stack
        alignItems='center'
        height={34}
        gap='2px'>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            color: palette.common.white,
          })}>
          {timeArray ? timeArray[1] : 0}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 10,
            fontStyle: 'normal',
            fontWeight: 400,
            color: palette.common[150],
          })}>
          Hour
        </Typography>
      </Stack>
      <Divider
        orientation='vertical'
        sx={{
          height: 24,
          margin: 'auto',
        }}
      />
      <Stack
        alignItems='center'
        height={34}
        gap='2px'>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            color: palette.common.white,
          })}>
          {timeArray ? timeArray[2] : 0}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 10,
            fontStyle: 'normal',
            fontWeight: 400,
            color: palette.common[150],
          })}>
          Min
        </Typography>
      </Stack>
      <Divider
        orientation='vertical'
        sx={{
          height: 24,
          margin: 'auto',
        }}
      />
      <Stack
        alignItems='center'
        height={34}
        gap='2px'>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            color: palette.common.white,
          })}>
          {timeArray ? timeArray[3] : 0}
        </Typography>
        <Typography
          sx={({ palette }) => ({
            height: 16,
            fontSize: 10,
            fontStyle: 'normal',
            fontWeight: 400,
            color: palette.common[150],
          })}>
          Sec
        </Typography>
      </Stack>
    </Box>
  );
};