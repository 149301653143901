import {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  IconButton,
  Stack,
} from '@mui/joy';
import {
  SwiperRef,
  SwiperSlide
} from 'swiper/react';
import {
  Navigation
} from 'swiper/modules';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  ArrowLeftIcon,
  ArrowRightIcon
} from '@shared/ui';
import {
  ChallengeListItem
} from '@entities/challenges';
import {
  selectActiveOrCompletedChallenges,
  selectGroupsActiveOrCompletedChallenges,
  useChallengeListSelector
} from '../model';
import {
  ChallengeListHeader
} from './challenge-list-header.component';
import {
  Swiper
} from './swiper.component';

export const ChallengeList: FC = () => {
  const activeChallengers = useChallengeListSelector(selectActiveOrCompletedChallenges);
  const groupsActiveOrCompletedChallengers = useChallengeListSelector(selectGroupsActiveOrCompletedChallenges);

  const [showSlider, setShowSlider] = useState<boolean>(false);

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const swiperRef: RefObject<SwiperRef> = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(481));
  const isTablet = useMediaQuery(theme.breakpoints.down(768));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.between(769, 1024));
  const isDesktop = useMediaQuery(theme.breakpoints.down(1349));

  const slidesPerView = useMemo(() => {
    if (isMobile) return 1;
    else if (isTablet) return 'auto';
    else return isDesktop ? 3 : isSmallDesktop ? 2 : 3;
  }, [isMobile, isTablet, isDesktop, isSmallDesktop]);

  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    }, 0);
  }, []);
  
  return ((isMobile && groupsActiveOrCompletedChallengers.length) || (!isMobile && activeChallengers.length)) ? (
    <Stack gap={2}>
      <ChallengeListHeader />
      <Stack
        direction='row'
        sx={({ breakpoints }) => ({
          gap: 0.5,
          alignItems: 'center',
          marginLeft: '-36px',
          marginRight: '-36px',
          [breakpoints.down(768)]: {
            marginLeft: 0,
            marginRight: 0,
          }
        })}>
        {!isTablet && (
          <IconButton
            variant='soft'
            ref={navigationPrevRef}
            sx={({ palette }) => ({
              background: 'transparent',
              color: palette.common.white,
              width: '20px',
              padding: 0,
              '&:disabled': {
                background: 'transparent',
              },
            })}>
            <ArrowLeftIcon />
          </IconButton>
        )}

        {isMobile && showSlider && (
          <Swiper
            slidesPerView={
              isTablet
                ? 'auto'
                : slidesPerView
            }
            spaceBetween={24}
            ref={swiperRef}
            modules={[Navigation]}
            navigation={{
              enabled: true,
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}>
            {groupsActiveOrCompletedChallengers.map((group, index) => (
              <SwiperSlide key={index}>
                <Stack rowGap={2}>
                  {group.map(({
                    title,
                    subTitle,
                    status,
                    endDate,
                    percentage,
                    badge,
                    currency,
                    awardAmount
                  }, subIndex) => (
                    <ChallengeListItem
                      key={title + subIndex}
                      title={title}
                      subTitle={subTitle}
                      status={status}
                      endDate={endDate}
                      percentage={percentage}
                      badgeUrl={badge?.url ?? ''}
                      currency={currency}
                      amount={awardAmount}
                    />
                  ))}
                </Stack>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {!isMobile && showSlider && (
          <Swiper
            slidesPerView={
              isTablet
                ? 'auto'
                : slidesPerView
            }
            spaceBetween={24}
            ref={swiperRef}
            modules={[Navigation]}
            navigation={{
              enabled: true,
              nextEl: navigationNextRef.current,
              prevEl: navigationPrevRef.current,
            }}>
            {activeChallengers.map(({
              title,
              subTitle,
              status,
              endDate,
              percentage,
              badge,
              currency,
              awardAmount
            }, index) => (
              <SwiperSlide key={`${title}-${index}-SwiperSlide-item`}>
                <ChallengeListItem
                  title={title}
                  subTitle={subTitle}
                  status={status}
                  endDate={endDate}
                  percentage={percentage}
                  badgeUrl={badge?.url ?? ''}
                  currency={currency}
                  amount={awardAmount}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {!isTablet && (
          <IconButton
            variant='soft'
            ref={navigationNextRef}
            sx={({ palette }) => ({
              background: 'transparent',
              color: palette.common.white,
              width: '20px',
              padding: 0,
              '&:disabled': {
                background: 'transparent',
              },
            })}>
            <ArrowRightIcon />
          </IconButton>
        )}
      </Stack>
    </Stack>
  ) : null;
};