import {
  type TournamentPlace,
  TournamentPlaceEnum
} from './types';

export const dummyData: Array<TournamentPlace> = [
  {
    place: TournamentPlaceEnum.First,
    player: 'Kuv***a13',
    score: '26 384',
    toWin: '19.000 GC + 200.000 SC'
  },
  {
    place: TournamentPlaceEnum.Second,
    player: 'he***ci',
    score: '18 643',
    toWin: '19.000 GC + 200.000 SC'
  },
  {
    place: TournamentPlaceEnum.Third,
    player: 'A**UR',
    score: '26 384',
    toWin: '19.000 GC + 200.000 SC'
  },
  {
    place: TournamentPlaceEnum.CurrentPlace,
    player: 'iv***ka',
    score: '231',
    toWin: '-'
  }
];