import { BaseSyntheticEvent, FC, ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/joy';
import { TextInput, PhoneInput, Button } from '@shared/ui';
import { OmegaApiResponseStatusEnum, omegaErrorsMapper } from '@shared/api';
import { isDefined } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import { mapLogin, useLazySignInQuery } from '@entities/session';
import { signInByPhoneSchema } from '../lib';
import { SignedInData } from '../model';

type SignInByPhoneFormProps = {
  onSignedIn(loginData: SignedInData): void;
  onNotCompletedSignUpError(props: {
    currency: CurrencyCodesEnum.SCO;
    secondaryCurrencies: CurrencyCodesEnum.GCO;
    verificationTarget: 'phone';
    mobile: Phone;
    password: Password;
  }): void;
  resetPasswordSlot: ReactElement;
  passedError?: Maybe<string>;
  onClearPassedError?(): void;
};

export const SignInByPhoneForm: FC<SignInByPhoneFormProps> = ({
  onSignedIn,
  onNotCompletedSignUpError,
  resetPasswordSlot,
  passedError,
  onClearPassedError
}) => {
  const [signIn, { isFetching, data }] = useLazySignInQuery();

  const [hasLostFocus, setHasLostFocus] = useState<boolean>(false);
  const {
    control,
    formState,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    handleSubmit
  } = useForm({
    resolver: yupResolver(signInByPhoneSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { mobile: '', password: '' },
  });

  useEffect(() => {
    if (passedError) {
      setError('root.serverError', {
        type: passedError,
        message: omegaErrorsMapper['SIGN-UP'][passedError]
      });
    }
    else {
      clearErrors('root.serverError');
    }
    // eslint-disable-next-line
  }, [passedError]);

  const handleFormSubmit = async (
    formData: { mobile: Phone; password: Password; },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const { data } = await signIn({
      ...formData,
      username: formData.mobile
    });

    if (data?.status === OmegaApiResponseStatusEnum.FailQuickOpenStatus) {
      return onNotCompletedSignUpError({
        ...formData,
        currency: CurrencyCodesEnum.SCO,
        secondaryCurrencies: CurrencyCodesEnum.GCO,
        verificationTarget: 'phone',
      });
    }

    if (data?.status === OmegaApiResponseStatusEnum.Success) {
      const loginData = mapLogin(data);
      onSignedIn(loginData);
    }
  };

  const handlePhoneValueChange = async (phone: Phone): Promise<void> => {
    setValue('mobile', phone);
    if (hasLostFocus) await trigger('mobile');
  };

  const handlePhoneBlur = async (): Promise<void> => {
    setHasLostFocus(true);
    await trigger('mobile');
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(handleFormSubmit)}>
      {(isDefined(data?.message) || formState?.errors?.root?.serverError) && (
        <Typography
          sx={({ palette }) => ({
            color: palette.common.error,
            fontWeight: 500,
            marginBottom: 2,
          })}>
          {formState?.errors?.root?.serverError?.message}
          {data?.message}
        </Typography>
      )}
      <Stack
        direction='column'
        rowGap={0.5}>
        <PhoneInput
          name='mobile'
          label='Phone Number'
          defaultCountry='us'
          disabled={isFetching}
          value={getValues('mobile')}
          onBlur={handlePhoneBlur}
          onChange={(args) => {
            handlePhoneValueChange(args);
            onClearPassedError?.();
          }}
          error={formState.errors?.mobile?.message}
        />
        <Controller
          name='password'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              type='password'
              placeholder='Enter your password'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={(args) => {
                onChange(args);
                onClearPassedError?.();
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack
        direction='column'
        alignItems='flex-end'
        justifyContent='center'
        gap={1}
        mb={2}>
        {resetPasswordSlot}
      </Stack>
      <Button
        disabled={
          !formState.isValid ||
          isFetching
        }
        loading={isFetching}
        fullWidth
        type='submit'>
        Log In
      </Button>
    </Box>
  );
};
