import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/joy';
import { Button } from '@shared/ui';

export const ChallengeListHeader: FC = () => {
  const navigate = useNavigate();

  const handleViewAllClick = (): void => {
    navigate(`/challenges`);
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      width='100%'>
      <Typography
        sx={({ palette }) => ({
          color: palette.common.white,
          fontSize: 20,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        })}>
        Complete the tasks to get rewards
      </Typography>
      <Button
        type='button'
        variant='plain'
        sx={({ palette }) => ({
          color: palette.common[150],
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
        })}
        onClick={handleViewAllClick}>
        View All
      </Button>
    </Stack>
  );
};