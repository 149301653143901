import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { messagesSlice } from './slice';
import { type ChannelMessage, type MessagesState } from './types';

type RootState = {
  [messagesSlice.name]: MessagesState;
};

export const selectMessagesInChannel =
  (channelId: string) =>
  (state: RootState): Maybe<Array<ChannelMessage>> =>
    state[messagesSlice.name].channels[channelId];

export const useMessagesSelector: TypedUseSelectorHook<{
  [messagesSlice.name]: MessagesState;
}> = useSelector;
