import { BaseSyntheticEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/joy';
import { TextInput, Button } from '@shared/ui';
import { confirmPasswordSchema } from '../lib';

const defaultValues = { oldPassword: '', newPassword: '', confirmPassword: '' }

export type ChangePasswordFormProps = {
  isLoading: boolean;
  error?: string;
  onSubmit: (formData: typeof defaultValues) => void | Promise<void>;
};

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ isLoading, onSubmit, error }) => {
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(confirmPasswordSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const handleFormSubmit = async (
    formData: typeof defaultValues,
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();
    onSubmit(formData);
  };

  return (
    <Stack
      component='form'
      onSubmit={handleSubmit(handleFormSubmit)}>
      {error && (
        <Typography
          sx={({ palette }) => ({
            color: palette.common.error,
            mb: .5
          })}>
          {error}
        </Typography>
      )}
      <Stack mb='0.125rem'>
        <Controller
          name='oldPassword'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Current password'
              placeholder='Type current password'
              type='password'
              disabled={isLoading}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={fieldState.error?.message}
              sx={({ palette }) => ({
                '.MuiInput-root': {
                  background: palette.common[900]
                }
              })}
            />
          )}
        />
        <Controller
          name='newPassword'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='New password'
              placeholder='Type new password'
              type='password'
              disabled={isLoading}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={fieldState.error?.message}
              sx={({ palette }) => ({
                '.MuiInput-root': {
                  background: palette.common[900]
                }
              })}
            />
          )}
        />
        <Controller
          name='confirmPassword'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Confirm new password'
              placeholder='Confirm new password'
              type='password'
              disabled={isLoading}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              error={fieldState.error?.message}
              sx={({ palette }) => ({
                '.MuiInput-root': {
                  background: palette.common[900]
                }
              })}
            />
          )}
        />
      </Stack>
      <Button
        sx={{ mt: 2 }}
        disabled={!formState.isValid || isLoading}
        loading={isLoading}
        fullWidth
        color='primary'
        variant='solid'
        type='submit'>
        Save
      </Button>
    </Stack>
  );
};