import {
  FC,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  SwiperRef,
  SwiperSlide
} from 'swiper/react';
import {
  Navigation
} from 'swiper/modules';
import {
  IconButton,
  Stack
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  ArrowLeftIcon,
  ArrowRightIcon
} from '@shared/ui';
import {
  selectCurrentVIPLevel,
  useSessionSelector
} from '@entities/session';
import {
  type Level,
  levelDetailsData,
} from '../model';
import {
  Swiper
} from './swiper.component';
import {
  LevelsSliderItem
} from './levels-slider-item.component';

export const LevelsSlider: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.between(768, 1024));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1115));

  const currentVIPLevel = useSessionSelector(selectCurrentVIPLevel);

  const navigationNextRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const navigationPrevRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const swiperRef: RefObject<SwiperRef> = useRef(null);

  const [showSlider, setShowSlider] = useState<boolean>(false);

  const slidesPerView: number = useMemo(() => {
    return isDesktop ? 4 : isSmallDesktop ? 2 : 3;
  }, [isDesktop, isSmallDesktop]);

  useEffect(() => {
    const levelNumber = levelDetailsData.find(
      (level: Level) => level.level === currentVIPLevel
    )?.number;
    if (levelNumber && showSlider) swiperRef.current?.swiper.slideTo(levelNumber - 1)
  }, [currentVIPLevel, showSlider]);

  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    }, 0);
  }, []);

  return (
    <Stack
      direction='row'
      sx={({ breakpoints }) => ({
        gap: 0.5,
        alignItems: 'center',
        marginLeft: '-36px',
        marginRight: '-36px',
        [breakpoints.down(768)]: {
          marginLeft: 0,
          marginRight: 0,
        }
      })}>
      {!isMobile && (
        <IconButton
          ref={navigationPrevRef}
          sx={({ palette }) => ({
            background: 'transparent',
            color: palette.common.white,
            width: '20px',
            padding: 0,
            '&:disabled': {
              background: 'transparent',
            },
          })}
          variant='soft'>
          <ArrowLeftIcon />
        </IconButton>
      )}
      {(currentVIPLevel && showSlider) ? (
        <Swiper
          slidesPerView={isMobile ? 'auto' : slidesPerView}
          spaceBetween={24}
          ref={swiperRef}
          modules={[Navigation]}
          navigation={{
            enabled: true,
            nextEl: navigationNextRef.current,
            prevEl: navigationPrevRef.current,
          }}>
          {levelDetailsData.map((level, index) => (
            <SwiperSlide key={index}>
              <LevelsSliderItem
                level={level}
                isCurrent={level.level === currentVIPLevel}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : null}
      {isMobile ? null : (
        <IconButton
          ref={navigationNextRef}
          sx={({ palette }) => ({
            background: 'transparent',
            color: palette.common.white,
            width: '20px',
            padding: 0,
            '&:disabled': { background: 'transparent' },
          })}
          variant='soft'>
          <ArrowRightIcon />
        </IconButton>
      )}
    </Stack>
  );
};
