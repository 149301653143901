import { mapCurrencyKeys } from './map-currency-keys';
import { mapShortProfile } from './map-short-profile';
import { SessionState } from '../model';

type MapLogin = Pick<SessionState, 'sessionKey' | 'currencySessionKeys' | 'shortProfile'>;
export const mapLogin = (data: any): MapLogin => {
  return {
    currencySessionKeys: mapCurrencyKeys(data?.associateAccounts),
    sessionKey: data?.sessionKey,
    shortProfile: mapShortProfile(data),
  };
};
