import { BaseSyntheticEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import {
  Link,
  Button,
  OtpInput,
  useToaster
} from '@shared/ui';
import {
  isEmpty,
  otpSchema,
  pruneUndefinedProperties,
  useModalQueryParam
} from '@shared/lib';
import {
  type OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper
} from '@shared/api';
import {
  useLazyResendVerificationCodeQuery,
  useLazySignUpConfirmationQuery
} from '@entities/session';
import {
  useSignUpSelector,
  selectIsOpenConfirmationSignUpModal,
  selectPropsToSignUp,
  closeConfirmationSignUpModal,
  openSuccessConfirmationSignUpModal,
  removePropsToSignUp
} from '../model';

export type ConfirmationSignUpModalProps = {
  onSignUpConfirmation(method: string): void;
}

export const ConfirmationSignUpModal: FC<ConfirmationSignUpModalProps> = ({ onSignUpConfirmation }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toaster = useToaster()
  const { openModal } = useModalQueryParam();

  const isOpenConfirmationSignUpModal = useSignUpSelector(selectIsOpenConfirmationSignUpModal);
  const propsToSignUp = useSignUpSelector(selectPropsToSignUp);

  const [signUpConfirmation, { isFetching }] = useLazySignUpConfirmationQuery();
  const [resendVerificationCode] = useLazyResendVerificationCodeQuery();

  const {
    control,
    formState,
    setError,
    setFocus,
    reset,
    handleSubmit
  } = useForm({
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { otp: '' },
  });

  const handleSendConfirmationCode = async (
    formData: { otp: string },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const { email, mobile } = propsToSignUp ?? {};
    const { data } = await signUpConfirmation(pruneUndefinedProperties({
      confirmationCode: formData.otp,
      email,
      mobile
    }));

    const { status, message, errors } = data as OmegaApiResponse;

    if (status === OmegaApiResponseStatusEnum.Fail && !isEmpty(errors)) {
      const { field, error } = errors?.[0] as any;
      let fieldName: Maybe<'otp' | 'root.serverError'> = null;
      switch (field) {
        case 'confirmationCode':
        case 'ipAddress':
          fieldName = 'otp';
          break;
        default:
          fieldName = 'root.serverError';
          break;
      }
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
      return;
    }

    if (status === OmegaApiResponseStatusEnum.Fail && message) {
      setError('otp', { type: 'otp', message });
      setFocus('otp');
      return;
    }

    const method = propsToSignUp?.email ? 'email' : 'mobile';

    onSignUpConfirmation(method);

    dispatch(closeConfirmationSignUpModal());
    dispatch(openSuccessConfirmationSignUpModal());
  };

  const handleResend = async (): Promise<void> => {
    const username = propsToSignUp?.email ?? propsToSignUp?.mobile;
    const verificationTarget = propsToSignUp?.email
      ? 'email'
      : 'mobile';
    const { data } = await resendVerificationCode({ username, verificationTarget });
    const { errors } = data as OmegaApiResponse;

    if (!isEmpty(errors)) {
      const { error } = errors?.[0] as any;
      const fieldName = 'root.serverError';
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
    } else {
      toaster.success({ message: 'New code sent successfully' });
    }
  };

  const handleTryAnotherMethod = (): void => {
    dispatch(removePropsToSignUp());
    dispatch(closeConfirmationSignUpModal());
    navigate('/');
    openModal('sign-up');
  };

  const handleFocus = (): void => {
    if (formState.errors.otp || formState.errors.root) {
      reset({});
    }
  };

  return (
    <Modal
      open={isOpenConfirmationSignUpModal}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints }) => ({
          maxWidth: 358,
          width: '100%',
          paddingBlock: 3,
          paddingInline: 3,
          [breakpoints.down(390)]: {
            maxWidth: 'calc(100vw - 2rem)',
          },
        })}>
        <Stack
          component='form'
          onSubmit={handleSubmit(handleSendConfirmationCode)}
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}>
          <Typography
            sx={({ palette }) => ({
              color: palette.common[700],
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%',
              textAlign: 'center'
            })}>
            Welcome to SweepLuxe
          </Typography>
          <Typography
            sx={({ palette }) => ({
              color: palette.common[150],
              textAlign: 'left',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%'
            })}>
            Claim your Sign Up Bonus by verifying your account with OTP received on your email!
          </Typography>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1}
            sx={({ palette }) => ({
              background: palette.common[475],
              borderColor: palette.common[925],
              borderStyle: 'solid',
              borderWidth: 1,
              width: '100%',
              paddingBlock: 1,
              paddingInline: 2,
              borderRadius: 8,
            })}>
            <Box
              width={22}
              height={28}
              component='img'
              loading='lazy'
              src='/assets/webp/sign_up_bonus.webp'
            />
            <Typography sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: ' 20px',
              '& span': {
                color: palette.common[1075]
              }
            })}>
              5,000 GC
            </Typography>
          </Stack>
          <Controller
            disabled={isFetching}
            name='otp'
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <OtpInput
                disabled={isFetching}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={handleFocus}
                error={formState.errors?.otp?.message}
              />
            )}
          />
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            gap={0.5}>
            <Typography sx={({ palette }) => ({
              color: palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '142.857%'
            })}>
              Didn’t receive a password?
            </Typography>
            <Stack alignItems='center'>
              <Typography sx={{
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '142.857%'
              }}>
                <Link
                  color='primary'
                  underline='always'
                  disabled={isFetching}
                  onClick={handleResend}>
                  Send new OTP
                </Link>
              </Typography>
              <Divider
                orientation='horizontal'
                sx={{
                  marginBlock: 1,
                  width: '127px',
                  margin: 'auto'
                }}>
                or
              </Divider>
              <Typography sx={{
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '142.857%'
              }}>
                <Link
                  color='primary'
                  underline='always'
                  disabled={isFetching}
                  onClick={handleTryAnotherMethod}>
                  Try another registration method
                </Link>
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            width='100%'
            sx={{
              '& .MuiButton-root': {
                width: '100%'
              }
            }}>
            <Button
              disabled={!formState.isValid || isFetching}
              loading={isFetching}
              color='primary'
              variant='solid'
              type='submit'>
              Submit
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};