import { baseApi, GAMES_PAGE_TAG } from '@shared/api';
import { GamesPageState } from '../model/types';
import { mapGamesPageState } from '../lib';

type GamesPageResponse = Prettify<GamesPageState & {
  categoryIds: Array<number>
}>

export const gamesPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGamesPage: build.query<GamesPageResponse, void>({
      query: () => ({ url: 'api/games-page' }),
      extraOptions: { showLoader: true },
      providesTags: [GAMES_PAGE_TAG],
      transformResponse: (response: any) => mapGamesPageState(response),
    }),
  }),
});

export const { useGetGamesPageQuery } = gamesPageApiSlice;
