import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const SilverCoinIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.9979 1.11719C11.7564 1.12179 7.69009 2.75012 4.69093 5.64494C1.69178 8.53976 0.00476494 12.4647 0 16.5586C0 16.6454 0 16.7307 0 16.8176V16.8492C0.0593182 19.8903 1.04784 22.8468 2.84152 25.3477C4.63521 27.8485 7.15416 29.7824 10.0823 30.9066C13.0104 32.0308 16.2174 32.2952 19.3006 31.6667C22.3839 31.0382 25.2062 29.5448 27.4134 27.3739C29.6206 25.2029 31.1143 22.4511 31.7072 19.4638C32.3001 16.4764 31.9658 13.3866 30.7461 10.582C29.5264 7.77745 27.4757 5.38301 24.8514 3.69917C22.227 2.01533 19.1459 1.1171 15.9946 1.11719H15.9979Z'
        fill='#2A2E33'
      />
      <path
        d='M16.0217 30.5668C24.7667 30.5668 31.8559 23.7242 31.8559 15.2834C31.8559 6.84261 24.7667 0 16.0217 0C7.27672 0 0.1875 6.84261 0.1875 15.2834C0.1875 23.7242 7.27672 30.5668 16.0217 30.5668Z'
        fill='url(#paint0_linear_7848_467420)'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M16.0145 30.4949C24.7188 30.4949 31.7751 23.6842 31.7751 15.2826C31.7751 6.8811 24.7188 0.0703125 16.0145 0.0703125C7.31016 0.0703125 0.253906 6.8811 0.253906 15.2826C0.253906 23.6842 7.31016 30.4949 16.0145 30.4949Z'
        fill='url(#paint1_radial_7848_467420)'
      />
      <path
        opacity='0.4'
        d='M16.0201 30.1192C11.8619 30.1192 7.87066 28.5405 4.90829 25.7241C1.94593 22.9076 0.250248 19.0794 0.1875 15.0664C0.1875 15.1422 0.1875 15.2244 0.1875 15.2955C0.1875 19.3489 1.85574 23.2363 4.82524 26.1025C7.79473 28.9687 11.8222 30.5789 16.0217 30.5789C20.2212 30.5789 24.2487 28.9687 27.2182 26.1025C30.1877 23.2363 31.8559 19.3489 31.8559 15.2955C31.8559 15.2181 31.8559 15.1375 31.8559 15.0664C31.7932 19.08 30.0971 22.9086 27.134 25.7252C24.1709 28.5417 20.1788 30.1201 16.0201 30.1192Z'
        fill='white'
      />
      <path
        opacity='0.4'
        d='M16.0201 30.1192C11.8619 30.1192 7.87066 28.5405 4.90829 25.7241C1.94593 22.9076 0.250248 19.0794 0.1875 15.0664C0.1875 15.1422 0.1875 15.2244 0.1875 15.2955C0.1875 19.3489 1.85574 23.2363 4.82524 26.1025C7.79473 28.9687 11.8222 30.5789 16.0217 30.5789C20.2212 30.5789 24.2487 28.9687 27.2182 26.1025C30.1877 23.2363 31.8559 19.3489 31.8559 15.2955C31.8559 15.2181 31.8559 15.1375 31.8559 15.0664C31.7932 19.08 30.0971 22.9086 27.134 25.7252C24.1709 28.5417 20.1788 30.1201 16.0201 30.1192Z'
        fill='white'
      />
      <path
        d='M16.0266 0.575006C20.1743 0.575133 24.1563 2.14623 27.1164 4.9505C30.0765 7.75476 31.7783 11.5682 31.8559 15.5709C31.8559 15.4745 31.8559 15.3798 31.8559 15.2834C31.8559 11.23 30.1877 7.3426 27.2182 4.47641C24.2487 1.61021 20.2212 0 16.0217 0C11.8222 0 7.79473 1.61021 4.82524 4.47641C1.85574 7.3426 0.1875 11.23 0.1875 15.2834C0.1875 15.3798 0.1875 15.4745 0.1875 15.5709C0.265552 11.5667 1.96886 7.75207 4.931 4.94763C7.89313 2.14319 11.8774 0.573055 16.0266 0.575006Z'
        fill='white'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        opacity='0.7'
        d='M16.0145 0.0703125C11.9069 0.0705034 7.96151 1.61791 5.01799 4.38321C2.07447 7.14852 0.365188 10.9134 0.253906 14.8767C1.9969 15.8734 4.73822 15.0757 6.53031 14.21C8.072 13.5229 9.9672 13.7519 11.6284 13.8672C15.7772 14.21 19.8114 15.9208 23.957 14.8972C24.7867 14.6681 25.9716 13.6334 26.8014 14.3238C27.4877 14.8672 28.3211 15.2091 29.2028 15.3088C30.0845 15.4085 30.9772 15.2618 31.7751 14.8861C31.6663 10.9212 29.9581 7.15394 27.0143 4.38661C24.0705 1.61928 20.1237 0.0705697 16.0145 0.0703125Z'
        fill='url(#paint2_linear_7848_467420)'
      />
      <path
        d='M15.9672 27.1262C22.74 27.1262 28.2304 21.8268 28.2304 15.2897C28.2304 8.75252 22.74 3.45312 15.9672 3.45312C9.19449 3.45312 3.7041 8.75252 3.7041 15.2897C3.7041 21.8268 9.19449 27.1262 15.9672 27.1262Z'
        fill='url(#paint3_linear_7848_467420)'
      />
      <path
        d='M16.0063 26.57C22.45 26.57 27.6737 21.528 27.6737 15.3084C27.6737 9.08884 22.45 4.04688 16.0063 4.04688C9.56254 4.04688 4.33887 9.08884 4.33887 15.3084C4.33887 21.528 9.56254 26.57 16.0063 26.57Z'
        fill='#A2BCBB'
      />
      <path
        d='M15.9988 26.0549C22.1795 26.0549 27.1899 21.2187 27.1899 15.253C27.1899 9.28733 22.1795 4.45117 15.9988 4.45117C9.81807 4.45117 4.80762 9.28733 4.80762 15.253C4.80762 21.2187 9.81807 26.0549 15.9988 26.0549Z'
        fill='url(#paint4_radial_7848_467420)'
      />
      <path
        opacity='0.25'
        d='M15.9955 25.2892C9.91385 25.2892 4.96637 20.8377 4.80762 15.0039C4.80762 15.1003 4.80762 15.195 4.80762 15.2914C4.80762 18.1562 5.98668 20.9037 8.08543 22.9295C10.1842 24.9552 13.0307 26.0933 15.9988 26.0933C18.9668 26.0933 21.8134 24.9552 23.9121 22.9295C26.0109 20.9037 27.1899 18.1562 27.1899 15.2914C27.1899 15.195 27.1899 15.1003 27.1899 15.0039C27.0246 20.8377 22.0755 25.2892 15.9955 25.2892Z'
        fill='white'
      />
      <path
        opacity='0.1'
        d='M23.0138 13.7889C22.9575 13.6186 22.8525 13.4671 22.7109 13.352C22.5693 13.2368 22.3969 13.1627 22.2135 13.138L18.5458 12.6231L16.9092 9.41473C16.8273 9.25449 16.7006 9.11956 16.5434 9.02521C16.3863 8.93086 16.2048 8.88086 16.0197 8.88086C15.8346 8.88086 15.6532 8.93086 15.496 9.02521C15.3388 9.11956 15.2121 9.25449 15.1302 9.41473L13.4936 12.6231L9.81942 13.1444C9.63835 13.172 9.46875 13.2475 9.32946 13.3625C9.19016 13.4775 9.08662 13.6276 9.03033 13.796C8.97405 13.9644 8.96721 14.1445 9.01059 14.3164C9.05397 14.4883 9.14586 14.6453 9.27607 14.7698L11.9307 17.2673L11.3038 20.7932C11.2716 20.9691 11.2915 21.1502 11.3613 21.3157C11.4311 21.4811 11.5479 21.6242 11.6983 21.7283C11.8483 21.8325 12.0252 21.8945 12.2095 21.9076C12.3938 21.9207 12.5782 21.8844 12.7424 21.8026L16.0238 20.1376L19.297 21.801C19.4397 21.8741 19.5987 21.9126 19.7602 21.9132C19.9049 21.9133 20.0479 21.8829 20.1792 21.8242C20.3106 21.7654 20.427 21.6798 20.5205 21.5732C20.614 21.4665 20.6823 21.3415 20.7206 21.2068C20.7588 21.0721 20.7662 20.9309 20.7422 20.7932L20.1153 17.2673L22.7699 14.7698C22.9021 14.6451 22.9952 14.4868 23.0383 14.3133C23.0815 14.1397 23.073 13.958 23.0138 13.7889Z'
        fill='white'
      />
      <path
        d='M22.7493 13.7472C22.7063 13.6179 22.6264 13.5029 22.5187 13.4154C22.411 13.3279 22.2799 13.2716 22.1405 13.2528L18.3484 12.7204L16.6529 9.40311C16.5907 9.28152 16.4946 9.17915 16.3753 9.10757C16.256 9.03598 16.1183 8.99805 15.9778 8.99805C15.8373 8.99805 15.6997 9.03598 15.5804 9.10757C15.4611 9.17915 15.3649 9.28152 15.3027 9.40311L13.6072 12.7204L9.81515 13.2528C9.676 13.2722 9.54526 13.3288 9.43773 13.4162C9.3302 13.5037 9.25019 13.6184 9.20675 13.7475C9.16331 13.8766 9.15819 14.0148 9.19196 14.1465C9.22573 14.2782 9.29704 14.3982 9.39781 14.4928L11.9035 16.9587L11.2488 20.6046C11.2234 20.7447 11.2377 20.8888 11.2902 21.0217C11.3426 21.1546 11.4312 21.2714 11.5467 21.3597C11.6953 21.4601 11.867 21.524 12.0469 21.546C12.2268 21.568 12.4095 21.5475 12.5794 21.4861L15.9721 19.7642L19.3631 21.4861C19.5068 21.5529 19.6652 21.585 19.8246 21.5795C19.984 21.574 20.1396 21.5311 20.278 21.4545C20.5104 21.2965 20.7428 20.8795 20.6937 20.6046L20.0391 16.9587L22.5447 14.4928C22.6482 14.3998 22.7223 14.2805 22.7585 14.1485C22.7947 14.0166 22.7915 13.8775 22.7493 13.7472Z'
        fill='url(#paint5_linear_7848_467420)'
      />
      <path
        opacity='0.1'
        d='M22.7489 13.0597C22.7056 12.9304 22.6256 12.8153 22.518 12.7276C22.4104 12.6399 22.2794 12.5831 22.1401 12.5637L18.348 12.0329L16.6525 8.71561C16.5903 8.59403 16.4942 8.49165 16.3749 8.42007C16.2555 8.34849 16.1179 8.31055 15.9774 8.31055C15.8369 8.31055 15.6992 8.34849 15.5799 8.42007C15.4606 8.49165 15.3645 8.59403 15.3023 8.71561L13.6068 12.0329L9.81473 12.5637C9.67559 12.5834 9.54493 12.6403 9.4375 12.7279C9.33007 12.8155 9.25014 12.9304 9.20674 13.0595C9.16334 13.1886 9.1582 13.3269 9.19189 13.4587C9.22558 13.5904 9.29676 13.7105 9.3974 13.8053L12.1404 16.3865L11.4857 20.0309C11.4607 20.1647 11.4753 20.3026 11.5278 20.4289C11.5803 20.5551 11.6686 20.6644 11.7826 20.7444C11.8965 20.8244 12.0315 20.8718 12.1721 20.8811C12.3127 20.8904 12.4531 20.8612 12.5773 20.797L15.97 19.0767L19.3611 20.797C19.4855 20.8604 19.6257 20.8888 19.766 20.8792C19.9063 20.8695 20.041 20.8221 20.1548 20.7424C20.2686 20.6626 20.357 20.5537 20.4099 20.4279C20.4629 20.3022 20.4782 20.1646 20.4543 20.0309L19.7997 16.3865L22.5427 13.8053C22.6464 13.7125 22.7209 13.5932 22.7574 13.4613C22.7939 13.3293 22.7909 13.1901 22.7489 13.0597Z'
        fill='#99BBBF'
      />
      <path
        d='M16.5255 8.99414L18.2701 12.392L22.1554 12.937C22.2619 12.9517 22.3619 12.995 22.4441 13.0619C22.5264 13.1287 22.5875 13.2165 22.6206 13.3153C22.6536 13.4141 22.6573 13.5198 22.6312 13.6205C22.6051 13.7212 22.5503 13.8127 22.4729 13.8848L19.6629 16.5292L20.3175 20.2636C20.3356 20.3655 20.3237 20.4702 20.2834 20.566C20.243 20.6618 20.1757 20.7447 20.089 20.8055C20.0024 20.8663 19.8999 20.9025 19.793 20.91C19.6862 20.9175 19.5793 20.8961 19.4845 20.8481L16.0099 19.0851L12.542 20.8465C12.4471 20.8949 12.34 20.9167 12.2329 20.9094C12.1258 20.9021 12.0229 20.866 11.936 20.8052C11.8491 20.7443 11.7816 20.6612 11.7412 20.5651C11.7008 20.4691 11.689 20.3641 11.7073 20.262L12.3718 16.5276L9.56006 13.8832C9.48266 13.8111 9.42782 13.7193 9.4018 13.6185C9.37579 13.5177 9.37963 13.4119 9.4129 13.3131C9.44616 13.2143 9.50751 13.1265 9.58995 13.0598C9.6724 12.993 9.77262 12.9499 9.8792 12.9354L13.7629 12.3905L15.4879 8.99414C15.5345 8.89945 15.608 8.81947 15.6998 8.76346C15.7917 8.70746 15.898 8.67773 16.0067 8.67773C16.1153 8.67773 16.2217 8.70746 16.3135 8.76346C16.4053 8.81947 16.4788 8.89945 16.5255 8.99414Z'
        fill='url(#paint6_linear_7848_467420)'
      />
      <path
        opacity='0.3'
        d='M12.3023 16.8555L11.6917 20.2952C11.6762 20.38 11.6818 20.4672 11.708 20.5496L12.3628 16.9044L12.3023 16.8555Z'
        fill='white'
      />
      <path
        d='M9.86953 13.3186L13.9922 12.7736L15.7286 9.37569C15.9397 8.96339 16.0723 8.96339 16.2834 9.37569L18.0215 12.7736L22.1441 13.3186C22.2504 13.3328 22.3503 13.3759 22.4323 13.4427C22.5142 13.5095 22.5749 13.5974 22.6073 13.6961C22.6335 13.6192 22.6419 13.5378 22.632 13.4574C22.6221 13.3771 22.5941 13.2998 22.55 13.231C22.5058 13.1622 22.4466 13.1036 22.3765 13.0592C22.3064 13.0148 22.2271 12.9858 22.1441 12.9742L18.267 12.4324L16.5305 9.03448C16.4832 8.94149 16.4098 8.86318 16.3187 8.80841C16.2275 8.75364 16.1224 8.72461 16.015 8.72461C15.9077 8.72461 15.8025 8.75364 15.7113 8.80841C15.6202 8.86318 15.5468 8.94149 15.4995 9.03448L13.7614 12.4324L9.87771 12.9774C9.79473 12.989 9.7154 13.0181 9.64525 13.0624C9.57509 13.1067 9.51581 13.1653 9.47155 13.2341C9.42729 13.3028 9.39911 13.3801 9.38898 13.4604C9.37885 13.5408 9.38702 13.6223 9.41291 13.6993C9.44445 13.6009 9.50397 13.5131 9.5847 13.4458C9.66543 13.3785 9.76412 13.3344 9.86953 13.3186Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M19.6632 16.8555L19.6108 16.9044L20.2574 20.5496C20.2837 20.4672 20.2893 20.38 20.2738 20.2952L19.6632 16.8555Z'
        fill='white'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M16.5255 8.99414L18.2701 12.392L22.1554 12.937C22.2619 12.9517 22.3619 12.995 22.4441 13.0619C22.5264 13.1287 22.5875 13.2165 22.6206 13.3153C22.6536 13.4141 22.6573 13.5198 22.6312 13.6205C22.6051 13.7212 22.5503 13.8127 22.4729 13.8848L19.6629 16.5292L20.3175 20.2636C20.3356 20.3655 20.3237 20.4702 20.2834 20.566C20.243 20.6618 20.1757 20.7447 20.089 20.8055C20.0024 20.8663 19.8999 20.9025 19.793 20.91C19.6862 20.9175 19.5793 20.8961 19.4845 20.8481L16.0099 19.0851L12.542 20.8465C12.4471 20.8949 12.34 20.9167 12.2329 20.9094C12.1258 20.9021 12.0229 20.866 11.936 20.8052C11.8491 20.7443 11.7816 20.6612 11.7412 20.5651C11.7008 20.4691 11.689 20.3641 11.7073 20.262L12.3718 16.5276L9.56006 13.8832C9.48266 13.8111 9.42782 13.7193 9.4018 13.6185C9.37579 13.5177 9.37963 13.4119 9.4129 13.3131C9.44616 13.2143 9.50751 13.1265 9.58995 13.0598C9.6724 12.993 9.77262 12.9499 9.8792 12.9354L13.7629 12.3905L15.4879 8.99414C15.5345 8.89945 15.608 8.81947 15.6998 8.76346C15.7917 8.70746 15.898 8.67773 16.0067 8.67773C16.1153 8.67773 16.2217 8.70746 16.3135 8.76346C16.4053 8.81947 16.4788 8.89945 16.5255 8.99414Z'
        fill='url(#paint7_radial_7848_467420)'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M22.1268 12.9774L18.2497 12.4324L16.5133 9.03448C16.4659 8.94149 16.3925 8.86318 16.3014 8.80841C16.2103 8.75364 16.1051 8.72461 15.9977 8.72461C15.8904 8.72461 15.7852 8.75364 15.6941 8.80841C15.6029 8.86318 15.5296 8.94149 15.4822 9.03448L13.7441 12.4324L9.86043 12.9774C9.76976 12.9895 9.68344 13.0225 9.6088 13.0736C9.53416 13.1248 9.47339 13.1925 9.43164 13.2712C9.92262 13.8651 10.1141 13.971 10.9177 14.516C13.3726 15.8571 19.5295 15.6217 21.2283 14.2869C21.865 13.7877 22.0925 13.7214 22.4771 13.159C22.3868 13.0597 22.2625 12.9952 22.1268 12.9774Z'
        fill='url(#paint8_linear_7848_467420)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_7848_467420'
          x1='16.0217'
          y1='0'
          x2='16.0217'
          y2='30.5668'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DBECEA' />
          <stop offset='0.05' stopColor='#DDEDEB' />
          <stop offset='0.32' stopColor='#E4F2F1' />
          <stop offset='0.62' stopColor='#D1E4E6' />
          <stop offset='1' stopColor='#B2CED4' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_7848_467420'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.8557 -2.10333) scale(12.6101 12.1714)'
        >
          <stop stopColor='white' />
          <stop offset='1' />
        </radialGradient>
        <linearGradient
          id='paint2_linear_7848_467420'
          x1='16.0145'
          y1='28.0354'
          x2='16.0145'
          y2='5.85668'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_7848_467420'
          x1='15.9672'
          y1='27.1262'
          x2='15.9672'
          y2='-1.95728'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E4F2F1' />
          <stop offset='0.43' stopColor='#D1E5E6' />
          <stop offset='1' stopColor='#B2D1D4' />
        </linearGradient>
        <radialGradient
          id='paint4_radial_7848_467420'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(16.2443 10.3718) scale(15.8113 15.2613)'
        >
          <stop offset='0.29' stopColor='#E4F2F1' />
          <stop offset='0.6' stopColor='#D1E4E6' />
          <stop offset='1' stopColor='#B2CCD4' />
        </radialGradient>
        <linearGradient
          id='paint5_linear_7848_467420'
          x1='15.9786'
          y1='21.5809'
          x2='15.9786'
          y2='8.99871'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9DBABD' />
          <stop offset='1' stopColor='#B2D2D4' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_7848_467420'
          x1='16.0099'
          y1='10.4854'
          x2='16.0099'
          y2='28.4511'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EBFAF9' />
          <stop offset='0.39' stopColor='#D8EDEC' />
          <stop offset='1' stopColor='#B2D4D1' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_7848_467420'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.8905 6.16019) scale(11.1912 10.8019)'
        >
          <stop offset='0.01' stopColor='white' />
          <stop offset='1' />
        </radialGradient>
        <linearGradient
          id='paint8_linear_7848_467420'
          x1='15.9503'
          y1='20.822'
          x2='15.9503'
          y2='13.2285'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
