import { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Button } from '@mui/joy';
import { useModalQueryParam } from '@shared/lib';
import { TextInput } from '@shared/ui';
import {
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper
} from '@shared/api';
import {
  ResetPasswordModalsQueries,
  SetNewPassword,
  useLazySetNewPasswordQuery
} from '../api';
import { confirmPasswordSchema } from '../lib';
import {
  useResetPasswordSelector,
  selectResetPasswordKey,
  selectEmail,
  selectMobile
} from '../model';

const defaultValues = { newPassword: '', confirmPassword: '' };

export const NewPasswordForm: FC = () => {
  const { toggleModal } = useModalQueryParam()
  const [setNewPassword, { isFetching }] = useLazySetNewPasswordQuery();
  const resetPasswordKey = useResetPasswordSelector(selectResetPasswordKey)
  const email = useResetPasswordSelector(selectEmail)
  const mobile = useResetPasswordSelector(selectMobile)
  const [error, setError] = useState<string>('');

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(confirmPasswordSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues,
  })

  const handleSendConfirmationCode = async (
    formData: {
      newPassword: Password,
      confirmPassword: Password
    },
  ): Promise<void> => {
    if (!(email || mobile) && resetPasswordKey) return;

    let params: SetNewPassword;
    if (email) {
      params = {
        newPassword: formData.newPassword,
        resetPasswordKey: resetPasswordKey!,
        email: email,
      };
    } else {
      params = {
        newPassword: formData.newPassword,
        resetPasswordKey: resetPasswordKey!,
        mobile: mobile!,
      };
    }

    const { isSuccess, data } = await setNewPassword(params)
    if (isSuccess && data.status === OmegaApiResponseStatusEnum.Success) {
      toggleModal(ResetPasswordModalsQueries.NewPasswordModal, 'login')
    } else if (isSuccess && data.status === OmegaApiResponseStatusEnum.NotFound) {
      setError(omegaErrorsMapper['NEW-PASSWORD-FORM'][data.status])
    } else if (isSuccess && data.status === OmegaApiResponseStatusEnum.InvalidPassword) {
      setError(omegaErrorsMapper['NEW-PASSWORD-FORM'][data.status])
    } else {
      setError('Something went wrong')
    }
  }

  return (
    <Stack
      component='form'
      gap='1rem'>
      {error && (
        <Typography
          sx={({ palette }) => ({
            color: palette.common.error,
          })}>
          {error}
        </Typography>
      )}
      <Stack>
        <Controller
          control={control}
          disabled={isFetching}
          name='newPassword'
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              placeholder='Enter your password'
              type='password'
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              error={fieldState.error?.message} />
          )}
        />
        <Controller
          control={control}
          disabled={isFetching}
          name='confirmPassword'
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Confirm password'
              placeholder='Confirm your password'
              type='password'
              onChange={onChange}
              value={value}
              onBlur={onBlur}
              error={fieldState.error?.message} />
          )}
        />
      </Stack>
      <Button
        loading={isFetching}
        fullWidth
        type='submit'
        disabled={!formState.isDirty || !formState.isValid}
        onClick={handleSubmit(handleSendConfirmationCode)}>
        Submit
      </Button>
    </Stack>
  );
};