import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { dynamicPageSlice } from './slice';
import { selectDynamicPageContent } from './common-selectors';
import {
  type DynamicPageState,
  type ContactUsContent
} from './types';

export const selectContactUsContent = createSelector(
  selectDynamicPageContent,
  (ContactUsContent) => ContactUsContent as Maybe<ContactUsContent>,
  {
    devModeChecks: { identityFunctionCheck: 'never' }
  }
);

export const useContactUsPageContentSelector: TypedUseSelectorHook<{
  [dynamicPageSlice.name]: DynamicPageState;
}> = useSelector;