import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  WorldPayCheckoutResult,
  type PaymentsState
} from './types';

const initialState: PaymentsState = {
  actionPurpose: null,
  openWorldPayCheckoutFailedPopup: false,
  openWorldPayCheckoutSuccessPopup: false,
  worldPayCheckoutResult: null,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    openWorldPayCheckoutFailedPopup: (
      state: PaymentsState,
      action: PayloadAction<{
        actionPurpose: string;
        result: WorldPayCheckoutResult;
      }>
    ) => {
      state.openWorldPayCheckoutFailedPopup = true;
      state.worldPayCheckoutResult = action.payload.result;
      state.actionPurpose = action.payload.actionPurpose;
      state.openWorldPayCheckoutSuccessPopup = false;
    },
    closeWorldPayCheckoutFailedPopup: (state: PaymentsState) => {
      state.openWorldPayCheckoutFailedPopup = false;
    },
    openWorldPayCheckoutSuccessPopup: (
      state: PaymentsState,
      action: PayloadAction<{
        actionPurpose: string;
        result: WorldPayCheckoutResult;
      }>
    ) => {
      state.openWorldPayCheckoutSuccessPopup = true;
      state.worldPayCheckoutResult = action.payload.result;
      state.actionPurpose = action.payload.actionPurpose;
      state.openWorldPayCheckoutFailedPopup = false;
    },
    closeWorldPayCheckoutSuccessPopup: (state: PaymentsState) => {
      state.openWorldPayCheckoutSuccessPopup = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState);
  }
});

export const {
  closeWorldPayCheckoutFailedPopup,
  openWorldPayCheckoutFailedPopup,
  closeWorldPayCheckoutSuccessPopup,
  openWorldPayCheckoutSuccessPopup,
} = paymentsSlice.actions;

export const paymentsReducer = paymentsSlice.reducer;