import {
  ComponentType,
  useMemo,
  useState
} from 'react';
import {
  Typography
} from '@mui/joy';
import {
  Link
} from '@shared/ui';
import {
  isDefined,
  useModalQueryParam,
  useQueryParamModalVisibility
} from '@shared/lib';
import {
  SingUpPromoBanner
} from '@entities/page-layout';
import {
  useGetPublicConsentsQuery
} from '@entities/session';
import {
  selectCodes,
  useCheckTrackingCodesSelector
} from '@features/check-tracking-codes';
import {
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  ConfirmationSignUpModal,
  SignUpByEmailForm,
  SignUpByPhoneForm,
  SuccessConfirmationSignUpModal
} from '@features/sign-up';
import {
  SocialLogin
} from '@features/authentication-via-socials';
import {
  AuthModal
} from '@widgets/auth-modal';

// eslint-disable-next-line operator-linebreak
export const withSignUpModal =
  <T extends object>(WrappedComponent: ComponentType<T>) =>
    (props: T) => {
      const openSignUpModal = useQueryParamModalVisibility('sign-up');
      const { toggleModal, closeModal } = useModalQueryParam();

      const { sendDataToGtm } = useSendDataToGtm();

      const { data: consents } = useGetPublicConsentsQuery();
      const consentVersionIds = useMemo(() => consents?.map(
        ({ consentVersionId }) => consentVersionId), [consents]
      );
      const trackingCodes = useCheckTrackingCodesSelector(selectCodes);
      const [passedError, setPassedError] = useState<Maybe<string>>(null);

      const handleClose = (): void => {
        closeModal('sign-up');
      };

      const handleSignIn = (): void => {
        toggleModal('sign-up', 'login');
      };

      const handlePassedError = (error: Maybe<string>): void => {
        if (isDefined(error)) {
          setPassedError('DUPLICATE_VIA_SOCIALS');
        } else {
          setPassedError(null);
        }
      };

      const handleClearPassedError = (): void => {
        setPassedError(null);
      };

      const handleSignUp = (method: string): void => {
        sendDataToGtm({
          dataLayer: {
            event: 'registration_start',
            method: method
          }
        });
      };

      const handleConfirmSignUp = (method: string): void => {
        sendDataToGtm({
          dataLayer: {
            event: 'registration_success',
            method: method
          }
        });
      }

      return (
        <>
          <WrappedComponent {...props} />
          <AuthModal
            open={openSignUpModal}
            onClose={handleClose}
            titleSlot={
              <Typography
                level='h3'
                fontSize='1.25rem'>
                Sign up
              </Typography>
            }
            headingSlot={
              <SocialLogin
                signUpMode
                onSocialSignUp={handleSignUp}
                consents={consents}
                onError={handlePassedError}
              />
            }
            bannerSlot={<SingUpPromoBanner />}
            emailForm={
              <SignUpByEmailForm
                consentVersionIds={consentVersionIds}
                trackingCodes={trackingCodes}
                passedError={passedError}
                onClearPassedError={handleClearPassedError}
                onSignUp={handleSignUp}
              />
            }
            phoneForm={
              <SignUpByPhoneForm
                consentVersionIds={consentVersionIds}
                trackingCodes={trackingCodes}
                passedError={passedError}
                onClearPassedError={handleClearPassedError}
                onSignUp={handleSignUp}
              />
            }
            bottomSlot={
              <Typography
                textAlign='center'
                color='neutral'
                pb={4}>
                Already have an account?&nbsp;
                <Link
                  color='success'
                  underline='none'
                  onClick={handleSignIn}>
                  Log in
                </Link>
              </Typography>
            }
          />
          <SuccessConfirmationSignUpModal />
          <ConfirmationSignUpModal onSignUpConfirmation={handleConfirmSignUp} />
        </>
      );
    };
