import { OmegaApiResponse, baseOmegaApi } from '@shared/api';
import {
  type SetNewPassword,
  type ConfirmPasswordReset,
  type SendResetPasswordByEmail,
  type SendResetPasswordByPhone
} from './types';

export const resetPasswordApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    sendResetPasswordOTP: build.query<OmegaApiResponse, SendResetPasswordByEmail | SendResetPasswordByPhone>({
      query: (params) => ({ url: 'ips/forgotPasswordStep1And2', params }),
      extraOptions: { auth: false, showLoader: false },
    }),
    confirmPasswordReset: build.query<OmegaApiResponse, ConfirmPasswordReset>({
      query: (params) => ({ url: 'ips/forgotPasswordPreStep3', params }),
      extraOptions: { auth: false, showLoader: false },
    }),
    setNewPassword: build.query<OmegaApiResponse, SetNewPassword>({
      query: (params) => ({ url: 'ips/forgotPasswordStep3', params }),
      extraOptions: { auth: false, showLoader: false },
    })
  }),
});

export const {
  useLazySendResetPasswordOTPQuery,
  useLazyConfirmPasswordResetQuery,
  useLazySetNewPasswordQuery
} = resetPasswordApiSlice;