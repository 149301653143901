// TODO: move to the env file 

type EnvironmentConfig = {
  development: {
    challenges: boolean;
    verifyPhone: boolean;
    paymentIQCashier: boolean;
  };
  test: {
    challenges: boolean;
    verifyPhone: boolean;
    paymentIQCashier: boolean;
  };
  stage: {
    challenges: boolean;
    verifyPhone: boolean;
    paymentIQCashier: boolean;
  };
  prod: {
    challenges: boolean;
    verifyPhone: boolean;
    paymentIQCashier: boolean;
  };
};

type Configs = {
  [key: string]: EnvironmentConfig[keyof EnvironmentConfig];
};

const configs: Configs = {
  development: {
    challenges: true,
    verifyPhone: false,
    paymentIQCashier: false
  },
  test: {
    challenges: true,
    verifyPhone: false,
    paymentIQCashier: false
  },
  stage: {
    challenges: false,
    verifyPhone: false,
    paymentIQCashier: false
  },
  prod: {
    challenges: false,
    verifyPhone: false,
    paymentIQCashier: false
  }
};

export const currentEnvironment = process.env.REACT_APP_ENV as keyof EnvironmentConfig || 'development';
export const featureToggles = configs[currentEnvironment];