import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  paymentsSlice
} from './slice';
import {
  type WorldPayCheckoutResult,
  type PaymentsState
} from './types';

type RootState = {
  [paymentsSlice.name]: PaymentsState;
};

export const selectOpenWorldPayCheckoutFailedPopup = (state: RootState): boolean => (
  state[paymentsSlice.name].openWorldPayCheckoutFailedPopup
);

export const selectOpenWorldPayCheckoutSuccessPopup = (state: RootState): boolean => (
  state[paymentsSlice.name].openWorldPayCheckoutSuccessPopup
);

export const selectWorldPayCheckoutResult = (state: RootState): Maybe<WorldPayCheckoutResult> => (
  state[paymentsSlice.name].worldPayCheckoutResult
);

export const selectActionPurpose = (actionPurpose: string) => (state: RootState): boolean => (
  state[paymentsSlice.name].actionPurpose === actionPurpose
);

export const usePaymentsSelector: TypedUseSelectorHook<{
  [paymentsSlice.name]: PaymentsState;
}> = useSelector;