import { isDefined } from '../common';
import { get } from './get';
import { initObject } from './init-object';

const getNewObjectFromPath = (value: any, newKey: any) =>
  isDefined(value) ? initObject(newKey, value) : {};

const getTransformedObject = (
  transformFn: any,
  value: any,
  key: any,
  originalPayload: any
) => initObject(key, transformFn(value, originalPayload));

const handleObjectMapperOption = (obj: any, mapperOption: any) => {
  const path = mapperOption.from || mapperOption.name;
  const newKey = mapperOption.name;
  const value = get(obj, path);

  if (value === undefined && mapperOption.from && !mapperOption.transform) {
    return {};
  }

  if (mapperOption.shouldApply) {
    const { condition, field } = mapperOption.shouldApply;
    const conditionValue = get(obj, field);
    if (
      (field && conditionValue === undefined) ||
      !condition(conditionValue, obj)
    )
      return {};
  }

  if (mapperOption.transform) {
    return getTransformedObject(mapperOption.transform, value, newKey, obj);
  }

  return getNewObjectFromPath(value, newKey);
};

export const mapper = <T extends object>(mapperOptions: Array<any>) => {
  return (mapperObject: any): T =>
    mapperOptions.reduce((mappedOutput: any, mapperOption: any) => {
      if (!mapperOption) return mappedOutput;

      switch (typeof mapperOption) {
        case 'object':
          return Object.assign(
            mappedOutput,
            handleObjectMapperOption(mapperObject, mapperOption)
          );
        case 'string':
          return Object.assign(
            mappedOutput,
            getNewObjectFromPath(get(mapperObject, mapperOption), mapperOption)
          );
        default:
          return mappedOutput;
      }
    }, {});
};
