import {
  FC,
} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import {
  useSelector
} from 'react-redux';
import {
  Box,
  Skeleton,
  Stack,
  StackProps,
  Typography
} from '@mui/joy';
import {
  Button, CarouselSlider
} from '@shared/ui';
import {
  selectHeading,
  selectSlider,
  selectPrizeGC,
  selectPrizeSC,
  selectAction,
  useTournamentDetailsPageSliceSelector,
} from '../model';

export type HeadingBlockProps = StackProps & {
  loading?: boolean;
};

export const HeadingBlock: FC<HeadingBlockProps> = ({
  sx,
  loading,
  ...props
}) => {
  const navigate = useNavigate();

  const heading = useTournamentDetailsPageSliceSelector(selectHeading);
  const prizeGC = useTournamentDetailsPageSliceSelector(selectPrizeGC);
  const prizeSC = useTournamentDetailsPageSliceSelector(selectPrizeSC);
  const action = useSelector(selectAction);
  const slider = useSelector(selectSlider);

  const handleActionButtonClick = (): void => {
    if (action?.url) navigate(action?.url);
  };

  return (
    <Stack
      direction='column'
      gap={2}
      sx={[() => ({
        height: '100%',
        // '&  .swiper.swiper-initialized': {
        //   aspectRatio: '24/9'
        // },
        '& .swiper-slide': {
          borderRadius: 8,
          overflow: 'hidden'
        }
      }),
      ...(sx ? Array.isArray(sx) ? sx : [sx] : [])
      ]}
      {...props}>
      <Typography
        sx={({ palette, breakpoints }) => ({
          marginBottom: 2,
          fontSize: 28,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '36px',
          color: palette.common.white,
          height: 20,
          [breakpoints.down(768)]: {
            fontSize: 20,
          },
        })}>
        <Skeleton
          overlay
          loading={loading}
          sx={{
            position: 'static',
            width: '50%',
            height: 'unset',
            borderRadius: 8
          }}>
          {heading}
        </Skeleton>
      </Typography>
      <CarouselSlider items={slider as any} />
      <Stack
        direction='row'
        justifyItems='center'
        justifyContent='space-between'
        gap={2}
        sx={({ breakpoints }) => ({
          width: '100%',
          [breakpoints.down(768)]: {
            flexDirection: 'column',
            justifyContent: 'center'
          }
        })}>
        <Skeleton
          loading={loading}
          variant='overlay'
          sx={{
            borderRadius: 8,
            position: 'static',
            height: 'unset',
            maxWidth: 378,
          }}>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={({ palette, breakpoints }) => ({
              width: '100%',
              maxWidth: 378,
              height: 40,
              paddingInline: 2,
              paddingBlock: 1,
              gap: 1,
              borderRadius: 8,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: palette.common[925],
              background: palette.common[475],
              [breakpoints.down(768)]: {
                maxWidth: '100%'
              }
            })}>
            <Box
              component='img'
              loading='lazy'
              src='/assets/webp/gift_blue_box_red_stripe.webp'
              sx={{
                height: 28,
              }}
            />
            <Typography
              sx={({ palette }) => ({
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                color: palette.common.white,
                'span': {
                  color: palette.common[1075],
                }
              })}>
              {prizeGC}&nbsp;GC&nbsp;+&nbsp;<span>{prizeSC}&nbsp;SC</span>
            </Typography>
          </Stack>
        </Skeleton>
        <Skeleton
          loading={loading}
          variant='overlay'
          sx={{
            borderRadius: 8,
            position: 'static',
            height: 'unset',
            maxWidth: 378,
          }}>
          <Button
            color='primary'
            sx={({ breakpoints }) => ({
              width: '100%',
              maxWidth: 378,
              height: 28,
              [breakpoints.down(768)]: {
                maxWidth: '100%'
              }
            })}
            onClick={handleActionButtonClick}>
            {action?.text}
          </Button>
        </Skeleton>
      </Stack>
    </Stack>
  );
};