import { mapper } from '@shared/lib';
import { ShortProfile } from '../model';

type MapShortProfile = (data: any) => ShortProfile;
export const mapShortProfile: MapShortProfile = mapper([
  { name: 'partyId', from: 'partyId' },
  { name: 'username', from: 'username' },
  { name: 'currency', from: 'currency' },
  { name: 'email', from: 'email' },
  { name: 'parentId', from: 'parentId' },
  { name: 'level', from: 'level' },
  { name: 'userType', from: 'userType' },
  { name: 'registrationStatus', from: 'registrationStatus' },
  { name: 'pendingLimitConfirmation', from: 'pendingLimitConfirmation' },
  { name: 'country', from: 'country' },
  {
    name: 'kycStatus',
    from: 'kycStatus',
    transform: (kycStatus: Maybe<string>) => kycStatus ?? 'UNKNOWN',
  },
  { name: 'securityVerificationRequiredFields', from: 'securityVerificationRequiredFields' },
]);
