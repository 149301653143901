import { FC } from 'react';
import { sanitize } from 'dompurify';
import { Box, Stack, Typography } from '@mui/joy';

export type ContactUsHtmlBlockProps = {
  title: Maybe<string>;
  html: any;
};

export const ContactUsHtmlBlock: FC<ContactUsHtmlBlockProps> = ({ title, html }) => {
  return (
    <Stack
      direction='column'
      gap={1.5}>
      {title && (
        <Typography sx={({ colorSchemes, breakpoints }) => ({
          width: '100%',
          fontSize: 28,
          fontWeight: 600,
          lineHeight: '36x',
          letterSpacing: 0,
          textAlign: 'start',
          color: colorSchemes.dark.palette.common.white,
          [breakpoints.down(490)]: {
            fontSize: 20,
            lineHeight: '30px',
          }
        })}>
          {title}
        </Typography>
      )}
      {html && (
        <Box
          component='div'
          sx={({ palette }) => ({
            '& figure': {
              overflow: 'auto',
              width: '100% !important',
              marginInline: '0 !important',
            },
            '& table': {
              width: '100%',
              borderRadius: 8,
              background: palette.common[475],
              borderCollapse: 'collapse',
              '& thead th': {
                paddingBlock: 1.5,
                paddingInline: 3,
              },
              '& thead tr:first-child th': {
                borderBottom: `1px solid ${palette.common[925]}`
              },
              '& tbody td': {
                paddingBlock: 2,
                paddingInline: 3,
              },
              '& tbody tr:first-child td': {
                borderTop: 'none'
              },
              '& tbody tr td': {
                borderTop: `1px solid ${palette.common[925]}`
              },
            },
            '& strong': {
              fontWeight: 'bold'
            },
          })}
          dangerouslySetInnerHTML={{
            __html: sanitize(html)
          }}
        />
      )}
    </Stack>
  );
};