import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { authViaSocialsSlice } from './slice';
import {
  type AuthViaSocialsState,
  type SocialLogin
} from './types';

type RootState = {
  [authViaSocialsSlice.name]: AuthViaSocialsState
};

export const selectIsOpenConfirmationAuthViaSocialsModal = (state: RootState): boolean => (
  state[authViaSocialsSlice.name].openConfirmationAuthViaSocialsModal
);

export const selectIsOpenSuccessConfirmationAuthViaSocialsModal = (state: RootState): boolean => (
  state[authViaSocialsSlice.name].openSuccessConfirmationAuthViaSocialsModal
);

export const selectPropsToAuthViaSocials = (state: RootState): Maybe<SocialLogin> => (
  state[authViaSocialsSlice.name].propsToAuthViaSocials
);

export const useAuthViaSocialsSliceSelector: TypedUseSelectorHook<{
  [authViaSocialsSlice.name]: AuthViaSocialsState;
}> = useSelector;
