import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const FullScreenOnIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.69645 6.04389C1.08117 6.04389 1.3929 5.73206 1.3929 5.34744V2.37796L5.8785 6.86263C6.01454 6.99858 6.19274 7.06655 6.37094 7.06655C6.54923 7.06655 6.72752 6.99858 6.86347 6.86254C7.13546 6.59055 7.13546 6.14956 6.86338 5.87767L2.37759 1.3929H5.34771C5.73234 1.3929 6.04416 1.08108 6.04416 0.69645C6.04416 0.311824 5.73243 0 5.34771 0H0.69645C0.311731 0 0 0.311824 0 0.69645V5.34753C0 5.73206 0.311731 6.04389 0.69645 6.04389Z'
        fill='#848587'
      />
      <path
        d='M19.3001 13.9546C18.9154 13.9546 18.6037 14.2664 18.6037 14.6511V17.6206L13.98 12.997C13.7079 12.725 13.267 12.725 12.995 12.997C12.723 13.2689 12.723 13.7099 12.995 13.9818L17.6186 18.6054H14.6492C14.2645 18.6054 13.9528 18.9172 13.9528 19.3019C13.9528 19.6865 14.2645 19.9983 14.6492 19.9983H19.3002C19.6849 19.9983 19.9966 19.6865 19.9966 19.3019V14.6511C19.9966 14.2664 19.6847 13.9546 19.3001 13.9546Z'
        fill='#848587'
      />
      <path
        d='M6.01612 12.997L1.3929 17.6206V14.6511C1.3929 14.2665 1.08117 13.9546 0.69645 13.9546C0.311731 13.9546 0 14.2665 0 14.6511V19.3021C0 19.6867 0.311731 19.9985 0.69645 19.9985H5.34725C5.73188 19.9985 6.0437 19.6867 6.0437 19.3021C6.0437 18.9174 5.73197 18.6056 5.34725 18.6056H2.37777L7.00109 13.9818C7.27307 13.7099 7.27307 13.2689 7.00099 12.9969C6.72919 12.725 6.2882 12.725 6.01612 12.997Z'
        fill='#848587'
      />
      <path
        d='M19.3039 0H14.653C14.2682 0 13.9565 0.311824 13.9565 0.69645C13.9565 1.08108 14.2682 1.3929 14.653 1.3929H17.6225L13.1376 5.87813C12.8656 6.15012 12.8656 6.59111 13.1377 6.8631C13.2736 6.99904 13.4519 7.06702 13.6301 7.06702C13.8083 7.06702 13.9866 6.99895 14.1226 6.863L18.6076 2.37777V5.34744C18.6076 5.73206 18.9193 6.04389 19.304 6.04389C19.6888 6.04389 20.0005 5.73206 20.0005 5.34744V0.69645C20.0004 0.311731 19.6886 0 19.3039 0Z'
        fill='#848587'
      />
    </SvgIcon>
  );
};
