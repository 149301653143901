import { FC } from 'react';
import { Stack, StackProps } from '@mui/joy';
import { GameListItemSkeleton } from '@entities/games';

export type LoadingListProps = StackProps & {
  length: number;
};

export const LoadingList: FC<LoadingListProps> = ({ length, sx, ...props }) => (
  <Stack
    direction='row'
    sx={[
      ({ breakpoints }) => ({
        columnGap: 2,
        [breakpoints.down(912)]: {
          display: 'grid',
          gridTemplateColumns: `repeat(${length}, auto)`,
          justifyContent: 'start',
          gap: '8px',
          overflow: 'hidden',
        },
      }),
      ...(Array.isArray(sx)
        ? sx
        : [sx])
    ]}
    {...props}>
    {Array(length).fill(null).map((_, i) => (
      <GameListItemSkeleton
        key={i}
        sx={({ breakpoints }) => ({
          width: '160px',
          height: '152px',
          [breakpoints.up(911)]: {
            width: '100%',
          },
          [breakpoints.down(390)]: {
            width: '160px',
            height: '120px',
          },
        })}
      />
    ))}
  </Stack>
);