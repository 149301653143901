import { FC, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  StackProps,
  Box,
  Typography,
  // Skeleton
} from '@mui/joy';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Button,
  IconButton,
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@shared/ui';
import {
  type GameCategory
} from '@entities/games';
import {
  setSearch
} from '@features/games-filter-by-search';

export type GamesCategoryItemHeaderProps = StackProps & {
  category: GameCategory;
  navigationNextRef: RefObject<HTMLButtonElement>;
  navigationPrevRef: RefObject<HTMLButtonElement>;
};

export const GamesCategoryItemHeader: FC<GamesCategoryItemHeaderProps> = ({
  category,
  navigationNextRef,
  navigationPrevRef,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleViewAllClick = (): void => {
    dispatch(setSearch(''));
    navigate(`/games?category=${category?.key}`);
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      sx={[
        ...(Array.isArray(props.sx)
          ? props.sx
          : [props.sx])
      ]}
      {...props}>
      <Stack
        direction='row'
        gap={1.5}
        sx={{
          height: 40,
        }}>

        {/* <Box
          sx={{
            borderRadius: 8,
            height: 40,
            position: 'relative',
            overflow: 'hidden',
            display: 'none',
            ...(isEmpty(category) && ({
              display: 'block',
              width: 150,
            })),
          }}>
          <Skeleton variant='overlay' />
        </Box> */}

        <Stack
          direction='row'
          alignItems='center'
          gap={0.5}>
          <Box
            component='img'
            loading='lazy'
            src={category.iconSrc}
            sx={({ breakpoints }) => ({
              width: '32px',
              aspectRatio: '1/1',
              [breakpoints.down(912)]: {
                width: '24px',
              },
            })}
          />
          <Typography
            sx={({ palette, breakpoints }) => ({
              fontSize: '28px',
              fontWeight: 600,
              fontStyle: 'normal',
              lineHeight: '36px',
              color: palette.common.white,
              [breakpoints.down(912)]: {
                fontSize: '24px',
                lineHeight: '30px',
              },
            })}>
            {category?.name}
          </Typography>
        </Stack>

        {!isMobile &&
          <Stack
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}>
            <IconButton
              variant='soft'
              ref={navigationPrevRef}
              sx={({ palette }) => ({
                background: 'transparent',
                color: palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              variant='soft'
              ref={navigationNextRef}
              sx={({ palette }) => ({
                background: 'transparent',
                color: palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}>
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        }
      </Stack>
      <Button
        type='button'
        variant='plain'
        onClick={handleViewAllClick}
        sx={({ palette }) => ({
          color: palette.common[150],
          fontSize: '16px',
          fontWeight: 500,
        })}>
        View All
      </Button>
    </Stack>
  );
};
