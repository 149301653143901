import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModalQueryParam } from '@shared/lib';
import { selectIsAuthenticated } from '@entities/session';

export const AuthenticatedOnly: FC<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { openModal } = useModalQueryParam();

  useEffect(() => {
    if (!isAuthenticated) openModal('login');
  }, [openModal, isAuthenticated]);

  return isAuthenticated ? children : null;
};
