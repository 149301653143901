import { FC } from 'react';
import { CircularProgress } from '@mui/joy';

export const SimpleCircularLoader: FC = () => (
  <CircularProgress
    value={75}
    size='md'
    thickness={4}
    sx={({ palette }) => ({
      color: palette.common[300],
      '--CircularProgress-linecap': 'auto',
      '--CircularProgress-trackColor': 'inherit',
    })}
  />
);