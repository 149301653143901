import { mapper } from '@shared/lib';
import { type LayoutState } from '../model';
import { mapPageHeader } from './map-page-header';
import { mapPageFooter } from './map-page-footer';
import { mapSidebarMenu } from './map-sidebar-menu';
import { mapStickyNavigation } from './map-sticky-navigation';

type MapLayoutState = (data: any) => LayoutState;
export const mapLayoutState: MapLayoutState = mapper([
  {
    name: 'header',
    from: 'data.attributes.layoutHeader',
    transform: (headerData: any) => headerData && mapPageHeader(headerData),
  },
  {
    name: 'footer',
    from: 'data.attributes.layoutFooter',
    transform: (footerData: any) => footerData && mapPageFooter(footerData),
  },
  {
    name: 'sidebarMenu',
    from: 'data.attributes.sidebarMenu',
    transform: (sidebarMenuData: any) => sidebarMenuData && mapSidebarMenu(sidebarMenuData),
  },
  {
    name: 'stickyNavigation',
    from: 'data.attributes.stickyNavigation',
    transform: (stickyNavigationData: any) => stickyNavigationData && mapStickyNavigation(stickyNavigationData),
  },
]);
