import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  type PlayGamePageState,
  type PlayGameItem
} from './types';
import { playGamePageSlice } from './slice';

type RootState = {
  [playGamePageSlice.name]: PlayGamePageState;
};

export const selectGameItem = (state: RootState): Maybe<PlayGameItem> => (
  state[playGamePageSlice.name].game
);

export const selectIsFullScreen = (state: RootState): boolean => (
  state[playGamePageSlice.name].isFullScreen ?? false
);

export const usePlayGamePageSelector: TypedUseSelectorHook<{
  [playGamePageSlice.name]: PlayGamePageState;
}> = useSelector;
