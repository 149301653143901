import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { UnknownAction } from 'redux';
import {
  Box,
  Skeleton,
  Tab,
  TabList,
  Tabs
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  isDefined,
  isEmpty,
  useQueryParamValue
} from '@shared/lib';
import {
  selectGameCategories,
  useGamesSelector,
  useGetGamesCategoriesQuery,
  selectGamesPageCategoryIds,
} from '@entities/games';
import {
  selectSelectedCategoryKey,
  useGamesFilterByCategorySelector,
  fetchGamesByCategory,
} from '../model';

export type CategoriesSelectProps = {
  preloadByFirstCategory: boolean;
  selectFavoriteSlot: ReactNode;
  onChange: () => void;
};

export const CategoriesSelect: FC<CategoriesSelectProps> = ({
  preloadByFirstCategory,
  selectFavoriteSlot,
  onChange,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const { modifyParam, value: category } = useQueryParamValue('category');
  const [searchParams] = useSearchParams();

  const categories = useGamesSelector(selectGameCategories);
  const gamesPageCategoryIds = useGamesSelector(selectGamesPageCategoryIds);
  const selectedCategoryKey = useGamesFilterByCategorySelector(selectSelectedCategoryKey);
  const { isFetching } = useGetGamesCategoriesQuery();
  const tabRefs = useRef<any>({});

  const categoriesToShow = useMemo(() => {
    return isDefined(gamesPageCategoryIds) &&
      Array.isArray(gamesPageCategoryIds) &&
      !isEmpty(gamesPageCategoryIds) &&
      !isEmpty(categories)
      ? gamesPageCategoryIds
        .filter((categoryId) =>
          categories?.some((categoryInStore) => categoryInStore?.id === categoryId)
        )
        .map((categoryId) =>
          categories?.find((categoryInStore) => categoryInStore?.id === categoryId)!
        )
      : [];
  }, [categories, gamesPageCategoryIds]);

  useEffect(() => {
    if (selectedCategoryKey) {
      const activeTabRef = tabRefs.current[selectedCategoryKey];
      if (isMobile && activeTabRef) {
        activeTabRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }
  }, [selectedCategoryKey, isMobile]);

  useEffect(() => {
    if (preloadByFirstCategory && categoriesToShow.length
      && !selectedCategoryKey && !searchParams.has('category')
    ) {
      const categoryKey = categoriesToShow[0]?.key;
      modifyParam(categoryKey);
    }
  }, [
    preloadByFirstCategory,
    selectedCategoryKey,
    searchParams,
    modifyParam,
    categoriesToShow,
  ]);

  useEffect(() => {
    if (selectedCategoryKey) dispatch(fetchGamesByCategory({
      categoryKey: selectedCategoryKey,
      page: 1,
      pageSize: isMobile
        ? 12
        : 24
    }));
  }, [selectedCategoryKey, isMobile, dispatch]);

  useEffect(() => {
    if (isDefined(category)) onChange?.();
    // eslint-disable-next-line
  }, [category]);

  const handleChange = (_: any, tab: Maybe<number | string>) => {
    if (typeof tab === 'string') {
      modifyParam(tab);
      setTimeout(onChange, 0);
    }
  };

  return (
    <Tabs
      onChange={handleChange}
      value={selectedCategoryKey}
      sx={({ breakpoints }) => ({
        [breakpoints.down(912)]: {
          position: 'absolute',
          top: 0,
          flexWrap: 'wrap',
          width: '100vw',
          maxWidth: 'fit-content',
          overflowX: 'auto',
          paddingInline: '1rem',
          marginInline: 'auto',
          scrollbarWidth: 'none'
        },
      })}>
      <TabList
        disableUnderline
        sx={({ colorSchemes }) => ({
          p: '0.1875rem',
          gap: '0.1875rem',
          background: colorSchemes.dark.palette.common[475]
        })}>
        {!isFetching &&
          categoriesToShow.map(({ id, key, name, iconSrc }) => (
            <Tab
              key={id}
              value={key}
              ref={(el) => tabRefs.current[key] = el}
              disableIndicator
              sx={{
                textWrap: 'nowrap',
                whiteSpace: 'nowrap'
              }}>
              <Box
                component='img'
                loading='lazy'
                src={iconSrc}
                sx={{
                  width: '1rem',
                  aspectRatio: '1/1'
                }} />
              {name}
            </Tab>
          ))}

        {isFetching &&
          Array(6)
            .fill(null)
            .map((_, i) => (
              <Tab disableIndicator key={i}>
                <Skeleton
                  variant='circular'
                  sx={{
                    width: '1rem',
                    height: '1rem'
                  }} />
                <Skeleton
                  variant='text'
                  sx={{
                    width: '3.125rem'
                  }}
                />
              </Tab>
            ))}
        {selectFavoriteSlot}
      </TabList>
    </Tabs>
  );
};
