import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from "@reduxjs/toolkit";
import { selectIsAuthenticated } from '@entities/session';
import {
  selectSidebarMenuItems,
  AuthVisibilityEnum,
} from '@entities/page-layout';
import {
  sidebarMenuSlice,
  type SidebarMenuState
} from './slice';

type RootState = {
  [sidebarMenuSlice.name]: SidebarMenuState;
};

export const selectIsOpenSidebarMenu = (state: RootState): boolean => (
  state[sidebarMenuSlice.name].open ?? false
);

export const selectMenuItems = createSelector(
  selectIsAuthenticated,
  selectSidebarMenuItems,
  (isAuthenticated, menuItems) => menuItems?.filter(({ authVisibility }) => (
    isAuthenticated
      ? authVisibility !== AuthVisibilityEnum.UnauthorizedOnly
      : authVisibility !== AuthVisibilityEnum.AuthorizedOnly
  )),
  { devModeChecks: { inputStabilityCheck: 'never' } }
);

export const useSidebarMenuSelector: TypedUseSelectorHook<{
  [sidebarMenuSlice.name]: SidebarMenuState
}> = useSelector;