import { FC, useMemo } from 'react';
import { Box, Stack } from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  type GamesItem,
  GameListItem,
} from '@entities/games';
import {
  selectSelectedCategoryKey,
  selectGamesByCategoryKey,
  selectIsLoading as selectIsLoadingByCategory,
  useGamesFilterByCategorySelector,
} from '@features/games-filter-by-category';
import {
  selectGames as selectGamesBySearch,
  selectIsLoading as selectIsLoadingBySearch,
  useGamesFilterBySearchSelector,
} from '@features/games-filter-by-search';
import {
  useGamesFilterByFavoriteSelector,
  selectGames as selectGamesByFavorite,
  selectIsLoading as selectIsLoadingByFavorite,
} from '@features/games-filter-by-favorite';
import {
  FavoriteGameSwitcher
} from '@features/favorite-game-switcher';
import {
  LaunchGame
} from '@features/launch-game';
import {
  FilterTypeEnum,
  selectActiveFilterType,
  useGamesListWidgetSelector
} from '../model';
import {
  LoadingList
} from './loading-list.component';
import {
  NoSearchResultsFound
} from './no-search-results-found.component';
import {
  GamesFilter
} from './games-filter.component';
import {
  LoadMoreButton
} from './load-more-button.component';

export const GameList: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);

  const selectedCategoryKey = useGamesFilterByCategorySelector(selectSelectedCategoryKey);
  const gamesByCategory = useGamesFilterByCategorySelector(selectGamesByCategoryKey(selectedCategoryKey));
  const gamesBySearch = useGamesFilterBySearchSelector(selectGamesBySearch);
  const gamesByFavorite = useGamesFilterByFavoriteSelector(selectGamesByFavorite);
  const isLoadingByCategory = useGamesFilterByCategorySelector(selectIsLoadingByCategory);
  const isLoadingBySearch = useGamesFilterBySearchSelector(selectIsLoadingBySearch);
  const isLoadingByFavorite = useGamesFilterByFavoriteSelector(selectIsLoadingByFavorite);

  const isLoading: boolean = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category: return isLoadingByCategory;
      case FilterTypeEnum.Search: return isLoadingBySearch;
      case FilterTypeEnum.Favorite: return isLoadingByFavorite;
    }
  }, [activeFilterType, isLoadingByCategory, isLoadingBySearch, isLoadingByFavorite]);

  const games: GamesItem[] = useMemo(() => {
    switch (activeFilterType) {
      default:
      case FilterTypeEnum.Category: return gamesByCategory ?? [];
      case FilterTypeEnum.Search: return gamesBySearch ?? [];
      case FilterTypeEnum.Favorite: return gamesByFavorite ?? [];
    }
  }, [activeFilterType, gamesByCategory, gamesBySearch, gamesByFavorite]);

  return (
    <Stack
      width='100%'
      gap='2rem'
      alignItems='center'>
      <GamesFilter />
      {games?.length ? (
        <Box
          sx={({ breakpoints }) => ({
            display: 'grid',
            width: '100%',
            gap: '1rem',
            gridTemplateColumns: 'repeat(auto-fit, 200px)',
            [breakpoints.down(1024)]: { justifyContent: 'center' },
            [breakpoints.down(768)]: {
              gap: '0.75rem',
              gridTemplateColumns: 'repeat(auto-fit, 173px)',
            },
            [breakpoints.down(390)]: { gridTemplateColumns: '1fr 1fr' },
          })}>
          {games?.map((game) => (
            <GameListItem
              key={game.id}
              game={game}
              favoriteSwitcherSlot={<FavoriteGameSwitcher game={game} />}
              launchGameSlot={<LaunchGame id={game?.id} />}
              bonusFeature
              volatility='Hight'
              rtp='98%'
            />
          ))}
          {isLoading ? <LoadingList length={isMobile ? 6 : 12} /> : null}
        </Box>
      ) : (
        <NoSearchResultsFound />
      )}
      {games.length ? <LoadMoreButton isLoading={isLoading} /> : null}
    </Stack>
  );
};
