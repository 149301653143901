import {
  BaseSyntheticEvent,
  FC,
  useEffect
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Controller,
  useForm
} from 'react-hook-form';
import {
  yupResolver
} from '@hookform/resolvers/yup';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  TextInput,
  Button,
  Checkbox,
  CheckboxFormLabel,
  TermsOfUseLink,
  PrivacyPolicyLink
} from '@shared/ui';
import {
  useModalQueryParam
} from '@shared/lib';
import {
  CurrencyCodesEnum
} from '@shared/types';
import {
  type OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper,
} from '@shared/api';
import {
  signUpByEmailSchema
} from '../lib';
import {
  useLazyQuickSignUpQuery
} from '../api';
import {
  type SignUp,
  openConfirmationSignUpModal,
  setPropsToSignUp
} from '../model';
import {
  RestrictionAreasLink
} from './restriction-areas-link.component';

export type SignUpByEmailFormProps = {
  trackingCodes?: Maybe<string>;
  consentVersionIds: Maybe<Array<number>>;
  passedError?: Maybe<string>;
  onClearPassedError?(): void;
  onSignUp(method: string): void;
};

export const SignUpByEmailForm: FC<SignUpByEmailFormProps> = ({
  trackingCodes,
  consentVersionIds,
  passedError,
  onClearPassedError,
  onSignUp
}) => {
  const dispatch = useDispatch();

  const { closeModal } = useModalQueryParam();
  const [quickSignUp, { isFetching }] = useLazyQuickSignUpQuery();

  const {
    control,
    formState,
    setError,
    clearErrors,
    setFocus,
    handleSubmit
  } = useForm({
    resolver: yupResolver(signUpByEmailSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
      ageAndStateConfirmation: false,
      termsAndPrivacyConfirmation: false
    },
  });

  useEffect(() => {
    if (passedError) {
      setError('root.serverError', {
        type: passedError,
        message: omegaErrorsMapper['SIGN-UP'][passedError]
      });
    }
    else {
      clearErrors('root.serverError');
    }
    // eslint-disable-next-line
  }, [passedError]);

  const handleSingUp = async (
    formData: { email: Email; password: Password; },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const propsToSignUp: SignUp = {
      ...formData,
      currency: CurrencyCodesEnum.SCO,
      secondaryCurrencies: CurrencyCodesEnum.GCO,
      verificationTarget: 'email',
      country: 'US',
      ...(trackingCodes && ({
        extraInfo: trackingCodes
      })),
      ...(consentVersionIds && ({
        consentedVersions: consentVersionIds.filter((id) => id !== 14)
      }))
    };

    const { data } = await quickSignUp(propsToSignUp)
    const { status, errors } = data as OmegaApiResponse;

    if (status === OmegaApiResponseStatusEnum.ValidationFail) {
      const { field, error } = errors?.[0] as any;
      const fieldName: any = field === 'email' ? field : 'root.serverError';
      setError(fieldName, {
        type: error,
        message: omegaErrorsMapper['SIGN-UP'][error]
      });
      setFocus(fieldName);
    } else {
      onSignUp('email');
      dispatch(setPropsToSignUp(propsToSignUp));
      dispatch(openConfirmationSignUpModal());
      closeModal('sign-up');
    }
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(handleSingUp)}>
      {formState?.errors?.root?.serverError && (
        <Typography
          sx={({ colorSchemes }) => ({
            color: colorSchemes.dark.palette.common.error,
            fontWeight: 500,
            marginBottom: 2,
          })}>
          {formState?.errors?.root?.serverError?.message}
        </Typography>
      )}
      <Stack
        direction='column'
        rowGap={0.5}
        mb={1.5}>
        <Controller
          disabled={isFetching}
          name='email'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Email'
              placeholder='Enter your email'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={(args) => {
                onChange(args);
                onClearPassedError?.();
              }}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          disabled={isFetching}
          name='password'
          control={control}
          render={({ field: { value, onBlur, onChange }, fieldState }) => (
            <TextInput
              label='Password'
              type='password'
              placeholder='Enter your password'
              disabled={isFetching}
              value={value}
              onBlur={onBlur}
              onChange={(args) => {
                onChange(args);
                onClearPassedError?.();
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      </Stack>
      <Stack
        direction='column'
        gap={1}
        mb={2}>
        <Stack direction='row' gap={1}>
          <Controller
            disabled={isFetching}
            name='ageAndStateConfirmation'
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Checkbox
                disabled={isFetching}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                sx={{
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
            )}
          />
          <CheckboxFormLabel>
            I am at least 18 years old and I am not a resident of the&nbsp;<RestrictionAreasLink />
          </CheckboxFormLabel>
        </Stack>
        <Stack direction='row' gap={1}>
          <Controller
            disabled={isFetching}
            name='termsAndPrivacyConfirmation'
            control={control}
            render={({ field: { value, onBlur, onChange } }) => (
              <Checkbox
                disabled={isFetching}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                sx={{
                  textAlign: 'justify',
                  gap: 1,
                  height: 36
                }}
              />
            )}
          />
          <CheckboxFormLabel>
            I accept the SweepLuxe&nbsp;
            <TermsOfUseLink />
            &nbsp;and the&nbsp;
            <PrivacyPolicyLink />
          </CheckboxFormLabel>
        </Stack>
      </Stack>
      <Button
        disabled={
          !formState.isValid ||
          isFetching
        }
        loading={isFetching}
        fullWidth
        type='submit'>
        Sign up
      </Button>
    </Box >
  );
};
