import {
  FC
} from 'react';
import {
  type LinkProps,
  Link
} from '@shared/ui';

export const PrivacyPolicyLink: FC<LinkProps> = ({ sx, ...props }) => (
  <Link
    color='primary'
    href='/privacy-policy'
    underline='none'
    sx={[{
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px'
    },
    ...(Array.isArray(sx) ? sx : [sx])]}
    {...props}>
    Privacy Policy
  </Link>
);