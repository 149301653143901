import { useEffect, useState } from 'react';
import { ComponentType } from 'react';
import { hotjar } from 'react-hotjar';

export const withHotjar = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithHotjar = (props: P) => {
    const [isHotjarInitialized, setHotjarInitialized] = useState(false);

    useEffect(() => {
      const initializeHotjar = () => {
        hotjar.initialize(
          process.env?.REACT_APP_HJID ? Number(process.env?.REACT_APP_HJID) : 0,
          process.env?.REACT_APP_HJSV ? Number(process.env?.REACT_APP_HJSV) : 0
        );
        setHotjarInitialized(true);
        window.removeEventListener('click', initializeHotjar);
        window.removeEventListener('scroll', initializeHotjar);
      };

      if (!isHotjarInitialized) {
        window.addEventListener('click', initializeHotjar);
        window.addEventListener('scroll', initializeHotjar);
      }

      return () => {
        window.removeEventListener('click', initializeHotjar);
        window.removeEventListener('scroll', initializeHotjar);
      };
    }, [isHotjarInitialized]);

    return <WrappedComponent {...props} />;
  };

  return WithHotjar;
};
