
import { mapper } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import { type CurrencyBalance } from '../model';

type MapCurrencyBalance = (data: any) => CurrencyBalance;
const mapCurrencyBalance: MapCurrencyBalance = mapper([
  { name: 'totalBalance', from: 'totalBalance' },
  { name: 'totalBalanceNumber', from: 'totalBalanceNumber' },
  { name: 'withdrawableBalance', from: 'withdrawableBalance' },
  { name: 'withdrawableBalanceNumber', from: 'withdrawableBalanceNumber' },
  { name: 'bonusBalance', from: 'bonusBalance' },
  { name: 'bonusBalanceNumber', from: 'bonusBalanceNumber' },
  { name: 'pendingBonusBalance', from: 'pendingBonusBalance' },
  { name: 'pendingBonusBalanceNumber', from: 'pendingBonusBalanceNumber' },
  { name: 'casinoPlayableBonusBalance', from: 'casinoPlayableBonusBalance' },
  { name: 'casinoPlayableBonusBalanceNumber', from: 'casinoPlayableBonusBalanceNumber' },
  { name: 'sportsbookPlayableBonusBalance', from: 'sportsbookPlayableBonusBalance' },
  { name: 'sportsbookPlayableBonusBalanceNumber', from: 'sportsbookPlayableBonusBalanceNumber' },
  { name: 'withdrawableEscrowBalance', from: 'withdrawableEscrowBalance' },
  { name: 'withdrawableEscrowBalanceNumber', from: 'withdrawableEscrowBalanceNumber' },
  { name: 'totalWithdrawableBalance', from: 'totalWithdrawableBalance' },
  { name: 'totalWithdrawableBalanceNumber', from: 'totalWithdrawableBalanceNumber' },
  { name: 'withdrawRestrictionAmount', from: 'withdrawRestrictionAmount' },
  { name: 'withdrawRestrictionAmountNumber', from: 'withdrawRestrictionAmountNumber' },
  { name: 'preBuyBalance', from: 'preBuyBalance' },
  { name: 'preBuyBalanceNumber', from: 'preBuyBalanceNumber' },
  { name: 'currency', from: 'currency' },
  { name: 'loyaltyPoint', from: 'loyaltyPoint' },
  { name: 'totalEscrowBalance', from: 'totalEscrowBalance' },
  { name: 'totalEscrowBalanceNumber', from: 'totalEscrowBalanceNumber' },
]);

export const mapCurrencyBalances = (data: any[]): Record<CurrencyCodesEnum, CurrencyBalance> => {
  return data.reduce(
    (acc: any, el: any) => ({
      ...acc,
      [el.currency]: mapCurrencyBalance(el),
    }), {});
};