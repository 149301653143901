import { createApi } from '@reduxjs/toolkit/query/react';
import { baseOmegaQuery } from './base-omega-query';
import {
  SESSION_TAG,
  HOME_PAGE_TAG,
  DEFAULT_PAGE_LAYOUT_TAG,
  GAMES_LIST_TAG,
  FAVORITE_GAMES_TAG,
  COIN_PACKAGES_TAG,
  PAYMENT_INFO_TAG
} from './tags';

export const baseOmegaApi = createApi({
  tagTypes: [
    SESSION_TAG,
    HOME_PAGE_TAG,
    DEFAULT_PAGE_LAYOUT_TAG,
    GAMES_LIST_TAG,
    FAVORITE_GAMES_TAG,
    COIN_PACKAGES_TAG,
    PAYMENT_INFO_TAG
  ],
  reducerPath: 'omegaApi',
  baseQuery: baseOmegaQuery,
  endpoints: () => ({}),
});