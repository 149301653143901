import { useEffect } from 'react';

// URL origin for the Relax games hosted on the cloud
const RELAX_ORIGIN = 'https://d2drhksbtcqozo.cloudfront.net';

enum RelaxGameMessageEnum {
  Listening = 'gprg_Listening',
  Ready = 'oprg_Ready',
  Exit = 'gprg_ExitGame',
};

export type UseRelaxGamesToLobby = {
  handler(): void;
};

export const useRelaxGamesToLobby = ({ handler }: UseRelaxGamesToLobby) => {
  useEffect(() => {
    const handleBackToLobbyFromRelaxGame = (event: MessageEvent) => {
      if (event.origin !== RELAX_ORIGIN) return;

      switch (event.data?.rgMessage) {
        default: break;
        case RelaxGameMessageEnum.Listening:
          const gameWindow = event.source as Window;
          gameWindow.postMessage({ rgMessage: RelaxGameMessageEnum.Ready }, RELAX_ORIGIN);
          break;
        case RelaxGameMessageEnum.Exit:
          handler();
          break;
      }
    };

    window.addEventListener('message', handleBackToLobbyFromRelaxGame);
    return () => {
      window.removeEventListener('message', handleBackToLobbyFromRelaxGame);
    };
  }, [handler]);
};
