import { mapper } from '@shared/lib';
import { type SidebarMenuItem } from '../model';

type MapSidebarMenuItem = (data: any) => SidebarMenuItem;
export const mapSidebarMenuItem: MapSidebarMenuItem = mapper([
  { name: 'id', from: 'id' },
  { name: 'text', from: 'text' },
  { name: 'slug', from: 'slug' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
  { name: 'order', from: 'order' },
  { name: 'authVisibility', from: 'authVisibility' }
]);