import { mapper } from '@shared/lib';

export type QuickSignupCompletionError = {
  field: string;
  error: string;
};

type MapQuickSignupCompletionError = (data: any) => QuickSignupCompletionError;
export const mapQuickSignupCompletionError: MapQuickSignupCompletionError = mapper([
  { name: 'error', from: 'error' },
  {
    name: 'field',
    from: 'field',
    transform: (fieldData: Maybe<string>) => {
      const fieldsMatrix: Record<string, string> = {
        'mobile': 'phone',
        'phone': 'phone',
      };
      return fieldsMatrix[fieldData ?? ''] ?? fieldData;
    },
  },
]);