import { FC } from 'react';
import { CredentialResponse } from '@react-oauth/google';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import { useToaster } from '@shared/ui';
import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  SessionState,
  mapLogin
} from '@entities/session';
import { useLazySocialLoginQuery } from '../api';
import {
  GoogleLoginButton
} from './google-login-button.component'
import { SocialLogin } from '../model';

export type GoogleSignInButtonProps = {
  onSignedIn?(loginData: Pick<
    SessionState,
    'currencySessionKeys' |
    'sessionKey' |
    'shortProfile'
  >): void;
  onNotCompletedSignUpError?(props: SocialLogin): void;
  onError?(error: Maybe<string>): void;
};

export const GoogleSignInButton: FC<GoogleSignInButtonProps> = ({
  onSignedIn,
  onNotCompletedSignUpError,
  onError
}) => {
  const { error } = useToaster();
  const [socialLogin] = useLazySocialLoginQuery();

  const handleAuthenticate = async (
    credentialResponse: CredentialResponse
  ): Promise<void> => {
    onError?.(null);

    const socialLoginProps: SocialLogin = {
      socialType: 'google',
      idToken: credentialResponse?.credential,
      currency: CurrencyCodesEnum.SCO,
      secondaryCurrencies: CurrencyCodesEnum.GCO,
    };
    const { data } = await socialLogin(socialLoginProps);

    if (data?.status !== OmegaApiResponseStatusEnum.Success) {
      return onError?.(data?.message ?? 'Something went wrong');
    }

    if (data.registrationStatus === UserRegistrationStatusEnum.QuickOpen2) {
      return onNotCompletedSignUpError?.({
        ...socialLoginProps,
        email: data?.email ?? ''
      });
    }

    try {
      const loginData = mapLogin(data);
      onSignedIn?.(loginData);
    } catch {
      return error({
        message: 'Something went wrong',
        autoHideDuration: 3000
      });
    }
  };

  return (
    <GoogleLoginButton
      locale='en_US'
      shape='rectangular'
      text='signin_with'
      size='medium'
      onSuccess={handleAuthenticate}
    />
  );
};