import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum,
  UserStateEnum
} from '@shared/types';

export enum UserKycStatusEnum {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Pass = 'PASS',
  Suspended = 'SUSPENDED',
  Requested = 'REQUESTED',
  Unknown = 'N/A'
};

export enum VerificationStatusEnum {
  UnVerified = 'UNVERIFIED',
  Verified = 'VERIFIED'
};

export enum VerificationMethodEnum {
  Email = 'EMAIL',
  Sms = 'SMS'
};

export enum CurrentVIPLevelEnum {
  Level1 = 'Bronze',
  Level2 = 'Silver',
  Level3 = 'Gold',
  Level4 = 'Ruby',
  Level5 = 'Emerald',
  Level6 = 'Sapphire',
  Level7 = 'Diamond',
  Level8 = 'Platinum',
};

export type ShortProfile = {
  partyId: string;
  username: string;
  currency: CurrencyCodesEnum;
  email: Email;
  parentId: string;
  level: string;
  userType: string;
  registrationStatus: UserRegistrationStatusEnum;
  pendingLimitConfirmation: string;
  country: null;
  kycStatus: UserKycStatusEnum;
  securityVerificationRequiredFields: null;
};

export type FullProfile = {
  userId: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  nickname: Maybe<string>;
  email: Maybe<string>;
  phone: Maybe<string>;
  birthDate: Maybe<string>;
  address: Maybe<string>;
  country: Maybe<string>;
  province: Maybe<string>;
  city: Maybe<string>;
  postalCode: Maybe<string>;
  registrationStatus: UserRegistrationStatusEnum;
  kycStatus: UserKycStatusEnum;
  emailVerificationStatus: VerificationStatusEnum;
  verificationStatus: VerificationStatusEnum;
  verificationMethod: VerificationMethodEnum;
};

export type CurrencyBalance = {
  totalBalance: string;
  totalBalanceNumber: number;
  withdrawableBalance: string;
  withdrawableBalanceNumber: number;
  bonusBalance: string;
  bonusBalanceNumber: number;
  pendingBonusBalance: string;
  pendingBonusBalanceNumber: number;
  casinoPlayableBonusBalance: string;
  casinoPlayableBonusBalanceNumber: number;
  sportsbookPlayableBonusBalance: string;
  sportsbookPlayableBonusBalanceNumber: number;
  withdrawableEscrowBalance: string;
  withdrawableEscrowBalanceNumber: number;
  totalWithdrawableBalance: string;
  totalWithdrawableBalanceNumber: number;
  withdrawRestrictionAmount: string;
  withdrawRestrictionAmountNumber: number;
  preBuyBalance: string;
  preBuyBalanceNumber: number;
  currency: CurrencyCodesEnum;
  loyaltyPoint: number;
  totalEscrowBalance: string;
  totalEscrowBalanceNumber: number;
};

export type UserBalance = {
  totalBalance: string;
  totalBalanceNumber: number;
  withdrawableBalance: string;
  withdrawableBalanceNumber: number;
  bonusBalance: string;
  bonusBalanceNumber: number;
  pendingBonusBalance: string;
  pendingBonusBalanceNumber: number;
  casinoPlayableBonusBalance: string;
  casinoPlayableBonusBalanceNumber: number;
  sportsbookPlayableBonusBalance: string;
  sportsbookPlayableBonusBalanceNumber: number;
  withdrawableEscrowBalance: string;
  withdrawableEscrowBalanceNumber: number;
  totalWithdrawableBalance: string;
  totalWithdrawableBalanceNumber: number;
  withdrawRestrictionAmount: string;
  withdrawRestrictionAmountNumber: number;
  preBuyBalance: string;
  preBuyBalanceNumber: number;
  currency: CurrencyCodesEnum;
  loyaltyPoint: number;
  totalEscrowBalance: string;
  totalEscrowBalanceNumber: number;
  status: string;
  currencySymbolPosition: Maybe<string>;
  vipStatus: string;
  message: string;
  currencyBalances: Record<CurrencyCodesEnum, CurrencyBalance>;
};

export type CurrencySessionKeys = {
  [CurrencyCodesEnum.SCO]: {
    sessionKey: string;
  };
  [CurrencyCodesEnum.GCO]: {
    sessionKey: string;
  };
};

export type LoyaltyStatus = {
  accumulatedLoyaltyPoint: Maybe<number>
  currentLoyaltyPoint: Maybe<number>
  currentVIPLevel: Maybe<CurrentVIPLevelEnum>
  remainingPointsForNextLevel: Maybe<number>
  totalPointsForNextLevel: Maybe<number>
};

export type SessionState = {
  shortProfile: Maybe<ShortProfile>;
  fullProfile: Maybe<FullProfile>;
  userBalance: Maybe<UserBalance>;
  currencySessionKeys: Maybe<CurrencySessionKeys>;
  sessionKey: string;
  toggledCurrencyCode: CurrencyCodesEnum;
  userState: UserStateEnum;
  emailPromotionSubscription: boolean;
  loyaltyStatus: Maybe<LoyaltyStatus>
};
