import { baseOmegaApi, SESSION_TAG } from '@shared/api';
import {
  type GetTransactionsRequest,
  type GetTransactionsResponse
} from './types';

export const transactionsApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getTransactions: build.query<GetTransactionsResponse, GetTransactionsRequest>({
      query: (args: GetTransactionsRequest) => ({ url: 'ips/getTransactionHistoryByCurrency', params: args }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: true, auth: true }
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery
} = transactionsApiSlicer;
