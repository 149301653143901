import { mapper } from '@shared/lib';
import { type ChallengeMedia } from '../model';

type MapChallengeMedia = (data: any) => ChallengeMedia;
export const mapChallengeMedia: MapChallengeMedia = mapper([
  { name: 'imageId', from: 'imageId' },
  { name: 'imageType', from: 'imageType' },
  { name: 'mimeType', from: 'mimeType' },
  { name: 'name', from: 'name' },
  { name: 'url', from: 'url' },
]);