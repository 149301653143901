import { ComponentType } from 'react';
import { CheckTrackingCodes } from '@features/check-tracking-codes';

export const withCheckTrackingCodes =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
    (props: P) =>
    (
      <CheckTrackingCodes>
        <WrappedComponent {...props} />
      </CheckTrackingCodes>
    );
