import { FC } from 'react';
import {
  Avatar,
  Stack,
  Typography
} from '@mui/joy';
import { isDefined } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';

export type ChallengeCoinsQuantityProps = {
  value: Maybe<number>;
  currency: CurrencyCodesEnum;
};

export const ChallengeCoinsAmount: FC<ChallengeCoinsQuantityProps> = ({ value, currency }) => {
  const currentCurrency = currency === CurrencyCodesEnum.GCO
    ? 'GC'
    : 'SC';
  const currentCurrencyBadge = currency === CurrencyCodesEnum.GCO
    ? '/assets/webp/gold-star-coin.webp'
    : '/assets/webp/silver-star-coin.webp';
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='center'
      gap={1}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        gap={.5}
        sx={{
          visibility: isDefined(value) ? 'visible' : 'hidden'
        }}>
        <Avatar
          src={currentCurrencyBadge}
          sx={{
            width: 16,
            height: 16
          }}
        />
        <Typography
          sx={({ palette }) => ({
            color: palette.common.white,
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px'
          })}>
          {`${value} ${currentCurrency}`}
        </Typography>
      </Stack>
    </Stack>
  );
};