import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { challengesPageApiSlice } from '../api';
import { type ChallengesPageState } from './types';

export const CHALLENGES_PER_LOAD = 6;

const initialState: ChallengesPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  heading: null,
  visibleCompletedOrExpiredChallengesCount: CHALLENGES_PER_LOAD,
};

export const challengesPageSlice = createSlice({
  name: 'challenges-page',
  initialState,
  reducers: {
    setVisibleCompletedOrExpiredChallengesCount: (state: ChallengesPageState, action: PayloadAction<number>) => {
      state.visibleCompletedOrExpiredChallengesCount = action.payload;
    },
    resetVisibleCompletedOrExpiredChallengesCount: (state: ChallengesPageState) => {
      state.visibleCompletedOrExpiredChallengesCount = CHALLENGES_PER_LOAD;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<ChallengesPageState>) => {
    builder.addMatcher(
      challengesPageApiSlice.endpoints.getChallengesPage.matchFulfilled,
      (state: ChallengesPageState) => {
        // state.packages = action.payload.data;
      }
    );
    builder.addMatcher(
      challengesPageApiSlice.endpoints.getChallengesPage.matchFulfilled,
      (state: ChallengesPageState, action: PayloadAction<Partial<ChallengesPageState>>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.heading = action.payload?.heading;
        // state.isLoading = false;
      }
    );
    builder.addMatcher(
      challengesPageApiSlice.endpoints.getChallengesPage.matchRejected,
      (state: ChallengesPageState) => {
        // state.packages = action.payload.data;
      }
    );
  },
});

export const {
  setVisibleCompletedOrExpiredChallengesCount,
  resetVisibleCompletedOrExpiredChallengesCount
} = challengesPageSlice.actions;

export const challengesPageReducer = challengesPageSlice.reducer;
