import { mapper } from '@shared/lib';
import { Consent } from '../api';

type MapConsent = (data: any) => Consent;
export const mapConsent: MapConsent = mapper([
  { name: 'id', from: 'consent.id' },
  { name: 'key', from: 'consent.key' },
  { name: 'name', from: 'consent.name' },
  { name: 'version', from: 'consent.version' },
  { name: 'consentVersionId', from: 'consent.consentVersionId' },
  {
    name: 'consented',
    from: 'consentStatus',
    transform: (consentStatus: string) => consentStatus === 'CONSENTED',
  },
]);

type MapConsents = (data: any) => Array<Consent>;
export const mapConsents: MapConsents = (data: any) => {
  return data.userConsents.map(mapConsent);
};
