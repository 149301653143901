import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type PromotionsFilterByCategoryState } from './types';
import { promotionCategoriesApiSlice } from '../api';

export const initialState: PromotionsFilterByCategoryState = {
  selectedCategoryKey: null,
  promotionCategories: null
};

export const promotionsFilteredByCategoriesSlice = createSlice({
  name: 'promotions-filter-by-category',
  initialState,
  reducers: {
    setSelectedCategoryKey: (state: PromotionsFilterByCategoryState, action: PayloadAction<Maybe<string>>) => {
      state.selectedCategoryKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(promotionCategoriesApiSlice.endpoints.getPromotionsCategories.matchFulfilled,
      (state: PromotionsFilterByCategoryState, action: PayloadAction<Pick<PromotionsFilterByCategoryState, 'promotionCategories'>>) => {
        state.promotionCategories = action.payload.promotionCategories
        state.promotionCategories?.unshift({ id: Math.random(), name: 'View all', key: 'view-all' })
      })
  }
});

export const promotionsFilteredByCategoriesReducer = promotionsFilteredByCategoriesSlice.reducer;

export const { setSelectedCategoryKey } = promotionsFilteredByCategoriesSlice.actions;