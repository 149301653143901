import { FC } from 'react';
import { Link as JoyLink, LinkProps as JoyLinkProps } from '@mui/joy';
import { useSpecialLinkHandler } from './use-special-link-handler';

export interface LinkProps extends Pick<
  JoyLinkProps,
  'color'
  | 'children'
  | 'underline'
  | 'href'
  | 'target'
  | 'onClick'
  | 'sx'
> {
  size?: 'sm' | 'md' | 'lg';
  color?: 'primary' | 'neutral' | 'success';
  disabled?: boolean;
};

export const Link: FC<LinkProps> = ({ children, href, ...props }: LinkProps) => {
  const handleLinkClick = useSpecialLinkHandler();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    handleLinkClick(href, event, props.onClick);
  };

  return (
    <JoyLink {...props} href={href} onClick={handleClick}>
      {children}
    </JoyLink>
  );
};
