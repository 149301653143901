import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum TransactionTypeWithCurrencyEnum {
  GameBetWithGC = 'GameBetWithGC',
  GameWinGC = 'GameWinGC',
  GameBetWithSC = 'GameBetWithSC',
  GameWinSC = 'GameWinSC',
  WithdrawalWithSC = 'WithdrawalWithSC',
  DepositWithSC = 'DepositWithSC'
};

export type TransactionFilterByTypeOption = {
  label: string;
  value: string;
};

export type TransactionFilterByTypeState = {
  currentTransactionTypeWithCurrency: TransactionTypeWithCurrencyEnum;
};

const initialState: TransactionFilterByTypeState = {
  currentTransactionTypeWithCurrency: TransactionTypeWithCurrencyEnum.DepositWithSC,
};

export const transactionFilterByTypeSlice = createSlice({
  name: 'transaction-filter-by-type',
  initialState,
  reducers: {
    resetTransactionFilterByType: (state: TransactionFilterByTypeState) => {
      Object.assign(state, initialState);
    },
    setCurrentTransactionTypeWithCurrency: (
      state: TransactionFilterByTypeState,
      action: PayloadAction<TransactionTypeWithCurrencyEnum>) => {
      state.currentTransactionTypeWithCurrency = action.payload;
    },
  }
});

export const {
  setCurrentTransactionTypeWithCurrency,
  resetTransactionFilterByType,
} = transactionFilterByTypeSlice.actions;

export const transactionFilterByTypeReducer = transactionFilterByTypeSlice.reducer;
