import {
  FC,
  useMemo
} from 'react';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  mapLevelImage
} from '@entities/session';
import {
  Level
} from '../model';

export type LevelsSliderItemProps = {
  level: Level;
  isCurrent: boolean;
};

export const LevelsSliderItem: FC<LevelsSliderItemProps> = ({
  level,
  isCurrent
}) => {
  const imgSrc = useMemo(() => mapLevelImage(level.level), [level]);
  const pointsString = useMemo(() => {
    return `${level.poinst} ${level.poinst === 1 ? 'point' : 'points'}`
  }, [level]);
  return (
    <Stack
      sx={({ palette }) => ({
        position: 'relative',
        width: isCurrent ? 300 : 302,
        minHeight: 300,
        height: 'calc(100% - 1px)',
        margin: 'auto',
        marginBottom: '4px',
        padding: 2,
        paddingTop: 1,
        gap: 2,
        boxSizing: 'border-box',
        borderRadius: 8,
        border: isCurrent
          ? `1px solid ${palette.common[1090]}`
          : `1px solid ${palette.common[925]}`,
        boxShadow: isCurrent
          ? '0px 0px 8px 0px rgba(255, 181, 0, 0.51)'
          : 'none',
        background: isCurrent
          ? palette.common[910]
          : palette.common[475],
      })}>
      {isCurrent && (
        <Stack
          direction='row'
          sx={{
            position: 'absolute',
            top: 10,
            left: 8,
            paddingInline: '8px',
            paddingBlock: '6px',
            alignItems: 'center',
            gap: 0.5,
            borderRadius: 8,
            boxShadow: '0px 0px 8px 0px rgba(255, 181, 0, 0.51)',
          }}>
          <Box
            sx={({ palette }) => ({
              width: 6,
              height: 6,
              borderRadius: '50%',
              background: palette.common[1090]
            })}
          />
          <Typography
            sx={({ palette }) => ({
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '18px',
              color: palette.common[1090],
            })}>
            My level
          </Typography>
        </Stack>
      )}
      <Stack
        alignItems='center'
        gap={1.5}>
        <Box
          component='img'
          loading='lazy'
          src={imgSrc}
          sx={{
            width: 80,
            aspectRatio: '1/1',
          }}
        />
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            lineHeight: '30px',
          }}>
          {level.label}
        </Typography>
        <Stack
          direction='row'
          gap={0.5}
          alignItems='center'>
          <Box
            component='img'
            loading='lazy'
            src='/assets/webp/star.webp'
            sx={{
              width: 12,
              aspectRatio: '1/1',
            }}
          />
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 500,
              lineHeight: '18px',
            }}>
            {pointsString}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={0.5}>
        {level.benefits.map((benefite, index) => (
          <Stack
            direction='row'
            alignItems='center'
            gap={1.5}
            key={index}>
            <Box
              sx={({ palette }) => ({
                width: 8,
                height: 8,
                borderRadius: '50%',
                background: isCurrent
                  ? palette.common[1090]
                  : palette.common[700],
              })}
            />
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              {benefite}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
