import { SvgIcon, SvgIconProps } from '@mui/joy';
import { FC } from 'react';

export const CloseIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M18 6L6 18M6 6L18 18'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </SvgIcon>
  );
};
