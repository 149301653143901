import { CurrencyCodesEnum } from '@shared/types';

export enum ChallengeStatusEnum {
  Claimable = 'CLAIMABLE',
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
};

export enum ChallengeTypeEnum {
  User = 'USER',
  Game = 'GAME'
};

export enum ChallengeSpecificationEnum {
  Login = 'LOGIN',
  Bet = 'BET'
};

export enum ChallengeMediaTypeEnum {
  Badge = 'BADGE',
  Icon = 'ICON',
};

export type ChallengeMedia = {
  url: Url;
  imageId: UniqueId;
  mimeType: string;
  name: string;
  imageType: ChallengeMediaTypeEnum;
};

export type Challenge = {
  awardAmount: number;
  badge: ChallengeMedia;
  icons: ChallengeMedia;
  challengeUUID: UniqueId;
  currency: CurrencyCodesEnum;
  endDate: string;
  percentage: number;
  progress: number;
  specification: ChallengeSpecificationEnum;
  status: ChallengeStatusEnum;
  target: string;
  title: string;
  subTitle: string;
  type: ChallengeTypeEnum;
};

export type ChallengesState = {
  challenges: Array<Challenge>;
  isLoading: boolean;
};