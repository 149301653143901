import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  homePageApiSlice
} from '../api';
import {
  type HomePageState
} from './types';

const initialState: HomePageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  playInThreeSteps: null,
  slider: null,
  gamesByCategoriesBlockBottom: null,
  gamesByCategoriesBlockTop: null,
  tournamentWidgetTitle: null,
  action: null,
};

export const homePageSlice = createSlice({
  name: 'home-page',
  initialState,
  reducers: {
    initializeState: (state: HomePageState, payload: PayloadAction<HomePageState>) => {
      state = { ...payload.payload };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<HomePageState>) => {
    builder.addMatcher(
      homePageApiSlice.endpoints.getHomePage.matchFulfilled,
      (state: HomePageState, action: PayloadAction<HomePageState>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.playInThreeSteps = action.payload?.playInThreeSteps;
        state.slider = action.payload?.slider;
        state.gamesByCategoriesBlockBottom = action.payload?.gamesByCategoriesBlockBottom;
        state.gamesByCategoriesBlockTop = action.payload?.gamesByCategoriesBlockTop;
        state.tournamentWidgetTitle = action.payload.tournamentWidgetTitle;
        state.action = action.payload.action;
      }
    );
  },
});

export const {
  initializeState,
} = homePageSlice.actions;

export const homePageReducer = homePageSlice.reducer;
