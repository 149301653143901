import {
  FC,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  Stack,
  Box,
  Typography
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  selectLastUpdated,
  useGamesSelector
} from '@entities/games';
import {
  useGamesFilterByFavoriteSelector,
  selectIsFavorite,
  setIsFavorite,
  selectDisplayFavoriteFilter,
  selectIsEmptyGamesList
} from '../model';
import { fetchGamesByFavorite } from '../api';

export type SelectFavoriteProps = {
  shouldUnselectFavorite?: Maybe<boolean>;
  onSelect(): void;
  onEmptyGamesList?(): void;
};

export const SelectFavorite: FC<SelectFavoriteProps> = ({
  shouldUnselectFavorite,
  onSelect,
  onEmptyGamesList
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);
  const displayFavoriteFilter = useSelector(selectDisplayFavoriteFilter);
  const isFavorite = useGamesFilterByFavoriteSelector(selectIsFavorite);
  const isEmptyGamesList = useGamesFilterByFavoriteSelector(selectIsEmptyGamesList);
  const lastUpdated = useGamesSelector(selectLastUpdated);

  const handleClick = async (): Promise<void> => {
    await dispatch(setIsFavorite(true));
    onSelect();
  };

  useEffect(() => {
    if (shouldUnselectFavorite) dispatch(setIsFavorite(false));
    // eslint-disable-next-line
  }, [shouldUnselectFavorite]);

  useEffect(() => {
    if (isAuthenticated)
      dispatch(fetchGamesByFavorite({
        page: 1,
        pageSize: isMobile
          ? 12
          : 24
      })).unwrap();
    if (isEmptyGamesList) onEmptyGamesList?.();
    // eslint-disable-next-line
  }, [isAuthenticated, isMobile, isFavorite, lastUpdated, isEmptyGamesList, fetchGamesByFavorite]);

  return displayFavoriteFilter ? (
    <Stack
      onClick={handleClick}
      sx={(theme) => ({
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        paddingBlock: 0.25,
        paddingInline: 1,
        borderRadius: 10,
        cursor: 'pointer',
        color: isFavorite
          ? theme.palette.common.white
          : theme.palette.common[150],
        background: isFavorite
          ? theme.palette.common[300]
          : 'transparent',
        '&:hover': {
          background: isFavorite
            ? theme.palette.common[300]
            : theme.palette.common[500],
        },
      })}>
      <Box
        component='img'
        loading='lazy'
        src='/assets/webp/star.webp'
        sx={{
          width: '1rem',
          aspectRatio: '1/1'
        }} />
      <Typography>
        Favorites
      </Typography>
    </Stack>
  ) : null;
};
