import { TypedUseSelectorHook, useSelector } from "react-redux";
import { resetPasswordSlice } from "./slice"
import { ResetPasswordState } from "./types"

type RootState = {
  [resetPasswordSlice.name]: ResetPasswordState
}

export const selectEmail = (state: RootState): Maybe<string> => (
  state[resetPasswordSlice.name].email
)

export const selectMobile = (state: RootState): Maybe<string> => (
  state[resetPasswordSlice.name].mobile
)

export const selectResetPasswordKey = (state: RootState): Maybe<string> => (
  state[resetPasswordSlice.name].resetPasswordKey
)

export const useResetPasswordSelector: TypedUseSelectorHook<{
  [resetPasswordSlice.name]: ResetPasswordState;
}> = useSelector;
