import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { circularLoaderSlice, type CircularLoaderState } from './slice';

type RootState = {
  [circularLoaderSlice.name]: CircularLoaderState;
};

export const selectShowLoader = (state: RootState): boolean => (
  state[circularLoaderSlice.name].activeRequest > 0
)

export const useCircularLoaderSelector: TypedUseSelectorHook<{
  [circularLoaderSlice.name]: CircularLoaderState;
}> = useSelector;
