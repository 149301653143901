import { createSelector } from '@reduxjs/toolkit';
import {
  type UserBalance,
  selectUserBalance,
} from '@entities/session';

export const selectCurrencyBalances = createSelector(selectUserBalance,
  (userBalance: Maybe<UserBalance>) => {
    return userBalance?.currencyBalances
  }
);