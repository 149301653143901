import { createBrowserRouter } from 'react-router-dom';
import { featureToggles } from '@shared/config';
import { WithPullingUserBalance } from '@entities/session';
import { WithPullingChallenges } from '@entities/challenges';
import { Home } from '@pages/home';
import { Games } from '@pages/games';
import { Dynamic } from '@pages/dynamic';
import { Tournaments } from '@pages/tournaments';
import { SomethingWrongPage } from '@pages/something-wrong-page';
import { TournamentDetailsPage } from '@pages/tournament-details';
import { Promotions } from '@pages/promotions';
import { PromotionDetailsPage } from '@pages/promotion-details';
import { PlayGamePage } from '@pages/play-game';
import { CheckAuthStatus } from '@pages/check-auth-status';
import { LuxeClubPage } from '@pages/luxe-club';
import { Challenges } from '@pages/challenges';
import { Account } from '@pages/account';
import { AuthenticatedOnly, RedirectIfNotAuthorized } from './guards';
import { BaseLayout, GameLayout } from './layouts';

export const appRouter = createBrowserRouter([
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/',
        element: (
          <RedirectIfNotAuthorized>
            <WithPullingUserBalance>
              <Home />
            </WithPullingUserBalance>
          </RedirectIfNotAuthorized>
        )
      },
      {
        path: '/games',
        element: (
          <WithPullingUserBalance>
            <Games />
          </WithPullingUserBalance>
        )
      },
      {
        path: '/:dynamicPageName',
        element: (
          <WithPullingUserBalance>
            <Dynamic />
          </WithPullingUserBalance>
        )
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/races',
        element: (
          <WithPullingUserBalance>
            <Tournaments />
          </WithPullingUserBalance>
        ),
      },
      {
        path: '/races/:tournamentSlug',
        element: (
          <WithPullingUserBalance>
            <TournamentDetailsPage />
          </WithPullingUserBalance>
        ),
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/promotions',
        element: (
          <WithPullingUserBalance>
            <Promotions />
          </WithPullingUserBalance>
        )
      },
      {
        path: '/promotions/:promotionSlug',
        element: (
          <WithPullingUserBalance>
            <PromotionDetailsPage />
          </WithPullingUserBalance>
        ),
      },
    ],
  },
  {
    element: <GameLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/games/:id',
        element: (
          <AuthenticatedOnly>
            <WithPullingUserBalance>
              <PlayGamePage />
            </WithPullingUserBalance>
          </AuthenticatedOnly>
        ),
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/account',
        element: (
          <AuthenticatedOnly>
            <WithPullingUserBalance>
              <Account />
            </WithPullingUserBalance>
          </AuthenticatedOnly>
        ),
      },
    ],
  },
  {
    element: <BaseLayout />,
    errorElement: <SomethingWrongPage />,
    children: [
      {
        path: '/luxe-club',
        element: (
          <AuthenticatedOnly>
            <WithPullingUserBalance>
              <LuxeClubPage />
            </WithPullingUserBalance>
          </AuthenticatedOnly>
        ),
      },
    ],
  },
  ...(featureToggles.challenges ? [
    {
      element: <BaseLayout />,
      errorElement: <SomethingWrongPage />,
      children: [
        {
          path: '/challenges',
          element: (
            <AuthenticatedOnly>
              <WithPullingUserBalance>
                <WithPullingChallenges>
                  <Challenges />
                </WithPullingChallenges>
              </WithPullingUserBalance>
            </AuthenticatedOnly>
          ),
        },
      ],
    },
  ] : []),
  {
    path: '/auth-status-check-verify-secure-endpoint-for-ssr-page-authentication',
    element: (
      <CheckAuthStatus />
    )
  },
]);