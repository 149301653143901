import {
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';

export enum AccountTabEnum {
  Profile = 'PROFILE',
  Setting = 'SETTINGS',
  Docs = 'DOCUMENTS',
  Transactions = 'TRANSACTIONS'
};

export type AccountPageState = {
  tabs: Array<AccountTabEnum>;
  currentTab: AccountTabEnum;
};

const initialState: AccountPageState = {
  tabs: [
    AccountTabEnum.Profile,
    AccountTabEnum.Setting,
    AccountTabEnum.Docs,
    AccountTabEnum.Transactions
  ],
  currentTab: AccountTabEnum.Profile
};

export const accountPageSlice = createSlice({
  name: 'account-page',
  initialState,
  reducers: {
    resetAccountPage: (state: AccountPageState) => {
      Object.assign(state, initialState);
    },
    setCurrentTab: (state: AccountPageState, action: PayloadAction<AccountTabEnum>) => {
      state.currentTab = action.payload;
    },
  },
});

export const {
  setCurrentTab,
  resetAccountPage
} = accountPageSlice.actions;

export const accountPageReducer = accountPageSlice.reducer;
