import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tournamentsPageApiSlice } from '../api';
import { type TournamentsPageState } from './types';

const initialState: TournamentsPageState = {
  title: null,
  keywords: null,
  description: null,
  canonical: null,
  headerText: null,
  isLoading: false,
};

export const tournamentsPageSlice = createSlice({
  name: 'tournaments-page',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TournamentsPageState>) => {
    builder.addMatcher(
      tournamentsPageApiSlice.endpoints.getTournamentsPage.matchPending,
      (state: TournamentsPageState) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      tournamentsPageApiSlice.endpoints.getTournamentsPage.matchFulfilled,
      (state: TournamentsPageState, action: PayloadAction<TournamentsPageState>) => {
        state.title = action.payload?.title;
        state.keywords = action.payload?.keywords;
        state.description = action.payload?.description;
        state.canonical = action.payload?.canonical;
        state.headerText = action.payload?.headerText;
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      tournamentsPageApiSlice.endpoints.getTournamentsPage.matchRejected,
      (state: TournamentsPageState) => {
        state.isLoading = true;
      }
    );
  },
});

export const tournamentsPageReducer = tournamentsPageSlice.reducer;
