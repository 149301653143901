import { mapper } from '@shared/lib';
import { type GameItem } from '../model';

type MapGamesItem = (data: any) => GameItem;
export const mapGamesItem: MapGamesItem = mapper([
  { name: 'id', from: 'id' },
  { name: 'name', from: 'attributes.name' },
  { name: 'launchId', from: 'attributes.launchId' },
  { name: 'segmentId', from: 'attributes.segmentId' },
  { name: 'segmentName', from: 'attributes.segmentName' },
  { name: 'productId', from: 'attributes.productId' },
  { name: 'productCode', from: 'attributes.productCode' },
  { name: 'bannerUrl', from: 'attributes.bannerUrl.data.attributes.url' },
  { name: 'supportsMobile', from: 'attributes.supportsMobile' },
  { name: 'supportsDesktop', from: 'attributes.supportsDesktop' },
  { name: 'omegaId', from: 'attributes.omegaId' },
  { name: 'gameId', from: 'attributes.gameId' },
  { name: 'rtp', from: 'attributes.rtp' },
  { name: 'volatility', from: 'attributes.volatility' },
  { name: 'features', from: 'attributes.features' },
  { name: 'ribbon', from: 'attributes.ribbon' },
]);
