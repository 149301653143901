import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { consentsApiSlice } from '@entities/session';
import { managePromotionalSubscriptionsApiSlicer } from '../api';

export type EmailPromotionSubscriptionState = {
  emailPromotionSubscription: boolean;
  smsPromotionSubscription: boolean;
};

const initialState: EmailPromotionSubscriptionState = {
  emailPromotionSubscription: false,
  smsPromotionSubscription: false
};

export const managePromotionalSubscriptionsSlice = createSlice({
  name: 'manage-promotional-subscriptions',
  initialState,
  reducers: {
    setSmsPromotionSubscription: (state: EmailPromotionSubscriptionState, action: PayloadAction<boolean>) => {
      state.smsPromotionSubscription = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<EmailPromotionSubscriptionState>) => {
    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.getEmailSubscriptionState.matchFulfilled,
      (state, action) => {
        if (action?.payload.status === OmegaApiResponseStatusEnum.Success) {
          state.emailPromotionSubscription = action.payload.subscribeToEmail === 'TRUE'
        }
      });
    builder.addMatcher(
      managePromotionalSubscriptionsApiSlicer.endpoints.updateEmailSubscription.matchFulfilled,
      (state) => {
        state.emailPromotionSubscription = !state.emailPromotionSubscription
      });
    builder.addMatcher(
      consentsApiSlice.endpoints.getConsents.matchFulfilled,
      (state, action) => {
        state.smsPromotionSubscription = action.payload.find((consent) => consent.consentVersionId === 14)?.consented ?? false
    });
  }
});

export const managePromotionalSubscriptionsReducer = managePromotionalSubscriptionsSlice.reducer;

export const { setSmsPromotionSubscription } = managePromotionalSubscriptionsSlice.actions;
