import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from '@shared/lib';
import { CurrencyCodesEnum } from '@shared/types';
import {
  type CurrencySessionKeys,
  selectCurrencySessionKeys
} from '@entities/session';
import {
  TransactionTypeEnum
} from '@entities/transactions';
import {
  type TransactionFilterByTypeState,
  transactionFilterByTypeSlice,
  TransactionTypeWithCurrencyEnum,
} from './slice';

type RootState = {
  [transactionFilterByTypeSlice.name]: TransactionFilterByTypeState;
};

export const selectCurrentTransactionTypeWithCurrency = (state: RootState): TransactionTypeWithCurrencyEnum => (
  state[transactionFilterByTypeSlice.name].currentTransactionTypeWithCurrency
);

export const selectTransactionType = (state: RootState): TransactionTypeEnum => {
  const currentTransactionTypeWithCurrency =
    state[transactionFilterByTypeSlice.name].currentTransactionTypeWithCurrency;
  const filterTransactionTypeMatrix: Record<TransactionTypeWithCurrencyEnum, TransactionTypeEnum> = {
    [TransactionTypeWithCurrencyEnum.GameBetWithGC]: TransactionTypeEnum.GameBet,
    [TransactionTypeWithCurrencyEnum.GameWinGC]: TransactionTypeEnum.GameWin,
    [TransactionTypeWithCurrencyEnum.GameBetWithSC]: TransactionTypeEnum.GameBet,
    [TransactionTypeWithCurrencyEnum.GameWinSC]: TransactionTypeEnum.GameWin,
    [TransactionTypeWithCurrencyEnum.WithdrawalWithSC]: TransactionTypeEnum.Withdrawal,
    [TransactionTypeWithCurrencyEnum.DepositWithSC]: TransactionTypeEnum.Deposit,
  };
  return filterTransactionTypeMatrix[currentTransactionTypeWithCurrency];
}

export const selectCurrencyType = (state: RootState): string => {
  const currentTransactionTypeWithCurrency =
    state[transactionFilterByTypeSlice.name].currentTransactionTypeWithCurrency;
  const filterCurrencyTypeMatrix: Record<TransactionTypeWithCurrencyEnum, string> = {
    [TransactionTypeWithCurrencyEnum.GameBetWithGC]: CurrencyCodesEnum.GCO,
    [TransactionTypeWithCurrencyEnum.GameWinGC]: CurrencyCodesEnum.GCO,
    [TransactionTypeWithCurrencyEnum.GameBetWithSC]: CurrencyCodesEnum.SCO,
    [TransactionTypeWithCurrencyEnum.GameWinSC]: CurrencyCodesEnum.SCO,
    [TransactionTypeWithCurrencyEnum.WithdrawalWithSC]: CurrencyCodesEnum.SCO,
    [TransactionTypeWithCurrencyEnum.DepositWithSC]: CurrencyCodesEnum.SCO,
  };
  return filterCurrencyTypeMatrix[currentTransactionTypeWithCurrency];
}

export const useTransactionFilterByTypeSelector: TypedUseSelectorHook<{
  [transactionFilterByTypeSlice.name]: TransactionFilterByTypeState;
}> = useSelector;

export const selectSessionKeyByTransactionType = createSelector(
  selectCurrencySessionKeys,
  selectCurrentTransactionTypeWithCurrency,
  (currencySessionKeys: Maybe<CurrencySessionKeys>, transactionTypeWithCurrency: TransactionTypeWithCurrencyEnum) => {
    if (isEmpty(currencySessionKeys)) return null;

    const transactionTypeCurrencyMatrix: Record<TransactionTypeWithCurrencyEnum, string> = {
      [TransactionTypeWithCurrencyEnum.GameBetWithGC]:
        currencySessionKeys?.GCO.sessionKey ?? '',
      [TransactionTypeWithCurrencyEnum.GameWinGC]:
        currencySessionKeys?.GCO.sessionKey ?? '',
      [TransactionTypeWithCurrencyEnum.GameBetWithSC]:
        currencySessionKeys?.SCO.sessionKey ?? '',
      [TransactionTypeWithCurrencyEnum.GameWinSC]:
        currencySessionKeys?.SCO.sessionKey ?? '',
      [TransactionTypeWithCurrencyEnum.WithdrawalWithSC]:
        currencySessionKeys?.SCO.sessionKey ?? '',
      [TransactionTypeWithCurrencyEnum.DepositWithSC]:
        currencySessionKeys?.SCO.sessionKey ?? '',
    };

    return transactionTypeCurrencyMatrix?.[transactionTypeWithCurrency];
  });