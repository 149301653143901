import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { transactionsSlice } from './slice';
import {
  type TransactionState,
  type Transaction,
  type TransactionPagination
} from './types';

type RootState = {
  [transactionsSlice.name]: TransactionState;
};

export const selectTransactions = (state: RootState): Array<Transaction> => (
  state[transactionsSlice.name].transactions
);

export const selectPagination = (state: RootState): TransactionPagination => (
  state[transactionsSlice.name].pagination
);

export const useTransactionsSelector: TypedUseSelectorHook<{
  [transactionsSlice.name]: TransactionState;
}> = useSelector;