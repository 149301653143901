import { createAsyncThunk } from '@reduxjs/toolkit';
import { gamesSlice, type GamesState, type FavoriteGame } from '@entities/games';
import { gamesFilterByFavoriteApiSlice } from './slice';
import { GetGamesByFavorite, GetGamesByFavoriteResponse } from './types';

type RootState = {
  [gamesSlice.name]: GamesState;
};

export const fetchGamesByFavorite = createAsyncThunk<GetGamesByFavoriteResponse, GetGamesByFavorite>(
  'gamesFilterByFavorite/fetchGamesByFavorite',
  async (payload, { dispatch, rejectWithValue, fulfillWithValue, getState }) => {
    const state = getState() as RootState;
    const favoriteGamesFromOmega = state[gamesSlice.name].myGamesDataFromOmegaApi;
    const favoriteGamesQuery = favoriteGamesFromOmega.map((favoriteGame: FavoriteGame) => [
      favoriteGame.gameId,
      favoriteGame.platformCode,
    ]);
    const favoriteGamesQueryJson = JSON.stringify(favoriteGamesQuery);
    const strapiQuery = {
      favoriteGames: favoriteGamesQueryJson,
    };

    try {
      const gamesResponse = await dispatch(
        gamesFilterByFavoriteApiSlice.endpoints.getFavoriteGames.initiate(strapiQuery)
      ).unwrap();
      return fulfillWithValue({ games: gamesResponse, ...payload });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
