import { mapper } from '@shared/lib';
import { ChallengesPageState } from '../model';

type MapChallengesPageState = (data: any) => ChallengesPageState;
export const mapChallengesPageState: MapChallengesPageState = mapper([
  { name: 'title', from: 'data.attributes.title' },
  { name: 'canonical', from: 'data.attributes.canonical' },
  { name: 'keywords', from: 'data.attributes.keywords' },
  { name: 'description', from: 'data.attributes.description' },
  { name: 'heading', from: 'data.attributes.heading' },
]);
