import { isEmpty, mapper } from '@shared/lib';
import { type HtmlContent } from '../model';

export type MapHtmlContent = (data: any) => HtmlContent;
export const mapHtmlContent: MapHtmlContent = mapper([
  {
    name: 'htmlBlock',
    from: 'content',
    transform: (contentData: Array<{ htmlBlockTitle: string, htmlBlock: string }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = content.htmlBlockTitle;
      const html = content?.htmlBlock;
      return { title, html };
    }
  },
]);