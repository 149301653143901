import { mapper } from '@shared/lib';
import { type DocumentsState } from '../model';
import { mapDocumentType } from './map-document-type';

type MapDocumentTypes = (data: any) => Pick<DocumentsState, 'documentTypes'>;
export const mapDocumentTypes: MapDocumentTypes = mapper([
  {
    name: 'documentTypes',
    from: 'documentTypes',
    transform: (documentTypes: Array<any>) => documentTypes?.map(mapDocumentType) || [],
  },
]);
