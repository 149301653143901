import {
  TypedUseSelectorHook,
  useSelector
} from 'react-redux';
import {
  VerificationMethodEnum,
  SessionState,
  sessionSlice
} from '@entities/session';

type RootState = {
  [sessionSlice.name]: SessionState;
};

export const selectIsSmsVerificationMethod = (state: RootState): boolean => (
  state[sessionSlice.name]?.fullProfile?.verificationMethod === VerificationMethodEnum.Sms
);

export const useVerifyPhoneSelector: TypedUseSelectorHook<{
  [sessionSlice.name]: SessionState;
}> = useSelector;