import { CurrencyCodesEnum } from '@shared/types';
import { TransactionTypeEnum } from '../model';

export const getLabelForTransactionType = (
  transactionType: TransactionTypeEnum,
  currencyCode: CurrencyCodesEnum
): string => {
  const labelPostfixMatrix: Record<CurrencyCodesEnum, string> = {
    GCO: 'GC',
    SCO: 'SC'
  };
  const labelForTransactionTypeMatrix: Record<TransactionTypeEnum, string> = {
    [TransactionTypeEnum.GameBet]: `Bet with ${labelPostfixMatrix[currencyCode]}`,
    [TransactionTypeEnum.GameWin]: `Win with ${labelPostfixMatrix[currencyCode]}`,
    [TransactionTypeEnum.Withdrawal]: 'Redemption',
    [TransactionTypeEnum.Deposit]: 'Purchase Awards',
  };
  return labelForTransactionTypeMatrix[transactionType];
};