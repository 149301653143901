import { FC, useCallback, useEffect, useRef } from 'react';
import { Box, Stack, Typography, styled } from '@mui/joy';
import { SwiperSlide, Swiper, SwiperRef } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css';
import { useGetGameWinnerListQuery } from '@entities/game-winners';
import { useLaunchGame } from '@features/launch-game';
import { GameWinnerItem, selectGameWinners, useGameWinnersSelector } from '../model';

export const StyledSwiper = styled(Swiper)(({ theme }) => ({
  width: '100%',

  '.swiper-wrapper': {
    transitionTimingFunction: 'linear',
  },

  '.swiper-slide': {
    width: 'auto',
  },

  [theme.breakpoints.down(912)]: {
    width: 'calc(100% + 16px)',
  },
}));

export const GameWinnersCarousel: FC = () => {
  const winners = useGameWinnersSelector(selectGameWinners());
  useGetGameWinnerListQuery();
  const { launchGame } = useLaunchGame();

  const swiperRef = useRef<SwiperRef>(null);

  const getPalyerName = useCallback((winner: GameWinnerItem): string => {
    if (!winner.playerName || winner.playerName.length < 2) {
      return 'P***r just won';
    }

    return `${winner.playerName[0]}***${winner.playerName.slice(-1)} just won`;
  }, []);

  const getAmountWonString = useCallback((winner: GameWinnerItem): string => {
    return `+${winner.amountWon} ${winner.currency === 'SCO' ? 'SC' : 'GC'}`;
  }, []);

  useEffect(() => {
    if (!swiperRef.current || !winners.length) {
      return;
    }

    swiperRef.current!.swiper?.slideNext(3000);
    let isMoving = true;

    const callback = (eventName: string) => {
      if (eventName === 'touchStart') {
        isMoving = false;
      }

      if (eventName === 'touchEnd') {
        isMoving = true;
      }

      if ((eventName === 'slideNextTransitionEnd' || eventName === 'slidePrevTransitionEnd') && isMoving) {
        swiperRef.current!.swiper?.slideNext(3000);
      }

      if (eventName === 'transitionEnd' && isMoving) {
        setTimeout(() => swiperRef.current!.swiper?.slideNext(3000), 0);
      }
    }
    swiperRef.current.swiper?.onAny(callback);

    return () => {
      //eslint-disable-next-line
      swiperRef.current?.swiper?.offAny(callback);
    }
  }, [winners]);

  const handleSlideClick = (gameId: number) => () => {
    launchGame(gameId);
  }

  return winners.length ? (
    <Stack gap={2}>
      <Typography
        sx={({ palette, breakpoints }) => ({
          fontSize: 28,
          fontWeight: 600,
          lineHeight: '36px',
          color: palette.common.white,
          [breakpoints.down(912)]: {
            fontSize: 24,
            lineHeight: '30px',
          }
        })}>
        Latest winners
      </Typography>

      <StyledSwiper
        spaceBetween={12}
        slidesPerView={'auto'}
        loop
        ref={swiperRef}>
        {winners.map((winner: GameWinnerItem, index: number) => (
          <SwiperSlide key={index}>
            <Stack
              onClick={handleSlideClick(winner.gameId)}
              direction='row'
              alignItems='center'
              sx={({ palette }) => ({
                width: 311,
                paddingBlock: 1,
                paddingInline: 1.5,
                gap: 2,
                background: palette.common[475],
                borderRadius: 8,
                boxSizing: 'border-box',
                border: `1px solid ${palette.common[300]}`,
                cursor: 'pointer',
                ':hover': {
                  border: `1px solid ${palette.common[875]}`,
                }
              })}>
              <Box
                sx={{
                  height: 75,
                  width: 100,
                  background: `url(${winner.gameBannerUrl}), lightgray 50% / cover no-repeat`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 4,
                }} />

              <Stack gap={0.5}>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common.white,
                  })}>
                  {getPalyerName(winner)}
                </Typography>

                <Typography
                  sx={({ palette }) => ({
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[825],
                  })}>
                  {getAmountWonString(winner)}
                </Typography>
              </Stack>
            </Stack>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Stack>
  )
    : null;
};
