import {
  BaseSyntheticEvent,
  FC
} from 'react'
import {
  IconButton,
  Modal,
  ModalDialog,
  Stack,
  Typography
} from '@mui/joy';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {
  selectFullProfile,
  useLazyGetPlayerInfoQuery,
  useLazySendVerifyConfirmationQuery,
  useLazyVerifyConfirmationQuery,
  useSessionSelector
} from '@entities/session';
import { isEmpty, otpSchema } from '@shared/lib';
import {
  useToaster,
  OtpInput,
  CloseIcon,
  Button,
  Link
} from '@shared/ui';
import {
  OmegaApiResponse,
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper
} from '@shared/api';

export type ConfirmPhoneChangeModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ConfirmPhoneChangeModal: FC<ConfirmPhoneChangeModalProps> = ({ open, onClose }) => {
  const toaster = useToaster()
  const profile = useSessionSelector(selectFullProfile)

  const [
    verifyConfirmation,
    { isFetching: isFetchingVerifyConfirmation }
  ] = useLazyVerifyConfirmationQuery();
  const [
    sendVerifyConfirmation,
    { isFetching: isFetchingSendVerifyConfirmation }
  ] = useLazySendVerifyConfirmationQuery();
  const [
    getPlayerInfo,
    { isFetching: isFetchingGetProfile }
  ] = useLazyGetPlayerInfoQuery();

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    setFocus,
  } = useForm({
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { otp: '' },
  })

  const handleSendConfirmationCode = async (
    formData: { otp: string },
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    if (!profile?.phone) return;

    const { data: verifyConfirmationData } = await verifyConfirmation({
      confirmationCode: formData.otp,
      mobile: profile?.phone,
      type: 'mobile'
    } as any);
    const { status: verifyConfirmationStatus } = verifyConfirmationData as OmegaApiResponse;
    if (verifyConfirmationStatus === OmegaApiResponseStatusEnum.Fail) {
      return toaster.error({
        message: verifyConfirmationData?.message ?? 'Something went wrong'
      });
    }

    const { data: getPlayerInfoData } = await getPlayerInfo();
    if (getPlayerInfoData?.status !== OmegaApiResponseStatusEnum.Success) {
      return toaster.error({ message: getPlayerInfoData?.message ?? 'Something went wrong' });
    }

    onClose();
  };

  const handleSendNewOtpCode = async (): Promise<void> => {
    if (!profile?.userId) return;

    reset({});

    const { data } = await sendVerifyConfirmation({
      mobile: profile.phone,
      verificationTarget: 'mobile',
      username: profile.userId
    } as any);

    const { errors } = data as OmegaApiResponse;

    if (!isEmpty(errors)) {
      const { error } = errors?.[0] as any;
      const fieldName = 'root.serverError';
      const type = error;
      const message = omegaErrorsMapper['CONFIRMATION-SIGN-UP-MODAL'][type];
      setError(fieldName, { type, message });
      setFocus(fieldName as any);
    } else {
      toaster.success({ message: 'New code sent successfully' });
    }
  };

  const handleFocus = (): void => {
    if (formState.errors.otp || formState.errors.root) {
      reset({});
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints }) => ({
          maxWidth: 358,
          width: '100%',
          paddingBlock: 3,
          paddingInline: 3,
          [breakpoints.down(390)]: {
            maxWidth: 'calc(100vw - 2rem)',
          },
        })}>
        <Stack
          component='form'
          mb='1rem'>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            mb='0.75rem'>
            <Stack direction='row'
              gap='0.5rem'
              alignItems='center'>
              <Typography sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: '1.125rem',
                fontWeight: 600,
                lineHeight: 'normal',
              })}>
                Confirm phone number
              </Typography>
            </Stack>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography sx={({ palette }) => ({
            color: palette.common[150],
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
          })}>
            Please enter the code received in order to confirm your phone number.
          </Typography>
        </Stack>
        <Stack component='form' gap='1rem'>
          <Controller
            control={control}
            disabled={isFetchingVerifyConfirmation || isFetchingSendVerifyConfirmation || isFetchingGetProfile}
            name='otp'
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <OtpInput
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={handleFocus}
                error={fieldState.error?.message}
              />
            )}
          />
          <Stack
            alignItems='center'
            gap='0.625rem'>
            <Typography sx={({ palette }) => ({
              color: palette.common.white
            })}>
              Didn't receive a code?
            </Typography>
            <Link
              color='primary'
              underline='always'
              disabled={
                isFetchingVerifyConfirmation ||
                isFetchingSendVerifyConfirmation ||
                isFetchingGetProfile
              }
              onClick={handleSendNewOtpCode}>
              Send new OTP
            </Link>
          </Stack>
          <Button
            color='primary'
            variant='solid'
            type='submit'
            fullWidth
            loading={
              isFetchingVerifyConfirmation ||
              isFetchingGetProfile
            }
            disabled={
              !formState.isDirty ||
              !formState.isValid ||
              isFetchingGetProfile ||
              isFetchingVerifyConfirmation ||
              isFetchingSendVerifyConfirmation
            }
            onClick={handleSubmit(handleSendConfirmationCode)}>
            Submit
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};