import {
  type Environment,
  type IPiqCashierConfig
} from 'paymentiq-cashier-bootstrapper';

type MapRedeemCashierConfig = {
  merchantId: string;
  sessionId: string;
  environment: Environment;
  userId: string;
  containerHeight: string;
  containerWidth: string;
  amount: string;
};

export const mapRedeemCashierConfig = ({ ...props }: MapRedeemCashierConfig) => {
  const config: IPiqCashierConfig = {
    globalSubmit: 'true',
    showTransactionOverview: 'true',
    showReceipt: 'true',
    mode: 'ecommerce',
    saveAccountOption: 'true',
    scrollToOffset: 8,
    showAccounts: 'inline',
    defaultLoader: 'true',
    showFooter: 'false',
    showListHeaders: 'true',
    fetchConfig: 'true',
    method: 'withdrawal',
    locale: 'en_US',
    ...props
  };
  return config;
};