import {
  FC,
  useMemo
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  Box
} from '@mui/joy';
import {
  MenuIcon
} from '@shared/ui';
import {
  selectStickyNavigation,
  useLayoutSelector
} from '@entities/page-layout';
import {
  selectIsAuthenticated,
  useSessionSelector
} from '@entities/session';
import {
  openSidebarMenu
} from '../model';
import {
  StickyNavigationItem
} from './sticky-navigation-menu-item.component';
import {
  StickyNavigationCenterButton
} from './sticky-navigation-center-button.component';

export const StickyNavigation: FC = () => {
  const dispatch = useDispatch();
  const stickyNavigation = useLayoutSelector(selectStickyNavigation);
  const isAuthenticated = useSessionSelector(selectIsAuthenticated);

  const { centerItem, navigationItems } = useMemo(() => {
    const menuItems = isAuthenticated
      ? stickyNavigation?.menuItemsAuth
      : stickyNavigation?.menuItemsNotAuth;

    const centerItem = menuItems?.find((item) => item.centerButton);
    const navigationItems = menuItems?.filter((item) => !item.centerButton) ?? [];

    return { centerItem, navigationItems };
  }, [isAuthenticated, stickyNavigation]);

  const halfLength = Math.ceil(navigationItems.length / 2);

  const handleOpenMenu = () => {
    dispatch(openSidebarMenu());
  };

  return (
    <Box
      sx={({ palette, breakpoints }) => ({
        zIndex: 5,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 3rem 1fr',
        paddingInline: '1rem',
        justifyContent: 'space-between',
        background: palette.common[900],
        [breakpoints.up(912)]: { display: 'none' },
      })}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}>
        {navigationItems
          .slice(0, halfLength)
          .map(({
            id,
            slug,
            text,
            iconSrc
          }) => (
            <StickyNavigationItem
              key={id}
              url={slug}
              text={text}
              icon={
                <Box
                  component='img'
                  loading='lazy'
                  src={iconSrc}
                  sx={{
                    height: '1.125rem',
                    width: '1.125rem',
                    margin: '0.3125rem',
                  }}
                />
              }
            />
          ))}
      </Box>
      {centerItem ? (
        <StickyNavigationCenterButton
          key={centerItem.id}
          url={centerItem.slug}
          text={centerItem.text}
          src={centerItem.iconSrc}
        />
      ) : null}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}>
        {navigationItems
          .slice(halfLength)
          .map(({
            id,
            slug,
            text,
            iconSrc
          }) => (
            <StickyNavigationItem
              key={id}
              url={slug}
              text={text}
              icon={
                <Box
                  component='img'
                  loading='lazy'
                  src={iconSrc}
                  sx={{
                    height: '1.125rem',
                    width: '1.125rem',
                    margin: '0.3125rem',
                  }}
                />
              }
            />
          ))}
        <StickyNavigationItem
          onClick={handleOpenMenu}
          text='Menu'
          icon={
            <MenuIcon
              sx={({ palette }) => ({
                fontSize: '1.125rem',
                margin: '0.3125rem',
                color: palette.common[1065]
              })}
            />
          }
        />
      </Box>
    </Box>
  );
};
