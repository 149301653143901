import { FC, forwardRef } from 'react';
import {
  IconButtonProps as JoyIconButtonProps,
  IconButton as JoyIconButton,
} from '@mui/joy';

export interface IconButtonProps extends JoyIconButtonProps { }

export const IconButton: FC<IconButtonProps> = forwardRef((props, ref) => (
  <JoyIconButton {...props} ref={ref} />
));
