import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Stack } from '@mui/joy';
import { CarouselSlider } from '@shared/ui';
import { FilterOperationEnum } from '@shared/lib';
import { useGoogleAds, useSendDataToGtm } from '@features/collect-analytics';
import { FaqBlock } from '@widgets/faq-block';
import { MetaTagsBlock } from '@widgets/meta-tags-block';
import { HowItWorksBlock } from '@widgets/how-it-works-block';
import { QualifyingGamesBlock } from '@widgets/qualifying-games-block';
import { useGetPromotionDetailsPageQuery } from '../api';
import {
  selectMetaTagTitle,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagCanonical,
  selectFaqBlock,
  selectHowItWorksBlock,
  selectAction,
  selectSlider,
  usePromotionDetailsPageSelector,
  selectGames,
} from '../model';

export const PromotionDetailsPage: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'promotion-details-page'
    }
  });
  const { promotionSlug } = useParams();
  const { isFetching } = useGetPromotionDetailsPageQuery({
    slug: { [FilterOperationEnum.Equals]: promotionSlug }
  }, {
    refetchOnMountOrArgChange: true
  });

  const title = usePromotionDetailsPageSelector(selectMetaTagTitle);
  const keywords = usePromotionDetailsPageSelector(selectMetaTagKeywords);
  const description = usePromotionDetailsPageSelector(selectMetaTagDescription);
  const canonical = usePromotionDetailsPageSelector(selectMetaTagCanonical);
  const howItWorksBlock = usePromotionDetailsPageSelector(selectHowItWorksBlock);
  const faqBlock = usePromotionDetailsPageSelector(selectFaqBlock);
  const slider = useSelector(selectSlider);
  const action = useSelector(selectAction);
  const qualifyingGames = useSelector(selectGames);

  return !isFetching && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockStart: 4,
          paddingBlockEnd: 8,
          [breakpoints.down(1350)]: {
            paddingInline: 2,
          },
          [breakpoints.down(414)]: {
            paddingBlockEnd: 3,
            paddingBlockStart: 2,
          }
        })}>
        {slider && (
          <Stack
            direction='column'
            sx={({ breakpoints }) => ({
              mb: 8,
              [breakpoints.down(414)]: {
                mb: 6,
              },
              '& .swiper-slide': {
                borderRadius: 8,
                overflow: 'hidden'
              }
            })}>
            <CarouselSlider items={slider} />
          </Stack>
        )}
        {howItWorksBlock && (
          <HowItWorksBlock
            title={howItWorksBlock.title}
            items={howItWorksBlock.items}
          />
        )}
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            width: '100%',
            marginTop: 1.5
          }}>
          {action && (
            <Button
              component={Link}
              to={action.url}
              color='primary'
              sx={({ breakpoints }) => ({
                height: 36,
                [breakpoints.down(390)]: {
                  width: '100%'
                }
              })}>
              {action.text}
            </Button>
          )}
        </Stack>
        {qualifyingGames && (<QualifyingGamesBlock games={qualifyingGames} isGamesLoading={isFetching} />)}
        {faqBlock && (
          <Stack
            direction='column'
            sx={({ breakpoints }) => ({
              mt: 3,
              [breakpoints.down(414)]: {
                mt: 2,
              }
            })}>
            <FaqBlock
              items={faqBlock.items}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};