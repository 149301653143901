import { mapper } from '@shared/lib';
import { Consent } from '../api';

type MapPublicConsent = (data: any) => Consent;
export const mapPublicConsent: MapPublicConsent = mapper([
  { name: 'id', from: 'id' },
  { name: 'key', from: 'key' },
  { name: 'name', from: 'name' },
  { name: 'version', from: 'version' },
  { name: 'consentVersionId', from: 'consentVersionId' },
]);

type MapPublicConsents = (data: any) => Array<Consent>;
export const mapPublicConsents: MapPublicConsents = (data: any) => {
  return data.consents.map(mapPublicConsent);
};
