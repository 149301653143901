import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pageLayoutApiSlice } from '../api';
import { mapLayoutState } from '../lib';
import { type LayoutState } from './types';

export const initialState: LayoutState = {
  header: null,
  footer: null,
  sidebarMenu: null,
  stickyNavigation: null,
};

export const pageLayoutSlice = createSlice({
  name: 'page-layout',
  initialState,
  reducers: {
    setLayout: (state: LayoutState, action: PayloadAction<LayoutState>) => {
      state.header = action.payload.header;
      state.footer = action.payload.footer;
      state.sidebarMenu = action.payload.sidebarMenu;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pageLayoutApiSlice.endpoints.getPageLayout.matchFulfilled,
      (state: LayoutState, action: PayloadAction<any>) => {
        const mappedState = mapLayoutState(action.payload);
        state.header = mappedState.header;
        state.footer = mappedState.footer;
        state.sidebarMenu = mappedState.sidebarMenu;
        state.stickyNavigation = mappedState.stickyNavigation;
      }
    );
  },
});

export const {
  setLayout
} = pageLayoutSlice.actions;

export const pageLayoutReducer = pageLayoutSlice.reducer;