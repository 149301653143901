import { Filter, FilterOperationEnum, FilterValue } from "./types";

export const buildStrapiQuery = (filter: Filter): string => {
  const buildCriteria = (key: string, value: FilterValue): string => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      return Object.entries(value)
        .map(([operation, opValue]) => {
          if (operation === FilterOperationEnum.Equals) {
            return `filters[${key}]=${encodeURIComponent(String(opValue))}`;
          } else {
            return `filters[${key}][${operation}]=${encodeURIComponent(String(opValue))}`;
          }
        })
        .join('&');
    }
    return `filters[${key}]=${encodeURIComponent(String(value))}`;
  };

  return Object.entries(filter)
    .map(([key, value]) => buildCriteria(key, value))
    .join('&');
};
