export enum TournamentStatusEnum {
  Pending = 'PENDING',
  Active = 'ACTIVE',
};

export enum AwardType {
  Credit = 'CREDIT'
};

export type AdditionalAward = {
  awardId: Maybe<number>;
  awardSubType: unknown;
  awardType: AwardType;
  bonusPlanId: number;
  amountWithCurrency: string;
  id: number;
};

export type BonusPlan = {
  planId: Maybe<number>;
  planName: Maybe<string>;
  playable: boolean;
  bonusAmount: Maybe<number>;
  bonusAmounts: any;
  currency: string[];
  description: Maybe<string>;
  imageUrl: Maybe<string>;
  startDate: Maybe<string>;
  endDate: Maybe<string>;
  paymentSubmethod: any;
  additionalAward: Maybe<AdditionalAward>;
};

export type TournamentAward = {
  id: number;
  type: Maybe<string>;
  noOfFreespin: Maybe<number>;
  loyaltyPoints: Maybe<number>;
  description: Maybe<string>;
  createdDate: Maybe<string>;
  lastModifiedDate: Maybe<string>;
  tournamentId: Maybe<number>;
  bonusPlanId: Maybe<number>;
  bonusPlan: BonusPlan;
  gameInfoId: any;
  awardRuleId: Maybe<number>;
  rankStart: Maybe<number>;
  rankEnd: Maybe<number>;
  rankType: Maybe<string>;
};

export type Tournament = {
  name: string;
  description: string;
  imageSrc: string;
  imageUrl?: string;
  prizeGC: number;
  prizeSC: number;
  startDate: string;
  endDate: string;
  includedTags: Maybe<Array<string>>;
  excludedTags: Maybe<Array<string>>;
  status: TournamentStatusEnum;
  buttonText: string;
  buttonUrl: string;
  awards: Maybe<Array<TournamentAward>>;
};

export type TournamentRanking = {
  id: number;
  tournamentId: number;
  partyId: number;
  points: number;
  ranking: number;
  rankingLast: number;
  rankingDate: string;
  awarded: null;
  awardedDate: null;
  amountBet: number;
  amountWin: number;
  betCount: number;
  winCount: number;
  userId: string;
  currency: string;
  nickname: string;
};

export type TournamentsState = {
  isLoading: boolean;
  publicTournaments: Array<Tournament>;
  tournamentRankings: Array<TournamentRanking>;
};

export type TournamentsPayload = {
  tournaments: Array<Tournament>;
  vipStatus?: string;
  isAuthenticated?: boolean;
}