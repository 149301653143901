import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/joy';

type StickyNavigationCenterButtonProps = {
  url: Url;
  text: string;
  src: Url;
};

export const StickyNavigationCenterButton: FC<StickyNavigationCenterButtonProps> = ({
  url,
  text,
  src
}) => {
  const navigate = useNavigate();
  const handleClick = (): void => {
    if (url) return navigate(url);
  };
  return (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        padding: '0.625rem 1rem',
        alignItems: 'center',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 0,
      })}>
      <Button
        onClick={handleClick}
        sx={{
          borderRadius: '50%',
          position: 'absolute',
          height: 48,
          width: 48,
          mb: '1.625rem',
          p: '0.625rem',
        }}>
        <Box
          component='img'
          loading='lazy'
          src={src}
          sx={{
            width: 25.67,
            height: 25.67,
          }}
        />
      </Button>
      <Typography sx={(theme) => ({
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '1.125rem',
        color: theme.colorSchemes.dark.palette.common.white,
      })}>
        {text}
      </Typography>
    </Box>
  );
};
