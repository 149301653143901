import { FC } from 'react';
import {
  ListItemButton as JoyListItemButton,
  ListItemButtonProps as JoyListItemButtonProps
} from '@mui/joy';

export type ListItemButtonProps = JoyListItemButtonProps & {
  variant?: 'soft' | 'plain';
  color?: 'neutral';
};

export const ListItemButton: FC<ListItemButtonProps> = ({ ...props }) => (
  <JoyListItemButton {...props} />
);