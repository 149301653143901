import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export type TransactionListState = {
  startDate: string;
  endDate: string;
};

const initialState: TransactionListState = {
  startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
  endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
};

export const transactionListSlice = createSlice({
  name: 'transaction-list',
  initialState,
  reducers: {
    resetTransactionList: (state: TransactionListState) => {
      Object.assign(state, initialState);
    },
    setStartDate: (state: TransactionListState, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setEndDate: (state: TransactionListState, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    },
  }
});

export const {
  setStartDate,
  setEndDate,
  resetTransactionList,
} = transactionListSlice.actions;
export const transactionListReducer = transactionListSlice.reducer;
