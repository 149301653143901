export enum GameItemLabelEnum {
  Top = 'Top',
  Hot = 'Hot',
  New = 'New',
};

export enum GameItemRibbonEnum {
  New = 'NEW',
  Luxe = 'LUXE'
};

export type GameItem = {
  id: number;
  name: string;
  launchId: string;
  segmentId: number;
  segmentName: string;
  productId: number;
  productCode: string;
  supportsMobile: boolean;
  supportsDesktop: boolean;
  bannerUrl: Url;
  omegaId: number;
  gameId: string;
  ribbon?: GameItemRibbonEnum;
  features?: Maybe<string>;
  volatility?: Maybe<string>;
  rtp?: Maybe<string>;
};

export type Pagination = {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
};

export type GamesWithPagination = {
  games: Array<GameItem>;
  pagination: Maybe<Pagination>;
};

export type GameCategory = {
  id: number;
  name: string;
  key: string;
  iconSrc: Url;
  games: Array<GameItem>;
};

export type FavoriteGame = {
  gameId: string;
  platformCode: string;
}

export type GamesState = {
  categories: Array<GameCategory>;
  myGamesDataFromOmegaApi: Array<FavoriteGame>;
  isFavoriteLoadingFromOmega: boolean;
  gamesPageCategoryIds: Array<number>;
  lastUpdated: Maybe<string>;
};
