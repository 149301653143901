import { FC, RefObject } from 'react';
import { Box, Stack, Typography } from '@mui/joy';
import 'swiper/css/pagination';
import 'swiper/css';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  IconButton
} from '@shared/ui';

export type QualifyingGamesHeaderProps = {
  navigationNextRef: RefObject<HTMLButtonElement>;
  navigationPrevRef: RefObject<HTMLButtonElement>;
};

export const QualifyingGamesHeader: FC<QualifyingGamesHeaderProps> = ({
  navigationNextRef,
  navigationPrevRef
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));
  return (
    <Stack
      direction='row'
      justifyContent='space-between'>
      <Stack
        direction='row'
        gap={1.5}>
        <Stack
          direction='row'
          alignItems='center'
          gap={0.5}>
          <Box 
            component='img'
            src='./assets/webp/race_gold_cup.webp' 
            sx={({ breakpoints }) => ({
              width: '32px',
              aspectRatio: '1/1',
              [breakpoints.down(912)]: {
                width: '24px',
              },
            })}/>
          <Typography
            sx={({ palette, breakpoints }) => ({
              fontSize: '28px',
              fontWeight: 600,
              fontStyle: 'normal',
              lineHeight: '36px',
              color: palette.common.white,
              [breakpoints.down(912)]: {
                fontSize: '24px',
                lineHeight: '30px',
              },
            })}>
            Qualifying Games
          </Typography>
        </Stack>
        {!isMobile &&
          <Stack
            direction='row'
            alignItems='center'
            sx={{ gap: '16px' }}>
            <IconButton
              ref={navigationPrevRef}
              sx={({ palette }) => ({
                background: 'transparent',
                color: palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              ref={navigationNextRef}
              sx={({ palette }) => ({
                background: 'transparent',
                color: palette.common.white,
                minWidth: '20px',
                minHeight: '20px',
                padding: 0,
                '&:disabled': {
                  background: 'transparent',
                },
              })}
              variant='soft'>
              <ArrowRightIcon />
            </IconButton>
          </Stack>
        }
      </Stack>
    </Stack>
  );
};
