import { createSelector } from 'reselect';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  type ChallengesState,
  ChallengeStatusEnum,
  challengesSlice
} from '@entities/challenges';
import { type ChallengesPageState } from './types';
import { challengesPageSlice } from './slice';
// import { dummyData } from './dummy-data';

dayjs.extend(utc);

type RootState = {
  [challengesSlice.name]: ChallengesState;
  [challengesPageSlice.name]: ChallengesPageState;
};

export const selectMetaTagKeywords = (state: RootState): Maybe<string> => (
  state[challengesPageSlice.name]?.keywords
);

export const selectMetaTagTitle = (state: RootState): Maybe<string> => (
  state[challengesPageSlice.name]?.title
);

export const selectMetaTagDescription = (state: RootState): Maybe<string> => (
  state[challengesPageSlice.name]?.description
);

export const selectMetaTagCanonical = (state: RootState): Maybe<string> => (
  state[challengesPageSlice.name]?.canonical
);

export const selectVisibleCompletedOrExpiredChallengesCount = (state: RootState) => (
  state[challengesPageSlice.name].visibleCompletedOrExpiredChallengesCount
);

export const selectPageHeading = (state: RootState) => (
  state[challengesPageSlice.name].heading
);

export const selectIsLoading = (state: RootState): boolean => (
  state[challengesSlice.name].isLoading
);

export const selectChallenges = (state: RootState) => (
  state[challengesSlice.name].challenges
  // dummyData
);

export const selectActiveChallenges = createSelector(
  [selectChallenges],
  (challenges) =>
    challenges.filter(({ status, endDate }) =>
      status === ChallengeStatusEnum.Active &&
      dayjs(endDate).utc().isAfter(dayjs().utc())
    )
);

export const selectCompletedAndExpiredChallenges = createSelector(
  [selectChallenges],
  (challenges) =>
    challenges.filter(({ status, endDate }) =>
      status !== ChallengeStatusEnum.Active &&
      dayjs(endDate).utc().isAfter(dayjs().utc())
    )
);

export const selectVisibleLoadMoreButton = createSelector(
  [selectCompletedAndExpiredChallenges, selectVisibleCompletedOrExpiredChallengesCount],
  (challenges, visibleCompletedOrExpiredChallengesCount) =>
    challenges.length > visibleCompletedOrExpiredChallengesCount
  // dummyData.length > visibleCompletedOrExpiredChallengesCount
);

export const useChallengesPageSelector: TypedUseSelectorHook<RootState> = useSelector;
