import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  GetGamesByFavoriteResponse,
  fetchGamesByFavorite
} from '../api';
import {
  type GamesFilterByFavoriteState
} from './types';

export const initialState: GamesFilterByFavoriteState = {
  games: [],
  pagination: null,
  isFavorite: false,
  isLoading: false,
};

export const gamesFilteredByFavoriteSlice = createSlice({
  name: 'games-filter-by-favorite',
  initialState,
  reducers: {
    setIsFavorite: (state: GamesFilterByFavoriteState, action: PayloadAction<boolean>) => {
      state.isFavorite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamesByFavorite.pending, (state: GamesFilterByFavoriteState) => {
        state.isLoading = true;
      })
      .addCase(
        fetchGamesByFavorite.fulfilled,
        (state: GamesFilterByFavoriteState, action: PayloadAction<GetGamesByFavoriteResponse>) => {
          const { games, page, pageSize } = action.payload;

          state.games = games.slice(0, page * pageSize);
          state.pagination = {
            page,
            pageSize,
            pageCount: Math.ceil(games.length / pageSize),
            total: games.length,
          };
          state.isLoading = false;
        }
      );
  },
});

export const gamesFilteredByFavoriteReducer = gamesFilteredByFavoriteSlice.reducer;

export const { setIsFavorite } = gamesFilteredByFavoriteSlice.actions;
