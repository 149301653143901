import { createAsyncThunk } from '@reduxjs/toolkit';
import { GamesWithPagination } from '@entities/games';
import { gamesFilterBySearchApiSlice } from './slice';
import { type GetGamesBySearch } from './types';

export const fetchGamesBySearch = createAsyncThunk<GamesWithPagination, GetGamesBySearch>(
  'gamesFilterByCategory/fetchGamesByCategory',
  async (payload: GetGamesBySearch, { dispatch, rejectWithValue, fulfillWithValue }) => {
    const strapiQuery = {
      'pagination[page]': payload.page,
      'pagination[pageSize]': payload.pageSize,
      ...(payload.search && {
        'filters[name][$containsi]': payload.search,
        'filters[gameCategories][id][$notNull]': true
      }),
      'filters[status][$eq]': 'ACTIVE',
    };

    try {
      const gamesResponse = await dispatch(
        gamesFilterBySearchApiSlice.endpoints.getGames.initiate(strapiQuery)
      ).unwrap();
      return fulfillWithValue(gamesResponse);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
