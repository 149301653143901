import {
  FC,
  ReactNode
} from 'react';
import {
  Divider,
  Typography,
  Stack,
} from '@mui/joy';
import {
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  AppLogo
} from '@shared/ui';
import {
  useLayoutSelector,
  selectPageFooterVariant1LogoImgSrc,
  selectPageFooterNavigationItems,
  selectPageFooterPromotionLegalNote
} from '@entities/page-layout';
import {
  NavigationSection
} from './navigation-section.component';

export type LayoutFooterProps = {
  followUsSlot?: ReactNode;
};

export const LayoutFooter: FC<LayoutFooterProps> = ({ followUsSlot }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(912));
  const variant1LogoImgSrc = useLayoutSelector(selectPageFooterVariant1LogoImgSrc);
  const navigationItems = useLayoutSelector(selectPageFooterNavigationItems);
  const promotionLegalNote = useLayoutSelector(selectPageFooterPromotionLegalNote);
  return (
    <Stack
      component='footer'
      gap={3}
      sx={({ breakpoints, colorSchemes }) => ({
        width: '100%',
        maxWidth: 1280,
        paddingBlock: 6,
        backgroundColor: colorSchemes.dark.palette.common[475],
        [breakpoints.down(913)]: {
          paddingBlock: 3,
        },
      })}>
      <Stack
        direction='column'
        gap={2}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            gap: 2,
            width: '100%',
            ...(isMobile && ({
              flexDirection: 'column',
              gap: 1,
            }))
          }}>
          {variant1LogoImgSrc ? (
            <AppLogo
              src={variant1LogoImgSrc}
              sx={{
                width: 210,
                height: 35.4,
                boxSizing: 'content-box',
              }}
            />
          ) : null}
          {followUsSlot}
        </Stack>
        {isMobile ? (
          <>
            <Stack direction='column' gap={2}>
              <NavigationSection data={navigationItems} />
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
            </Stack>
            <Stack
              direction='column'
              gap={2}
              width='100%'>
              <Typography
                sx={({ palette }) => ({
                  width: 'fit-content',
                  borderRadius: 8,
                  padding: .5,
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  color: palette.common[300],
                  backgroundColor: palette.common[900],
                })}>
                +18
              </Typography>
              <Divider />
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common[150]
                  })}>
                  &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                </Typography>
              </Stack>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              direction='column'
              gap={2}>
              {promotionLegalNote && (
                <Typography
                  sx={({ palette }) => ({
                    maxWidth: 677,
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                    color: palette.common[150]
                  })}>
                  {promotionLegalNote}
                </Typography>
              )}
              <NavigationSection data={navigationItems} />
            </Stack>
            <Stack
              direction='column'
              gap={4}
              width='100%'>
              <Divider />
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'>
                <Typography
                  sx={({ palette }) => ({
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    color: palette.common[150]
                  })}>
                  &copy; {new Date().getFullYear()} SweepLuxe. All rights reserved.
                </Typography>
                <Typography
                  sx={({ palette }) => ({
                    borderRadius: 8,
                    padding: .5,
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 500,
                    color: palette.common[300],
                    backgroundColor: palette.common[900],
                  })}>
                  +18
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Stack >
  );
};