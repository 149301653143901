import { CurrentVIPLevelEnum } from '../model'

export const mapLevelImage = (level: Maybe<CurrentVIPLevelEnum>): string => {
  const levelImageMap = {
    [CurrentVIPLevelEnum.Level1]: '/assets/webp/level1.webp',
    [CurrentVIPLevelEnum.Level2]: '/assets/webp/level2.webp',
    [CurrentVIPLevelEnum.Level3]: '/assets/webp/level3.webp',
    [CurrentVIPLevelEnum.Level4]: '/assets/webp/level4.webp',
    [CurrentVIPLevelEnum.Level5]: '/assets/webp/level5.webp',
    [CurrentVIPLevelEnum.Level6]: '/assets/webp/level6.webp',
    [CurrentVIPLevelEnum.Level7]: '/assets/webp/level7.webp',
    [CurrentVIPLevelEnum.Level8]: '/assets/webp/level8.webp',
  }
  if (!level) return levelImageMap[CurrentVIPLevelEnum.Level1];
  const src = levelImageMap[level]
  return src ? src : levelImageMap[CurrentVIPLevelEnum.Level1]
}