import { styled } from '@mui/joy';
import { Swiper as SwiperReact } from 'swiper/react';

export const Swiper = styled(SwiperReact)(({ theme }) => ({
  width: '100%',
  '.swiper-slide': {
    height: 'auto',
  },
  [theme.breakpoints.down(768)]: {
    width: 'calc(100% + 32px)',
    marginLeft: -16,
    marginRight: -16,
    paddingInline: 16,
    '.swiper-slide': {
      width: '100%',
      maxWidth: 302,
    },
  },
}));