import { FC } from 'react';
import {
  type AuthResponse
} from 'react-facebook-login-hook';
import { Button } from '@mui/joy';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  FacebookIcon,
  useToaster
} from '@shared/ui';
import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  SessionState,
  mapLogin
} from '@entities/session';
import { useFacebookAuthentication } from '../lib';
import { useLazySocialLoginQuery } from '../api';
import { SocialLogin } from '../model';

export type FacebookSignInButtonProps = {
  onSignedIn?(loginData: Pick<
    SessionState,
    'currencySessionKeys' |
    'sessionKey' |
    'shortProfile'
  >): void;
  onNotCompletedSignUpError?(props: SocialLogin): void;
  onError?(error: Maybe<string>): void;
};

export const FacebookSignInButton: FC<FacebookSignInButtonProps> = ({
  onSignedIn,
  onNotCompletedSignUpError,
  onError
}) => {
  const { error } = useToaster();
  const [socialLogin, { isFetching }] = useLazySocialLoginQuery();

  const { authenticateWithFacebook } = useFacebookAuthentication({
    async onSuccess(response: {
      authResponse: AuthResponse;
    }) {
      const socialLoginProps: SocialLogin = {
        socialType: 'facebook',
        idToken: response.authResponse.accessToken ?? '',
        currency: CurrencyCodesEnum.SCO,
        secondaryCurrencies: CurrencyCodesEnum.GCO,
      };
      const { data } = await socialLogin(socialLoginProps);

      if (data?.status !== OmegaApiResponseStatusEnum.Success) {
        return onError?.(data?.message ?? 'Something went wrong');
      }

      if (data.registrationStatus === UserRegistrationStatusEnum.QuickOpen2) {
        return onNotCompletedSignUpError?.({
          ...socialLoginProps,
          email: data?.email ?? ''
        });
      }

      try {
        const loginData = mapLogin(data);
        onSignedIn?.(loginData);
      } catch {
        return error({
          message: 'Something went wrong',
          autoHideDuration: 3000
        });
      }
    }
  });

  const handleAuthenticate = async (): Promise<void> => {
    onError?.(null);
    await authenticateWithFacebook();
  };

  return (
    <Button
      disabled={isFetching}
      startDecorator={(
        <FacebookIcon />
      )}
      variant='plain'
      size='sm'
      sx={({ palette }) => ({
        borderRadius: 8,
        paddingBlock: .8,
        fontSize: 14,
        fontWeight: 400,
        color: palette.common.white,
        backgroundColor: palette.common[275],
        ':hover': { backgroundColor: palette.common[325] },
        ':active': { backgroundColor: palette.common[375] },
        width: 164,
        '& svg': {
          height: 16,
          width: 16
        }
      })}
      onClick={handleAuthenticate}>
      With Facebook
    </Button>
  );
};