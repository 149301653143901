import { baseOmegaApi } from '@shared/api';
import { toQueryString } from '@shared/lib';
import { mapConsents, mapPublicConsents } from '../lib';
import { Consent, SaveConsents } from './types';

export const consentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicConsents: build.query<Array<Consent>, void>({
      query: () => ({ url: '/ips/consents' }),
      extraOptions: { auth: false, showLoader: false },
      transformResponse: (response: any) => mapPublicConsents(response),
    }),
    getConsents: build.query<Array<Consent>, string>({
      query: (sessionKey: string) => ({
        url: '/ips/user/consents',
        params: { sessionKey }
      }),
      extraOptions: { auth: false, showLoader: true },
      transformResponse: (response: any) => mapConsents(response),
    }),
    saveConsents: build.query<any, SaveConsents>({
      query: (args: SaveConsents) => ({
        url: `/ips/user/consents/save${toQueryString(args, { allowDuplicates: true })}`,
      }),
      extraOptions: { auth: false, showLoader: false },
    }),
  }),
});

export const {
  useGetPublicConsentsQuery,
  useGetConsentsQuery,
  useLazyGetConsentsQuery,
  useLazySaveConsentsQuery
} = consentsApiSlice;
