import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/joy';
import { isDefined, useQueryParamValue } from '@shared/lib';
import { CategoriesSelect } from '@features/games-filter-by-category';
import { Search, setSearch } from '@features/games-filter-by-search';
import { SelectFavorite, setIsFavorite } from '@features/games-filter-by-favorite';
import {
  FilterTypeEnum,
  selectActiveFilterType,
  setActiveFilterType,
  useGamesListWidgetSelector
} from '../model';

export const GamesFilter: FC = () => {
  const dispatch = useDispatch();
  const { removeParam, value: category } = useQueryParamValue('category');

  const activeFilterType = useGamesListWidgetSelector(selectActiveFilterType);
  const shouldPreloadByFirstCategory =
    useMemo(() => activeFilterType === FilterTypeEnum.Category, [activeFilterType]);
  const shouldUnselectFavorite =
    useMemo(() => isDefined(category), [category]);

  const handleChangeCategory = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Category));
    dispatch(setSearch(''));
    dispatch(setIsFavorite(false));
  };

  const handleChangeSearch = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Search));
    removeParam();
    dispatch(setIsFavorite(false));
  };

  const handleClearSearch = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Category));
  };

  const handleClickFavorite = () => {
    dispatch(setActiveFilterType(FilterTypeEnum.Favorite));
    dispatch(setSearch(''));
    removeParam();
  };

  return (
    <Stack
      sx={({ palette, breakpoints }) => ({
        p: '0.75rem',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '0.5rem',
        background: palette.common[475],
        columnGap: '0.5rem',
        [breakpoints.down(912)]: {
          position: 'relative',
          width: 'fit-content',
          justifyContent: 'center',
          maxWidth: '100%',
          flexDirection: 'column',
          p: 0,
          gap: '1rem',
          background: palette.common[900],
          pt: 'calc(1rem + 2.75rem)',
        },
      })}>
      <CategoriesSelect
        onChange={handleChangeCategory}
        preloadByFirstCategory={shouldPreloadByFirstCategory}
        selectFavoriteSlot={
          <SelectFavorite
            shouldUnselectFavorite={shouldUnselectFavorite}
            onSelect={handleClickFavorite}
            onEmptyGamesList={handleChangeCategory}
          />
        }
      />
      <Search
        onClear={handleClearSearch}
        onSearch={handleChangeSearch}
      />
    </Stack>
  );
};
