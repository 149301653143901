import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Stack } from '@mui/joy';
import { Button } from '@shared/ui';
import {
  CHALLENGES_PER_LOAD,
  setVisibleCompletedOrExpiredChallengesCount,
  resetVisibleCompletedOrExpiredChallengesCount,
  selectVisibleCompletedOrExpiredChallengesCount,
  selectVisibleLoadMoreButton,
  useChallengesPageSelector,
} from '../model';

export type LoadMoreChallengesButtonProps = {
  onClick?: () => void;
};

export const LoadMoreChallengesButton: FC<LoadMoreChallengesButtonProps> = ({ onClick }) => {
  const dispatch = useDispatch();

  const visibleLoadMoreButton = useChallengesPageSelector(selectVisibleLoadMoreButton);
  const visibleCompletedOrExpiredChallengesCount = useChallengesPageSelector(selectVisibleCompletedOrExpiredChallengesCount);

  const handleLoadMoreChallenges = (): void => {
    dispatch(setVisibleCompletedOrExpiredChallengesCount(
      visibleCompletedOrExpiredChallengesCount + CHALLENGES_PER_LOAD
    ));
    onClick?.();
  };

  useEffect(() => {
    dispatch(resetVisibleCompletedOrExpiredChallengesCount());
    // eslint-disable-next-line
  }, []);

  return visibleLoadMoreButton && (
    <Stack
      direction='column'
      justifyContent='center'
      alignItems='center'>
      <Button
        onClick={handleLoadMoreChallenges}
        variant='solid'
        color='primary'
        sx={({ breakpoints }) => ({
          [breakpoints.down(768)]: {
            maxWidth: '22.375rem',
            width: '100%',
          },
        })}>
        Load more
      </Button>
    </Stack>
  );
};