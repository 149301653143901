import {
  FC,
  Fragment,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  Button,
  Stack,
  Typography,
} from '@mui/joy';
import {
  Dropdown,
  useToaster
} from '@shared/ui';
import {
  OmegaApiResponse,
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  DOCUMENT_ACCEPT,
  DEFAULT_FILE_SIZE,
} from '../lib';
import {
  type DocumentType,
  selectDocumentTypes,
  useDocumentsSelector,
} from '../model';
import {
  useLazyUploadUserDocumentQuery,
  fetchDocumentTypesAndUserDocuments,
} from '../api';
import {
  UserDocumentsList
} from './user-documents-list';
import {
  LabeledFragment
} from './labeled-fragment.component';
import {
  FormLayoutFragment
} from './form-layout-fragment.component';
import {
  SingleDocumentDropzone
} from './single-document-dropzone.component';

export const UploadDocumentsForm: FC = () => {
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const { error } = useToaster();

  const [selectedDocumentType, setSelectedDocumentType] = useState<Maybe<string>>(null);
  const [errorCounter, setErrorCounter] = useState<number>(0);

  const [idCardFrontFile, setIdCardFrontFile] = useState<Maybe<File>>();
  const [idCardBackFile, setIdCardBackFile] = useState<Maybe<File>>();
  const [otherDocumentsFile, setOtherDocumentsFile] = useState<Maybe<File>>(null);
  const [shouldClear, setShouldClear] = useState<boolean>(false);

  const documentTypes = useDocumentsSelector(selectDocumentTypes);
  const documentTypesWithExcludedUserIdCards = useMemo(
    () => documentTypes
      .filter(({ documentType }) => !(['IDENTITY_CARD', 'IDENTITY_CARD_FRONT', 'IDENTITY_CARD_BACK']
        .includes(documentType))),
    [documentTypes]);
  const [uploadUserDocument, { isFetching }] = useLazyUploadUserDocumentQuery();

  useEffect(() => {
    dispatch(fetchDocumentTypesAndUserDocuments());
  }, [dispatch]);

  useEffect(() => {
    if (documentTypes.length > 0 && !selectedDocumentType)
      setSelectedDocumentType(documentTypes[0]?.documentType)
  }, [documentTypes, selectedDocumentType]);

  const handleSelectDocumentType = (value: string): void => {
    setSelectedDocumentType(value);
  };

  const handleUploadConfirm = async (): Promise<void> => {
    const uploadDenied = errorCounter > 0;
    if (uploadDenied) return;

    const allowUploadOtherDocs = otherDocumentsFile && selectedDocumentType;
    if (allowUploadOtherDocs) {
      const { data: uploadUserDocumentResponse } = await uploadUserDocument({
        documentType: selectedDocumentType,
        file: otherDocumentsFile,
      });
      const { status, errors, message } = uploadUserDocumentResponse as OmegaApiResponse;
      const requestFailed =
        status === OmegaApiResponseStatusEnum.ValidationFail ||
        status !== OmegaApiResponseStatusEnum.Success;
      if (requestFailed) {
        const requestFailedError = message ?? errors?.[0].error ?? 'Something went wrong';
        error({ message: requestFailedError });
        return;
      } else {
        setOtherDocumentsFile(null);
      }
    }

    const allowUploadIdCardFrontFile = idCardFrontFile;
    if (allowUploadIdCardFrontFile) {
      const { data: uploadUserDocumentResponse } = await uploadUserDocument({
        documentType: 'IDENTITY_CARD_FRONT',
        file: idCardFrontFile,
      });
      const { status, errors, message } = uploadUserDocumentResponse as OmegaApiResponse;
      const requestFailed =
        status === OmegaApiResponseStatusEnum.ValidationFail ||
        status !== OmegaApiResponseStatusEnum.Success;
      if (requestFailed) {
        const requestFailedError = message ?? errors?.[0].error ?? 'Something went wrong';
        error({ message: requestFailedError });
        return;
      } else {
        setIdCardFrontFile(null);
      }
    }

    const allowUploadIdCardBackFile = idCardBackFile;
    if (allowUploadIdCardBackFile) {
      const { data: uploadUserDocumentResponse } = await uploadUserDocument({
        documentType: 'IDENTITY_CARD_BACK',
        file: idCardBackFile,
      });
      const { status, errors, message } = uploadUserDocumentResponse as OmegaApiResponse;
      const requestFailed =
        status === OmegaApiResponseStatusEnum.ValidationFail ||
        status !== OmegaApiResponseStatusEnum.Success;
      if (requestFailed) {
        const requestFailedError = message ?? errors?.[0].error ?? 'Something went wrong';
        error({ message: requestFailedError });
        return;
      } else {
        setIdCardBackFile(null);
      }
    }

    dispatch(fetchDocumentTypesAndUserDocuments());
    setShouldClear(true);
  };

  const handleOtherDocumentsUploaded = async (
    file: Nullable<File>,
    hasError: boolean
  ): Promise<void> => {
    const preventUpload = hasError || !file || !selectedDocumentType;
    if (preventUpload) {
      setErrorCounter(errorCounter => errorCounter === 3 ? errorCounter : ++errorCounter);
      setOtherDocumentsFile(null);
      return;
    } else {
      setErrorCounter(errorCounter => errorCounter > 0 ? --errorCounter : errorCounter);
      setOtherDocumentsFile(file);
    }

    setShouldClear(false);
  };

  const handleIdCardUploaded = ({ isIdCardFront }: { isIdCardFront: boolean }) =>
    async (file: Nullable<File>, hasError: boolean): Promise<void> => {
      const shouldPreventUpload = hasError || !file;

      if (shouldPreventUpload) {
        setErrorCounter(errorCounter => errorCounter === 3 ? errorCounter : ++errorCounter);
        isIdCardFront ? setIdCardFrontFile(null) : setIdCardBackFile(null);
      } else {
        setErrorCounter(errorCounter => errorCounter > 0 ? --errorCounter : errorCounter);
        isIdCardFront ? setIdCardFrontFile(file) : setIdCardBackFile(file);
      }

      setShouldClear(false);
    };

  return (
    <Stack gap={3} width='100%'>
      <FormLayoutFragment>
        <LabeledFragment
          label='ID Card - Front'
          tooltip={
            <Fragment>
              <Typography>
                Please take a photo of the front side of one of these valid documents:
              </Typography>
              <Typography>
                ID card/Driving license/Passport or Resident card.
              </Typography>
              <Typography>
                Make sure that all four corners are visible on the photo
              </Typography>
            </Fragment>
          }>
          <SingleDocumentDropzone
            maxSize={DEFAULT_FILE_SIZE}
            disabled={isFetching}
            shouldClear={shouldClear}
            accept={DOCUMENT_ACCEPT}
            onDrop={handleIdCardUploaded({ isIdCardFront: true })}
          />
        </LabeledFragment>
        <LabeledFragment
          label='ID Card - Back'
          tooltip={
            <Fragment>
              <Typography>
                Please take a photo of the back side of one of these valid documents:
              </Typography>
              <Typography>
                ID card/Driving license/Passport or Resident card.
              </Typography>
              <Typography>
                Make sure that all four corners are visible on the photo
              </Typography>
            </Fragment>
          }>
          <SingleDocumentDropzone
            maxSize={DEFAULT_FILE_SIZE}
            disabled={isFetching}
            shouldClear={shouldClear}
            accept={DOCUMENT_ACCEPT}
            onDrop={handleIdCardUploaded({ isIdCardFront: false })}
          />
        </LabeledFragment>
      </FormLayoutFragment>
      <FormLayoutFragment>
        <LabeledFragment
          label='Choose document type'
          tooltip={
            <Fragment>
              <Typography>
                Please take a photo or upload a PDF  of your additional documents:
              </Typography>
              <Typography>
                Ensure that all four corners of the document are visible in the photo, and that your full name and address are clearly displayed.
              </Typography>
              <Typography>
                Additionally, please note that the proof of address and bank statement must be no older than three months.
              </Typography>
            </Fragment>
          }>
          <Dropdown
            disabled={isFetching}
            value={selectedDocumentType}
            onChange={handleSelectDocumentType}
            inputProps={{ root: { sx: { minHeight: 'unset' } } }}
            options={
              documentTypesWithExcludedUserIdCards.map(({ documentType }: DocumentType) => ({
                // TODO: need refactor
                label: documentType.replace('_', ' ').replace('_', ' '),
                value: documentType,
              }))
            }
          />
          <SingleDocumentDropzone
            maxSize={DEFAULT_FILE_SIZE}
            disabled={isFetching}
            shouldClear={shouldClear}
            accept={DOCUMENT_ACCEPT}
            onDrop={handleOtherDocumentsUploaded}
          />
          <Button
            variant='solid'
            color='primary'
            loading={isFetching}
            disabled={
              (!idCardFrontFile && !idCardBackFile && !otherDocumentsFile)
              || errorCounter > 0
            }
            onClick={handleUploadConfirm}>
            Confirm
          </Button>
        </LabeledFragment>
        <LabeledFragment label='Uploaded documents'>
          <UserDocumentsList />
        </LabeledFragment>
      </FormLayoutFragment>
    </Stack>
  );
};