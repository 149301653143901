import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/joy';
import {
  FacebookIcon,
} from '@shared/ui';
import {
  isDefined,
  isEmpty,
  useModalQueryParam
} from '@shared/lib';
import {
  OmegaApiResponse,
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  mapLogin,
  setSignedInData
} from '@entities/session';
import {
  useFacebookAuthentication
} from '../lib';
import {
  SocialLogin,
  openConfirmationAuthViaSocialsModal,
  removePropsToAuthViaSocials,
  setPropsToAuthViaSocials
} from '../model';
import { useLazySocialLoginQuery } from '../api';

export type FacebookSignUpButtonProps = {
  trackingCodes?: Maybe<string>;
  consentIds: Maybe<Array<number>>;
  onConfirmConsents?(
    completeAuthenticationCallback: () => Promise<void>
  ): void;
  onError?(error: Maybe<string>): void;
  onSocialSignUp(method: string): void;
};

export const FacebookSignUpButton: FC<FacebookSignUpButtonProps> = ({
  trackingCodes,
  consentIds,
  onConfirmConsents,
  onError,
  onSocialSignUp
}) => {
  const dispatch = useDispatch();
  const { closeModal } = useModalQueryParam();
  const [socialLogin] = useLazySocialLoginQuery();

  const authenticate = async (propsToSignUp: SocialLogin): Promise<void> => {
    const { data } = await socialLogin(propsToSignUp);
    const { status, registrationStatus } = data as OmegaApiResponse & {
      registrationStatus?: UserRegistrationStatusEnum
    };

    if (registrationStatus === UserRegistrationStatusEnum.QuickReg) {
      const signedInData = mapLogin(data);
      dispatch(setSignedInData(signedInData));
      dispatch(removePropsToAuthViaSocials());
      closeModal('sign-up');
      return;
    }

    if (status !== OmegaApiResponseStatusEnum.Success) {
      return onError?.(data?.message ?? 'Something went wrong');
    }

    const dataWithEmailFromOmega = data as unknown as { email: Email };
    dispatch(setPropsToAuthViaSocials({
      ...propsToSignUp,
      email: dataWithEmailFromOmega.email
    }));
    dispatch(openConfirmationAuthViaSocialsModal());
    closeModal('sign-up');
  };

  const { authenticateWithFacebook } = useFacebookAuthentication({
    async onSuccess({ authResponse }) {

      const propsToSignUp: SocialLogin = {
        socialType: 'facebook',
        idToken: authResponse.accessToken,
        currency: CurrencyCodesEnum.SCO,
        secondaryCurrencies: CurrencyCodesEnum.GCO,
        ...(trackingCodes && ({
          extraInfo: trackingCodes
        })),
        ...(consentIds && ({
          consentedVersions: consentIds
        }))
      };

      if (isDefined(consentIds) && !isEmpty(consentIds)) {
        onConfirmConsents?.(
          async () => await authenticate(propsToSignUp)
        );
      } else {
        await authenticate(propsToSignUp);
      }
    }
  });

  const handleAuthenticate = async (): Promise<void> => {
    onError?.(null);
    onSocialSignUp('facebook');
    await authenticateWithFacebook();
  };

  return (
    <Button
      startDecorator={(
        <FacebookIcon />
      )}
      variant='plain'
      size='sm'
      sx={({ palette }) => ({
        borderRadius: 8,
        paddingBlock: .8,
        fontSize: 14,
        fontWeight: 400,
        color: palette.common.white,
        backgroundColor: palette.common[275],
        ':hover': { backgroundColor: palette.common[325] },
        ':active': { backgroundColor: palette.common[375] },
        width: 164,
        '& svg': {
          height: 16,
          width: 16
        }
      })}
      onClick={handleAuthenticate}>
      With&nbsp;Facebook
    </Button>
  );
};