import { Stack } from '@mui/joy';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@entities/session';

export const CheckAuthStatus: FC = () => {
  const isAuth = useSelector(selectIsAuthenticated);
    
  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'checkAuth') {
        if (event.source && event.origin) {
          (event.source as Window).postMessage(isAuth ? 'authenticated' : 'nonAuthenticated', event.origin);
        }
      }
    });
  }, [isAuth]);
  return <Stack></Stack>;
};
