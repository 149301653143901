import { mapper } from '@shared/lib';
import { GamesWithPagination } from '../model';
import { mapGamesItem } from './map-games-item';

type MapGamesWithPagination = (data: any) => GamesWithPagination;
export const mapGamesWithPagination: MapGamesWithPagination = mapper([
  {
    name: 'games',
    from: 'data',
    transform: (games: Array<any>) => games?.map(mapGamesItem) || [],
  },
  {
    name: 'pagination',
    from: 'meta.pagination',
  },
]);
