import { mapper } from '@shared/lib';
import { type StickyNavigationItem } from '../model';

type MapStickyNavigationItem = (data: any) => StickyNavigationItem;
export const mapStickyNavigationItem: MapStickyNavigationItem = mapper([
  { name: 'id', from: 'id' },
  { name: 'text', from: 'text' },
  { name: 'slug', from: 'slug' },
  { name: 'centerButton', from: 'centerButton' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
]);
