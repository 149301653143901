import {
  createSlice
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';

export type SidebarMenuState = {
  open: boolean;
};

const initialState: SidebarMenuState = {
  open: false
};

export const sidebarMenuSlice = createSlice({
  name: 'sidebar-menu',
  initialState,
  reducers: {
    openSidebarMenu: (state: SidebarMenuState) => {
      state.open = true;
    },
    closeSidebarMenu: (state: SidebarMenuState) => {
      state.open = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState)
  }
});

export const {
  openSidebarMenu,
  closeSidebarMenu
} = sidebarMenuSlice.actions;

export const sidebarMenuReducer = sidebarMenuSlice.reducer;