import { baseOmegaApi, SESSION_TAG } from '@shared/api';
import {
  type GetChallengesResponse
} from './types';

export const challengesApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getChallenges: build.query<GetChallengesResponse, void>({
      query: () => ({ url: '/ips/challenge' }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: true, auth: true }
    }),
  }),
});

export const {
  useGetChallengesQuery,
  useLazyGetChallengesQuery
} = challengesApiSlicer;
