import { FC } from 'react';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { Stack, Typography } from '@mui/joy';

export const NoSearchResultsFound: FC = () => {
  return (
    <Stack
      alignItems='center'
      marginInline='auto'
      sx={() => ({
        paddingBlock: '2.5rem',
        gap: '1.5rem',
      })}
    >
      <SearchIcon sx={(theme) => ({ fontSize: '3.9375rem', color: theme.colorSchemes.dark.palette.common[475] })} />
      <Stack gap='0.5rem'>
        <Typography
          sx={(theme) => ({
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '1.125rem',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.colorSchemes.dark.palette.common.white,
          })}
        >
          Sorry, no results found
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.125rem',
            letterSpacing: '0em',
            textAlign: 'center',
            color: theme.colorSchemes.dark.palette.common.white,
          })}
        >
          Please, try searching for another game
        </Typography>
      </Stack>
    </Stack>
  );
};
