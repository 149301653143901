import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { IconButton, Stack, Tooltip, Typography } from '@mui/joy';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export type LabeledContainerProps = PropsWithChildren<{
  label: string;
  tooltip?: Maybe<string> | ReactNode;
}>

export const LabeledFragment: FC<LabeledContainerProps> = ({ label, tooltip, children }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleTooltipClose = (): void => {
    setShowTooltip(false);
  };

  const handleTooltipOpen = (): void => {
    setShowTooltip(true);
  };

  return (
    <Stack
      component='aside'
      direction='column'
      width='100%'
      gap={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
            color: palette.common.white,
          })}>
          {label}
        </Typography>
        {tooltip && (
          <Tooltip
            open={showTooltip}
            title={tooltip}
            onClose={handleTooltipClose}
            placement='bottom-start'
            sx={({ palette }) => ({
              '&.MuiTooltip-root': {
                borderRadius: '0.5rem',
                background: palette.common[475],
                boxShadow: 'none',
                padding: '0.75rem',
                zIndex: 1,
                maxWidth: 353,
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: palette.common[925]
              },
            })}>
            <IconButton
              onClick={handleTooltipOpen}
              sx={{
                padding: 0,
                minHeight: 'min-content',
                minWidth: 'min-content',
                fontSize: '14px',
              }}>
              <HelpOutlineIcon
                sx={({ palette }) => ({
                  color: palette.common.white,
                  fontSize: '1.25rem',
                })}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {children}
    </Stack>
  );
};