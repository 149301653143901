import { BaseSyntheticEvent, FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { Button, Sheet, Stack } from '@mui/joy';
import {
  OmegaApiResponseStatusEnum,
  omegaErrorsMapper
} from '@shared/api';
import { capitalize } from '@shared/lib';
import {
  TextInput,
  PhoneInput,
  Dropdown,
  useToaster,
  DatePicker
} from '@shared/ui';
import {
  selectFullProfile,
  selectIsPlayerRegistrationStatus,
  updateFullProfile,
  useSessionSelector,
  useGetPlayerInfoQuery,
  FullProfile,
} from '@entities/session';
import {
  type EditableFullProfile,
  editFullProfileSchema,
  countries,
  states,
  mapEditableFullProfile,
  mapFullProfileToUpdate,
  mapQuickSignupCompletionError
} from '../lib';
import {
  useLazyQuickSignupCompletionQuery,
} from '../api';

export type EditFullProfileFormProps = {
  onSuccessChanged?: () => void;
};

export const EditFullProfileForm: FC<EditFullProfileFormProps> = () => {
  const { success, error } = useToaster();
  const dispatch = useDispatch();

  const { refetch } = useGetPlayerInfoQuery();
  const [quickSignUpCompletion, { isFetching }] = useLazyQuickSignupCompletionQuery();

  const isPlayerRegistrationStatus = useSessionSelector(selectIsPlayerRegistrationStatus);
  const fullProfile = useSessionSelector(selectFullProfile);
  const { birthDate, ...defaultValues } = mapEditableFullProfile(fullProfile);

  const [hasPhoneLostFocus, setPhoneLostFocus] = useState<boolean>(false);
  const [hasProvinceFocus, setProvinceFocus] = useState<boolean>(false);
  const [hasCountryLostFocus, setCountryLostFocus] = useState<boolean>(false);
  const originalNicknameRef = useRef<Maybe<string>>(defaultValues?.nickname);

  const {
    control,
    formState,
    setError,
    setFocus,
    setValue,
    getValues,
    trigger,
    handleSubmit
  } = useForm({
    resolver: yupResolver(editFullProfileSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      birthDate:
        isPlayerRegistrationStatus
          ? birthDate
          : (null as any),
      ...defaultValues
    }
  });

  const handleFormSubmit = async (
    formData: Omit<EditableFullProfile, 'kycStatus' | 'registrationStatus'>,
    event?: BaseSyntheticEvent
  ): Promise<void> => {
    event?.stopPropagation();
    event?.preventDefault();

    const isFormValid = await trigger();
    if (!isFormValid) return;

    const { nickname, ...formDataProps } = formData;
    const { email, mobile, ...fullProfileToUpdate } = mapFullProfileToUpdate({
      ...formDataProps,
      ...(nickname !== originalNicknameRef.current && ({ nickname })),
    });

    const { data } = await quickSignUpCompletion({
      ...fullProfileToUpdate,
      // prevention of sending email or phone if these methods were used by the user to register
      ...(!Boolean(defaultValues.email) && ({ email })),
      ...(!Boolean(defaultValues.phone) && ({ mobile })),
    });

    if (data?.status === OmegaApiResponseStatusEnum.ValidationFail) {
      data?.errors?.forEach((quickSignupCompletionError: { field: string; error: string; }) => {
        const { field, error } = mapQuickSignupCompletionError(quickSignupCompletionError);
        const errorMessage = omegaErrorsMapper['EDIT-FULL-PROFILE'][error] ?? 'Something went wrong';

        setError(field as any, {
          type: error,
          message: errorMessage.replace('{{field}}', capitalize(field))
        });

        setFocus(field as any);
      });
      return;
    }

    if (data?.status !== OmegaApiResponseStatusEnum.Success) {
      return error({ message: data?.message ?? 'Something went wrong' });
    }

    dispatch(updateFullProfile(formData as unknown as FullProfile));
    success({ message: 'Profile was updated!' });
    refetch();
  };

  const handlePhoneValueChange = async (phone: Phone): Promise<void> => {
    setValue('phone', phone);
    if (hasPhoneLostFocus) await trigger('phone');
  };

  const handlePhoneBlur = async (): Promise<void> => {
    setPhoneLostFocus(true);
    trigger('phone');
  };

  const handleCountryValueChange = (country: string): void => {
    setValue('country', country, { shouldValidate: true });
    if (hasCountryLostFocus) trigger('country');
  };

  const handleCountryBlur = async (): Promise<void> => {
    setCountryLostFocus(true);
    await trigger('country');
  };

  const handleProvinceValueChange = async (province: string): Promise<void> => {
    setValue('province', province, { shouldValidate: true });
    if (hasProvinceFocus) await trigger('province');
  };

  const handleProvinceBlur = async (): Promise<void> => {
    setProvinceFocus(true);
    await trigger('province');
  };

  return (
    <Stack
      direction='column'
      gap={3}
      width='100%'
      component='form'
      sx={{
        paddingTop: 'env(safe-area-inset-top)',
        paddingBottom: 'env(safe-area-inset-bottom)',
        paddingLeft: 'env(safe-area-inset-left)',
        paddingRight: 'env(safe-area-inset-right)',
      }}>
      <Sheet
        sx={({ breakpoints }) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          columnGap: 3,
          [breakpoints.down(1024)]: {
            gridTemplateColumns: '100%',
          }
        })}>
        <Sheet
          sx={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='firstName'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='First Name'
                placeholder='Type name'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={{
            gridRowStart: 2,
            gridRowEnd: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='lastName'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='Last Name'
                placeholder='Type last name'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={{
            gridRowStart: 3,
            gridRowEnd: 4,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='nickname'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='Nickname'
                placeholder='Type nickname'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={{
            gridRowStart: 4,
            gridRowEnd: 5,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='email'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='Email'
                placeholder='Type email'
                disabled={true}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={{
            gridRowStart: 5,
            gridRowEnd: 6,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='birthDate'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <DatePicker
                label='Date of Birth'
                views={['year', 'month', 'day']}
                value={dayjs(value)}
                maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                onBlur={onBlur}
                onChange={newValue => {
                  onChange(newValue);
                  trigger('birthDate');
                }}
                sx={{ width: '100%' }}
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                errorMessage={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={{
            gridRowStart: 6,
            gridRowEnd: 7,
            gridColumnStart: 1,
            gridColumnEnd: 2,
          }}>
          <Controller
            name='address'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='Address'
                placeholder='Type Address'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 2,
            gridColumnEnd: 3,
            [breakpoints.down(1024)]: {
              gridRowStart: 7,
              gridRowEnd: 8,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })}>
          <Dropdown
            label='Country'
            placeholder='Select Country'
            value={getValues('country')}
            disabled={
              isPlayerRegistrationStatus ||
              isFetching
            }
            onBlur={handleCountryBlur}
            onChange={handleCountryValueChange}
            error={formState.errors?.country?.message}
            options={countries.map(({ name, code }) => ({ label: name, value: code }))}
          />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 2,
            gridColumnEnd: 3,
            [breakpoints.down(1024)]: {
              gridRowStart: 8,
              gridRowEnd: 9,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })}>
          <Dropdown
            label='State'
            placeholder='Select State'
            disabled={isPlayerRegistrationStatus || isFetching}
            value={getValues('province')}
            onBlur={handleProvinceBlur}
            onChange={handleProvinceValueChange}
            error={formState.errors?.province?.message}
            options={states.map(({ name, code }) => ({ label: name, value: code }))}
          />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 2,
            gridColumnEnd: 3,
            [breakpoints.down(1024)]: {
              gridRowStart: 9,
              gridRowEnd: 10,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })}>
          <Controller
            name='city'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='City'
                placeholder='Type City'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 2,
            gridColumnEnd: 3,
            [breakpoints.down(1024)]: {
              gridRowStart: 10,
              gridRowEnd: 11,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })}>
          <Controller
            name='postalCode'
            control={control}
            render={({ field: { value, onBlur, onChange }, fieldState }) => (
              <TextInput
                label='Zip code'
                placeholder='Type Zip code'
                disabled={
                  isPlayerRegistrationStatus ||
                  isFetching
                }
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Sheet>
        <Sheet
          sx={({ breakpoints }) => ({
            gridColumnStart: 2,
            gridColumnEnd: 3,
            [breakpoints.down(1024)]: {
              gridRowStart: 11,
              gridRowEnd: 12,
              gridColumnStart: 1,
              gridColumnEnd: 2,
            }
          })}>
          <PhoneInput
            name='phone'
            label='Phone Number'
            disabled={
              Boolean(defaultValues.phone) ||
              isPlayerRegistrationStatus ||
              isFetching
            }
            value={getValues('phone') ?? ''}
            onBlur={handlePhoneBlur}
            onChange={handlePhoneValueChange}
            error={formState.errors?.phone?.message}
          />
        </Sheet>
      </Sheet>
      <Stack
        direction='row'>
        <Button
          disabled={
            isPlayerRegistrationStatus ||
            isFetching
          }
          loading={
            isFetching
          }
          variant='solid'
          color='primary'
          size='lg'
          type='submit'
          sx={({ breakpoints }) => ({
            width: 132,
            [breakpoints.down(600)]: {
              width: '100%'
            }
          })}
          onClick={handleSubmit(handleFormSubmit)}>
          Save changes
        </Button>
      </Stack>
    </Stack>
  );
};