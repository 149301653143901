import { createAsyncThunk } from '@reduxjs/toolkit';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { favoriteGamesApiSlice } from './slice';
import { FavoriteGame } from '../model';

export const addFavoriteGame = createAsyncThunk<void, FavoriteGame>(
  'add-favorite-game',
  async (favoriteGameToAdd: FavoriteGame, { dispatch, rejectWithValue }) => {
    try {
      const responseFromOmega = await dispatch(
        favoriteGamesApiSlice.endpoints.addMyFavoriteGameToOmega.initiate(favoriteGameToAdd, { forceRefetch: true })
      ).unwrap();

      const { status } = responseFromOmega as OmegaApiResponse;
      if (status === OmegaApiResponseStatusEnum.Success) {
        await dispatch(
          favoriteGamesApiSlice.endpoints.getMyFavoriteGamesFromOmega.initiate(null as any, { forceRefetch: true })
        ).unwrap();
      }
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
