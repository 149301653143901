import { CurrencySessionKeys } from '../model';

type MapCurrencyKeys = CurrencySessionKeys;
export const mapCurrencyKeys = (data: Array<any>): MapCurrencyKeys => {
  return data.reduce(
    (acc: any, item: any) => ({
      ...acc,
      [item.currency]: {
        sessionKey: item.sessionKey,
      },
    }),
    {}
  );
};
