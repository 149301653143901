import {
  FC,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  Box,
  Skeleton,
  Stack,
  Typography
} from '@mui/joy';
import {
  useQueryParamValue
} from '@shared/lib';
import {
  PromotionCard
} from '@entities/promotions/ui';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  PromotionsCategoriesSelect,
  selectIsCategoriesContainKey,
  setSelectedCategoryKey,
  usePromotionsFilterByCategorySelector,
} from '@features/promotions-filter-by-category';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  selectPromotionCards,
  selectPromotionsPageHeading,
  selectPromotionsPageMetaCanonical,
  selectPromotionsPageMetaDescription,
  selectPromotionsPageMetaKeywords,
  selectPromotionsPageMetaTitle,
  usePromotionsPageSelector
} from '../model';
import {
  useGetPromotionsPageQuery,
  useLazyGetPromotionCardsQuery
} from '../api';
import {
  SkeletonList
} from './skeleton-list.component';


export const Promotions: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'promotions'
    }
  });

  const { value: categoryKey } = useQueryParamValue('category');
  const { isFetching } = useGetPromotionsPageQuery();
  const [
    getPromotionCards,
    { isFetching: isCardsFetching }
  ] = useLazyGetPromotionCardsQuery()
  const isCategoryContainKey = usePromotionsFilterByCategorySelector(
    selectIsCategoriesContainKey
  );

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();

  const title = usePromotionsPageSelector(selectPromotionsPageMetaTitle)
  const description = usePromotionsPageSelector(selectPromotionsPageMetaDescription)
  const keywords = usePromotionsPageSelector(selectPromotionsPageMetaKeywords)
  const canonical = usePromotionsPageSelector(selectPromotionsPageMetaCanonical)

  const heading = usePromotionsPageSelector(selectPromotionsPageHeading)
  const promotionCards = useSelector(selectPromotionCards)

  useEffect(() => {
    dispatch(setSelectedCategoryKey(categoryKey));
    if (categoryKey && isCategoryContainKey) getPromotionCards(categoryKey);
    // eslint-disable-next-line
  }, [categoryKey, dispatch, isCategoryContainKey]);

  return isFetching ? null : (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 'calc(80rem + 10rem)',
          paddingInline: '5rem',
          paddingBlock: '3rem 4rem',
          [breakpoints.down(768)]: {
            paddingInline: '1rem',
            paddingBlock: '1.5rem',
          }
        })}>
        <Box
          sx={{ width: '100%' }}>
          <Typography
            sx={({ palette, breakpoints }) => ({
              fontSize: '1.75rem',
              fontWeight: 600,
              lineHeight: '2.25rem',
              color: palette.common.white,
              marginBottom: '2rem',
              [breakpoints.down(768)]: {
                fontSize: '1.25rem',
                lineHeight: '1.875rem',
                marginBottom: '1rem',
              }
            })}>
            {isFetching ? <Skeleton variant='text' width='5rem' /> : heading}
          </Typography>
        </Box>
        <Stack width='100%' gap={'1rem'} alignItems='center'>
          <Stack
            sx={({ palette, breakpoints }) => ({
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'space-between',
              width: '100%',
              borderRadius: '0.5rem',
              background: palette.common[900],
              columnGap: '0.5rem',
              [breakpoints.down(912)]: {
                width: 'fit-content',
                justifyContent: 'center',
                maxWidth: '100%',
                flexDirection: 'column',
                p: 0,
                gap: '1rem',
              },
            })}>
            <PromotionsCategoriesSelect />
          </Stack>
          <Box
            sx={({ breakpoints }) => ({
              display: 'grid',
              width: '100%',
              gap: '1.5rem',
              gridTemplateColumns: '1fr 1fr 1fr',
              [breakpoints.down(1024)]: {
                gridTemplateColumns: '1fr 1fr',
              },
              [breakpoints.down(768)]: {
                gridTemplateColumns: 'minmax(0, 25.625rem)',
                justifyContent: 'center',
              },
            })}>
            {isCardsFetching ? <SkeletonList length={3} /> : null}
            {!isCardsFetching ? promotionCards?.map((item) => (
              <PromotionCard
                key={item.id}
                item={item}
              />
            )) : null}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};