import { FC, useState } from 'react';
import { Modal, ModalDialog, Typography, Stack, IconButton } from '@mui/joy';
import { CloseIcon, useToaster } from '@shared/ui';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { transformErrorMessage } from '../lib';
import { useLazyChangePasswordQuery } from '../api';
import { ChangePasswordForm } from './change-password-form.component';

export type ChangePasswordModelProps = {
  open: boolean;
  onClose?: () => void;
};

export const ChangePasswordModal: FC<ChangePasswordModelProps> = ({ open, onClose }) => {
  const toaster = useToaster()
  const [changePassword, { isFetching }] = useLazyChangePasswordQuery();
  const [error, setError] = useState<string>('');

  const handleSubmit = async (formData: any): Promise<void> => {
    setError('');

    const { isError, data } = await changePassword({
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
    });

    if (isError) {
      return toaster.error({ message: 'Something when wrong!' });
    }
    if (data?.status === OmegaApiResponseStatusEnum.ValidationFail) {
      return setError(transformErrorMessage(data?.errors[0].error))
    };

    toaster.success({ message: 'Password was updated successfully' });
    onClose?.();
  };

  const handleClose = () => {
    if (isFetching) return;
    onClose?.();
  };

  return (
    <Modal open={open}>
      <ModalDialog
        layout='center'
        sx={({ breakpoints }) => ({
          [breakpoints.down(407)]: {
            width: '100%',
          }
        })}>
        <Stack
          direction='column'
          sx={({ palette, breakpoints }) => ({
            padding: 3,
            borderRadius: 8,
            background: palette.common[475],
            width: '22.375rem',
            [breakpoints.down(407)]: {
              width: '100%',
            }
          })}>
          <Stack mb='1rem'>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                width: '100%',
                mb: '0.75rem',
              }}>
              <Typography sx={({ palette }) => ({
                fontSize: '1.25rem',
                fontWeight: 600,
                lineHeight: '1.875rem',
                color: palette.common.white,
              })}>
                Change password
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Typography sx={({ palette }) => ({
              fontSize: '0.875rem',
              fontWeight: 400,
              lineHeight: '1.25rem',
              color: palette.common[150]
            })}>
              Please create a new password
            </Typography>
          </Stack>
          <ChangePasswordForm
            isLoading={isFetching}
            onSubmit={handleSubmit}
            error={error}
          />
        </Stack>
      </ModalDialog>
    </Modal>
  );
};