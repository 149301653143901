import {
  baseOmegaApi,
  OmegaApiResponse,
  SESSION_TAG
} from '@shared/api';
import {
  UserRegistrationStatusEnum
} from '@shared/types';
import { toQueryString } from '@shared/lib';
import { SocialLogin } from '../model';

export type SocialLoginResponse = OmegaApiResponse & {
  registrationStatus?: UserRegistrationStatusEnum;
  email?: Maybe<string>;
};

export const authenticationApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    socialLogin: build.query<SocialLoginResponse, SocialLogin>({
      query: (args: SocialLogin) => ({
        url: `ips/socialLogin${toQueryString(args, { allowDuplicates: true })}`,
        extraOptions: { showLoader: false },
        providesTags: [SESSION_TAG],
      }),
    }),
  }),
});

export const {
  useSocialLoginQuery,
  useLazySocialLoginQuery
} = authenticationApiSlice;
