import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { dynamicPageSlice } from './slice';
import { selectDynamicPageContent } from './common-selectors';
import {
  type DynamicPageState,
  type HtmlContent
} from './types';

export const selectHtmlContent = createSelector(
  selectDynamicPageContent,
  (htmlContent) => htmlContent as Maybe<HtmlContent>
);

export const selectHtmlBlock = createSelector(
  selectHtmlContent,
  (htmlContent) => htmlContent?.htmlBlock
);

export const useHtmlPageContentSelector: TypedUseSelectorHook<{
  [dynamicPageSlice.name]: DynamicPageState;
}> = useSelector;