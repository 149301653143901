import {
  baseOmegaApi,
  OmegaApiResponse,
  SESSION_TAG
} from '@shared/api';
import {
  toQueryString
} from '@shared/lib';
import {
  type SignIn,
  type CheckLoggedInSuccessResponse,
  type SignUpConfirmation,
  type ResendVerificationCode,
  type SendVerifyConfirmation,
  type VerifyConfirmation,
} from './types';

export const authApiSlicer = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.query<OmegaApiResponse, SignIn>({
      query: (params: SignIn) => ({ url: 'ips/login', params: params }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: false }
    }),
    signOut: build.query<OmegaApiResponse, void>({
      query: () => ({ url: 'ips/logout' }),
      providesTags: [SESSION_TAG],
      extraOptions: { auth: true, showLoader: true },
    }),
    checkLoggedIn: build.query<CheckLoggedInSuccessResponse, void>({
      query: () => ({ url: 'ips/checkLoggedIn' }),
      extraOptions: { auth: true, showLoader: false },
    }),
    signUpConfirmation: build.query<OmegaApiResponse, Partial<SignUpConfirmation>>({
      query: (params) => ({ url: `ips/signupConfirmation${toQueryString(params, { allowDuplicates: true })}` }),
      providesTags: [SESSION_TAG],
      extraOptions: { showLoader: false },
    }),
    resendVerificationCode: build.query<OmegaApiResponse, ResendVerificationCode>({
      query: (params) => ({ url: 'ips/resendVerificationCode', params }),
      extraOptions: { showLoader: false },
    }),
    sendVerifyConfirmation: build.query<OmegaApiResponse, SendVerifyConfirmation>({
      query: (params) => ({ url: 'ips/sendVerifyConfirmation', params }),
      extraOptions: { auth: true, showLoader: false },
    }),
    verifyConfirmation: build.query<OmegaApiResponse, VerifyConfirmation>({
      query: (params) => ({ url: 'ips/verifyConfirmation', params }),
      extraOptions: { auth: true, showLoader: false },
    }),
  }),
});

export const {
  useLazySignInQuery,
  useLazySignOutQuery,
  useLazyCheckLoggedInQuery,
  useSignInQuery,
  useSignOutQuery,
  useCheckLoggedInQuery,
  useSendVerifyConfirmationQuery,
  useVerifyConfirmationQuery,
  useLazySignUpConfirmationQuery,
  useLazyResendVerificationCodeQuery,
  useLazySendVerifyConfirmationQuery,
  useLazyVerifyConfirmationQuery,
} = authApiSlicer;
