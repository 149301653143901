import { FC, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/joy';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { otpSchema, useModalQueryParam } from '@shared/lib';
import { Link, OtpInput, Button, useToaster } from '@shared/ui';
import {
  ConfirmPasswordReset,
  ResetPasswordModalsQueries,
  SendResetPasswordByEmail,
  SendResetPasswordByPhone,
  useLazyConfirmPasswordResetQuery,
  useLazySendResetPasswordOTPQuery
} from '../api';
import {
  selectEmail,
  selectMobile,
  setResetPasswordKey,
  useResetPasswordSelector
} from '../model';

export const ConfirmForm: FC = () => {
  const dispatch = useDispatch()
  const toaster = useToaster()
  const { toggleModal } = useModalQueryParam()
  const [confirmResetPassword, { isFetching: isFetchingConfirm }] = useLazyConfirmPasswordResetQuery();
  const [sendNewOtp, { isFetching: isFetchingOtp }] = useLazySendResetPasswordOTPQuery();
  const email = useResetPasswordSelector(selectEmail)
  const mobile = useResetPasswordSelector(selectMobile)
  const [error, setError] = useState<string>('');

  const {
    control,
    formState,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { otp: '' },
  })

  const handleSendConfirmationCode = async (formData: { otp: string }): Promise<void> => {
    if (!email && !mobile) return;
    setError('')
    dispatch(setResetPasswordKey(formData.otp))
    let params: ConfirmPasswordReset;
    if (email) {
      params = { resetPasswordKey: formData.otp, email };
    } else {
      params = { resetPasswordKey: formData.otp, mobile: mobile! };
    }

    const { isSuccess, data } = await confirmResetPassword(params)
    if (isSuccess && data.status === OmegaApiResponseStatusEnum.Success) {
      toggleModal(ResetPasswordModalsQueries.ConfirmResetPassword, ResetPasswordModalsQueries.NewPasswordModal)
    } else if (isSuccess && data.status !== OmegaApiResponseStatusEnum.Success) {
      setError('Invalid entry. Please try again.')
    } else {
      setError('Something went wrong')
    }
  }

  const handleSendNewOtpCode = async (): Promise<void> => {
    if (!email && !mobile) return;

    reset({})
    setError('')

    let params: SendResetPasswordByPhone | SendResetPasswordByEmail;
    if (email) {
      params = { email };
    } else {
      params = { mobile: mobile! };
    }

    const { isSuccess, data } = await sendNewOtp(params)
    if (isSuccess && data.status === OmegaApiResponseStatusEnum.Success) {
      toaster.success({ message: 'New code sent successfully' })
    } else {
      toaster.error({ message: 'Something went wrong' })
    }
  };

  const handleFocus = (): void => {
    if (formState.errors.otp || formState.errors.root) {
      reset({});
    }
  };

  return (
    <Stack component='form' gap='1rem'>
      {error && <Typography
        sx={({ palette }) => ({
          color: palette.common.error,
        })}
      >{error}
      </Typography>}
      <Controller
        control={control}
        disabled={isFetchingConfirm || isFetchingOtp}
        name='otp'
        render={({ field: { value, onBlur, onChange }, fieldState }) => (
          <OtpInput
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={handleFocus}
            error={fieldState.error?.message}
          />
        )}
      />
      <Stack alignItems='center' gap='0.625rem'>
        <Typography sx={({ palette }) => ({ color: palette.common.white })}>
          Didn't receive a code?
        </Typography>
        <Link
          disabled={isFetchingConfirm || isFetchingOtp}
          onClick={handleSendNewOtpCode}>
          Send new OTP
        </Link>
      </Stack>
      <Button
        fullWidth
        loading={isFetchingConfirm}
        type='submit'
        disabled={
          !formState.isDirty ||
          !formState.isValid ||
          isFetchingConfirm ||
          isFetchingOtp
        }
        onClick={handleSubmit(handleSendConfirmationCode)}>
        Submit
      </Button>
    </Stack>
  );
};