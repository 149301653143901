import { IconButton } from '@mui/joy';
import { FC } from 'react';
import { FullScreenOnIcon, FullScreenOffIcon } from '@shared/ui';
import { selectIsFullScreen, usePlayGamePageSelector } from '../model';

export const FullScreenToggle: FC = () => {
  const isFullScreen = usePlayGamePageSelector(selectIsFullScreen);
  const handleToggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };
  return (
    <IconButton
      size='md'
      onClick={handleToggleFullScreen}
      sx={({ breakpoints }) => ({
        '@media (orientation: landscape) and (max-height: 500px)': {
          display: 'none',
        },
        [breakpoints.down(769)]: {
          display: 'none',
        },
      })}>
      {isFullScreen ? (
        <FullScreenOffIcon sx={{ fontSize: 16 }} />
      ) : (
        <FullScreenOnIcon sx={{ fontSize: 16 }} />
      )}
    </IconButton>
  );
};
