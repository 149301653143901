import {
  FC,
  Fragment,
  useCallback,
  useEffect
} from 'react';
import {
  useDispatch
} from 'react-redux';
import {
  ThunkDispatch,
  UnknownAction
} from '@reduxjs/toolkit';
import {
  Stack
} from '@mui/joy';
import {
  useQueryParamValue,
} from '@shared/lib';
import {
  setSelectedCategoryKey
} from '@features/games-filter-by-category';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  GameList
} from '@widgets/game-list';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  selectIsLoading,
  selectMetaTagCanonical,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagTitle,
  useGamesPageSelector,
} from '../model';
import { fetchGamesPage } from '../api';

export const Games: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'games'
    }
  });
  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, UnknownAction>>();
  const { value: categoryKey } = useQueryParamValue('category');

  const isLoading = useGamesPageSelector(selectIsLoading);
  const keywords = useGamesPageSelector(selectMetaTagKeywords);
  const title = useGamesPageSelector(selectMetaTagTitle);
  const description = useGamesPageSelector(selectMetaTagDescription);
  const canonical = useGamesPageSelector(selectMetaTagCanonical);

  const getPageData = useCallback(async () => {
    await dispatch(fetchGamesPage())
  }, [dispatch])

  useEffect(() => {
    getPageData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(setSelectedCategoryKey(categoryKey));
  }, [categoryKey, dispatch]);

  return isLoading ? null : (
    <Fragment>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          paddingBlock: '3rem 4rem',
          maxWidth: '82rem',
          width: '100%',
          height: '100%',
          paddingInline: '1rem',
          alignItems: 'center',
          [breakpoints.down(912)]: { paddingBlock: '1.5rem' },
          [breakpoints.down(768)]: { paddingInline: '1rem' },
        })}>
        <GameList />
      </Stack>
    </Fragment>
  );
};
