import { mapper } from '@shared/lib';
import { type Challenge } from '../model';
import { mapChallengeMedia } from './map-challenge-media';

type MapChallenge = (data: any) => Challenge;
export const mapChallenge: MapChallenge = mapper([
  { name: 'awardAmount', from: 'awardAmount' },
  {
    name: 'badge',
    from: 'badge',
    transform: (badgeData: Maybe<any>) => badgeData && mapChallengeMedia(badgeData),
  },
  {
    name: 'icon',
    from: 'icon',
    transform: (iconData: Maybe<any>) => iconData && mapChallengeMedia(iconData),
  },
  { name: 'challengeUUID', from: 'challengeUUID' },
  { name: 'currency', from: 'currency' },
  { name: 'endDate', from: 'endDate' },
  { name: 'percentage', from: 'percentage' },
  { name: 'progress', from: 'progress' },
  { name: 'specification', from: 'specification' },
  { name: 'status', from: 'status' },
  { name: 'target', from: 'target' },
  {
    name: 'title',
    from: 'title',
    transform: (titleData: Maybe<any>) => {
      const defaultValue = 'Challenge';
      if (!titleData) return defaultValue;

      const match = titleData.match(/\{\{'(.+?)'\}\}|{{"(.+?)"}}|{{(.+?)}}|(.+?):|^([^:]+)$/);
      if (match) {
        return match[1] || match[2] || match[3] || match[4] || match[5];
      }

      return defaultValue;
    },
  },
  {
    name: 'subTitle',
    from: 'title',
    transform: (titleData: Maybe<any>) => {
      const defaultValue = 'Complete the task and grab reward';
      if (!titleData) return defaultValue;

      const match = titleData.match(/:\s*(.*)$/);
      if (match && match[1]) {
        return match[1];
      }

      const matchWithoutColon = titleData.match(/\{\{'(.+?)'\}\}|{{"(.+?)"}}|{{(.+?)}}|(.+?)$/);
      if (matchWithoutColon) {
        return matchWithoutColon[4] || defaultValue;
      }

      return defaultValue;
    },
  },
  { name: 'type', from: 'type' },
]);