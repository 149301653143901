import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, BoxProps, Skeleton, Stack } from '@mui/joy';

export type AppLogoProps = Pick<
  BoxProps, 'width' | 'height' | 'sx'> & {
    src?: Url;
  };

export const AppLogo: FC<AppLogoProps> = ({ src = '/assets/webp/logo_horizontal.webp', ...props }) => {
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const handleLoad = (): void => {
    setImageLoading(false);
  };
  return (
    <Link
      to='/'
      style={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
      }}>
      <Stack
        alignItems='center'
        justifyContent='center'
        sx={{
          width: 'fit-content',
          height: 'fit-content',
          position: 'relative'
        }}>
        <Skeleton
          loading={imageLoading}
          sx={[
            { width: 210, height: 36 },
            ...(Array.isArray(props.sx)
              ? props.sx
              : [props.sx])
          ]}
        />
        <Box
          component='img'
          loading='lazy'
          src={src}
          alt='Sweep Luxe Logo'
          onLoad={handleLoad}
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: 210,
            cursor: 'pointer',
          }}
          {...props}
        />
      </Stack>
    </Link>
  );
};
