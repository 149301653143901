import { mapper } from '@shared/lib';
import { type TournamentRanking } from '../model';

type MapTournamentRanking = (data: any) => TournamentRanking;
const mapTournamentRanking: MapTournamentRanking = mapper([
  { name: 'id', from: 'id' },
  { name: 'tournamentId', from: 'tournamentId' },
  { name: 'partyId', from: 'partyid' },
  { name: 'points', from: 'points' },
  { name: 'ranking', from: 'ranking' },
  { name: 'rankingLast', from: 'rankingLast' },
  { name: 'rankingDate', from: 'rankingDate' },
  { name: 'awarded', from: 'awarded' },
  { name: 'awardedDate', from: 'awardedDate' },
  { name: 'amountBet', from: 'amountBet' },
  { name: 'amountWin', from: 'amountWin' },
  { name: 'betCount', from: 'betCount' },
  { name: 'winCount', from: 'winCount' },
  { name: 'userId', from: 'userid' },
  { name: 'currency', from: 'currency' },
  { name: 'nickname', from: 'nickname' },
]);

type MapTournamentRankings = (data: { tournamentRankingList: Array<any> }) => Array<TournamentRanking>;
export const mapTournamentRankings: MapTournamentRankings = ({
  tournamentRankingList
}: {
  tournamentRankingList: Array<any>
}) => {
  return tournamentRankingList?.map(mapTournamentRanking) ?? [];
};
