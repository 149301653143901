import {
  FC,
  useEffect
} from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Stack
} from '@mui/joy';
import {
  FilterOperationEnum,
  isDefined,
  isEmpty
} from '@shared/lib';
import {
  useLazyGetPublicTournamentsQuery,
  useLazyGetTournamentRankingQuery
} from '@entities/tournaments';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  TournamentPlacement
} from '@widgets/tournament-placement';
import {
  TournamentLeaderboard
} from '@widgets/tournament-leaderboard';
import {
  QualifyingGamesBlock
} from '@widgets/qualifying-games-block';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  useGetTournamentDetailsPageQuery
} from '../api';
import {
  selectMetaTagTitle,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagCanonical,
  selectTournamentId,
  selectLeaderTitle,
  useTournamentDetailsPageSliceSelector,
  selectGames,
} from '../model';
import {
  HeadingBlock
} from './heading-block.component';
import {
  HtmlBlock
} from './html-block.component';

export const TournamentDetailsPage: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'tournament-details'
    }
  });

  const { tournamentSlug } = useParams();
  const navigate = useNavigate();

  const {
    isFetching: isFetchingGetPublicTournaments,
    isSuccess,
    data
  } = useGetTournamentDetailsPageQuery(
    { slug: { [FilterOperationEnum.Equals]: tournamentSlug } },
    { refetchOnMountOrArgChange: true }
  );
  const [getPublicTournaments] = useLazyGetPublicTournamentsQuery();
  const [getTournamentRanking] = useLazyGetTournamentRankingQuery();

  const title = useTournamentDetailsPageSliceSelector(selectMetaTagTitle);
  const keywords = useTournamentDetailsPageSliceSelector(selectMetaTagKeywords);
  const description = useTournamentDetailsPageSliceSelector(selectMetaTagDescription);
  const canonical = useTournamentDetailsPageSliceSelector(selectMetaTagCanonical);
  const leaderboardTitle = useTournamentDetailsPageSliceSelector(selectLeaderTitle);
  const tournamentId = useTournamentDetailsPageSliceSelector(selectTournamentId);
  const qualifyingGames = useTournamentDetailsPageSliceSelector(selectGames);

  useEffect(() => {
    if ((isEmpty(data?.slider) || isEmpty(data?.action)) && isSuccess) {
      navigate('/races');
    }
  }, [
    data,
    isSuccess,
    navigate
  ]);

  useEffect(() => {
    if (isDefined(tournamentId) && isSuccess) {
      getPublicTournaments({ id: tournamentId! }).then();
      getTournamentRanking({
        id: tournamentId!,
        rankingStart: 1,
        rankingEnd: 50
      }).then();
    }
  }, [
    isSuccess,
    tournamentId,
    getPublicTournaments,
    getTournamentRanking
  ]);

  return isFetchingGetPublicTournaments ? null : (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        sx={({ breakpoints }) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          rowGap: 2,
          columnGap: 5,
          width: '100%',
          height: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockStart: 4,
          [breakpoints.down(1350)]: {
            paddingInline: 2,
          },
          [breakpoints.down(768)]: {
            rowGap: 3,
            paddingBlockStart: 3,
          },
          [breakpoints.down(414)]: {
            paddingBlockStart: 3,
          }
        })}>
        <HeadingBlock
          loading={isFetchingGetPublicTournaments}
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 3
          }}
        />
        <TournamentPlacement
          sx={{
            paddingBlock: 3,
            gridColumnStart: 1,
            gridColumnEnd: 3,
          }}
        />
        <TournamentLeaderboard
          title={leaderboardTitle}
          sx={({ breakpoints }) => ({
            paddingBlock: 3,
            gridColumnStart: 1,
            gridColumnEnd: 2,
            minHeight: 624,
            [breakpoints.down(1024)]: {
              gridColumnEnd: 3,
              minHeight: '100%',
              paddingBlock: 0,
            },
          })}
        />
        <HtmlBlock
          loading={isFetchingGetPublicTournaments}
          sx={({ breakpoints }) => ({
            paddingBlock: 3,
            gridColumnStart: 2,
            gridColumnEnd: 3,
            minHeight: 624,
            [breakpoints.down(1024)]: {
              gridColumnStart: 1,
              gridColumnEnd: 3,
              paddingBlockStart: 3,
              paddingBlockEnd: 0,
              minHeight: '100%',
            },
          })}
        />
      </Stack>
      <Stack sx={({ breakpoints }) => ({
        width: '100%',
        height: '100%',
        maxWidth: 1280,
        marginBlock: 'auto',
        paddingBlockStart: 4,
        paddingBlockEnd: 8,
        [breakpoints.down(1350)]: { paddingInline: 2 },
        [breakpoints.down(768)]: {
          paddingBlockStart: 3,
          paddingBlockEnd: 3,
        },
        [breakpoints.down(414)]: { paddingBlockStart: 3 }
      })}>
        {qualifyingGames ? (
          <QualifyingGamesBlock
            games={qualifyingGames}
            isGamesLoading={isFetchingGetPublicTournaments}
          />
        ) : null}
      </Stack>
    </>
  );
};