import { baseApi } from "@shared/api";
import { PromotionsFilterByCategoryState } from "../model";
import { mapPromotionCategories } from "../lib";

export const promotionCategoriesApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotionsCategories: build.query<Pick<PromotionsFilterByCategoryState, 'promotionCategories'>, void>({
      query: () => ({ url: '/api/promotion-categories' }),
      extraOptions: { auth: true },
      transformResponse: (response: any) => mapPromotionCategories(response),
    }),
  }),
});

export const { useLazyGetPromotionsCategoriesQuery } = promotionCategoriesApiSlice;