
import { mapper } from '@shared/lib';
import { type UserBalance } from '../model';
import { mapCurrencyBalances } from './map-currency-balances';

type MapUserBalance = (data: any) => UserBalance;
export const mapUserBalance: MapUserBalance = mapper([
  { name: 'totalBalance', from: 'totalBalance' },
  { name: 'totalBalanceNumber', from: 'totalBalanceNumber' },
  { name: 'withdrawableBalance', from: 'withdrawableBalance' },
  { name: 'bonusBalance', from: 'bonusBalance' },
  { name: 'bonusBalanceNumber', from: 'bonusBalanceNumber' },
  { name: 'pendingBonusBalance', from: 'pendingBonusBalance' },
  { name: 'pendingBonusBalanceNumber', from: 'pendingBonusBalanceNumber' },
  { name: 'casinoPlayableBonusBalance', from: 'casinoPlayableBonusBalance' },
  { name: 'casinoPlayableBonusBalanceNumber', from: 'casinoPlayableBonusBalanceNumber' },
  { name: 'sportsbookPlayableBonusBalance', from: 'sportsbookPlayableBonusBalance' },
  { name: 'sportsbookPlayableBonusBalanceNumber', from: 'sportsbookPlayableBonusBalanceNumber' },
  { name: 'withdrawableEscrowBalance', from: 'withdrawableEscrowBalance' },
  { name: 'withdrawableEscrowBalanceNumber', from: 'withdrawableEscrowBalanceNumber' },
  { name: 'totalWithdrawableBalance', from: 'totalWithdrawableBalance' },
  { name: 'totalWithdrawableBalanceNumber', from: 'totalWithdrawableBalanceNumber' },
  { name: 'withdrawableBalanceNumber', from: 'withdrawableBalanceNumber' },
  { name: 'withdrawRestrictionAmount', from: 'withdrawRestrictionAmount' },
  { name: 'withdrawRestrictionAmountNumber', from: 'withdrawRestrictionAmountNumber' },
  { name: 'preBuyBalance', from: 'preBuyBalance' },
  { name: 'preBuyBalanceNumber', from: 'preBuyBalanceNumber' },
  { name: 'currency', from: 'currency' },
  { name: 'loyaltyPoint', from: 'loyaltyPoint' },
  { name: 'totalEscrowBalance', from: 'totalEscrowBalance' },
  { name: 'totalEscrowBalanceNumber', from: 'totalEscrowBalanceNumber' },
  { name: 'status', from: 'status' },
  { name: 'currencySymbolPosition', from: 'currencySymbolPosition' },
  { name: 'vipStatus', from: 'vipStatus' },
  { name: 'message', from: 'message' },
  {
    name: 'currencyBalances',
    from: 'currencyBalances',
    transform: (currencyBalancesData: any) => mapCurrencyBalances(currencyBalancesData),
  },
]);
