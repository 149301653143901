import {
  FC
} from 'react';
import {
  Box,
  Stack,
  Typography
} from '@mui/joy';
import {
  isEmpty
} from '@shared/lib';
import {
  ChallengeListItem
} from '@entities/challenges';
import {
  useGoogleAds,
  useSendDataToGtm
} from '@features/collect-analytics';
import {
  MetaTagsBlock
} from '@widgets/meta-tags-block';
import {
  selectPageHeading,
  selectIsLoading,
  selectMetaTagCanonical,
  selectMetaTagDescription,
  selectMetaTagKeywords,
  selectMetaTagTitle,
  selectActiveChallenges,
  selectCompletedAndExpiredChallenges,
  selectVisibleCompletedOrExpiredChallengesCount,
  useChallengesPageSelector
} from '../model';
import {
  useGetChallengesPageQuery
} from '../api';
import {
  LoadMoreChallengesButton
} from './load-more-challenges-button.component';
import {
  NoChallengesPlaceholder
} from './no-challenges-placeholder';

export const Challenges: FC = () => {
  useGoogleAds({ adsLabel: 'PRZWCPysh-QZEIrhv9g9' });
  useSendDataToGtm({
    dataLayer: {
      event: 'page_view',
      pagePath: window.location.pathname,
      pageTitle: 'challenges-page'
    }
  });

  useGetChallengesPageQuery();

  const heading = useChallengesPageSelector(selectPageHeading);
  const isLoading = useChallengesPageSelector(selectIsLoading);
  const keywords = useChallengesPageSelector(selectMetaTagKeywords);
  const title = useChallengesPageSelector(selectMetaTagTitle);
  const description = useChallengesPageSelector(selectMetaTagDescription);
  const canonical = useChallengesPageSelector(selectMetaTagCanonical);
  const activeChallenges = useChallengesPageSelector(selectActiveChallenges);
  const completedAndExpiredChallenges = useChallengesPageSelector(selectCompletedAndExpiredChallenges);
  const visibleCompletedOrExpiredChallengesCount = useChallengesPageSelector(selectVisibleCompletedOrExpiredChallengesCount);

  return !isLoading && (
    <>
      <MetaTagsBlock
        title={title}
        description={description}
        keywords={keywords}
        canonical={canonical}
      />
      <Stack
        direction='column'
        sx={({ breakpoints }) => ({
          width: '100%',
          height: '100%',
          maxWidth: 1280,
          marginBlock: 'auto',
          paddingBlockStart: 5,
          [breakpoints.down(1350)]: { paddingInline: 2 },
          [breakpoints.down(414)]: { paddingBlockStart: 3 }
        })}>
        <Typography
          sx={({ palette }) => ({
            color: palette.common.white,
            fontSize: 28,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '36px'
          })}>
          {heading ? heading : 'My Challenges'}
        </Typography>
        {!isEmpty(activeChallenges) && (
          <Stack
            direction='column'
            rowGap={2}
            sx={{
              paddingBlockStart: 4,
              paddingBlockEnd: 4.25,
            }}>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: 20,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '30px'
              })}>
              Active
            </Typography>
            <Box
              sx={(theme) => ({
                display: 'grid',
                width: '100%',
                rowGap: 3,
                columnGap: 1,
                gridTemplateColumns: 'repeat(auto-fit, 411px)',
                [theme.breakpoints.down(1350)]: {
                  columnGap: 2,
                  gridTemplateColumns: 'repeat(auto-fit, 48.5%) !important',
                },
                [theme.breakpoints.down(831)]: {
                  rowGap: 2,
                  gridTemplateColumns: 'repeat(auto-fit, 100%) !important',
                },
                [theme.breakpoints.down(390)]: {
                  rowGap: 2,
                  gridTemplateColumns: '1fr 1fr',
                },
              })}>
              {activeChallenges.map(({
                title,
                subTitle,
                status,
                endDate,
                percentage,
                badge,
                currency,
                awardAmount
              }, index) => (
                <ChallengeListItem
                  key={`${index}-active`}
                  title={title}
                  subTitle={subTitle}
                  status={status}
                  endDate={endDate}
                  percentage={percentage}
                  badgeUrl={badge?.url ?? ''}
                  currency={currency}
                  amount={awardAmount}
                />
              ))}
            </Box>
          </Stack>
        )}
        {!isEmpty(completedAndExpiredChallenges) && (
          <Stack
            direction='column'
            rowGap={2}
            sx={{
              paddingBlockStart: 4,
              paddingBlockEnd: 4.25,
            }}>
            <Typography
              sx={({ palette }) => ({
                color: palette.common.white,
                fontSize: 20,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '30px'
              })}>
              Completed
            </Typography>
            <Box
              sx={({ breakpoints }) => ({
                display: 'grid',
                width: '100%',
                rowGap: 3,
                columnGap: 1,
                gridTemplateColumns: 'repeat(auto-fit, 411px)',
                [breakpoints.down(1350)]: {
                  columnGap: 2,
                  gridTemplateColumns: 'repeat(auto-fit, 48.9%) !important',
                },
                [breakpoints.down(831)]: {
                  rowGap: 2,
                  gridTemplateColumns: 'repeat(auto-fit, 100%) !important',
                },
                [breakpoints.down(390)]: {
                  rowGap: 2,
                  gridTemplateColumns: '1fr 1fr',
                },
              })}>
              {completedAndExpiredChallenges
                .slice(0, visibleCompletedOrExpiredChallengesCount)
                .map(({
                  title,
                  subTitle,
                  status,
                  endDate,
                  percentage,
                  badge,
                  currency,
                  awardAmount
                }, index) => (
                  <ChallengeListItem
                    key={`${index}-completed-or-expired`}
                    title={title}
                    subTitle={subTitle}
                    status={status}
                    endDate={endDate}
                    percentage={percentage}
                    badgeUrl={badge?.url ?? ''}
                    currency={currency}
                    amount={awardAmount}
                  />
                ))}
            </Box>
            <LoadMoreChallengesButton />
          </Stack>
        )}
        {isEmpty(activeChallenges) && isEmpty(completedAndExpiredChallenges) && (
          <NoChallengesPlaceholder />
        )}
      </Stack>
    </>
  );
};