import { TypedUseSelectorHook, useSelector } from 'react-redux';
import {
  type RedeemCoinsState,
  redeemCoinsSlice
} from './slice';

type RootState = {
  [redeemCoinsSlice.name]: RedeemCoinsState;
};

export const selectIsOpenConfirmCoinRedemptionPopup = (state: RootState): boolean => (
  state[redeemCoinsSlice.name].isOpenConfirmCoinRedemptionPopup
);

export const selectCoinRedeem = (state: RootState): number => (
  state[redeemCoinsSlice.name].coinRedeem
);

export const useRedeemCoinsSelector: TypedUseSelectorHook<{
  [redeemCoinsSlice.name]: RedeemCoinsState;
}> = useSelector;