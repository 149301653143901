import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Sheet,
  Stack,
  StackProps,
  Table,
  Typography,
} from '@mui/joy';
import { isDefined, isEmpty } from '@shared/lib';
import {
  selectTournamentLeaderboardItems
} from '../model';
import {
  LeaderboardNotAvailable
} from './leaderboard-not-available.component';

export type TournamentLeaderboardProps = Omit<StackProps, 'title'> & {
  title: Maybe<string>;
};

export const TournamentLeaderboard: FC<TournamentLeaderboardProps> = ({
  title,
  sx,
  ...props
}) => {
  const tournamentLeaderboardItems = useSelector(selectTournamentLeaderboardItems);
  const isLeaderboardAvailable = useMemo(() =>
    isDefined(tournamentLeaderboardItems) && !isEmpty(tournamentLeaderboardItems),
    [tournamentLeaderboardItems]
  );
  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='flex-start'
      rowGap={2}
      sx={[{
        width: '100%',
      },
      ...(sx ? Array.isArray(sx) ? sx : [sx] : [])
      ]}
      {...props}>
      {title && (
        <Typography
          sx={({ breakpoints }) => ({
            fontSize: 28,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '36px',
            [breakpoints.down(768)]: {
              fontSize: 20,
            }
          })}>
          {title}
        </Typography>
      )}
      <Sheet
        sx={({ palette }) => ({
          height: 572,
          maxHeight: 572,
          width: '100%',
          borderRadius: 8,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: palette.common[925],
          backgroundColor: isLeaderboardAvailable
            ? palette.common[475]
            : palette.common[900],
          overflow: 'hidden',
          position: 'relative',
        })}>
        <Table
          size='ml'
          sx={({ palette }) => ({
            gap: 1.25,
            width: '100%',
            '& thead th': {
              borderWidth: 0,
              height: 52,
              color: palette.common[150],
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              paddingInlineEnd: 2,
              verticalAlign: 'middle'
            },
            '& thead th:nth-child(1)': {
              width: '15%'
            },
            '& thead th:nth-child(2)': {
              width: '30%',
              textAlign: 'start',
              paddingInlineStart: 0
            },
            '& thead th:nth-child(3)': {
              width: '20%',
              textAlign: 'start',
              paddingInlineStart: 0
            },
            '& thead th:nth-child(4)': {
              width: '40%',
              textAlign: 'end',
              paddingInlineStart: 0
            },
            '& tbody td': {
              height: 52,
              color: palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              paddingInlineEnd: 2,
            },
            '& tbody td:nth-child(1)': {
              color: palette.common[700]
            },
            '& tbody td:nth-child(2)': {
              textAlign: 'start',
              paddingInlineStart: 0
            },
            '& tbody td:nth-child(3)': {
              textAlign: 'start',
              paddingInlineStart: 0
            },
            '& tbody td:nth-child(4)': {
              textAlign: 'end',
              paddingInlineStart: 0
            },
            '& tbody tr:nth-child(odd)': {
              backgroundColor: palette.common[900],
            },
            '& tbody tr:nth-child(even)': {
              backgroundColor: palette.common[475],
            },
            '& tbody tr:not(:last-of-type)>td, & thead th:not([colspan])': {
              borderWidth: 0,
              borderStyle: 'none',
            },
            '& tbody tr.current-place': {
              backgroundColor: palette.common[565],
              boxShadow: '0px -10px 10px -10px rgba(255, 181, 0, 0.35) inset, 0px 10px 10px -10px rgba(255, 181, 0, 0.35) inset'
            },
            '& tbody tr.current-place>td': {
              borderTopWidth: 1,
              borderBottomWidth: 1,
              borderRightWidth: 0,
              borderLeftWidth: 0,
              borderStyle: 'solid',
              borderColor: palette.common[1090],
              color: palette.common[1090],
            },
          })}>
          <thead>
            <tr>
              <th>&#35;</th>
              <th>Player</th>
              <th>Score</th>
              <th>To Win</th>
            </tr>
          </thead>
          {isLeaderboardAvailable ? (
            <tbody>
              {tournamentLeaderboardItems?.map(({
                rank,
                player,
                score,
                toWin,
                isCurrentPlace
              }, index) => (
                <tr
                  className={isCurrentPlace ? 'current-place' : ''}
                  key={`${rank}-${player}-${index}`}>
                  <td>{rank}</td>
                  <td>{player}</td>
                  <td>{score}</td>
                  <td>{toWin}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <LeaderboardNotAvailable />
          )}
        </Table>
      </Sheet>
    </Stack >
  );
};