import { createAsyncThunk } from '@reduxjs/toolkit';
import { OmegaApiResponse, OmegaApiResponseStatusEnum } from '@shared/api';
import { mapMessages } from '../lib';
import { FetchMessagesResponse, GetMessagesHistoryPayload } from './types';
import { messagesApiSlice } from './slice';

export const fetchMessages = createAsyncThunk<FetchMessagesResponse, GetMessagesHistoryPayload>(
  'fetch-messages',
  async (payload: GetMessagesHistoryPayload, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const responseFromOmega = await dispatch(
        messagesApiSlice.endpoints.getMessagesHistory.initiate(payload)
      ).unwrap();

      const { status } = responseFromOmega as OmegaApiResponse;
      if (status !== OmegaApiResponseStatusEnum.Success) {
        throw new Error('Fail to fetch messages from OmegaApi');
      }

      const messages = mapMessages(responseFromOmega);

      return fulfillWithValue({ messages, channelId: payload.channel });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
