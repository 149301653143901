import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AccountTabEnum, AccountPageState, accountPageSlice } from './slice';

type RootState = {
  [accountPageSlice.name]: AccountPageState;
};

export const selectTabs = (state: RootState): Array<AccountTabEnum> => (
  state[accountPageSlice.name].tabs
);

export const selectCurrentTab = (state: RootState): AccountTabEnum => (
  state[accountPageSlice.name].currentTab
);

export const useAccountPageSelector: TypedUseSelectorHook<{
  [accountPageSlice.name]: AccountPageState;
}> = useSelector;
