export enum UserDocumentStatusEnum {
  Approved = 'APPROVED',
  NotReviewed = 'NOT_REVIEWED',
  OnHold = 'ON_HOLD',
  Declined = 'DECLINED',
};

export type DocumentType = {
  documentType: string;
};

export type UserDocument = {
  id: string;
  fileName: string;
  documentNumber: string;
  documentType: string;
  status: UserDocumentStatusEnum;
};

export type DocumentsState = {
  documentTypes: Array<DocumentType>;
  userDocuments: Array<UserDocument>;
};
