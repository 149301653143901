import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const GoldCoinIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M32 16.8566C32 16.7602 32 16.6622 32 16.5657C32 12.467 30.3143 8.53608 27.3137 5.63782C24.3131 2.73956 20.2435 1.11133 16 1.11133C11.7565 1.11133 7.68687 2.73956 4.68629 5.63782C1.68571 8.53608 0 12.467 0 16.5657C0 16.659 0 16.7523 0 16.8566C0.0847023 20.9004 1.80728 24.751 4.79803 27.5819C7.78877 30.4128 11.8093 31.9984 15.9967 31.9984C20.1841 31.9984 24.2047 30.4128 27.1954 27.5819C30.1862 24.751 31.9087 20.9004 31.9935 16.8566H32Z'
        fill='#2A2E33'
      />
      <path
        d='M16.016 30.5946C24.7622 30.5946 31.8523 23.7462 31.8523 15.2983C31.8523 6.85034 24.7622 0.00195312 16.016 0.00195312C7.26985 0.00195312 0.179688 6.85034 0.179688 15.2983C0.179688 23.7462 7.26985 30.5946 16.016 30.5946Z'
        fill='url(#paint0_linear_7848_469834)'
      />
      <path
        opacity='0.3'
        d='M16.0127 30.1514C11.8542 30.1514 7.86255 28.5714 4.90006 25.7525C1.93756 22.9336 0.242018 19.1022 0.179688 15.0859C0.179687 15.1618 0.179688 15.244 0.179688 15.3152C0.179688 19.372 1.84815 23.2627 4.81804 26.1313C7.78792 28.9999 11.8159 30.6115 16.016 30.6115C20.216 30.6115 24.2441 28.9999 27.214 26.1313C30.1838 23.2627 31.8523 19.372 31.8523 15.3152C31.8523 15.2377 31.8523 15.1571 31.8523 15.0859C31.79 19.1033 30.0935 22.9357 27.1296 25.7547C24.1657 28.5738 20.1724 30.1531 16.0127 30.1514Z'
        fill='#FDF356'
      />
      <path
        d='M16.0193 0.577442C20.1677 0.577561 24.1504 2.14994 27.1111 4.95653C30.0719 7.76311 31.7743 11.5798 31.8523 15.586C31.8523 15.4896 31.8523 15.3947 31.8523 15.2983C31.8523 11.2414 30.1838 7.35075 27.214 4.48214C24.2441 1.61352 20.2161 0.00195313 16.016 0.00195312C11.816 0.00195313 7.78792 1.61352 4.81804 4.48214C1.84815 7.35075 0.179688 11.2414 0.179688 15.2983C0.179688 15.3947 0.179688 15.4896 0.179688 15.586C0.257745 11.5787 1.96105 7.76107 4.92323 4.95431C7.8854 2.14755 11.8698 0.575904 16.0193 0.577442Z'
        fill='#FDF356'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M15.9912 30.5382C24.6966 30.5382 31.7538 23.7217 31.7538 15.3131C31.7538 6.90443 24.6966 0.0878906 15.9912 0.0878906C7.2857 0.0878906 0.228516 6.90443 0.228516 15.3131C0.228516 23.7217 7.2857 30.5382 15.9912 30.5382Z'
        fill='url(#paint1_radial_7848_469834)'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        opacity='0.7'
        d='M15.9912 0.0488281C11.883 0.0490192 7.93714 1.59772 4.99323 4.36537C2.04932 7.13301 0.339812 10.9011 0.228516 14.8677C1.97174 15.8653 4.71343 15.0669 6.50575 14.2005C8.04765 13.5127 9.94309 13.742 11.6045 13.8574C15.7538 14.2005 19.7886 15.9127 23.9347 14.8882C24.7646 14.659 25.9496 13.6234 26.7795 14.3143C27.4659 14.8582 28.2995 15.2004 29.1812 15.3002C30.063 15.3999 30.9559 15.2531 31.7538 14.8772C31.645 10.9089 29.9366 7.13843 26.9925 4.36876C24.0483 1.5991 20.101 0.0490855 15.9912 0.0488281Z'
        fill='url(#paint2_linear_7848_469834)'
      />
      <path
        d='M15.9913 27.1481C22.765 27.1481 28.2561 21.8443 28.2561 15.3016C28.2561 8.75895 22.765 3.45508 15.9913 3.45508C9.21768 3.45508 3.72656 8.75895 3.72656 15.3016C3.72656 21.8443 9.21768 27.1481 15.9913 27.1481Z'
        fill='url(#paint3_linear_7848_469834)'
      />
      <path
        d='M15.9404 26.5909C22.385 26.5909 27.6094 21.5447 27.6094 15.3199C27.6094 9.09505 22.385 4.04883 15.9404 4.04883C9.49585 4.04883 4.27148 9.09505 4.27148 15.3199C4.27148 21.5447 9.49585 26.5909 15.9404 26.5909Z'
        fill='#EE9B00'
      />
      <path
        d='M15.9661 26.1239C22.1476 26.1239 27.1587 21.2837 27.1587 15.3129C27.1587 9.34219 22.1476 4.50195 15.9661 4.50195C9.78455 4.50195 4.77344 9.34219 4.77344 15.3129C4.77344 21.2837 9.78455 26.1239 15.9661 26.1239Z'
        fill='url(#paint4_radial_7848_469834)'
      />
      <path
        opacity='0.25'
        d='M15.9628 25.294C9.88034 25.294 4.93221 20.8387 4.77344 15C4.77344 15.0964 4.77344 15.1913 4.77344 15.2877C4.77344 18.155 5.95266 20.9048 8.05168 22.9323C10.1507 24.9597 12.9976 26.0987 15.9661 26.0987C18.9345 26.0987 21.7814 24.9597 23.8805 22.9323C25.9795 20.9048 27.1587 18.155 27.1587 15.2877C27.1587 15.1913 27.1587 15.0964 27.1587 15C26.9934 20.8387 22.0452 25.294 15.9628 25.294Z'
        fill='#FDF356'
      />
      <path
        opacity='0.1'
        d='M22.9784 13.8266C22.9221 13.6562 22.8171 13.5046 22.6755 13.3893C22.5339 13.2741 22.3614 13.1999 22.178 13.1752L18.5082 12.6598L16.8714 9.44878C16.7898 9.28833 16.6632 9.15319 16.5061 9.05867C16.349 8.96416 16.1677 8.91406 15.9826 8.91406C15.7975 8.91406 15.6161 8.96416 15.459 9.05867C15.3019 9.15319 15.1754 9.28833 15.0938 9.44878L13.457 12.6598L9.78064 13.1816C9.59747 13.2071 9.42536 13.2816 9.28383 13.3968C9.1423 13.512 9.03703 13.6632 8.97994 13.8332C8.92285 14.0033 8.91623 14.1853 8.96084 14.3588C9.00544 14.5323 9.09949 14.6903 9.2323 14.8147L11.8872 17.308L11.2603 20.8368C11.2281 21.0129 11.248 21.1942 11.3178 21.3598C11.3876 21.5254 11.5044 21.6685 11.6548 21.7728C11.8048 21.877 11.9818 21.9391 12.1661 21.9522C12.3504 21.9653 12.5349 21.9289 12.6991 21.8471L15.9793 20.1807L19.2611 21.8455C19.4032 21.9189 19.5617 21.9575 19.7227 21.9578C19.8674 21.9576 20.0103 21.927 20.1415 21.8682C20.2727 21.8093 20.389 21.7236 20.4825 21.6169C20.5759 21.5102 20.6442 21.3852 20.6826 21.2505C20.721 21.1158 20.7286 20.9746 20.7048 20.8368L20.0714 17.308L22.7247 14.8084C22.8586 14.6846 22.9535 14.5267 22.9984 14.3529C23.0433 14.1792 23.0364 13.9967 22.9784 13.8266Z'
        fill='#FDF356'
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity='0.6'
        d='M22.7609 13.7548C22.7182 13.6255 22.6386 13.5104 22.5311 13.4228C22.4237 13.3353 22.2929 13.2788 22.1537 13.2599L18.3611 12.7271L16.6654 9.40701C16.6034 9.2849 16.5072 9.18203 16.3877 9.11008C16.2682 9.03814 16.1302 9 15.9894 9C15.8486 9 15.7106 9.03814 15.5911 9.11008C15.4716 9.18203 15.3754 9.2849 15.3134 9.40701L13.6176 12.7271L9.82508 13.2599C9.68626 13.2799 9.55595 13.3369 9.4488 13.4245C9.34165 13.512 9.26192 13.6267 9.21856 13.7557C9.17519 13.8846 9.16992 14.0227 9.20333 14.1543C9.23674 14.286 9.30752 14.4061 9.4077 14.501L11.9137 16.969L11.2589 20.618C11.236 20.7595 11.2527 20.9045 11.3074 21.0376C11.3621 21.1708 11.4528 21.2874 11.5699 21.3753C11.7189 21.4757 11.8908 21.5397 12.071 21.5617C12.2512 21.5838 12.4343 21.5632 12.6044 21.5018L15.9894 19.7769L19.3809 21.5002C19.5246 21.5671 19.683 21.5992 19.8424 21.5937C20.0019 21.5882 20.1575 21.5452 20.2959 21.4686C20.5299 21.3105 20.7607 20.8931 20.7133 20.618L20.0585 16.969L22.5645 14.501C22.6668 14.4072 22.7395 14.2873 22.7742 14.1553C22.809 14.0233 22.8044 13.8844 22.7609 13.7548Z'
        fill='url(#paint5_linear_7848_469834)'
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.15'>
        <path
          d='M22.7624 13.0966C22.7196 12.9671 22.6399 12.8519 22.5326 12.7641C22.4252 12.6763 22.2944 12.6195 22.1551 12.6001L18.3626 12.0689L16.6668 8.74877C16.6044 8.62715 16.508 8.52479 16.3886 8.45323C16.2691 8.38167 16.1314 8.34375 15.9908 8.34375C15.8503 8.34375 15.7125 8.38167 15.5931 8.45323C15.4736 8.52479 15.3773 8.62715 15.3148 8.74877L13.6191 12.0689L9.82654 12.6001C9.68737 12.6199 9.5567 12.6768 9.44925 12.7645C9.34181 12.8521 9.26187 12.9671 9.21847 13.0963C9.17506 13.2255 9.16992 13.3639 9.20361 13.4958C9.2373 13.6277 9.30849 13.7479 9.40915 13.8428L12.1541 16.4262L11.4994 20.0736C11.4748 20.2076 11.4898 20.3456 11.5425 20.4718C11.5953 20.598 11.6837 20.7073 11.7978 20.7873C11.9118 20.8673 12.0468 20.9147 12.1874 20.924C12.328 20.9334 12.4684 20.9044 12.5928 20.8404L15.9908 19.1186L19.3823 20.8404C19.5067 20.9038 19.647 20.9323 19.7873 20.9226C19.9276 20.9129 20.0623 20.8655 20.1761 20.7857C20.2899 20.7059 20.3783 20.5968 20.4313 20.471C20.4842 20.3451 20.4996 20.2075 20.4757 20.0736L19.821 16.4262L22.566 13.8428C22.6681 13.7488 22.7406 13.629 22.7754 13.497C22.8101 13.365 22.8056 13.2262 22.7624 13.0966Z'
          fill='#EE9B00'
        />
      </g>
      <path
        d='M16.5564 9.03749L18.2931 12.4382L22.1789 12.9837C22.2855 12.9982 22.3857 13.0413 22.4682 13.1081C22.5506 13.175 22.612 13.2628 22.6453 13.3617C22.6785 13.4606 22.6824 13.5665 22.6564 13.6674C22.6303 13.7683 22.5755 13.8601 22.4981 13.9323L19.686 16.5789L20.3506 20.3164C20.3688 20.4186 20.3571 20.5238 20.3167 20.6198C20.2762 20.7159 20.2087 20.7991 20.1218 20.8601C20.0349 20.921 19.932 20.9571 19.8249 20.9644C19.7178 20.9717 19.6107 20.9499 19.5158 20.9014L16.0408 19.1354L12.5658 20.8998C12.4709 20.9483 12.3638 20.9702 12.2567 20.9628C12.1496 20.9555 12.0467 20.9194 11.9598 20.8585C11.8729 20.7976 11.8054 20.7143 11.7649 20.6183C11.7245 20.5222 11.7128 20.4171 11.731 20.3149L12.3956 16.5773L9.58352 13.9307C9.50611 13.8585 9.45127 13.7667 9.42524 13.6658C9.39922 13.5649 9.40307 13.459 9.43634 13.3601C9.46961 13.2612 9.53096 13.1734 9.61342 13.1065C9.69587 13.0397 9.79611 12.9966 9.9027 12.9821L13.7885 12.4367L15.5252 9.0359C15.5727 8.94291 15.6463 8.86464 15.7375 8.80997C15.8287 8.75529 15.934 8.7264 16.0413 8.72656C16.1487 8.72673 16.2538 8.75594 16.3449 8.8109C16.4359 8.86585 16.5092 8.94435 16.5564 9.03749Z'
        fill='url(#paint6_linear_7848_469834)'
      />
      <path
        opacity='0.3'
        d='M12.2998 16.8652L11.6877 20.3078C11.673 20.3927 11.6786 20.4798 11.704 20.5624L12.3588 16.9143L12.2998 16.8652Z'
        fill='#FDF356'
      />
      <path
        d='M9.90018 13.3284L14.0233 12.7829L15.7617 9.38148C15.9712 8.96875 16.1054 8.96875 16.3149 9.38148L18.0532 12.7829L22.1764 13.3284C22.2827 13.3426 22.3827 13.3856 22.4648 13.4525C22.5468 13.5194 22.6074 13.6075 22.6396 13.7064C22.6658 13.6294 22.6742 13.5479 22.6643 13.4674C22.6544 13.387 22.6264 13.3096 22.5823 13.2408C22.5381 13.1719 22.4789 13.1132 22.4088 13.0688C22.3387 13.0244 22.2594 12.9954 22.1764 12.9837L18.2906 12.4382L16.5539 9.03675C16.5065 8.94367 16.4331 8.86527 16.342 8.81045C16.2508 8.75562 16.1456 8.72656 16.0383 8.72656C15.9309 8.72656 15.8257 8.75562 15.7346 8.81045C15.6434 8.86527 15.57 8.94367 15.5227 9.03675L13.786 12.4382L9.90018 12.9837C9.8172 12.9954 9.73787 13.0244 9.66775 13.0688C9.59763 13.1132 9.53842 13.1719 9.49428 13.2408C9.45013 13.3096 9.42213 13.387 9.41222 13.4674C9.40232 13.5479 9.41076 13.6294 9.43696 13.7064C9.46915 13.6075 9.52979 13.5194 9.6118 13.4525C9.69382 13.3856 9.79383 13.3426 9.90018 13.3284Z'
        fill='#FDF356'
      />
      <path
        opacity='0.3'
        d='M19.736 16.8652L19.6836 16.9143L20.3383 20.5624C20.3638 20.4798 20.3694 20.3927 20.3547 20.3078L19.736 16.8652Z'
        fill='#FDF356'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M16.5564 9.03749L18.2931 12.4382L22.1789 12.9837C22.2855 12.9982 22.3857 13.0413 22.4682 13.1081C22.5506 13.175 22.612 13.2628 22.6453 13.3617C22.6785 13.4606 22.6824 13.5665 22.6564 13.6674C22.6303 13.7683 22.5755 13.8601 22.4981 13.9323L19.686 16.5789L20.3506 20.3164C20.3688 20.4186 20.3571 20.5238 20.3167 20.6198C20.2762 20.7159 20.2087 20.7991 20.1218 20.8601C20.0349 20.921 19.932 20.9571 19.8249 20.9644C19.7178 20.9717 19.6107 20.9499 19.5158 20.9014L16.0408 19.1354L12.5658 20.8998C12.4709 20.9483 12.3638 20.9702 12.2567 20.9628C12.1496 20.9555 12.0467 20.9194 11.9598 20.8585C11.8729 20.7976 11.8054 20.7143 11.7649 20.6183C11.7245 20.5222 11.7128 20.4171 11.731 20.3149L12.3956 16.5773L9.58352 13.9307C9.50611 13.8585 9.45127 13.7667 9.42524 13.6658C9.39922 13.5649 9.40307 13.459 9.43634 13.3601C9.46961 13.2612 9.53096 13.1734 9.61342 13.1065C9.69587 13.0397 9.79611 12.9966 9.9027 12.9821L13.7885 12.4367L15.5252 9.0359C15.5727 8.94291 15.6463 8.86464 15.7375 8.80997C15.8287 8.75529 15.934 8.7264 16.0413 8.72656C16.1487 8.72673 16.2538 8.75594 16.3449 8.8109C16.4359 8.86585 16.5092 8.94435 16.5564 9.03749Z'
        fill='url(#paint7_radial_7848_469834)'
      />
      <path
        style={{ mixBlendMode: 'screen' }}
        d='M22.1351 12.9829L18.2492 12.4375L16.5126 9.03669C16.4652 8.94363 16.3918 8.86525 16.3007 8.81043C16.2095 8.75562 16.1043 8.72656 15.997 8.72656C15.8896 8.72656 15.7844 8.75562 15.6933 8.81043C15.6021 8.86525 15.5287 8.94363 15.4814 9.03669L13.7447 12.4375L9.85885 12.9829C9.76837 12.995 9.68226 13.0281 9.60787 13.0793C9.53348 13.1305 9.47302 13.1983 9.43164 13.277C9.92269 13.8714 10.1126 13.9774 10.9162 14.5228C13.3715 15.8651 19.5292 15.6295 21.2364 14.2936C21.8715 13.794 22.0991 13.7276 22.4837 13.1647C22.3941 13.0653 22.2702 13.0007 22.1351 12.9829Z'
        fill='url(#paint8_linear_7848_469834)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_7848_469834'
          x1='16.016'
          y1='0.00195312'
          x2='16.016'
          y2='30.5946'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8DF00' />
          <stop offset='0.14' stopColor='#FCE900' />
          <stop offset='0.3' stopColor='#FFEF00' />
          <stop offset='1' stopColor='#F3A700' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_7848_469834'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.8324 -2.08758) scale(12.6118 12.1817)'
        >
          <stop stopColor='#FFF77D' />
          <stop offset='1' />
        </radialGradient>
        <linearGradient
          id='paint2_linear_7848_469834'
          x1='15.9912'
          y1='28.0375'
          x2='15.9912'
          y2='5.84008'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFFAB0' />
          <stop offset='1' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_7848_469834'
          x1='15.9913'
          y1='27.1481'
          x2='15.9913'
          y2='-1.95989'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFE100' />
          <stop offset='0.17' stopColor='#FDD600' />
          <stop offset='0.7' stopColor='#F7B900' />
          <stop offset='1' stopColor='#F5AE00' />
        </linearGradient>
        <radialGradient
          id='paint4_radial_7848_469834'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(16.0856 5.99759) scale(22.5048 21.7374)'
        >
          <stop offset='0.31' stopColor='#FFDF00' />
          <stop offset='1' stopColor='#F3A700' />
        </radialGradient>
        <linearGradient
          id='paint5_linear_7848_469834'
          x1='15.9894'
          y1='21.595'
          x2='15.9894'
          y2='9.00227'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EE9B00' />
          <stop offset='0.61' stopColor='#F3B500' />
          <stop offset='1' stopColor='#F5C000' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_7848_469834'
          x1='16.0408'
          y1='8.7276'
          x2='16.0408'
          y2='30.0966'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFEF00' />
          <stop offset='0.73' stopColor='#FAC800' />
          <stop offset='1' stopColor='#F7CF00' />
        </linearGradient>
        <radialGradient
          id='paint7_radial_7848_469834'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(15.9213 6.20115) scale(11.1926 10.811)'
        >
          <stop stopColor='#FFF66E' />
          <stop offset='1' />
        </radialGradient>
        <linearGradient
          id='paint8_linear_7848_469834'
          x1='15.9577'
          y1='20.8342'
          x2='15.9577'
          y2='13.2343'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFFAB0' />
          <stop offset='1' />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
