import { FC } from 'react';
import {
  ListItemDecorator as JoyListItemDecorator,
  ListItemDecoratorProps as JoyListItemDecoratorProps
} from '@mui/joy';

export type ListItemDecoratorProps = JoyListItemDecoratorProps;

export const ListItemDecorator: FC<ListItemDecoratorProps> = ({ ...props }) => (
  <JoyListItemDecorator {...props} />
);