import { mapper } from '@shared/lib';
import { type SocialMedia } from '../model';

type MapSocialMedia = (data: SocialMedia) => SocialMedia;
export const mapSocialMedia: MapSocialMedia = mapper([
  { name: 'href', from: 'href' },
  {
    name: 'icon',
    from: 'iconSrc.data.attributes.url',
  }
]);