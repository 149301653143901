import {
  type TextWithInlineLink,
  mapTextWithInlineLink,
  mapper,
  isEmpty,
  mapIconLink,
} from '@shared/lib';
import {
  type ContactUsContent,
  type BoxedContactBlockItem
} from '../model';

type MapBoxedContactBlockItem = (data: any) => BoxedContactBlockItem;
const mapBoxedContactUsBlockItem: MapBoxedContactBlockItem = mapper([
  { name: 'title', from: 'title' },
  { name: 'iconSrc', from: 'iconSrc.data.attributes.url' },
  {
    name: 'description',
    from: 'description',
    transform: (detailsData: TextWithInlineLink) => {
      const details = mapTextWithInlineLink(detailsData);
      return details;
    },
  },
  {
    name: 'icons',
    from: 'icons',
    transform: (icons: Maybe<Array<any>>) => {
      if (!Array.isArray(icons) || isEmpty(icons)) return [];
      return icons.map((iconData: any) => mapIconLink(iconData));
    },
  },
]);

export type MapContactUsContent = (data: any) => ContactUsContent;
export const mapContactUsContent: MapContactUsContent = mapper([
  {
    name: 'boxedContactUsBlock',
    from: 'content',
    transform: (contentData: Array<{ boxedContactUsTitle: any; boxedContactUsBlock: Array<any> }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = content?.boxedContactUsTitle;
      const items = content?.boxedContactUsBlock?.map((boxedContactBlockItemData: any) =>
        mapBoxedContactUsBlockItem(boxedContactBlockItemData)
      );
      return { title, items };
    },
  },
  {
    name: 'contactUsHtmlBlock',
    from: 'content',
    transform: (contentData: Array<{ htmlBlockTitle: string; htmlBlock: string }>) => {
      const content = contentData?.[0];
      if (isEmpty(content)) return null;
      const title = content?.htmlBlockTitle;
      const html = content?.htmlBlock;
      return { html, title };
    },
  },
]);