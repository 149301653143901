import { FC } from 'react'
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography } from '@mui/joy';
import { emailSchema, useModalQueryParam } from '@shared/lib';
import { Button, TextInput } from '@shared/ui';
import { OmegaApiResponseStatusEnum } from '@shared/api';
import { ResetPasswordModalsQueries, useLazySendResetPasswordOTPQuery } from '../api';
import { setEmail } from '../model';

export const EmailForm: FC = () => {
  const dispatch = useDispatch()
  const { toggleModal } = useModalQueryParam()
  const [sendOpt, { isFetching, isError, data }] = useLazySendResetPasswordOTPQuery();

  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(emailSchema),
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: { email: '' }
  })

  const handleFormSubmit = async (
    formData: { email: Email; },
  ) => {
    dispatch(setEmail(formData.email))
    const { isSuccess, data } = await sendOpt({ email: formData.email })
    if (isSuccess && data.status === OmegaApiResponseStatusEnum.Success) {
      toggleModal(ResetPasswordModalsQueries.SentResetPassword, ResetPasswordModalsQueries.ConfirmResetPassword)
    }
  }

  return (
    <Stack
      component='form'
      gap='0.5rem'>
      {!isFetching && ((data && data?.status !== OmegaApiResponseStatusEnum.Success) || isError) && (
        <Typography
          sx={({ palette }) => ({
            color: palette.common.error,
          })}>
          Something went went!
        </Typography>
      )}
      <Controller
        control={control}
        name='email'
        render={({ field: { value, onBlur, onChange }, fieldState }) => (
          <TextInput
            label='Email'
            placeholder='Enter your email'
            disabled={isFetching}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      <Button
        loading={isFetching}
        fullWidth
        type='submit'
        onClick={handleSubmit(handleFormSubmit)}
        disabled={
          !formState.isDirty ||
          !formState.isValid ||
          isFetching
        }>
        Continue
      </Button>
    </Stack>
  );
}