import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { CredentialResponse } from '@react-oauth/google';
import {
  CurrencyCodesEnum,
  UserRegistrationStatusEnum
} from '@shared/types';
import {
  isDefined,
  isEmpty,
  useModalQueryParam
} from '@shared/lib';
import {
  OmegaApiResponse,
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  mapLogin,
  setSignedInData
} from '@entities/session';
import {
  GoogleLoginButton
} from './google-login-button.component';
import {
  useLazySocialLoginQuery
} from '../api';
import {
  SocialLogin,
  setPropsToAuthViaSocials,
  openConfirmationAuthViaSocialsModal,
  removePropsToAuthViaSocials
} from '../model';


export type GoogleSignUpButtonProps = {
  trackingCodes?: Maybe<string>;
  consentIds: Maybe<Array<number>>;
  onConfirmConsents?(
    completeAuthenticationCallback: () => Promise<void>
  ): void;
  onError?(error: Maybe<string>): void;
  onSocialSignUp(method: string): void;
};

export const GoogleSignUpButton: FC<GoogleSignUpButtonProps> = ({
  trackingCodes,
  consentIds,
  onConfirmConsents,
  onError,
  onSocialSignUp
}) => {
  const dispatch = useDispatch();
  const { closeModal } = useModalQueryParam();
  const [socialLogin] = useLazySocialLoginQuery();

  const authenticate = async (propsToSignUp: SocialLogin): Promise<void> => {
    const { data } = await socialLogin(propsToSignUp);
    const { status, registrationStatus } = data as OmegaApiResponse & {
      registrationStatus?: UserRegistrationStatusEnum
    };

    if (registrationStatus === UserRegistrationStatusEnum.QuickReg) {
      const signedInData = mapLogin(data);
      dispatch(setSignedInData(signedInData));
      dispatch(removePropsToAuthViaSocials());
      closeModal('sign-up');
      return;
    }

    if (status !== OmegaApiResponseStatusEnum.Success) {
      return onError?.(data?.message ?? 'Something went wrong');
    }

    const dataWithEmailFromOmega = data as unknown as { email: Email };
    dispatch(setPropsToAuthViaSocials({
      ...propsToSignUp,
      email: dataWithEmailFromOmega.email
    }));
    dispatch(openConfirmationAuthViaSocialsModal());
    closeModal('sign-up');
  };

  const handleAuthenticate = async (
    credentialResponse: CredentialResponse
  ): Promise<void> => {
    onError?.(null);
    onSocialSignUp('google');
    const propsToSignUp: SocialLogin = {
      socialType: 'google',
      idToken: credentialResponse?.credential,
      currency: CurrencyCodesEnum.SCO,
      secondaryCurrencies: CurrencyCodesEnum.GCO,
      ...(trackingCodes && ({
        extraInfo: trackingCodes
      })),
      ...(consentIds && ({
        consentedVersions: consentIds
      }))
    };

    if (isDefined(consentIds) && !isEmpty(consentIds)) {
      onConfirmConsents?.(
        async () => await authenticate(propsToSignUp)
      );
    } else {
      await authenticate(propsToSignUp);
    }
  };

  return (
    <GoogleLoginButton
      locale='en_US'
      shape='rectangular'
      text='signin_with'
      size='medium'
      onSuccess={handleAuthenticate}
    />
  );
};