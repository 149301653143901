import { FC, SyntheticEvent } from 'react';
import Snackbar, { SnackbarProps, SnackbarCloseReason } from '@mui/joy/Snackbar';
import { Close } from '@mui/icons-material';
import { IconButton } from '@shared/ui';
import { ToastColor } from '../lib';
import { ToastIcon } from './toast-icon';

export type ToastSnackbarProps = SnackbarProps & {
  message: string;
  onClose: () => void;
  color: ToastColor;
}

export const ToastSnackbar: FC<ToastSnackbarProps> = ({ color, message, onClose, ...props }) => {

  const handleClose = (_: Event | SyntheticEvent<any, Event> | null, reason: SnackbarCloseReason): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      color={color}
      onClose={handleClose}
      startDecorator={<ToastIcon type={color} />}
      endDecorator={<IconButton variant='plain' onClick={onClose} sx={{
        ml: 'auto',
        color: 'inherit',
        '--Icon-color': 'unset',
        "&:hover": {
          backgroundColor: 'transparent',
          color: 'inherit',
        }
      }}
      >
        <Close />
      </IconButton>}
      {...props}
    >
      {message}
    </Snackbar>
  );
};
