import { baseApi } from '@shared/api';
import { ChallengesPageState } from '../model';
import { mapChallengesPageState } from '../lib';

export const challengesPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getChallengesPage: build.query<Partial<ChallengesPageState>, void>({
      query: () => ({ url: 'api/challenges-page' }),
      transformResponse: (response: any) => mapChallengesPageState(response),
    }),
  }),
});

export const { useGetChallengesPageQuery } = challengesPageApiSlice;
