import { baseApi } from '@shared/api';
import { LuxeClubPageState } from '../model';
import { mapLuxeClubPageState } from '../lib';

export const luxeClubPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLuxeClubPage: build.query<LuxeClubPageState, void>({
      query: () => ({ url: '/api/luxe-club-page' }),
      extraOptions: { showLoader: true },
      transformResponse: mapLuxeClubPageState,
    })
  })
})

export const { useGetLuxeClubPageQuery } = luxeClubPageApiSlice;