import { FC } from 'react';
import { Stack } from '@mui/joy';
import { GameItemLabelEnum } from '../model';

export type GameLabelProps = {
  type: GameItemLabelEnum;
};

export const GameLabel: FC<GameLabelProps> = ({ type }) => {
  return (
    <Stack
      sx={(theme) => ({
        padding: '0.25rem 1.25rem',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '0.5rem 0rem',
        userSelect: 'none',
        color: theme.colorSchemes.dark.palette.common.white,
        fontSize: '0.75rem',
        fontWeight: 500,
        lineHeight: '18px',
        ...(type === GameItemLabelEnum.Top && {
          background: theme.colorSchemes.dark.palette.common[1325],
        }),
        ...(type === GameItemLabelEnum.Hot && {
          background: theme.colorSchemes.dark.palette.common[1060],
        }),
        ...(type === GameItemLabelEnum.New && {
          background: theme.colorSchemes.dark.palette.common[800],
        }),
      })}
    >
      {type}
    </Stack>
  );
};
