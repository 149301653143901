import { baseApi, DYNAMIC_PAGE_TAG } from '@shared/api';
import { buildStrapiQuery, type Filter } from '@shared/lib';
import { DynamicPageState } from '../model';
import { mapDynamicPageState } from '../lib';

export const dynamicPageApiSlice = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDynamicPage: build.query<DynamicPageState, Filter>({
      query: (filter: Filter) => ({
        url: `api/dynamic-pages?${buildStrapiQuery(filter)}`
      }),
      extraOptions: { showLoader: true },
      providesTags: [DYNAMIC_PAGE_TAG],
      transformResponse: (response: { data: unknown[] }) =>
        mapDynamicPageState(response.data[0]),
    })
  }),
});

export const {
  useGetDynamicPageQuery
} = dynamicPageApiSlice;
