export type GetFileSizeOptions = {
  returnValueIfEmpty: string;
};

export const getFileSize = (
  file: Maybe<File>,
  options: GetFileSizeOptions
): string => {
  if (!file) return options.returnValueIfEmpty;
  return `${(file.size / 1024).toFixed(0)} KB`;
};