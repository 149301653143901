import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/joy';

export const FullScreenOffIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.37383 1.0222C5.98909 1.0222 5.67734 1.33404 5.67734 1.71869L5.67734 4.68832L1.1915 0.203411C1.05545 0.0674568 0.877244 -0.000519688 0.699036 -0.000519672C0.520735 -0.000519657 0.342434 0.0674573 0.20648 0.203505C-0.0655213 0.475506 -0.0655213 0.916521 0.206573 1.18843L4.6926 5.67343L1.72231 5.67343C1.33767 5.67343 1.02583 5.98527 1.02583 6.36992C1.02583 6.75457 1.33758 7.06641 1.72231 7.06641L6.37383 7.06641C6.75856 7.06641 7.07031 6.75457 7.07031 6.36992L7.07031 1.71859C7.07031 1.33404 6.75856 1.0222 6.37383 1.0222Z'
        fill='#848587'
      />
      <path
        d='M13.4906 18.8344C13.8753 18.8344 14.1871 18.5225 14.1871 18.1379L14.1871 15.1682L18.811 19.7921C19.0831 20.0641 19.524 20.0641 19.796 19.7921C20.068 19.5201 20.068 19.0791 19.796 18.8072L15.1722 14.1833L18.1417 14.1833C18.5264 14.1833 18.8382 13.8715 18.8382 13.4868C18.8382 13.1022 18.5264 12.7904 18.1417 12.7904L13.4905 12.7904C13.1057 12.7904 12.794 13.1022 12.794 13.4868L12.794 18.1379C12.7941 18.5225 13.1059 18.8344 13.4906 18.8344Z'
        fill='#848587'
      />
      <path
        d='M1.19059 19.7921L5.81406 15.1682L5.81406 18.1379C5.81406 18.5225 6.12581 18.8344 6.51054 18.8344C6.89528 18.8344 7.20703 18.5225 7.20703 18.1379L7.20703 13.4866C7.20703 13.102 6.89528 12.7902 6.51054 12.7902L1.8595 12.7902C1.47485 12.7902 1.16301 13.102 1.16301 13.4866C1.16301 13.8713 1.47476 14.1831 1.8595 14.1831L4.82913 14.1831L0.205574 18.8072C-0.0664268 19.0792 -0.0664268 19.5202 0.205667 19.7922C0.477483 20.0641 0.918499 20.0641 1.19059 19.7921Z'
        fill='#848587'
      />
      <path
        d='M13.6332 7.06641L18.2845 7.06641C18.6692 7.06641 18.9809 6.75457 18.9809 6.36992C18.9809 5.98527 18.6692 5.67343 18.2845 5.67343L15.3147 5.67343L19.7999 1.18796C20.0719 0.915963 20.0719 0.474948 19.7998 0.202947C19.6639 0.0669923 19.4856 -0.0009851 19.3074 -0.000985085C19.1292 -0.000985069 18.9509 0.0670858 18.8148 0.20304L14.3296 4.68851L14.3296 1.71869C14.3296 1.33404 14.0179 1.0222 13.6331 1.0222C13.2484 1.0222 12.9366 1.33404 12.9366 1.71869L12.9366 6.36992C12.9367 6.75466 13.2486 7.06641 13.6332 7.06641Z'
        fill='#848587'
      />
    </SvgIcon>
  );
};
