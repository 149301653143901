import { FC, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { withProviders } from './providers';
import { appRouter } from './app-router';

export const AppEntry: FC = withProviders(() => {
  return (
    <Suspense>
      <RouterProvider router={appRouter} />
    </Suspense>
  );
});
