
import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function useSpecialLinkHandler() {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback((
    href: Maybe<string>,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => void) => {

    if (href) {
      const url = new URL(href, window.location.origin);
      const hasSpecialParam = [
        'login',
        'buy',
        'redeem',
        'sign-up',
        'chat'
      ].some(param => url.searchParams.has(param));

      if (hasSpecialParam) {
        event.preventDefault();
        const newPath = `${location.pathname}${url.search}`;
        navigate(newPath, { replace: true });
      } else if (href && href.startsWith('/')) {
        event.preventDefault();
        navigate(href, { relative: 'path' });
      }
    }

    onClick?.(event);
  }, [navigate, location]);
}
