import { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Modal,
  ModalDialog,
  Sheet,
  Stack,
  Typography,
} from '@mui/joy';
import {
  Button,
  IconButton,
  CloseIcon,
  CheckCircleIcon
} from '@shared/ui';
import {
  OmegaApiResponseStatusEnum
} from '@shared/api';
import {
  setSignedInData,
  mapLogin,
} from '@entities/session';
import {
  useAuthViaSocialsSliceSelector,
  selectIsOpenSuccessConfirmationAuthViaSocialsModal,
  selectPropsToAuthViaSocials,
  closeSuccessConfirmationAuthViaSocialsModal,
  removePropsToAuthViaSocials,
} from '../model';
import { useLazySocialLoginQuery } from '../api';

export const SuccessConfirmationAuthViaSocialsModal: FC = () => {
  const dispatch = useDispatch();
  const [socialLogin, { isFetching }] = useLazySocialLoginQuery();

  const propsToSocialLogin = useAuthViaSocialsSliceSelector(selectPropsToAuthViaSocials);
  const isOpenSuccessConfirmationSignUpModal = useAuthViaSocialsSliceSelector(
    selectIsOpenSuccessConfirmationAuthViaSocialsModal
  );

  const signInAfterSignUp = async (): Promise<void> => {
    if (propsToSocialLogin) {
      const { email, ...props } = propsToSocialLogin;

      const { data } = await socialLogin(props);
      if (data?.status === OmegaApiResponseStatusEnum.Success) {
        const signedInData = mapLogin(data);
        dispatch(setSignedInData(signedInData));
        dispatch(removePropsToAuthViaSocials());
        dispatch(closeSuccessConfirmationAuthViaSocialsModal());
      }
    }
  };

  const handleClose = (): void => {
    if (!isFetching) signInAfterSignUp();
  };

  const handlePlayNow = (): void => {
    signInAfterSignUp();
  };

  return (
    <Modal open={isOpenSuccessConfirmationSignUpModal}>
      <ModalDialog layout='center'>
        <Stack
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          gap={2}
          sx={{
            maxHeight: 384,
            maxWidth: 358,
            paddingBlock: 3,
            paddingInline: 3
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              gap={1}>
              <Sheet sx={({ colorSchemes }) => ({
                background: colorSchemes.dark.palette.common[700],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '45%',
                height: 32,
                width: 32
              })}>
                <CheckCircleIcon sx={({ colorSchemes }) => ({
                  fontSize: 16,
                  color: colorSchemes.dark.palette.common[700]
                })} />
              </Sheet>
              <Typography sx={({ colorSchemes }) => ({
                color: colorSchemes.dark.palette.common[700],
                fontSize: 20,
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '150%',
              })}>
                Success!
              </Typography>
            </Stack>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Typography sx={({ colorSchemes }) => ({
            color: colorSchemes.dark.palette.common[150],
            textAlign: 'left',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%'
          })}>
            Your account is now successfully verified
            and has been awarded with:
          </Typography>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            gap={1}
            sx={({ colorSchemes }) => ({
              background: colorSchemes.dark.palette.common[475],
              borderColor: colorSchemes.dark.palette.common[925],
              borderStyle: 'solid',
              borderWidth: 1,
              width: '100%',
              paddingBlock: 1,
              paddingInline: 2,
              borderRadius: 8,
            })}>
            <Box
              width={22}
              height={28}
              component='img'
              loading='lazy'
              src='/assets/webp/sign_up_bonus.webp'
            />
            <Typography sx={({ colorSchemes }) => ({
              color: colorSchemes.dark.palette.common.white,
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: ' 20px',
              '& span': {
                color: colorSchemes.dark.palette.common[1075]
              }
            })}>
              5,000 GC
            </Typography>
          </Stack>
          <Stack
            direction='row'
            width='100%'
            sx={{
              '& .MuiButton-root': {
                width: '100%'
              }
            }}>
            <Button
              disabled={isFetching}
              color='primary'
              variant='solid'
              onClick={handlePlayNow}>
              Play now
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};