import {
  PAYMENT_INFO_TAG,
  baseOmegaApi
} from '@shared/api';
import {
  GetPaymentInfoParams,
  GetPaymentInfoResponse,
  type UpdatePaymentParams,
  type UpdatePaymentResponse,
} from './types';

export const paymentsApiSlice = baseOmegaApi.injectEndpoints({
  endpoints: (build) => ({
    getPaymentInfo: build.query<GetPaymentInfoResponse, GetPaymentInfoParams>({
      query: (params) => ({ url: 'ips/getPaymentInformation', params }),
      extraOptions: { auth: true, showLoader: true }
    }),
    updatePayment: build.query<UpdatePaymentResponse, UpdatePaymentParams>({
      query: (params) => ({ url: 'ips/updatePayment', params }),
      providesTags: (result) => result ? [PAYMENT_INFO_TAG] : [],
      extraOptions: { auth: true, showLoader: true }
    })
  }),
});

export const {
  useLazyUpdatePaymentQuery,
  useUpdatePaymentQuery,
  useLazyGetPaymentInfoQuery,
  useGetPaymentInfoQuery,
} = paymentsApiSlice;
