import { FC, useState } from 'react';
import { Button, Stack, Typography, StackProps } from '@mui/joy';
import { ChangePasswordModal } from './change-password-modal.component';

export type ChangePasswordCardProps = Maybe<{
  inputProps?: Maybe<{
    root?: StackProps
  }>
}>;

export const ChangePasswordCard: FC<ChangePasswordCardProps> = (props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <Stack
      direction='column'
      gap={1.25}
      sx={[
        ({ palette, breakpoints }) => ({
          paddingInline: 2,
          paddingBlockStart: 2,
          borderRadius: 8,
          width: 426.5,
          background: palette.common[475],
          [breakpoints.down(768)]: {
            width: '100%'
          }
        }),
        ...(props?.inputProps?.root && Array.isArray(props?.inputProps?.root?.sx)
          ? props?.inputProps?.root?.sx
          : [props?.inputProps?.root?.sx])
      ]}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'>
        <Typography
          sx={({ palette }) => ({
            fontSize: 20,
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '30px',
            color: palette.common.white
          })}>
          Password
        </Typography>
      </Stack>
      <Stack
        direction='column'
        gap={5.75}>
        <Typography
          sx={({ palette }) => ({
            maxHeight: 20,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common[150]
          })}>
          You can change your current password here.
        </Typography>
        <Button
          variant='solid'
          color='primary'
          size='md'
          onClick={handleOpenModal}>
          Change
        </Button>
        <ChangePasswordModal
          open={openModal}
          onClose={handleCloseModal}
        />
      </Stack>
    </Stack>
  );
};