import {
  PayloadAction,
  createSlice
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  type SocialLogin,
  type AuthViaSocialsState
} from './types';

const initialState: AuthViaSocialsState = {
  openConfirmationAuthViaSocialsModal: false,
  openSuccessConfirmationAuthViaSocialsModal: false,
  propsToAuthViaSocials: null
};

export const authViaSocialsSlice = createSlice({
  name: 'auth-via-socials',
  initialState,
  reducers: {
    openConfirmationAuthViaSocialsModal: (state: AuthViaSocialsState) => {
      state.openConfirmationAuthViaSocialsModal = true;
    },
    closeConfirmationAuthViaSocialsModal: (state: AuthViaSocialsState) => {
      state.openConfirmationAuthViaSocialsModal = false;
    },
    openSuccessConfirmationAuthViaSocialsModal: (state: AuthViaSocialsState) => {
      state.openSuccessConfirmationAuthViaSocialsModal = true;
    },
    closeSuccessConfirmationAuthViaSocialsModal: (state: AuthViaSocialsState) => {
      state.openSuccessConfirmationAuthViaSocialsModal = false;
    },
    setPropsToAuthViaSocials: (state: AuthViaSocialsState, action: PayloadAction<SocialLogin>) => {
      state.propsToAuthViaSocials = action.payload;
    },
    removePropsToAuthViaSocials: (state: AuthViaSocialsState) => {
      state.propsToAuthViaSocials = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState);
  }
});

export const {
  closeConfirmationAuthViaSocialsModal,
  closeSuccessConfirmationAuthViaSocialsModal,
  openConfirmationAuthViaSocialsModal,
  openSuccessConfirmationAuthViaSocialsModal,
  removePropsToAuthViaSocials,
  setPropsToAuthViaSocials
} = authViaSocialsSlice.actions;

export const authViaSocialsReducer = authViaSocialsSlice.reducer;
