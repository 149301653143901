import { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  IconButton,
  MenuIcon,
} from '@shared/ui';
import { openSidebarMenu } from '../model';

export const SidebarMenuButton: FC = () => {
  const dispatch = useDispatch();

  const handleToggleSidebarMenu = (): void => {
    dispatch(openSidebarMenu());
  };

  return (
    <IconButton
      size='sm'
      sx={{
        height: 'min-content'
      }}
      onClick={handleToggleSidebarMenu}>
      <MenuIcon
        sx={({ palette }) => ({
          fontSize: 20,
          color: palette.common[1065]
        })}
      />
    </IconButton>
  );
};