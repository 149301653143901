import { CurrentVIPLevelEnum } from '../model'

type MapLevelLoyalty = (data: any) => Maybe<CurrentVIPLevelEnum>
export const mapLevel: MapLevelLoyalty = (name: string) => {
  const VIPLevelMap: Record<string, CurrentVIPLevelEnum> = {
    'BRONZE': CurrentVIPLevelEnum.Level1,
    'SILVER': CurrentVIPLevelEnum.Level2,
    'GOLD': CurrentVIPLevelEnum.Level3,
    'RUBY': CurrentVIPLevelEnum.Level4,
    'EMERALD': CurrentVIPLevelEnum.Level5,
    'SAPPHIRE': CurrentVIPLevelEnum.Level6,
    'DIAMOND': CurrentVIPLevelEnum.Level7,
    'PLATINUM': CurrentVIPLevelEnum.Level8,
  };

  return VIPLevelMap[name];
}
