import {
  createSlice
} from '@reduxjs/toolkit';
import {
  resetStore
} from '@shared/store-reset';
import {
  NotificationsSidebarState
} from './types';

const initialState: NotificationsSidebarState = {
  isOpen: false,
};

export const notificationsSidebarSlice = createSlice({
  name: 'notifications-sidebar',
  initialState,
  reducers: {
    openNotificationsSidebar: (state: NotificationsSidebarState) => {
      state.isOpen = true;
    },
    closeNotificationsSidebar: (state: NotificationsSidebarState) => {
      state.isOpen = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore, () => initialState)
  }
});

export const notificationsSidebarReducer = notificationsSidebarSlice.reducer;

export const { closeNotificationsSidebar, openNotificationsSidebar } = notificationsSidebarSlice.actions;
