import { FC, useState } from 'react';
import { Tooltip } from '@mui/joy';
import { InfoOutlined } from '@mui/icons-material';
import { IconButton } from '@shared/ui';

export const CoinRedemptionRulesInfo: FC = () => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const handleClose = (): void => {
    setOpenTooltip(false);
  };

  const handleOpen = (): void => {
    setOpenTooltip(true);
  };

  return (
    <Tooltip
      title='Amount of coins to be played through in a Sweepstakes Game at least once.'
      placement='bottom-start'
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      slotProps={{
        root: {
          open: openTooltip,
          sx: ({ zIndex, palette }) => ({
            borderRadius: 8,
            fontSize: 12,
            background: palette.common[925],
            boxShadow: 'none',
            paddingInline: 1.5,
            paddingBlock: 1,
            zIndex: zIndex.tooltip,
            maxWidth: 251
          })
        }
      }}>
      <IconButton
        size='sm'
        sx={{
          padding: 0,
          marginLeft: .6,
          borderRadius: 45,
          minHeight: 'min-content',
          minWidth: 'min-content',
        }}>
        <InfoOutlined sx={{ fontSize: 14 }} />
      </IconButton>
    </Tooltip>
  );
};