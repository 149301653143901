import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ResetPasswordState } from "./types"

const initialState: ResetPasswordState = {
  email: null,
  mobile: null,
  resetPasswordKey: null,
}

export const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers: {
    resetState: () => initialState,
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
      state.mobile = null
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.mobile = action.payload
      state.email = null
    },
    setResetPasswordKey: (state, action: PayloadAction<string>) => {
      state.resetPasswordKey = action.payload;
    }
  }
})

export const {
  resetState,
  setEmail,
  setPhone,
  setResetPasswordKey
} = resetPasswordSlice.actions

export const resetPasswordReducer = resetPasswordSlice.reducer;