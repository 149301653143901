import { ChangeEvent, FC } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Typography } from '@mui/joy';
import { useToaster } from '@shared/ui';
import { 
  selectSessionKey, 
  useGetConsentsQuery, 
  useLazySaveConsentsQuery, 
  useSessionSelector 
} from '@entities/session';
import { 
  selectSmsPromotionSubscription, 
  setSmsPromotionSubscription, 
  usePromotionSubscriptionSelector 
} from '../model';

export const SmsSubscriptionSwitcher: FC = () => {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const sessionKey = useSessionSelector(selectSessionKey);
  const { isFetching: isFetchingState } = useGetConsentsQuery(sessionKey);
  const [saveConsents, { isFetching }] = useLazySaveConsentsQuery();
  const smsPromotionalSubscription = usePromotionSubscriptionSelector(selectSmsPromotionSubscription);
  
  const handleUpdateSubscription = async (event: ChangeEvent<HTMLInputElement>) => {
    const isSubscribed = event.target.checked;
    const consents = isSubscribed 
    ? { consentedVersions: [14], unConsentedVersions: [] } 
    : { unConsentedVersions: [14], consentedVersions: [] };
    const { isSuccess } = await saveConsents({ ...consents, sessionKey });
    if (isSuccess) {
      dispatch(setSmsPromotionSubscription(isSubscribed));
      toaster.success({ message: 'Sms subscription was updated' });
    }
  };

  return (
    <Switch
      onChange={handleUpdateSubscription}
      disabled={isFetchingState || isFetching}
      checked={smsPromotionalSubscription}
      slotProps={{
        root: {
          sx: {
            alignItems: 'flex-start',
            alignSelf: 'flex-start'
          }
        }
      }}
      endDecorator={(
        <Typography
          sx={({ palette }) => ({
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            color: palette.common[150]
          })}>
          I give consent to receive SMS messages. Message and data rates may apply. Reply STOP to opt out
        </Typography>
      )}
    />
  );
};