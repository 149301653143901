import { extendTheme } from '@mui/joy';

export const joyTheme = extendTheme({
  cssVarPrefix: 'joy',
  colorSchemes: {
    dark: {
      palette: {
        common: {
          50: '#EAEAEA',
          100: '#9C9C9C',
          125: '#CCC',
          137: '#B2B2B2',
          145: '#595961',
          150: '#959595',
          175: '#81848F',
          200: '#848587',
          250: '#1A36F8',
          275: '#1877F2',
          225: '#003EFF',
          300: '#465DFB',
          325: '#4193FF',
          350: '#2742FF',
          375: '#3C7AD8',
          400: '#35448A',
          450: '#212121',
          475: '#2A3648',
          485: '#344257',
          495: '#2A384D',
          500: '#354359',
          505: '#202C3D',
          515: '#565B63',
          550: '#172131',
          565: '#111823',
          575: '#1D293B',
          580: '#223044',
          600: '#1C293C',
          650: '#D1D1D1',
          675: '#D1FFCB',
          700: '#17B356',
          750: '#18CE61',
          800: '#09B04C',
          825: '#09B438',
          845: '#216319',
          850: '#277546',
          855: '#355431',
          875: '#1F3BFF',
          900: '#172232',
          910: '#111823',
          925: '#3E414F',
          950: '#464D5B',
          1000: '#242C38',
          1050: '#6C7076',
          1060: '#EAC33B',
          1065: '#FCD329',
          1075: '#FDCC06',
          1085: '#FFE929',
          1090: '#F7B616',
          1100: '#FFFF',
          1150: '#44546C',
          1200: '#3B4F6E',
          1250: '#3849A1',
          1300: '#949AA3',
          1315: '#9E0000',
          1325: '#FB082F',
          1350: '#FFE2E3',
          white: '#FFF',
          black: '#212121',
          error: '#D3232F',
        },
        primary: {
          solidColor: 'var(--joy-palette-common-white)',
          solidBg: 'var(--joy-palette-common-300)',
          solidHoverBg: 'var(--joy-palette-common-350)',
          solidActiveBg: 'var(--joy-palette-common-875)',
          solidDisabledBg: 'var(--joy-palette-common-1250)',
          solidDisabledColor: 'var(--joy-palette-common-1300)',
        },
        neutral: {
          solidColor: 'var(--joy-palette-common-white)',
          solidBg: 'var(--joy-palette-common-900)',
          solidHoverBg: 'var(--joy-palette-common-600)',
          solidActiveBg: 'var(--joy-palette-common-550)',
          solidDisabledBg: 'var(--joy-palette-common-1000)',
          solidDisabledColor: 'var(--joy-palette-common-1050)',
        },
        success: {
          solidColor: 'var(--joy-palette-common-white)',
          solidBg: 'var(--joy-palette-common-700)',
          solidHoverBg: 'var(--joy-palette-common-750)',
          solidActiveBg: 'var(--joy-palette-common-800)',
          solidDisabledBg: 'var(--joy-palette-common-850)',
          solidDisabledColor: 'var(--joy-palette-common-650)',
        },
        text: {
          icon: 'var(--joy-palette-common-150)',
          primary: 'var(--joy-palette-common-white)',
        },
        background: {
          body: 'var(--joy-palette-common-900)',
        },
        divider: 'var(--joy-palette-common-925)',
      },
    },
  },
  fontFamily: {
    body: 'Arial, Helvetica, sans-serif',
  },
  fontSize: {
    sm: '.875rem',
    md: '.875rem',
    lg: '.875rem',
    xl: '1rem',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 390,
      md: 678,
      lg: 1024,
      xl: 1440,
    },
  },
  zIndex: {
    badge: 1,
    table: 10,
    popup: 1000,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    JoyButton: {
      defaultProps: {
        size: 'lg',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          /**
           * Common
           */
          '&.MuiButton-fullWidth': {
            width: '100%',
          },
          borderRadius: '8px',

          /**
           * Sizing
           */
          ...(ownerState.size === 'sm' && {
            minHeight: 'var(--Button-minHeight, 2rem)',
            paddingBlock: '.25rem',
            paddingInline: '1rem',
            fontSize: 'theme.fontSize.sm',
            fontWeight: 'var(--joy-fontWeight-lg, 500)',
          }),
          ...(ownerState.size === 'md' && {
            minHeight: 'var(--Button-minHeight, 2.25rem)',
            paddingBlock: '.25rem',
            paddingInline: '1rem',
            fontSize: theme.fontSize.md,
            fontWeight: 'var(--joy-fontWeight-lg, 500)',
          }),
          ...(ownerState.size === 'lg' && {
            minHeight: 'var(--Button-minHeight, 2.5rem)',
            paddingBlock: '.25rem',
            paddingInline: '1rem',
            fontSize: theme.fontSize.lg,
            fontWeight: 'var(--joy-fontWeight-lg, 500)',
          }),
          ...(ownerState.size === 'xl' && {
            '--Icon-fontSize': '2rem',
            '--Button-gap': '1rem',
            minHeight: 'var(--Button-minHeight, 2.75rem)',
            paddingBlock: '0.625rem',
            paddingInline: '1.5rem',
            fontSize: theme.fontSize.xl,
            fontWeight: 'var(--joy-fontWeight-xl, 600)',
          }),

          /**
           * Variants & Colors
           * */
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'primary' && {
            boxShadow: '0px 3px 3px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 28px 0px rgba(96, 115, 249, 0.40)',
            ':disabled': {
              boxShadow: '0px 3px 3px 0px #5260A2 inset, 0px 3px 28px 0px rgba(96, 115, 249, 0.20)',
            },
          }),
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'success' && {
            boxShadow: '0px 4px 2px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 16px 0px rgba(18, 197, 126, 0.50)',
            ':disabled': {
              boxShadow: '0px 4px 2px 0px rgba(255, 255, 255, 0.10) inset, 0px 3px 6px 0px rgba(18, 197, 126, 0.30)',
            },
          }),

          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            border: `1px solid var(--Primary-Colors-Primary, ${theme.palette.common[300]})`,
            background: `var(--Neutral-Colors-Surface-2, ${theme.palette.common[475]})`,
            boxShadow: '0px 3px 3px 0px rgba(39, 26, 254, 0.50) inset, 0px 3px 28px 0px rgba(96, 115, 249, 0.40)',
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'success' && {
            border: `1px solid var(--Primary-Colors-Secondary, ${theme.palette.common[700]})`,
            background: `var(--Neutral-Colors-Surface-2, ${theme.palette.common[475]})`,
            boxShadow: '0px 4px 3px 0px rgba(255, 255, 255, 0.30) inset, 0px 3px 16px 0px rgba(18, 197, 126, 0.50)',
          }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'neutral' && {
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'rgba(0, 0, 0, 0)',
            ':hover': {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.common[600],
              background: theme.palette.common[600],
            },
            ':active': {
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.colorSchemes.dark.palette.common[550],
              background: theme.colorSchemes.dark.palette.common[550],
            },
          }),
        }),
      },
    },
    JoyIconButton: {
      defaultProps: {
        variant: 'plain',
        color: 'neutral',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          /**
           * Common
           */
          borderRadius: '8px',

          /**
           * Colors
           * */
          ...(ownerState.size === 'md' && {
            fontSize: '1.25rem',
            '--IconButton-size': '2rem',
          }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'neutral' && {
            color: theme.colorSchemes.dark.palette.text.icon,
            ':hover': {
              color: theme.colorSchemes.dark.palette.text.icon,
              backgroundColor: theme.colorSchemes.dark.palette.common[500],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.text.icon,
              backgroundColor: theme.colorSchemes.dark.palette.common[1200],
            },
            ':disabled': {
              color: theme.colorSchemes.dark.palette.common[1150],
            },
          }),
        }),
      },
    },
    JoyLink: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '28px',

          /**
           * Sizing
           */
          ...(ownerState.size === 'sm' && {
            fontSize: 14,
          }),
          ...(ownerState.size === 'md' && {
            fontSize: 16,
          }),
          ...(ownerState.size === 'lg' && {
            fontSize: 18,
          }),
          ...(ownerState.size === 'xl' && {
            fontSize: 22,
          }),
          /**
           * Colors
           * */
          ...(ownerState.color === 'primary' && {
            color: theme.colorSchemes.dark.palette.common[300],
            textDecorationColor: theme.colorSchemes.dark.palette.common[300],
            ':hover': {
              color: theme.colorSchemes.dark.palette.common[350],
              textDecorationColor: theme.colorSchemes.dark.palette.common[350],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.common[875],
              textDecorationColor: theme.colorSchemes.dark.palette.common[875],
            },
            '&.Mui-disabled': {
              color: theme.colorSchemes.dark.palette.common[1250],
              textDecorationColor: theme.colorSchemes.dark.palette.common[1250],
            },
          }),
          ...(ownerState.color === 'success' && {
            color: theme.colorSchemes.dark.palette.common[700],
            textDecorationColor: theme.colorSchemes.dark.palette.common[700],
            ':hover': {
              color: theme.colorSchemes.dark.palette.common[750],
              textDecorationColor: theme.colorSchemes.dark.palette.common[750],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.common[800],
              textDecorationColor: theme.colorSchemes.dark.palette.common[800],
            },
            '&.Mui-disabled': {
              color: theme.colorSchemes.dark.palette.common[850],
              textDecorationColor: theme.colorSchemes.dark.palette.common[850],
            },
          }),
          ...(ownerState.color === 'neutral' && {
            color: theme.colorSchemes.dark.palette.common[150],
            textDecorationColor: theme.colorSchemes.dark.palette.common[150],
            ':hover': {
              color: theme.colorSchemes.dark.palette.common[125],
              textDecorationColor: theme.colorSchemes.dark.palette.common[125],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.common[137],
              textDecorationColor: theme.colorSchemes.dark.palette.common[137],
            },
            '&.Mui-disabled': {
              color: theme.colorSchemes.dark.palette.common[145],
              textDecorationColor: theme.colorSchemes.dark.palette.common[145],
            },
          }),
        }),
      },
    },
    JoyCheckbox: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
      },
      styleOverrides: {
        checkbox: ({ ownerState, theme }) => ({
          /**
           * Common
           */
          borderRadius: '4px',

          /**
           * Variants & Colors
           **/
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            color: theme.colorSchemes.dark.palette.common[300],
            borderColor: theme.colorSchemes.dark.palette.common[300],
            backgroundColor: theme.colorSchemes.dark.palette.common[900],
            ':hover': {
              color: theme.colorSchemes.dark.palette.common[350],
              borderColor: theme.colorSchemes.dark.palette.common[350],
              backgroundColor: theme.colorSchemes.dark.palette.common[900],
            },
            ':active': {
              color: theme.colorSchemes.dark.palette.common[250],
              borderColor: theme.colorSchemes.dark.palette.common[250],
              backgroundColor: theme.colorSchemes.dark.palette.common[450],
            },
            '&.Mui-disabled': {
              color: 'var(--variant-plainDisabledColor, var(--joy-palette-common-400))',
              borderColor: 'var(--variant-plainDisabledColor, var(--joy-palette-common-400))',
            },
          }),
        }),
      },
    },
    JoySwitch: {
      defaultProps: {
        variant: 'outlined',
        color: 'primary',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ':hover': {
            ...(ownerState.checked === false && {
              '& .MuiSwitch-track': {
                borderColor: theme.colorSchemes.dark.palette.common[100],
                backgroundColor: theme.colorSchemes.dark.palette.common[100],
              },
            }),
          },
        }),
        track: ({ ownerState, theme }) => ({
          borderRadius: 90,

          /**
           * Variants & Colors
           **/
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            borderColor: theme.colorSchemes.dark.palette.common[200],
            backgroundColor: theme.colorSchemes.dark.palette.common[200],
            boxShadow: 'none',
            ...(ownerState.checked === true && {
              borderColor: theme.colorSchemes.dark.palette.common[300],
              backgroundColor: theme.colorSchemes.dark.palette.common[300],
            }),
            ...(ownerState.disabled === true && {
              borderColor: theme.colorSchemes.dark.palette.common[950],
              backgroundColor: theme.colorSchemes.dark.palette.common[950],
            }),
          }),
        }),
        thumb: ({ ownerState, theme }) => ({
          borderRadius: 90,

          /**
           * Variants & Colors
           **/
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
            borderColor: theme.colorSchemes.dark.palette.common[50],
            backgroundColor: theme.colorSchemes.dark.palette.common[50],
            boxShadow: 'none',
            ...(ownerState.disabled === true && {
              borderColor: theme.colorSchemes.dark.palette.common[150],
              backgroundColor: theme.colorSchemes.dark.palette.common[150],
            }),
          }),
        }),
      },
    },
    JoyListItemButton: {
      defaultProps: {
        color: 'neutral',
        variant: 'soft',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          /**
           * Variants & Colors
           * */
          '&.MuiListItemButton-root': {
            borderRadius: 8,
            ...(ownerState.variant === 'soft' &&
              ownerState.color === 'neutral' && {
              backgroundColor: theme.colorSchemes.dark.palette.common[900],
              ':hover': {
                backgroundColor: theme.colorSchemes.dark.palette.common[485],
              },
              ':active': {
                backgroundColor: theme.colorSchemes.dark.palette.common[495],
              },
              ':disabled': {
                backgroundColor: theme.colorSchemes.dark.palette.common[505],
              },
            }),
            ...(ownerState.variant === 'plain' &&
              ownerState.color === 'neutral' && {
              '&.MuiListItemButton-root': {
                ':hover': {
                  backgroundColor: theme.colorSchemes.dark.palette.common[485],
                },
                ':active': {
                  backgroundColor: theme.colorSchemes.dark.palette.common[495],
                },
                ':disabled': {
                  backgroundColor: theme.colorSchemes.dark.palette.common[505],
                },
              },
            }),
          },
        }),
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.colorSchemes.dark.palette.background.body,
        }),
      },
    },
    JoyAvatar: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'xs' && {
            height: 20,
            width: 20,
          }),
        }),
      },
    },
    JoyModal: {
      styleOverrides: {
        root: () => ({
          zIndex: 1300,
        }),
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.colorSchemes.dark.palette.background.body,
          boxShadow: `0px 0px 16px 0px ${theme.colorSchemes.dark.palette.common[300]}`,
          borderColor: theme.colorSchemes.dark.palette.common[925],
          borderWidth: 1,
          borderStyle: 'solid',
          padding: 0,
          borderRadius: 12,
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'neutral' && {
            padding: '0.4375rem 0.75rem',
            borderRadius: '0.5rem',
            border: '0.0625rem',
            lineHeight: '1.5rem',
            color: theme.colorSchemes.dark.palette.common.white,
            '::placeholder': {
              color: theme.colorSchemes.dark.palette.common[150],
            },
            borderColor: theme.colorSchemes.dark.palette.common[925],
            backgroundColor: theme.colorSchemes.dark.palette.common[475],
            boxShadow: 'none',
            borderStyle: 'solid',
            ...(!ownerState.error && {
              ':hover': {
                borderColor: theme.colorSchemes.dark.palette.common[925],
                boxShadow: `0px 0px 4px 0px ${theme.colorSchemes.dark.palette.common[225]}`,
              },
              '&.Mui-focused': {
                borderColor: theme.colorSchemes.dark.palette.common[650],
                boxShadow: 'none',
                '--Input-focusedHighlight': theme.colorSchemes.dark.palette.common[650],
              },
            }),
            '&.Mui-disabled': {
              borderColor: theme.colorSchemes.dark.palette.common[925],
              backgroundColor: theme.colorSchemes.dark.palette.common[505],
              color: theme.colorSchemes.dark.palette.common[515],
            },
            '&.Mui-error': {
              borderColor: `${theme.colorSchemes.dark.palette.common.error} !important`,
              color: theme.colorSchemes.dark.palette.common.error,
              '--Input-focusedHighlight': 'none',
              boxShadow: 'none',
            },
          }),
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderRadius: '0.5rem',
          border: '0.0625rem',
          lineHeight: '1.5rem',
          color: theme.colorSchemes.dark.palette.common.white,
          '::placeholder': {
            color: theme.colorSchemes.dark.palette.common[150],
          },
          borderColor: theme.colorSchemes.dark.palette.common[925],
          backgroundColor: theme.colorSchemes.dark.palette.common[475],
          boxShadow: 'none',
          borderStyle: 'solid',
          ...(!ownerState.error && {
            ':hover': {
              borderColor: theme.colorSchemes.dark.palette.common[925],
              boxShadow: `0px 0px 4px 0px ${theme.colorSchemes.dark.palette.common[225]}`,
            },
            '&.Mui-focused': {
              borderColor: theme.colorSchemes.dark.palette.common[650],
              boxShadow: 'none',
              '--Input-focusedHighlight': theme.colorSchemes.dark.palette.common[650],
            },
          }),
          '&.Mui-disabled': {
            borderColor: theme.colorSchemes.dark.palette.common[925],
            backgroundColor: theme.colorSchemes.dark.palette.common[505],
            color: theme.colorSchemes.dark.palette.common[515],
          },
          '&.Mui-error': {
            borderColor: `${theme.colorSchemes.dark.palette.common.error} !important`,
            color: theme.colorSchemes.dark.palette.common.error,
            '--Input-focusedHighlight': 'none',
            boxShadow: 'none',
          },
        })
      }
    },
    JoyTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '0.3125rem 0.5rem',
          borderRadius: '0.5625rem',
          color: theme.colorSchemes.dark.palette.common[150],
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: '1.3125rem',
          gap: '0.5rem',
          '&.Mui-selected ': {
            backgroundColor: theme.colorSchemes.dark.palette.common[300] + ' !important',
            color: theme.colorSchemes.dark.palette.common.white,
          },
          '&.Mui-disabled': {
            color: theme.colorSchemes.dark.palette.common[1200],
          },
          '&:not(.Mui-selected, [aria-selected="true"]):hover': {
            backgroundColor: theme.colorSchemes.dark.palette.common[500],
            color: theme.colorSchemes.dark.palette.common[150],
          },
          '&:not(.Mui-selected, [aria-selected="true"]):active': {
            backgroundColor: theme.colorSchemes.dark.palette.common[1200],
          },
        }),
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '0.75rem',
          border: `0.0625rem solid ${theme.colorSchemes.dark.palette.common[925]}`,
          '--joy-radius-xl': '0.75rem',
          backgroundColor: 'transparent',
          padding: '0.1875rem',
          gap: '3px',
        }),
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'transparent',
        }),
      },
    },
    JoyTabPanel: {
      styleOverrides: {
        root: () => ({
          padding: 0,
        }),
      },
    },
    JoySkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    JoySnackbar: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: ({ ownerState, theme: { palette } }) => ({
          zIndex: 1400,
          maxWidth: '22.375rem',
          ...(ownerState.variant === 'outlined' && {
            padding: '1rem',
            borderRadius: '0.25rem',
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.4375rem',
            wordBreak: 'break-word',
            ...(ownerState.color === 'danger' && {
              backgroundColor: palette.common[1350],
              boxShadow: '0rem 0rem 1.5rem 0rem rgba(255, 0, 0, 0.40)',
              color: palette.common[1315],
            }),
            ...(ownerState.color === 'success' && {
              backgroundColor: palette.common[675],
              boxShadow: `0rem 0rem 1.5rem 0rem ${palette.common[845]}`,
              color: palette.common[855],
            })
          }),
        }),
      },
    },
    JoyLinearProgress: {
      defaultProps: {
        variant: 'soft'
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'soft' && {
            backgroundColor: theme.palette.common[900],
            '--LinearProgress-radius': '0.75rem',
            padding: '0.375rem',
            '--_LinearProgress-padding': 'max((var(--LinearProgress-thickness) - 2* var(--variant-borderWidth, 0px) - var(--LinearProgress-progressThickness)) / 2, 2px);'
          })
        })
      }
    },
    JoyTable: {
      defaultProps: {
        variant: 'soft'
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.common[475],
          '--TableCell-headBackground': theme.palette.common[475],
          '& th:first-child': {
            borderTopLeftRadius: '8px !important',
          },
          '& th:last-child': {
            borderTopRightRadius: '8px !important',
          },
          '& th': {
            textAlign: 'left',
            borderColor: theme.palette.common[925],
            color: theme.palette.common[150],
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
          ...(ownerState.size === 'ml' && ({
            '& th': {
              height: 44,
              paddingBlock: '12px',
              paddingInline: '24px',
            },
            '& td': {
              height: 72,
              maxHeight: 72,
              verticalAlign: 'middle',
              paddingInlineStart: '24px',
              // paddingBlock: '16px',
            },
            '& tr': {
              minHeight: '72px',
            },
          }))
        }),
      },
    },
  },
});
