import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { promotionsPageApiSlice } from '../api';
import { PromotionsPageState } from './types';

const initialState: PromotionsPageState = {
  title: null,
  description: null,
  keywords: null,
  canonical: null,
  heading: null,
  promotionCards: null,
}

export const promotionsPageSlice = createSlice({
  name: 'promotions-page',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(promotionsPageApiSlice.endpoints.getPromotionsPage.matchFulfilled,
        (state: PromotionsPageState, action: PayloadAction<PromotionsPageState>) => {
          state.description = action.payload.title
          state.title = action.payload.description
          state.keywords = action.payload.keywords
          state.canonical = action.payload.canonical
          state.heading = action.payload.heading
        })
      .addMatcher(promotionsPageApiSlice.endpoints.getPromotionCards.matchFulfilled,
        (state: PromotionsPageState, action: PayloadAction<Pick<PromotionsPageState, 'promotionCards'>>) => {
          state.promotionCards = action.payload.promotionCards
        })
    
  }
})

export const promotionsPageReducer = promotionsPageSlice.reducer